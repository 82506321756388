import {
  DemographicsInput,
  UserRole,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { GetMember_getMember as Member } from '@headspace/carehub-graphql/dist/member-support/generated/GetMember';
import { NoteAction } from 'app/appointments/types';
import {
  AppointmentsTab,
  UILocation,
} from 'app/patients/tabs/appointments/AppointmentsTab';
import { AppointmentsTabSchedule } from 'app/patients/tabs/appointments/AppointmentsTabSchedule';
import DocumentUpload from 'app/patients/tabs/document-upload/DocumentUpload';
import { NotesTab } from 'app/patients/tabs/notes/NotesTab';
import { useLogger } from 'app/state/log/useLogger';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { SummaryTab } from './SummaryTab';

export enum Tabs {
  SUMMARY,
  APPOINTMENTS,
  APPOINTMENTS_SCHEDULE,
  NOTES,
  DOCUMENTS,
}

type TabContentProps = {
  activeTab: Tabs;
  member: Member;
  onUpdateDemographics: (demographics: Partial<DemographicsInput>) => void;
};

export function TabContent(props: TabContentProps) {
  const { activeTab, member, onUpdateDemographics } = props;
  const history = useHistory();
  const logger = useLogger();

  switch (activeTab) {
    case Tabs.SUMMARY:
      return (
        <SummaryTab
          member={member}
          onUpdateDemographics={onUpdateDemographics}
        />
      );
    case Tabs.APPOINTMENTS:
      return (
        <AppointmentsTab
          uiLocation={UILocation.MS}
          patientId={member.id}
          navigateTo={(url) => history.push(url)}
          signedLockedAction={NoteAction.DOWNLOAD}
        />
      );
    case Tabs.APPOINTMENTS_SCHEDULE:
      return (
        <AppointmentsTabSchedule
          patientId={member.id}
          navigateTo={(url) => history.push(url)}
          signedLockedAction={NoteAction.DOWNLOAD}
          role={UserRole.MEMBER_SUPPORT}
          memberTimezone={member.preferences?.timezone}
        />
      );
    case Tabs.NOTES:
      return (
        <NotesTab
          patientId={member.id}
          navigateTo={(url) => history.push(url)}
          signedLockedAction={NoteAction.DOWNLOAD}
          role={UserRole.MEMBER_SUPPORT}
        />
      );
    case Tabs.DOCUMENTS:
      return <DocumentUpload memberId={member.id} />;
    default:
      logger.error(new Error('TabContent: Unimplemented contents for tab'), {
        activeTab,
      });
      return (
        <SummaryTab
          member={member}
          onUpdateDemographics={onUpdateDemographics}
        />
      );
  }
}
