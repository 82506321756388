import {
  EscalateTeenToClinicalMutation,
  EscalateTeenToClinicalMutationVariables,
} from '@headspace/carehub-graphql/dist/care-team/generated/EscalateTeenToClinicalMutation';
import { escalateTeenToClinicalMutation } from '@headspace/carehub-graphql/dist/care-team/mutations';
import { EscalateTeenToClinicalMutationInput } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { useMutationWithGlobalState } from 'hooks/useMutationWithGlobalState';

export interface UseEscalateTeenMemberToClinicalHook {
  escalateTeenToClinical: (
    input: EscalateTeenToClinicalMutationInput,
  ) => Promise<void>;
}

export default function useEscalateTeenMemberToClinicalModal(): UseEscalateTeenMemberToClinicalHook {
  const [
    EscalateTeenMemberToClinicalModalMutationFn,
  ] = useMutationWithGlobalState<
    EscalateTeenToClinicalMutation,
    EscalateTeenToClinicalMutationVariables
  >(escalateTeenToClinicalMutation);

  const escalateTeenToClinical = async (
    input: EscalateTeenToClinicalMutationInput,
  ) => {
    const { data, errors } = await EscalateTeenMemberToClinicalModalMutationFn({
      input,
    });

    if (errors && errors.length > 0) {
      throw errors.map((error) => error.message).join(', ');
    }

    if (
      data?.escalateTeenToClinical?.exception &&
      data.escalateTeenToClinical.exception.length > 0
    ) {
      throw data?.escalateTeenToClinical?.exception;
    }

    if (data?.escalateTeenToClinical?.success) {
    } else {
      throw data?.escalateTeenToClinical?.exception;
    }
  };

  return { escalateTeenToClinical };
}
