import { Field } from '@ginger.io/react-use-form/dist/types';
import { secondsToReadableString } from 'app/notes-ui/utils';
import {
  tooltipComplete,
  tooltipNotReady,
  tooltipReady,
} from 'i18n/en/populateAppointmentDuration.json';
import React, { useEffect, useState } from 'react';

import PopulateAppointmentDurationButton from './PopulateAppointmentDurationButton';
import { useAppointmentDuration } from './useAppointmentDuration';

type Props = {
  field: Field<string>;
  appointmentId: string;
};

const PopulateAppointmentDurationContainer = (props: Props) => {
  const { field, appointmentId } = props;

  const [tooltipTitle, setTooltipTitle] = useState(tooltipNotReady);
  const [disabled, setDisabled] = useState(true);

  const duration = useAppointmentDuration({ appointmentId });

  useEffect(() => {
    if (duration) {
      setDisabled(false);
      setTooltipTitle(tooltipReady);
    }
  }, [duration]);

  const clickAppointmentDuration = () => {
    if (duration) {
      field.setValue(
        `${field.value}\n\n` +
          `Zoom Appointment Duration: ${secondsToReadableString(duration)}`,
      );
      setDisabled(true);
      setTooltipTitle(tooltipComplete);
    }
  };

  return (
    <PopulateAppointmentDurationButton
      disabled={disabled}
      onClick={clickAppointmentDuration}
      tooltipTitle={tooltipTitle}
    />
  );
};

export default PopulateAppointmentDurationContainer;
