import { ApolloCache } from '@apollo/client/cache';
import { KeyGenerator } from '@ginger.io/vault-core/dist/crypto';
import { Base64 } from '@ginger.io/vault-core/dist/crypto/Base64';
import {
  VaultItem,
  VaultItem_SchemaType as SchemaType,
} from '@ginger.io/vault-core/dist/generated/protobuf-schemas/vault-core/VaultItem';
import {
  getClinicalCareTeamGroupId,
  getCoachingTeamGroupId,
} from '@ginger.io/vault-core/dist/IdHelpers';
import {
  AdditionalDemographicInfo,
  AdditionalDemographicInfo_Version as Version,
} from '@ginger.io/vault-member-chart/dist/generated/protobuf-schemas/vault-member-chart/AdditionalDemographicInfo';
import { UpdateVaultItemInputParams } from '@ginger.io/vault-ui';
import {
  CreateMemberChartVaultItems,
  CreateMemberChartVaultItemsVariables,
} from '@headspace/carehub-graphql/dist/coach-member-chart/generated/CreateMemberChartVaultItems';
import {
  GetMemberChartVaultItemById,
  GetMemberChartVaultItemByIdVariables,
} from '@headspace/carehub-graphql/dist/coach-member-chart/generated/GetMemberChartVaultItemById';
import {
  UpdateMemberChartVaultItems,
  UpdateMemberChartVaultItemsVariables,
} from '@headspace/carehub-graphql/dist/coach-member-chart/generated/UpdateMemberChartVaultItems';
import {
  createVaultItems,
  getMemberChartVaultItemById,
  updateVaultItems,
} from '@headspace/carehub-graphql/dist/coach-member-chart/queries';
import {
  createMemberChartVaultItem,
  createVaultInputParams,
  mapPaginatedVaultItems,
  MemberChartMapQueries,
  updateMemberChartVaultItem,
} from 'app/coach/member-chart/queries';
import { useAppState } from 'app/state';
import { useLogger } from 'app/state/log/useLogger';
import { useMappedQuery } from 'app/vault/hooks/useMappedQuery';
import { useMutationWithGlobalState as useMutation } from 'hooks/useMutationWithGlobalState';
import Messages from 'i18n/en/memberChartCard.json';
import { isClinicianOrSupervisor, isCoachOrSupervisor } from 'utils';

import {
  AdditionalDemographicInfoHookState,
  AdditionalDemographicInfoValues,
  MutationResponse,
  VersionedAdditionalDemographicInfo,
} from './types/memberProfleTypes';

export function inputToVaultItem(data: {
  memberId: string;
  textValue: string;
  version: Version;
}): VaultItem {
  const { memberId, textValue, version = Version.v0 } = data; // protobuf vault item defintion
  return {
    data: AdditionalDemographicInfo.encode({
      memberId,
      textValue,
      version,
    }).finish(),
    schemaType: SchemaType.vault_member_chart_additional_demographic_info,
  };
}

interface Options {
  generateId?: () => string;
  keyGenerator: KeyGenerator;
}

export function useAdditionalDemographicInfo(
  memberId: string,
  opts: Partial<Options>,
): AdditionalDemographicInfoHookState {
  const logger = useLogger();

  const { role, timezone, vaultUserId } = useAppState(({ user }) => ({
    role: user.role!,
    timezone: user.timezone!,
    vaultUserId: user.vaultUserId!,
  }));
  const rawVaultId = `member-chart-${memberId}-additional-demographic-info`;
  const rawGroupId = isClinicianOrSupervisor(role)
    ? getClinicalCareTeamGroupId(memberId)
    : isCoachOrSupervisor(role)
    ? getCoachingTeamGroupId(memberId)
    : undefined;
  const userInfo = { role, timezone, vaultUserId };
  if (!rawGroupId) {
    logger.warning('useAdditionalDemographicInfo:: GroupID not defined');
  }

  const [createAdditionalDemographicInfoFn] = useMutation<
    CreateMemberChartVaultItems,
    CreateMemberChartVaultItemsVariables
  >(createVaultItems, {
    update(cache, { data }) {
      if (!data) return;
      void getVariables(rawVaultId, rawGroupId)
        .then((value) => updateAdditionalCache(cache, data, value))
        .catch();
    },
  });

  const [updateAdditionalDemographicInfoFn] = useMutation<
    UpdateMemberChartVaultItems,
    UpdateMemberChartVaultItemsVariables
  >(updateVaultItems);

  const additionalDemographicInfo = useMappedQuery<
    GetMemberChartVaultItemById,
    GetMemberChartVaultItemByIdVariables,
    AdditionalDemographicInfoValues
  >(
    {
      query: getMemberChartVaultItemById,
      variables: () => getVariables(rawVaultId, rawGroupId),
    },
    async ({ getVaultItemById }) => {
      if (!getVaultItemById?.encryptedItem) {
        return getEmptyAdditionalDemographicInfoValue();
      }
      const data = await mapPaginatedVaultItems(
        getVaultItemById.encryptedItem,
        { role, timezone, vaultUserId },
        MemberChartMapQueries.ADDITIONAL_DEMOGRAPHIC_INFO,
      );

      return {
        id: data.id,
        sourceVersion: data.sourceVersion,
        tooltipText: `${data.updatedAt} • ${data.updatedBy} last updated`,
        value: data.textValue,
      };
    },
  );
  const createVaultItem = async (
    input: VersionedAdditionalDemographicInfo,
  ): Promise<MutationResponse> => {
    try {
      const inputParams = {
        itemId: `member-chart-${memberId}-additional-demographic-info`,
        tags: [`member-chart-${memberId}`],
        vaultItem: inputToVaultItem(input),
      };
      const param = await createVaultInputParams(memberId, role, inputParams);
      return createMemberChartVaultItem({
        createMemberChartVaultItemFn: (data) =>
          createAdditionalDemographicInfoFn(data, {
            amplitudeMetadata: {
              action: 'Create',
              source: 'Member Profile > Additional Info',
            },
          }),
        logger,
        opts,
        param,
        query: MemberChartMapQueries.ADDITIONAL_DEMOGRAPHIC_INFO,
        source: input.source || '',
        userInfo,
      }) as Promise<MutationResponse>;
    } catch (e) {
      logger.error(new Error('Unable to create Additional Info'), {
        error: e,
        inputSource: input.source,
        memberId,
      });
      return {
        data: null,
        errorMessage: Messages.failureToCreateAdditionalInfo,
        success: false,
      };
    }
  };

  const updateVaultItem = async (
    input: VersionedAdditionalDemographicInfo,
  ): Promise<MutationResponse> => {
    try {
      const inputParams = {
        itemId: rawVaultId,
        sourceVersion: input.sourceVersion,
        tags: [`member-chart-${memberId}`],
        vaultItem: inputToVaultItem(input),
      };
      const param = (await createVaultInputParams(
        memberId,
        role,
        inputParams,
        true,
      )) as UpdateVaultItemInputParams;
      return updateMemberChartVaultItem({
        logger,
        opts,
        param,
        query: MemberChartMapQueries.ADDITIONAL_DEMOGRAPHIC_INFO,
        source: input.source || '',
        updateMemberChartVaultItemFn: (data) =>
          updateAdditionalDemographicInfoFn(data, {
            amplitudeMetadata: {
              action: 'Update',
              source: 'Member Profile > Additional Info',
            },
          }),
        userInfo,
      }) as Promise<MutationResponse>;
    } catch (e) {
      logger.error(new Error('Unable to update Additional Info'), {
        error: e,
        inputSource: input.source,
        memberId,
      });
      return {
        data: null,
        errorMessage: Messages.failureToUpdateAdditionalInfo,
        success: false,
      };
    }
  };
  const saveAdditionalDemographicInfo = (
    input: VersionedAdditionalDemographicInfo,
  ): Promise<MutationResponse> => {
    const { data } = additionalDemographicInfo;
    if (data && data?.id) {
      return updateVaultItem(input);
    }
    return createVaultItem(input);
  };
  return {
    additionalDemographicInfo,
    createAdditionalDemographicInfo: (
      input: VersionedAdditionalDemographicInfo,
    ) => createVaultItem(input),
    saveAdditionalDemographicInfo,
    updateAdditionalDemographicInfo: async (
      input: VersionedAdditionalDemographicInfo,
    ) => updateVaultItem(input),
  };
}

export function getEmptyAdditionalDemographicInfoValue(): AdditionalDemographicInfoValues {
  return {
    id: undefined,
    tooltipText: '',
    value: '',
  };
}

async function getVariables(
  vaultId: string,
  groupId?: string,
): Promise<GetMemberChartVaultItemByIdVariables> {
  return {
    groupId: groupId ? await Base64.hash(groupId) : undefined,
    vaultId: await Base64.hash(vaultId),
  };
}

function updateAdditionalCache(
  cache: ApolloCache<any>,
  data: CreateMemberChartVaultItems,
  variables: GetMemberChartVaultItemByIdVariables,
) {
  const items = cache.readQuery<
    GetMemberChartVaultItemById,
    GetMemberChartVaultItemByIdVariables
  >({
    query: getMemberChartVaultItemById,
    variables,
  });
  const vaultItemId = items?.getVaultItemById?.encryptedItem.id;
  if (!vaultItemId) {
    cache.writeQuery<
      GetMemberChartVaultItemById,
      GetMemberChartVaultItemByIdVariables
    >({
      data: {
        getVaultItemById: {
          ...data.createVaultItems[0],
        },
      },
      query: getMemberChartVaultItemById,
      variables,
    });
  }
}
