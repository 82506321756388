import { CoachMemberTasksInitialConsultItem_ItemType as CoachItemType } from '@ginger.io/vault-member-chart/dist/generated/protobuf-schemas/vault-member-chart/member-tasks/CoachMemberTasksInitialConsultItem';
import { PsychiatristMemberTasksInitialConsultItem_ItemType as PsychiatristItemType } from '@ginger.io/vault-member-chart/dist/generated/protobuf-schemas/vault-member-chart/member-tasks/PsychiatristMemberTasksInitialConsultItem';
import { TherapistMemberTasksInitialConsultItem_ItemType as TherapistItemType } from '@ginger.io/vault-member-chart/dist/generated/protobuf-schemas/vault-member-chart/member-tasks/TherapistMemberTasksInitialConsultItem';
import {
  Reason,
  UserRole,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { camelCaseToTitleCase, toSentenceCase } from 'utils';

export const coachItemTypeToStringMap: Record<CoachItemType, string> = {
  [CoachItemType.greet]: 'Greet\nShare availability, gather initial info',
  [CoachItemType.set_the_stage]: 'Set the Stage\nExplain consultation',
  [CoachItemType.review_model_of_care]:
    'Review Model of Care\nExplain coaching / therapy / psychiatry',
  [CoachItemType.agreements]: 'Agreements\nDiscuss how you’ll work together',
  [CoachItemType.gather]: 'Gather\nBuild rapport, establish focus points',
  [CoachItemType.collaborate_on_plan]:
    'Collaborate on Goal\nEstablish focus points and potential interventions / action items',
  [CoachItemType.schedule_followup]:
    'Schedule Follow-up\nDiscuss ongoing sessions, text-based, cadence, 24/7 collaboration model, your coaching schedule',
  [CoachItemType.close]:
    'Close\nConfirm agreed upon steps, session summarization, thank for participation',
  [CoachItemType.followup]:
    'Follow-up\nSend promised resources, any Support requests, add to "My Follow-ups"',
  [CoachItemType.collaborate_on_care]:
    'Collaborate on Care\nCoordinate with care team as needed',
  [CoachItemType.complete_charting]:
    'Complete Charting\nDiscovery session note, member background, focus areas & goals',
  [CoachItemType.undefined_type]: '',
  [CoachItemType.UNRECOGNIZED]: '',
};
export const psychiatristItemTypeToStringMap: Record<
  PsychiatristItemType,
  string
> = {
  [PsychiatristItemType.undefined_type]: '',
  [PsychiatristItemType.UNRECOGNIZED]: '',
  [PsychiatristItemType.greet]:
    'Greet\nIntroduce, verify identity & location, review informed consent, share concurrent note process',
  [PsychiatristItemType.set_the_stage]:
    'Set the Stage\nOrient to objectives, length & purpose of appointment',
  [PsychiatristItemType.review_model_of_care]:
    'Review Model of Care\nExplain coaching / therapy / psychiatry, collaboration, MBC & surveys, mindfulness',
  [PsychiatristItemType.gather]:
    'Gather\nComprehensive history & diagnostic assessment',
  [PsychiatristItemType.collaborate_on_plan]:
    'Collaborate on Goal\nDiscuss options, risk / benefit / side effects, send prescriptions, ROIs for care coordination',
  [PsychiatristItemType.schedule_followup]:
    'Schedule Follow-up\nReasoning for session cadence, how to contact / reschedule',
  [PsychiatristItemType.close]:
    'Close\nAcknowledge potential emotional response to session, confirm agreed upon steps, thank for participation, invite self care',
  [PsychiatristItemType.followup]:
    'Follow-up\nLab requisition, send promised resources, any Support requests, add to "My Follow-ups"',
  [PsychiatristItemType.collaborate_on_care]:
    'Collaborate on Care\nCoordinate with internal & external providers as needed',
  [PsychiatristItemType.complete_charting]:
    'Complete Charting\nConfirm member / note details, sign & lock',
};
export const therapistItemTypeToStringMap: Record<TherapistItemType, string> = {
  [TherapistItemType.undefined_type]: '',
  [TherapistItemType.UNRECOGNIZED]: '',
  [TherapistItemType.greet]:
    'Greet\nIntroduce, verify identity & location, review informed consent, share concurrent note process',
  [TherapistItemType.set_the_stage]:
    'Set the Stage\nOrient to objectives, length & purpose of appointment',
  [TherapistItemType.review_model_of_care]:
    'Review Model of Care\nExplain coaching / therapy / psychiatry, collaboration, MBC & surveys, mindfulness',
  [TherapistItemType.gather]:
    'Gather\nComprehensive history & diagnostic assessment',
  [TherapistItemType.collaborate_on_plan]:
    'Collaborate on Goal\nDiagnostic impressions, recommendations, MBC goal-setting, ROIs for care coordination',
  [TherapistItemType.schedule_followup]:
    'Schedule Follow-up\nEstablish plan & reasoning for session cadence, 4 session blocks, how to contact / reschedule',
  [TherapistItemType.close]:
    'Close\nAcknowledge potential emotional response to session, confirm agreed upon steps, thank for participation, invite self care',
  [TherapistItemType.followup]:
    'Follow-up\nSend promised resources, any Support requests, add to "My Follow-ups"',
  [TherapistItemType.collaborate_on_care]:
    'Collaborate on Care\nCoordinate with internal & external providers as needed',
  [TherapistItemType.complete_charting]:
    'Complete Charting\nConfirm member / note details, sign & lock',
};
export const initialConsultLabelsByRole: Record<
  UserRole,
  Record<number, string>
> = {
  CLINICAL_SUPERVISOR: therapistItemTypeToStringMap,
  CLINICIAN: therapistItemTypeToStringMap,
  COACH: coachItemTypeToStringMap,
  COACH_SUPERVISOR: coachItemTypeToStringMap,
  MEMBER_SUPPORT: therapistItemTypeToStringMap,
  PSYCHIATRIST: psychiatristItemTypeToStringMap,
  PSYCHIATRIST_SUPERVISOR: psychiatristItemTypeToStringMap,
  THERAPIST: therapistItemTypeToStringMap,
  THERAPIST_SUPERVISOR: therapistItemTypeToStringMap,
};
const mapReasonToText: Record<string, string> = {
  [Reason.RiskyChatDetected]: 'Risk detected in chat',
  [Reason.AtRiskPhq9Q9]: 'Review risk (PHQ9)',
  [Reason.AssessmentRiskAlert]: 'Review risk (PHQ9)',
  [Reason.HighSurveyScore]: 'Review risk (high score)',
  [Reason.SIReviewTriageNotes]: 'Review SI triage notes',
  [Reason.IntakeRiskAlert]: 'Review risk (intake form)',
  [Reason.NewActionableCollabMessage]: 'Review collab message',
  [Reason.NewClinicalDocumentUpload]: 'Review New Document',
  [Reason.CareProviderNotesDue]: 'Submit note',
  [Reason.ClinicalNotePastDue]: 'Submit session notes',
  [Reason.CoachingTeamLeadChanged]: 'Contact re: lead change',
  [Reason.ReengagementReminder]: 'Contact re: inactivity',
  [Reason.TerminationReminder]: 'Write termination note',
  [Reason.PHQGADReminder]: 'Send PHQ/GAD reminder',
  [Reason.SurveyScoreWorsened]: 'Survey Score Worsened',
  [Reason.SICoachAdded]: 'SI Coach added',
  [Reason.MissedSession]: 'Contact re: missed session',
};
export const displayTaskLabel = (
  label: string,
  related_care_provider_name: string = '',
): string => {
  if (label === Reason.RiskyChatDetected && related_care_provider_name) {
    return `Escalate: Suicide Risk (chat with ${related_care_provider_name})`;
  }
  return mapReasonToText[label] ?? toSentenceCase(camelCaseToTitleCase(label));
};
