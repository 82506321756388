import {
  field,
  Fields,
  nonEmptyArrayField,
  stringField,
  useForm,
} from '@ginger.io/react-use-form';
import { ClinicianRole } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/ClinicianRole';
import {
  TerminationNote,
  TerminationNote_Reason,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/TerminationNote';
import { Metadata_NoteStatus as NoteStatus } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/Metadata';
import { UserRole } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import Container from '@mui/material/Container';
import {
  EnumMultiSelectDropdownField,
  TextAreaField,
} from 'app/notes-ui/forms/fields';
import { NoteFormControlLabel } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { SaveButton } from 'app/notes-ui/forms/form-controls/SaveButton';
import { NoteType } from 'app/notes-ui/NoteHeader';
import { NoteHeaderV2 } from 'app/notes-ui/NoteHeaderV2';
import styles from 'app/notes-ui/out-of-session/OutOfSessionNoteContainer.module.scss';
import {
  clickedSaveDraftTerminationNote,
  clickedSignAndLockTerminationNote,
  reasonForContactSelected,
} from 'app/state/amplitude/actions/notes';
import { NoteRoutes, patientNoteRoute } from 'app/top-nav/Routes';
import {
  clinicianRoleEnumMap,
  NonAppointmentNoteAction,
} from 'app/vault/api/NonAppointmentNotesAPI';
import { useSnackNotification } from 'hooks/useSnackNotification';
import Messages from 'i18n/en/notes.json';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Width } from 'types/StyleTypes';
import { isClinicalSupervisor, isClinician } from 'utils';
import { labelFromEnumValue } from 'utils/notes';

type Props = {
  patientId: string;
  clinicianId: string;
  clinicianName: string;
  memberName: string;
  noteStatus: NoteStatus;
  vaultItemId?: string;
  onSubmit: (
    data: TerminationNote,
    action: NonAppointmentNoteAction,
    itemId?: string,
  ) => Promise<{ itemId?: string }>;
  initialValue?: TerminationNote;
  clinicianRole?: UserRole;
  allowedGroupWriteAccess?: boolean;
  navigateTo: (url: string) => void;
};

function TerminationNotesForm(props: {
  disabled?: boolean;
  fields: Fields<TerminationNote>;
  patientId: string;
}) {
  const { fields, disabled, patientId } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      reasonForContactSelected({
        patientId,
        reason: fields.reasons.value
          .map((_) => labelFromEnumValue(TerminationNote_Reason, _))
          .join(', '),
      }),
    );
  }, [dispatch, fields.reasons.value]);

  return (
    <Container className={styles.root} maxWidth="sm">
      <NoteFormControlLabel label="Reason for termination*">
        <EnumMultiSelectDropdownField
          testId="terminationReason"
          options={TerminationNote_Reason}
          field={fields.reasons}
          label="Select reason(s)*"
          disabled={disabled}
        />
      </NoteFormControlLabel>
      <TextAreaField
        disabled={disabled}
        testId="treatmentSummary"
        label="Treatment summary*"
        field={fields.treatmentSummary}
        width={Width.FULL}
        tooltip="Member participated in (specific services). Member reached/did not reach goal of _________________ with a PHQ-9 score of __ and GAD-7 score of ____. Member was informed of termination by phone call/email/member support message/termination session."
      />
      <TextAreaField
        disabled={disabled}
        testId="careContinuationPlan"
        label="Aftercare plan*"
        field={fields.careContinuationPlan}
        width={Width.FULL}
        tooltip="After care plan to consist of medication management with __. Member will continue with Coach _ for maintenance of achieved goals. Agreed upon safety plan includes _"
      />
      {disabled && fields.goalsMet.value && (
        <TextAreaField
          disabled={disabled}
          testId="goalsMet"
          label="Goals met? And if no, which goals not met and why?*"
          field={fields.goalsMet}
        />
      )}
    </Container>
  );
}

export function TerminationNotesContainer(props: Props) {
  const {
    initialValue,
    patientId,
    clinicianId,
    noteStatus,
    vaultItemId,
    clinicianRole = UserRole.CLINICIAN,
    allowedGroupWriteAccess = false,
  } = props;

  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const {
    showErrorNotification,
    showSuccessNotification,
  } = useSnackNotification();

  const { fields, validate, getValue } = useForm<TerminationNote>(
    {
      careContinuationPlan: stringField(),
      clinicianId: stringField({ default: clinicianId }),
      clinicianRole: field<ClinicianRole>({
        default: clinicianRoleEnumMap[clinicianRole],
      }),
      goalsMet: stringField({ rules: [] }),
      patientId: stringField({ default: patientId }),
      reasons: nonEmptyArrayField(),
      treatmentSummary: stringField(),
    },
    initialValue,
  );

  const onNoteLocked = async () => {
    const isValid = await validate();
    if (!isValid || noteStatus === NoteStatus.signed_and_locked_note) return;
    dispatch(clickedSignAndLockTerminationNote({ patientId }));
    const value = getValue();
    if (vaultItemId && noteStatus === NoteStatus.draft_note) {
      await props.onSubmit(value, NonAppointmentNoteAction.LOCK, vaultItemId);
    } else {
      const { itemId } = await props.onSubmit(
        value,
        NonAppointmentNoteAction.CREATE_AND_LOCK,
      );
      props.navigateTo(
        patientNoteRoute(patientId, NoteRoutes.TERMINATION, itemId),
      );
    }
  };

  const onSubmit = async () => {
    try {
      dispatch(clickedSaveDraftTerminationNote({ patientId }));
      if (!(await validate())) return;
      setSaving(true);
      const value = getValue();
      if (vaultItemId && noteStatus === NoteStatus.draft_note) {
        await props.onSubmit(
          value,
          NonAppointmentNoteAction.UPDATE,
          vaultItemId,
        );
      } else {
        const { itemId } = await props.onSubmit(
          value,
          NonAppointmentNoteAction.CREATE,
        );
        props.navigateTo(
          patientNoteRoute(patientId, NoteRoutes.TERMINATION, itemId),
        );
      }
      showSuccessNotification(Messages.savedTerminationNoteSuccessfully);
    } catch (e) {
      showErrorNotification(Messages.failureToSaveTerminationNote);
    } finally {
      setSaving(false);
    }
  };

  const isLocked = noteStatus === NoteStatus.signed_and_locked_note;
  const isOwner = clinicianId === fields.clinicianId.value;
  const canSignAndLock =
    (isOwner && isClinician(clinicianRole) && !allowedGroupWriteAccess) ||
    (isOwner && isClinicalSupervisor(clinicianRole)) ||
    (isClinicalSupervisor(clinicianRole) && allowedGroupWriteAccess);

  return (
    <div>
      <NoteHeaderV2
        disabled={isLocked || !canSignAndLock}
        status={noteStatus}
        noteType={NoteType.TERMINATION}
        memberId={props.patientId}
        memberName={props.memberName}
        clinicianName={props.clinicianName}
        onValidate={validate}
        onNoteLocked={onNoteLocked}
      />
      <TerminationNotesForm
        patientId={patientId}
        fields={fields}
        disabled={isLocked}
      />
      {allowedGroupWriteAccess && !isLocked && (
        <Container className={styles.root} maxWidth="sm">
          <SaveButton
            isLoading={saving}
            onSubmit={onSubmit}
            label="Save draft note"
          />
        </Container>
      )}
    </div>
  );
}
