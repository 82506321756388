import { useMutation } from '@apollo/client';
import {
  NewEventForMe,
  NewEventForMeVariables,
} from '@headspace/carehub-graphql/dist/appointments/generated/NewEventForMe';
import { newEventForMeMutation } from '@headspace/carehub-graphql/dist/appointments/queries';
import { DEFAULT_TIMEZONE } from 'app/clinician/ClinicianSettingsComponent';
import { useAppState, useDispatch } from 'app/state';
import {
  clickScheduleNewEventCreateAction,
  newEventViewedAction,
} from 'app/state/amplitude/actions/events';
import { useLogger } from 'app/state/log/useLogger';
import { useOnMount } from 'hooks/useOnMount';
import { useSnackNotification } from 'hooks/useSnackNotification';
import Messages from 'i18n/en/appointment.json';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { NewEventForm } from './NewEventForm';

export function NewEventContainer(props: RouteComponentProps<{}>) {
  const { history } = props;
  const [createNewEvent] = useMutation<NewEventForMe, NewEventForMeVariables>(
    newEventForMeMutation,
  );
  const dispatch = useDispatch();
  const {
    showErrorNotification,
    showSuccessNotification,
  } = useSnackNotification();
  const timezone = useAppState((_) => _.user.timezone) ?? DEFAULT_TIMEZONE;
  const logger = useLogger();

  useOnMount(() => {
    dispatch(newEventViewedAction());
  });

  async function onCreate(variables: NewEventForMeVariables) {
    let response;
    try {
      response = await createNewEvent({ variables });
      const events = response.data?.createClinicalEventForMe?.events;
      if (events) {
        dispatch(
          clickScheduleNewEventCreateAction({
            eventIds: events.map((e) => e.id),
          }),
        );
        showSuccessNotification(Messages.eventCreated);
        history.goBack();
        return;
      }
      if (response.errors) {
        const errors = response.errors.map((_) => _.message).join(', ');
        const message = [Messages.errorCreatingEvent, errors].join(': ');
        showErrorNotification(message, true);
        logger.error(new Error(Messages.eventCreationFailure), {
          errors: response.errors,
        });
      }
    } catch (error) {
      logger.error(new Error(Messages.eventCreationFailure), { error });
      const errors = error.message;
      const message = [Messages.errorCreatingEvent, errors].join(': ');
      showErrorNotification(message, true);
    }
  }

  return (
    <NewEventForm
      onSave={onCreate}
      onClose={history.goBack}
      timezone={timezone}
    />
  );
}

export const NewEventScreen = withRouter(NewEventContainer);
