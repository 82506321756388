import { CoachClinicianCollaborationChatMessage_Author_AuthorType as AuthorType } from '@ginger.io/vault-care-collaboration/dist/generated/protobuf-schemas/vault-care-collaboration/CoachClinicianCollaborationChatMessage';
import { SI_ASSESSMENT_SPECIALIZATION } from 'app/care-team/constants';
import {
  Coach,
  CurrentCoach,
  MS,
  Psychiatrist,
  Therapist,
} from 'app/care-team/types';
import { RootState } from 'app/state/hooks/baseTypedHooks';
import { useAppState } from 'app/state/index';
import { State } from 'app/state/schema';
import { isClinicianOrSupervisor, isTeen } from 'utils';
// returns a new object (i.e. breaks reference equality) with equal top-level properties

export const selectCareTeamScreenState = () => {
  return useAppState((state: State) => {
    const {
      activeView,
      selectedCareTeamMemberProfile,
      riskStatus,
    } = state.careTeam;
    return {
      activeView,
      selectedCareTeamMemberProfile,
      riskStatus,
    };
  });
};

// coaches selectors
const selectPastCoaches = (): Coach[] => {
  return useAppState((state: State) =>
    cleanArray(state.careTeam.coachingCareTeam?.past?.coaches),
  );
};

/**
 * Current care team is a combination of current coaches and current MS
 */
const selectCurrentCareTeam = (): (Coach | MS)[] => {
  return useAppState(({ careTeam }: State) => [
    ...cleanArray(careTeam.coachingCareTeam?.current?.coaches),
    ...cleanArray(careTeam.coachingCareTeam?.current?.ms),
  ]);
};

const selectCurrentCoaches = (): CurrentCoach[] => {
  return useAppState((state: State) =>
    cleanArray(state.careTeam.coachingCareTeam?.current?.coaches),
  );
};

const selectCurrentMS = (): MS[] => {
  return useAppState((state: State) =>
    cleanArray(state.careTeam.coachingCareTeam?.current?.ms),
  );
};

const selectHasSICoach = (): boolean | undefined => {
  return useAppState((state: State) => {
    return state.careTeam.coachingCareTeam?.current?.coaches?.some((coach) =>
      coach?.specializations.includes(SI_ASSESSMENT_SPECIALIZATION),
    );
  });
};

const selectHasMS = (): boolean | number => {
  return useAppState((state: State) => {
    return state.careTeam.coachingCareTeam?.current?.ms?.length ?? 0 > 0;
  });
};

export const selectCareTeamFetchStatus = (state: State) => {
  const { error, isFetching } = state.careTeam;
  return {
    error,
    isFetching: isFetching,
    success: !isFetching && !error,
  };
};

export const selectCoachState = () => {
  return {
    hasMS: selectHasMS(),
    hasSICoach: selectHasSICoach(),
    pastCoaches: selectPastCoaches(),
    currentCareTeam: selectCurrentCareTeam(),
    currentCoaches: selectCurrentCoaches(),
    currentMS: selectCurrentMS(),
  };
};

// clinical selectors
const selectCurrentTherapists = (): Therapist[] => {
  return useAppState((state: State) =>
    cleanArray(state.careTeam.clinicalCareTeam?.current?.therapists),
  );
};

const selectCurrentPsychiatrists = (): Psychiatrist[] => {
  return useAppState((state: State) =>
    cleanArray(state.careTeam.clinicalCareTeam?.current?.psychiatrists),
  );
};

const selectPastTherapists = (): Therapist[] => {
  return useAppState((state: State) =>
    cleanArray(state.careTeam.clinicalCareTeam?.past?.therapists),
  );
};

const selectPastPsychiatrists = (): Psychiatrist[] => {
  return useAppState((state: State) =>
    cleanArray(state.careTeam.clinicalCareTeam?.past?.psychiatrists),
  );
};

const selectHasClinical = () => {
  return useAppState((state: State) => {
    return (
      (state.careTeam.clinicalCareTeam?.current?.psychiatrists?.length ??
        0 > 0) ||
      (state.careTeam.clinicalCareTeam?.current?.therapists?.length ?? 0 > 0)
    );
  });
};

const selectHasIntakeSent = () => {
  return useAppState((state: State) => {
    return state.careTeam.hasIntakeSent;
  });
};

export const selectUserState = ({ user }: RootState) => {
  const { userId, firstName, lastName, role } = user;
  const authorType = isClinicianOrSupervisor(role)
    ? AuthorType.clinician
    : AuthorType.coach;
  return {
    userId,
    firstName: firstName ?? '',
    lastName: lastName ?? '',
    authorType,
  };
};

export const selectClinicalState = () => {
  return {
    hasIntakeSent: selectHasIntakeSent(),
    hasClinical: selectHasClinical(),
    pastTherapists: selectPastTherapists(),
    pastPsychiatrists: selectPastPsychiatrists(),
    currentTherapists: selectCurrentTherapists(),
    currentPsychiatrists: selectCurrentPsychiatrists(),
  };
};

export const selectCareTeamButtonState = (state: State) => {
  const {
    riskStatus,
    dateOfBirth,
    coachingCareTeam,
    clinicalCareTeam,
    hasIntakeSent,
  } = state.careTeam;
  return {
    hasClinical:
      (clinicalCareTeam?.current?.psychiatrists?.length ?? 0) > 0 ||
      (clinicalCareTeam?.current?.therapists?.length ?? 0) > 0,
    hasIntakeSent,
    hasMS: (coachingCareTeam?.current?.ms?.length ?? 0) > 0,
    hasSICoach: coachingCareTeam?.current?.coaches?.some((coach) =>
      coach?.specializations.includes(SI_ASSESSMENT_SPECIALIZATION),
    ),
    isTeen: isTeen(dateOfBirth),
    riskStatus,
  };
};

function cleanArray<T>(param: (T | null)[] | null | undefined): T[] {
  return (param ?? []).flatMap((_) => (_ ? [_] : []));
}
