import { BooleanWithComments } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/BooleanWithComments';
import { DateMessage as Date } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/Date';
import { initializeOptions } from 'app/notes-ui/forms/form-controls/EnumCheckboxGroup';
import { ProtobufEnum } from 'app/notes-ui/types';
import { ILogger } from 'app/state/log/Logger';
import { Font } from 'shared-components/pdf/constants';
import { PDFBuilder, PDFBuilderImpl } from 'shared-components/pdf/PDFBuilder';
import { formatTimestampWithTz } from 'utils/dateTime';
import { labelFromEnumValue, protobufDateToString } from 'utils/notes';

import { Answer, NoteHeader } from './types';

export type HeaderFormattingOptions = {
  col1Width: number;
  col2Width: number;
  col3Width: number;
};

const defaultHeaderFormattingOptions: HeaderFormattingOptions = {
  // 468 points total, with a standard 1-inch margin: (8.5" * 72 points - 2 * 72 points)
  col1Width: 225,
  col2Width: 175,
  col3Width: 68,
};

export interface NotePDFBuilder {
  build(): typeof PDFDocument;
  noteHeader(
    props: NoteHeader,
    headerFormattingOptions?: Partial<HeaderFormattingOptions>,
  ): this;
  amendment(text: string, unformattedTimestamp: string, timezone: string): this;
  section(
    sectionEnum: string,
    buildContent: () => boolean,
    noContentMsg: string,
    bottomPadding?: boolean,
  ): this;
  subsection(
    headerText: string,
    buildContent: () => void,
    bottomPadding?: boolean,
  ): this;
  shortTextAnswer(label: string, value?: string | number): this;
  longTextAnswer(label: string, value?: string): this;
  yesOrNoAnswer(label: string, value?: boolean): this;
  dateAnswer(label: string, date?: Date): this;
  radioItemAnswer(type: ProtobufEnum, label: string, value?: number): this;
  enumSelectionsGroup<T>(
    type: ProtobufEnum,
    values: T[],
    headerLabel: string,
    noDataLabel: string,
    keyLabels?: Record<string, string>,
  ): this;
  yesOrNoAnswersGroup(
    headerLabel: string,
    answers: Answer[],
    noSelectionsMsg: string,
  ): this;
  endorsedOrDeniedAnswersGroup(
    headerLabel: string,
    answers: Answer[],
    noSelectionsMsg: string,
  ): this;
  textAnswersGroup(
    headerLabel: string,
    answers: Answer[],
    noSelectionsMsg: string,
  ): this;
  stringGroup(
    headerLabel: string,
    values: string[],
    noSelectionsMsg: string,
  ): this;
  yesOrNoWithCommentAnswer(
    headerLabel: string,
    answer: BooleanWithComments | undefined,
    noSelectionsMsg: string,
  ): this;
  table<T extends Record<string, any>>(
    headerLabel: string,
    columnLabels: { [key in keyof T]?: string },
    records: T[],
    noSelectionsMsg: string,
  ): this;
  padding(): this;
}

export class NotePDFBuilderImpl implements NotePDFBuilder {
  constructor(
    protected password?: string,
    protected sectionToLabel: Record<string, string> = {},
    protected enableNewPdfFont?: boolean,
    protected pdfBuilder: PDFBuilder = new PDFBuilderImpl(
      password,
      enableNewPdfFont,
    ),
    protected logger?: ILogger,
  ) {}

  build(): typeof PDFDocument {
    return this.pdfBuilder.build();
  }

  /**
   * Write a header at the top of the page, with basic metadata about the patient and appointment.
   */
  noteHeader(
    props: NoteHeader,
    headerFormattingOptions?: Partial<HeaderFormattingOptions>,
  ) {
    try {
      const { col1Width, col2Width, col3Width } = {
        ...defaultHeaderFormattingOptions,
        ...headerFormattingOptions,
      };
      const {
        patient,
        clinician,
        dateTime,
        isTerminationNote = false,
        signingClinician,
        updatedAt,
      } = props;

      this.pdfBuilder.headerTextInColumns([
        {
          content: [
            {
              label: 'Patient',
              value: `${patient.firstName} ${patient.lastName}`,
            },
            { label: 'Clinician', value: clinician.name },
            {
              label: 'Signed & Locked E-Signature',
              value: `${signingClinician?.name}, ${updatedAt}`,
            },
            {
              label: '',
              value: '',
            },
          ],
          width: col1Width,
        },
        {
          content: [
            { label: 'DOB', value: patient.dateOfBirth },
            {
              label: isTerminationNote ? 'Terminated' : 'Visit',
              value: dateTime,
            },
          ],
          width: col2Width,
        },
        {
          content: [{ label: 'Gender', value: patient.gender }],
          width: col3Width,
        },
      ]);

      return this;
    } catch (e) {
      if (this.logger) {
        this.logger.error(
          new Error('[PDFBuilder]: Error rendering PDF note header'),
          {
            originalError: e,
            section: 'NOTE_HEADER',
          },
        );
      }

      throw e;
    }
  }

  section(
    sectionEnum: string,
    buildContent: () => boolean,
    noContentMsg: string,
    bottomPadding = true,
  ): this {
    try {
      this.sectionHeading(sectionEnum);
      return this.sectionContent(buildContent, noContentMsg, bottomPadding);
    } catch (e) {
      if (this.logger) {
        this.logger.error(
          new Error('[PDFBuilder]: Error rendering PDF section'),
          {
            originalError: e,
            section: sectionEnum,
          },
        );
      }

      throw e;
    }
  }

  subsection(
    headerText: string,
    buildContent: () => void,
    bottomPadding = true,
  ): this {
    this.pdfBuilder.h2(headerText);
    return this.sectionContent(buildContent, undefined, bottomPadding);
  }

  shortTextAnswer(label: string, value?: string | number) {
    if (!value) return this;

    this.inlineLabel(label);
    this.pdfBuilder.text(value.toString().trim());
    return this;
  }

  longTextAnswer(label: string, value?: string) {
    if (!value) return this;

    this.pdfBuilder.h3(label).text(value.trim());

    return this;
  }

  yesOrNoAnswer(label: string, value?: boolean) {
    const answer = value ? 'Yes' : 'No';
    return this.shortTextAnswer(label, answer);
  }

  dateAnswer(label: string, date?: Date) {
    if (!date) return this;

    const dateString = protobufDateToString(date);
    return this.shortTextAnswer(label, dateString);
  }

  radioItemAnswer(type: ProtobufEnum, label: string, value?: number) {
    const selection = labelFromEnumValue(type, value);

    this.inlineLabel(label);
    this.pdfBuilder.text(selection || 'No selection');
    return this;
  }

  /**
   * Add a header label and a list of selected options
   */
  enumSelectionsGroup<T>(
    type: ProtobufEnum,
    values: T[],
    headerLabel: string,
    noDataLabel: string,
    keyLabels: Record<string, string> = {},
  ) {
    const { options: labelToChecked } = initializeOptions(
      type,
      {},
      values,
      keyLabels,
    );
    const responses = Object.entries(labelToChecked)
      .filter(([_, isChecked]) => isChecked)
      .map(([label]) => label);

    this.pdfBuilder.h3(headerLabel);

    if (responses.length > 0) {
      this.pdfBuilder.ul(responses);
    } else {
      this.pdfBuilder.text(noDataLabel);
    }
    return this;
  }

  yesOrNoAnswersGroup(
    headerLabel: string,
    answers: Answer[],
    noSelectionsMsg: string,
  ) {
    this.pdfBuilder.h3(headerLabel);

    const selectedItems = NotePDFBuilderImpl.onlyTruthyAnswers(answers);

    if (selectedItems.length > 0) {
      const selectedItemLabels = selectedItems.map(({ label }) => label);
      this.pdfBuilder.ul(selectedItemLabels);
    } else {
      this.pdfBuilder.text(noSelectionsMsg);
    }
    return this;
  }

  endorsedOrDeniedAnswersGroup(
    headerLabel: string,
    answers: Answer[],
    noSelectionsMsg: string,
  ) {
    this.pdfBuilder.h3(headerLabel);
    if (answers.length > 0) {
      const selectedItemLabels = answers.map(({ label, value }) => {
        const displayedValue = value ? 'Endorsed' : 'Denied';
        return `${label}: ${displayedValue}`;
      });
      this.pdfBuilder.ul(selectedItemLabels);
    } else {
      this.pdfBuilder.text(noSelectionsMsg);
    }
    return this;
  }

  textAnswersGroup(
    headerLabel: string,
    answers: Answer[],
    noSelectionsMsg: string,
  ) {
    this.pdfBuilder.h3(headerLabel);

    // Exclude empty text boxes.
    const selectedItems = NotePDFBuilderImpl.onlyTruthyAnswers(answers);

    if (selectedItems.length > 0) {
      const selectedItemLabels = selectedItems.map(({ label, value }) => {
        const trimmedValue = `${value}`.trim().replace(/[\n]+/g, ' ');
        return `${label}: ${trimmedValue}`;
      });
      this.pdfBuilder.ul(selectedItemLabels);
    } else {
      this.pdfBuilder.text(noSelectionsMsg);
    }
    return this;
  }

  amendment(
    text: string,
    unformattedTimestamp: string,
    timezone: string,
  ): this {
    const formattedTimestamp = formatTimestampWithTz(
      unformattedTimestamp,
      timezone,
    );
    this.subsection(formattedTimestamp, () => {
      this.pdfBuilder.text(text);
    });
    return this;
  }

  table<T extends Record<string, any>>(
    headerLabel: string,
    columnLabels: Record<keyof T, string>,
    records: T[],
    noSelectionsMsg: string,
  ): this {
    this.pdfBuilder.h3(headerLabel);

    if (records.length === 0) {
      this.pdfBuilder.text(noSelectionsMsg);
    } else {
      const labels: string[] = [];
      const keys: (keyof T)[] = [];

      Object.entries(columnLabels).forEach(([key, label]) => {
        keys.push(key as keyof T);
        labels.push(label);
      });

      const rows: string[][] = records.map((record) => {
        return keys.map((key) => `${record[key] || '-'}`);
      });

      this.pdfBuilder.table(labels, rows);
      this.padding();
    }
    return this;
  }

  yesOrNoWithCommentAnswer(
    headerLabel: string,
    answer: BooleanWithComments | undefined,
    noSelectionsMsg: string,
  ): this {
    if (answer === undefined) {
      this.pdfBuilder.text(noSelectionsMsg);
      return this;
    }
    this.yesOrNoAnswer(headerLabel, answer.isPresent);
    if (answer.description) {
      this.padding();
      this.pdfBuilder.text(answer.description);
    }
    return this;
  }

  padding(): this {
    this.pdfBuilder.lineBreak();
    return this;
  }

  private sectionContent(
    buildContent: () => boolean | void,
    noContentMsg?: string,
    bottomPadding = true,
  ): this {
    const hasContent = buildContent();

    if (noContentMsg && !hasContent) {
      this.pdfBuilder.text(noContentMsg);
    }

    if (bottomPadding) {
      this.pdfBuilder.lineBreak();
    }
    return this;
  }

  private inlineLabel(label: string) {
    this.pdfBuilder.inlineText(`${label}: `, {
      font: this.enableNewPdfFont ? Font.NOTO_BOLD : Font.HELVETICA_BOLD,
    });
    return this;
  }

  private sectionHeading(sectionEnum: string) {
    this.pdfBuilder.h1(this.sectionToLabel[sectionEnum]);
    return this;
  }

  private static onlyTruthyAnswers(values: Answer[]) {
    return values.filter(({ value }) => value);
  }

  stringGroup(headerLabel: string, values: string[], noSelectionsMsg: string) {
    this.pdfBuilder.h3(headerLabel);
    if (values.length > 0) {
      this.pdfBuilder.ul(values);
    } else {
      this.pdfBuilder.text(noSelectionsMsg);
    }
    return this;
  }
}
