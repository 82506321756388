import { UserRole } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import * as Sentry from '@sentry/react';
import { MemberChartErrorState } from 'app/member-chart-cards/error-state/MemberChartErrorState';
import { useAppState } from 'app/state';
import { useLogger } from 'app/state/log/useLogger';
import { TransientFeatureFlag, useFeatureFlags } from 'hooks/useFeatureFlags';
import React, { useEffect, useState } from 'react';
import { Route, RouteProps } from 'react-router';
import { NotAuthorizedScreen } from 'shared-components/error-state/NotAuthorizedScreen';
import { NotLoggedInScreen } from 'shared-components/error-state/NotLoggedInScreen';
import { ComingSoon } from 'shared-components/throwaway/ComingSoon';

type ConfigProps = {
  permittedRoles: UserRole[];
  component: React.ComponentType<any>;
  requiresFeatureFlag?: TransientFeatureFlag; // if provided checks whether the feature is enabled for auth user
  componentProps?: any;
};

type Props = Omit<RouteProps, 'render'> & ConfigProps;

export type LocationState = { previousPath: string };

const SentryRoute = Sentry.withSentryRouting(Route);

export function SecureRoute(props: Props) {
  const {
    permittedRoles,
    component,
    path,
    strict,
    exact,
    sensitive,
    requiresFeatureFlag,
    componentProps,
  } = props;
  return (
    <SentryRoute
      path={path}
      exact={exact}
      strict={strict}
      sensitive={sensitive}
      render={() => (
        <SecureRouteWrapper
          data-testid="wrapper"
          permittedRoles={permittedRoles}
          component={component}
          requiresFeatureFlag={requiresFeatureFlag}
          componentProps={componentProps}
        />
      )}
    />
  );
}

export function SecureRouteWrapper(props: ConfigProps) {
  const {
    permittedRoles,
    component: Component,
    requiresFeatureFlag,
    componentProps,
  } = props;
  const { oktaAuth, authState } = useOktaAuth();
  const { transientFeatureFlags } = useFeatureFlags();
  const role = useAppState(({ user }) => user.role);
  const logger = useLogger();

  const pendingLogin = React.useRef(false);
  const isAuthenticated = authState?.isAuthenticated ?? false;
  const error = authState?.error;
  const isLoading = !error && (pendingLogin.current || role === null);

  const [handleLoginError, setHandleLoginError] = useState<Error | null>(null);

  const isAuthorized = role !== null && permittedRoles.includes(role);

  useEffect(() => {
    const handleLogin = async () => {
      if (pendingLogin.current) return;

      pendingLogin.current = true;

      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin,
      );

      await oktaAuth.signInWithRedirect({ originalUri });
    };
    if (!authState) return;

    if (!authState.isAuthenticated) {
      logger.info(
        'SecureRoute: User is not authenticated, redirecting to okta login.',
      );
      handleLogin().catch((err) => {
        const loginError = new Error(
          'SecureRoute: unable to redirect to okta login',
          {
            cause: err,
          },
        );
        logger.error(loginError, { err });
        setHandleLoginError(err);
      });
    } else {
      pendingLogin.current = false;
    }
  }, [authState, oktaAuth]);

  if (handleLoginError) {
    return (
      <MemberChartErrorState showMessage={true} error={handleLoginError} />
    );
  }

  if (isLoading) {
    return null;
  }

  if (isAuthenticated && isAuthorized) {
    const featureEnabled = requiresFeatureFlag
      ? transientFeatureFlags[requiresFeatureFlag]
      : true;
    return featureEnabled ? <Component {...componentProps} /> : <ComingSoon />;
  }
  if (!isAuthenticated) {
    return <NotLoggedInScreen />;
  }
  return <NotAuthorizedScreen />;
}
