import { KeyGenerator } from '@ginger.io/vault-core/dist/crypto';
import {
  VaultItem,
  VaultItem_SchemaType as SchemaType,
} from '@ginger.io/vault-core/dist/generated/protobuf-schemas/vault-core/VaultItem';
import {
  NotesUserMetadata,
  NotesUserMetadata_Version,
} from '@ginger.io/vault-shared-care-notes/dist/generated/protobuf-schemas/vault-shared-care-notes/NotesUserMetadata';
import {
  createVaultItemInput,
  CreateVaultItemInputParams,
  updateVaultItemInput,
  UpdateVaultItemInputParams,
  VaultItemPermissions,
} from '@ginger.io/vault-ui';
import {
  CreateMemberChartVaultItems,
  CreateMemberChartVaultItemsVariables,
} from '@headspace/carehub-graphql/dist/coach-member-chart/generated/CreateMemberChartVaultItems';
import {
  UpdateMemberChartVaultItems,
  UpdateMemberChartVaultItemsVariables,
} from '@headspace/carehub-graphql/dist/coach-member-chart/generated/UpdateMemberChartVaultItems';
import {
  createVaultItems,
  updateVaultItems,
} from '@headspace/carehub-graphql/dist/coach-member-chart/queries';
import {
  MutationResponse,
  NotesUserMetadataParams,
} from 'app/coach/coach-notes/CoachNotesTypes';
import { getVariables as getCoachNotesVariables } from 'app/coach/coach-notes/notesQueryVariables';
import { updateCache as updateCoachNotesCache } from 'app/coach/coach-notes/queries';
import { useAppState } from 'app/state';
import { useLogger } from 'app/state/log/useLogger';
import { updateCache } from 'app/vault/hooks/NonAppointments/queries';
import { getVariables } from 'app/vault/hooks/NonAppointments/useOutOfSessionAndTerminationNotes';
import {
  CreateVaultItemInput,
  UpdateVaultItemInput,
} from 'generated/globalTypes';
import { useMutationWithGlobalState as useMutation } from 'hooks/useMutationWithGlobalState';
import Messages from 'i18n/en/vault.json';

function inputToMetadataVaultItem(data: NotesUserMetadataParams): VaultItem {
  const { vaultItemId, hasReadItem } = data;
  return {
    data: NotesUserMetadata.encode({
      hasReadItem,
      vaultItemId,
      version: NotesUserMetadata_Version.v0,
    }).finish(),
    schemaType: SchemaType.vault_shared_care_notes_user_metadata,
  };
}

export interface NotesUserMetadataHookResponse {
  saveNotesUserMetadata: (
    vaultItemId: string,
    metadataId?: string,
    hasReadItem?: boolean,
  ) => Promise<void>;
  createNotesUserMetadata: (
    params: NotesUserMetadataParams,
  ) => Promise<MutationResponse>;
  updateNotesUserMetadata: (
    itemId: string,
    params: NotesUserMetadataParams,
  ) => Promise<MutationResponse>;
}

export function useNotesUserMetadata({
  memberId,
  isCoachNotesUser,
  keyGenerator = new KeyGenerator(),
}: {
  memberId: string;
  isCoachNotesUser?: boolean;
  keyGenerator?: KeyGenerator;
}): NotesUserMetadataHookResponse {
  const logger = useLogger();

  const { userId, role } = useAppState(({ user }) => ({
    role: user.role!,
    userId: user.userId!,
  }));

  const additionalInfo = { memberId, role, userId };

  const handleMutationError = (
    error: Error,
    action: string,
    vaultItemId: string,
  ) => {
    // logging the error as a warning since the unread indicator is not critical to the function of the app
    logger.warning(`useNotesUserMetadata.${action}: ${error.message}`, {
      ...additionalInfo,
      error,
      vaultItemId,
    });
    return {
      errorMessage: `useNotesUserMetadata.${action}:${vaultItemId} - ${error.message}`,
      id: null,
      success: false,
    };
  };

  const handleCacheUpdateError = (error: Error) => {
    logger.warning(
      `useNotesUserMetadata.updateCache: Error occurred while trying to update the cache`,
      {
        ...additionalInfo,
        error,
      },
    );
  };

  const [createMetadataVaultItemsFn] = useMutation<
    CreateMemberChartVaultItems,
    CreateMemberChartVaultItemsVariables
  >(createVaultItems, {
    update(cache, { data }) {
      if (!data) return;

      if (isCoachNotesUser) {
        void getCoachNotesVariables(memberId, userId, role)
          .then((variables) =>
            updateCoachNotesCache({
              cache,
              data,
              isMetadataCache: true,
              variables,
            }),
          )
          .catch(handleCacheUpdateError);
      } else {
        void getVariables(memberId, userId, role)
          .then((variables) => updateCache({ cache, data, variables }))
          .catch(handleCacheUpdateError);
      }
    },
  });

  const [updateVaultItemsFn] = useMutation<
    UpdateMemberChartVaultItems,
    UpdateMemberChartVaultItemsVariables
  >(updateVaultItems);

  const createNotesUserMetadata = async (
    data: NotesUserMetadataParams,
  ): Promise<MutationResponse> => {
    const { vaultItemId } = data;
    const vaultItem = inputToMetadataVaultItem(data);
    try {
      const inputParams: CreateVaultItemInputParams = {
        groupsToShareWith: undefined,
        itemId: `item-metadata-${vaultItemId}-${userId}`,
        permissions: VaultItemPermissions.Writable,
        tags: [`notes-items-metadata-${userId}-${memberId}`],
        vaultItem,
      };
      const item = await createVaultItemInput(
        inputParams,
        keyGenerator,
        () => '',
        { hashItemIds: true },
      );

      const {
        errors: createVaultItemErrors,
        data: response,
      } = await createMetadataVaultItemsFn({
        input: [(item as unknown) as CreateVaultItemInput],
      });

      if (createVaultItemErrors || !response) {
        throw new Error(
          createVaultItemErrors
            ?.map((_: { message: any }) => _.message)
            .join('\n') ?? Messages.failureToCreateNoteMetadata,
        );
      }

      return {
        id: response.createVaultItems[0].encryptedItem.id,
        success: true,
      };
    } catch (e) {
      return handleMutationError(e, 'createNotesUserMetadata', vaultItemId);
    }
  };

  const updateNotesUserMetadata = async (
    itemId: string,
    data: NotesUserMetadataParams,
  ): Promise<MutationResponse> => {
    const { vaultItemId } = data;
    const vaultItem = inputToMetadataVaultItem(data);
    try {
      const inputParams: UpdateVaultItemInputParams = {
        groupId: undefined,
        itemId,
        permissions: VaultItemPermissions.Writable,
        tags: [`notes-items-metadata-${userId}-${memberId}`],
        vaultItem,
      };
      const item = await updateVaultItemInput(inputParams, keyGenerator, {
        hashItemIds: true,
      });
      const { errors: updateError, data: response } = await updateVaultItemsFn({
        input: [(item as unknown) as UpdateVaultItemInput],
      });

      if (updateError || !response) {
        throw new Error(
          updateError?.map((_: { message: any }) => _.message).join('\n') ??
            Messages.failureToUpdateNoteMetadata,
        );
      }

      return {
        id: response.updateVaultItems[0].id,
        success: true,
      };
    } catch (e) {
      return handleMutationError(e, 'updateNotesUserMetadata', vaultItemId);
    }
  };

  const saveNotesUserMetadata = async (
    vaultItemId: string,
    metadataId?: string,
    hasReadItem?: boolean,
  ): Promise<void> => {
    const data = { hasReadItem: true, vaultItemId };

    if (!metadataId) {
      await createNotesUserMetadata(data);
    } else if (!hasReadItem) {
      await updateNotesUserMetadata(metadataId, data);
    }
  };

  return {
    createNotesUserMetadata,
    saveNotesUserMetadata,
    updateNotesUserMetadata,
  };
}
