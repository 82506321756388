import { field, stringField, useForm } from '@ginger.io/react-use-form';
import {
  ClinicalEventType,
  UpdateClinicalEventInput,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { DateTimeRangeField, DropDownField } from 'app/notes-ui/forms/fields';
import { DATE_TIME_FORMAT } from 'app/notes-ui/forms/form-controls/DateTimeRangeInput';
import { NoteFormControlLabel } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { TopLevelRoutes } from 'app/top-nav/Routes';
import moment from 'moment';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Size } from 'types/StyleTypes';

import styles from './AppointmentScreen.module.scss';
import { EventControls } from './EventControls';
import { EmptyFormValue } from './NewEventForm';

export type EventFormFields = {
  eventType: ClinicalEventType | EmptyFormValue;
  start: string;
  end: string;
};

export type EventFormProps = {
  onSave: (event: UpdateClinicalEventInput) => Promise<void>;
  onDelete: () => Promise<void>;
  onClose: () => void;
  initialValues: EventFormFields;
  timezone: string;
};

export function EventForm({
  onSave,
  onClose,
  initialValues,
  timezone,
  onDelete,
}: EventFormProps) {
  const { fields, validate, getValue } = useForm<EventFormFields>(
    {
      end: stringField(),
      eventType: field(),
      start: stringField(),
    },
    initialValues,
  );
  async function onSaveWrapper() {
    const valid = await validate();
    if (!valid) {
      return;
    }
    const eventData = getValue();
    const start = moment
      .tz(eventData.start, DATE_TIME_FORMAT, timezone)
      .toISOString();
    const end = moment
      .tz(eventData.end, DATE_TIME_FORMAT, timezone)
      .toISOString();
    const eventType = eventData.eventType as ClinicalEventType;
    onSave({ end, eventType, start });
  }

  return (
    <EventControls
      onSave={onSaveWrapper}
      onClose={onClose}
      onDelete={onDelete}
      disableMenuAction={false}
    >
      <div className={styles.flex}>
        <div className={styles.controlGroup}>
          <NoteFormControlLabel label="Event Type">
            <DropDownField
              field={fields.eventType}
              dropdownProps={{
                className: styles.input,
                dataTestId: 'eventType',
                options: [
                  {
                    name: 'Interview Panel',
                    value: ClinicalEventType.INTERVIEW_PANEL,
                  },
                  { name: 'QA Work', value: ClinicalEventType.QA_WORK },
                  {
                    name: 'Special Project',
                    value: ClinicalEventType.SPECIAL_PROJECT,
                  },
                  { name: 'Training', value: ClinicalEventType.TRAINING },
                ],
                placeholder: 'Event Type',
                size: Size.MD,
              }}
            />
          </NoteFormControlLabel>
        </div>
      </div>

      <div className={styles.flex}>
        <DateTimeRangeField
          label="Date and Time"
          field={{ end: fields.end, start: fields.start }}
        >
          <div className={styles.timezone}>
            <NavLink to={TopLevelRoutes.SETTINGS}>{timezone}</NavLink>
          </div>
        </DateTimeRangeField>
      </div>
    </EventControls>
  );
}
