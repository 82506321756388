import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthService } from 'app/state/features/auth/AuthService';

import { IAuthSlice, SessionState } from './types';

export const getInitialState = (): IAuthSlice => ({
  sessionState: SessionState.NOT_INITIALIZED,
});

const authSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(AuthService.logout.fulfilled, (state) => {
      Object.assign(state, {
        sessionState: SessionState.NOT_INITIALIZED,
      });
    });
  },
  initialState: getInitialState(),
  name: 'auth',
  // we can use extra reducers API to respond to actions of other slices
  reducers: {
    updateSessionState(state, { payload }: PayloadAction<SessionState>) {
      state.sessionState = payload;
    },
  },
});

export default authSlice.reducer;

export const { updateSessionState } = authSlice.actions;
