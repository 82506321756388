import { CoachShiftStatusEnum } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { Circle } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import styles from 'app/inbox/vertical-menu/styles/index.module.scss';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import React from 'react';
import { classNameCombiner } from 'utils';
import { snakeCaseToTitleCase } from 'utils/snakeCaseToTitleCase';

const OFFLINE_INDICATOR_STATUSES = [
  CoachShiftStatusEnum.LUNCH,
  CoachShiftStatusEnum.MEETING,
  CoachShiftStatusEnum.ONE_ON_ONE,
  CoachShiftStatusEnum.PROCESS_GROUP,
  CoachShiftStatusEnum.REST_BREAK,
  CoachShiftStatusEnum.SNAPS,
  CoachShiftStatusEnum.WRAP_UP,
];

const COACH_SHIFT_STATUS_INDICATOR_MAP = new Map<CoachShiftStatusEnum, string>([
  [CoachShiftStatusEnum.ONLINE, styles.green],
  [CoachShiftStatusEnum.REPLY_ONLY, styles.tumeric],
  [CoachShiftStatusEnum.OFFLINE, styles.red],
]);

const COACH_SHIFT_STATUS_INDICATOR_MAP_V2 = new Map<
  CoachShiftStatusEnum,
  string
>([
  [CoachShiftStatusEnum.ONLINE, styles.blue],
  [CoachShiftStatusEnum.REPLY_ONLY, styles.green],
  [CoachShiftStatusEnum.OFFLINE, styles.red],
]);

const CoachShiftStatusDisplay: Partial<Record<CoachShiftStatusEnum, string>> = {
  [CoachShiftStatusEnum.REPLY_ONLY]: 'Scheduled/Reply-only',
  [CoachShiftStatusEnum.ONLINE]: 'Accepting ITMS',
};

type Props = {
  firstName: string;
  lastName: string;
  currentStatus: CoachShiftStatusEnum;
  onStatusChange: (
    status: CoachShiftStatusEnum,
  ) => (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
};

export function CoachMenuItems({
  firstName,
  lastName,
  currentStatus,
  onStatusChange,
}: Props) {
  const {
    enable_itms_toggle: enableItmsToggle,
  } = useFeatureFlags().transientFeatureFlags;
  const fullName = `${firstName} ${lastName}`;
  const indicatorStyle = enableItmsToggle
    ? COACH_SHIFT_STATUS_INDICATOR_MAP_V2.get(currentStatus) ?? styles.red
    : COACH_SHIFT_STATUS_INDICATOR_MAP.get(currentStatus) ?? styles.red;

  const menuItems = [
    <MenuItem
      className={classNameCombiner([
        styles.menuItem,
        styles.specialMenuItem,
        enableItmsToggle ? styles.menuItemContainer : '',
      ])}
      key="special"
    >
      <ListItemIcon
        data-testid="indicator"
        className={classNameCombiner([
          styles.listItemIcon,
          enableItmsToggle ? '' : styles.specialItemIcon,
          indicatorStyle,
        ])}
      >
        <Circle />
      </ListItemIcon>
      <ListItemText>
        <div className={styles.username} data-testid="username">
          {fullName}
        </div>
        <div className={styles.userStatus} data-testid="user-status">
          {enableItmsToggle
            ? CoachShiftStatusDisplay[currentStatus]
            : snakeCaseToTitleCase(currentStatus)}
        </div>
      </ListItemText>
    </MenuItem>,
  ];

  const onlineAndReplyOnly = [
    <MenuItem
      className={styles.menuItem}
      onClick={onStatusChange(CoachShiftStatusEnum.ONLINE)}
      key="online"
      data-testid={CoachShiftStatusEnum.ONLINE}
    >
      <ListItemIcon
        className={classNameCombiner([styles.listItemIcon, styles.green])}
      >
        <Circle />
      </ListItemIcon>
      <ListItemText>
        <div className={styles.topLevelStatus}>Online</div>
      </ListItemText>
    </MenuItem>,
    <MenuItem
      className={styles.menuItem}
      onClick={onStatusChange(CoachShiftStatusEnum.REPLY_ONLY)}
      key="reply-only"
      data-testid={CoachShiftStatusEnum.REPLY_ONLY}
    >
      <ListItemIcon
        className={classNameCombiner([styles.listItemIcon, styles.tumeric])}
      >
        <Circle />
      </ListItemIcon>
      <ListItemText>
        <div className={styles.topLevelStatus}>Reply-only</div>
      </ListItemText>
    </MenuItem>,
  ];

  const itmsToggle = [
    <MenuItem
      className={styles.menuItem}
      onClick={onStatusChange(CoachShiftStatusEnum.REPLY_ONLY)}
      key={`${CoachShiftStatusEnum.REPLY_ONLY}-itms`}
      data-testid={`${CoachShiftStatusEnum.REPLY_ONLY}-itms`}
    >
      <ListItemIcon
        className={classNameCombiner([styles.listItemIcon, styles.green])}
      >
        <Circle />
      </ListItemIcon>
      <ListItemText>
        <div className={styles.topLevelStatus}>Scheduled/Reply-only</div>
      </ListItemText>
    </MenuItem>,
    <MenuItem
      className={styles.menuItem}
      onClick={onStatusChange(CoachShiftStatusEnum.ONLINE)}
      key={`${CoachShiftStatusEnum.ONLINE}-itms`}
      data-testid={`${CoachShiftStatusEnum.ONLINE}-itms`}
    >
      <ListItemIcon
        className={classNameCombiner([styles.listItemIcon, styles.blue])}
      >
        <Circle />
      </ListItemIcon>
      <ListItemText>
        <div className={styles.topLevelStatus}>Accepting ITMS</div>
      </ListItemText>
    </MenuItem>,
  ];

  const offlineIndicators = [
    <MenuItem
      className={styles.menuItem}
      onClick={onStatusChange(CoachShiftStatusEnum.OFFLINE)}
      key="offline"
      data-testid={CoachShiftStatusEnum.OFFLINE}
    >
      <ListItemIcon
        className={classNameCombiner([styles.listItemIcon, styles.red])}
      >
        <Circle />
      </ListItemIcon>
      <ListItemText>
        <div className={styles.topLevelStatus}>Offline</div>
      </ListItemText>
    </MenuItem>,
    ...OFFLINE_INDICATOR_STATUSES.map((status) => (
      <MenuItem
        className={styles.menuItem}
        onClick={onStatusChange(status)}
        key={status}
        data-testid={status}
      >
        <ListItemIcon
          className={classNameCombiner([styles.listItemIcon, styles.white])}
        >
          <Circle />
        </ListItemIcon>
        <ListItemText>{snakeCaseToTitleCase(status)}</ListItemText>
      </MenuItem>
    )),
  ];

  return (
    <>
      {enableItmsToggle
        ? [...menuItems, ...itmsToggle, ...offlineIndicators]
        : [...menuItems, ...onlineAndReplyOnly, ...offlineIndicators]}
    </>
  );
}
