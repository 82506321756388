import FourDots from 'app/charts/FourDots';
import PSSLineChart from 'app/charts/PSSLineChart';
import { Box } from 'shared-components/Box';
import { PSS_SCORE_LABEL } from 'app/patients/tabs/surveys/SurveysV3/constants';
import { ScoreDetails } from 'app/patients/tabs/surveys/SurveysV3/ScoreDetails';
import {
  GetMemberSurvey_getMember_surveyResponses,
  GetMemberSurvey_getMember_surveyResponses_pssSurveys_surveys,
} from 'app/queries/generated/GetMemberSurvey';
import { viewSurveyTabAction } from 'app/state/amplitude/actions/surveys';
import { sortTimestampAsc } from 'utils';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { COLUMN_WIDTH, Columns, Grid } from 'shared-components/grid';
import { NavigationTabs } from 'shared-components/tabs/NavigationTabs';

import PssInfoBox from './PssInfoBox';
import styles from './SurveysTabV3PSS.module.scss';
import { PSSSummary, PSSSurveyResponse } from './types';
import { useTabLinksForSurveyV3 } from './utils';

interface Props {
  id: string;
  pathPrefix: string;
  coachingPaths?: boolean;
  surveyResponses?: GetMemberSurvey_getMember_surveyResponses | null;
}

export default function SurveysTabV3PSS(props: Props) {
  const { id, pathPrefix, coachingPaths = false, surveyResponses } = props;
  const tabs = useTabLinksForSurveyV3(pathPrefix, id, coachingPaths);

  const pss = formatPSS(surveyResponses);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(viewSurveyTabAction());
  }, [dispatch]);

  const [selectedInfo, setSelectedInfo] = useState<PSSSummary | null>(null);
  const [selectedResponse, setSelectedResponse] = useState<
    MaybeUndefined<GetMemberSurvey_getMember_surveyResponses_pssSurveys_surveys>
  >(undefined);

  const handleClick = (index: number, data: PSSSurveyResponse) => {
    setSelectedInfo({
      stressLevel: data.stress ?? '',
    });
    setSelectedResponse(surveyResponses?.pssSurveys?.surveys[index]);
  };

  return (
    <div className={styles.body}>
      <div className={styles.content}>
        <Grid>
          <Columns widths={[COLUMN_WIDTH.FULL]}>
            <Box>
              <div className={styles.surveyScore}>
                <NavigationTabs links={tabs} preserveQueries={true} />
                <div className={styles.graph}>
                  {pss.length >= 1 ? (
                    <PSSLineChart
                      disableTooltip={true}
                      responses={pss}
                      onClick={handleClick}
                    />
                  ) : (
                    <p>Member has not completed this survey yet. </p>
                  )}
                </div>
                <PssInfoBox info={selectedInfo} />
                <ScoreDetails
                  Component={FourDots}
                  scoreLabels={PSS_SCORE_LABEL}
                  questions={surveyResponses?.pssSurveys?.questions}
                  answers={selectedResponse}
                />
              </div>
            </Box>
          </Columns>
        </Grid>
      </div>
    </div>
  );
}

export function formatPSS(
  surveyResponses?: GetMemberSurvey_getMember_surveyResponses | null,
): PSSSurveyResponse[] {
  return (surveyResponses?.pssSurveys?.surveys ?? [])
    .sort(sortTimestampAsc)
    .map(({ ...rest }) => {
      return {
        ableToControlIrritationsInYourLife:
          rest.ableToControlIrritationsInYourLife ?? 0,
        angeredByThingsOutsideOfYourControl:
          rest.angeredByThingsOutsideOfYourControl ?? 0,
        feltConfidentToHandlePersonalProblems:
          rest.feltConfidentToHandlePersonalProblems ?? 0,
        feltDifficultiesPiling: rest.feltDifficultiesPiling ?? 0,
        feltNervousAndStressed: rest.feltNervousAndStressed ?? 0,
        feltOnTopOfThings: rest.feltOnTopOfThings ?? 0,
        feltThingsGoingYourWay: rest.feltThingsGoingYourWay ?? 0,
        foundCouldNotCopeWithAllTheThings:
          rest.foundCouldNotCopeWithAllTheThings ?? 0,
        stress: rest.stress ?? '',
        timestamp: rest.timestamp ?? '',
        total: rest.total ?? 0,
        unableToControlImportantThings:
          rest.unableToControlImportantThings ?? 0,
        upsetBecauseOfSomethingUnexpected:
          rest.upsetBecauseOfSomethingUnexpected ?? 0,
      };
    });
}
