import { UserRole } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { State } from 'app/state/schema';
import { useSelector } from 'react-redux';

export const selectUserRole = () =>
  useSelector((state: State) => state.user.role);

export const selectUserIdAndRole = ({ user }: State) => ({
  role: user.role,
  userId: user.userId,
});

export const selectShouldDisplayInboxV2 = (enableInboxV2: boolean) => (
  state: State,
) => {
  const { role } = state.user;
  return (
    enableInboxV2 &&
    (state.user.role === UserRole.COACH || role === UserRole.COACH_SUPERVISOR)
  );
};
