import { BooleanOption } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/BooleanOption';
import { Metadata_NoteStatus } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/Metadata';
import {
  TherapyIntakeSection,
  TherapyIntakeSectionName,
} from '@ginger.io/vault-clinical-notes/dist/TherapyIntakeSection';
import { DeleteVaultItemsMutation } from '@ginger.io/vault-ui/src/generated/graphql';
import { ShareableClinicianNoteType } from 'app/coach/coach-notes/CoachNotesTypes';
import { ClinicalNoteUploadData } from 'app/notes-ui/qa-checks/types';
import { useUploadClinicalNoteForQaChecks } from 'app/notes-ui/qa-checks/useUploadClinicalNoteForQaChecks';
import { useAppState } from 'app/state';
import { careProviderNotesEvent } from 'app/state/amplitude/actions/notes';
import { ILogger } from 'app/state/log/Logger';
import { useLogger } from 'app/state/log/useLogger';
import { SubsectionType } from 'app/vault/api/ShareableSubsectionTypes';
import {
  TherapyIntakeNote,
  TherapyIntakeNotesAPI,
} from 'app/vault/api/TherapyIntakeNotesAPI';
import {
  GetAppointmentById_getAppointmentById as Appointment,
  GetAppointmentById_getAppointmentById,
} from 'app/vault/generated/GetAppointmentById';
import { validateTherapyIntakeNote } from 'app/vault/validations/therapyIntake';
import { NoteType } from 'generated/globalTypes';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useOnMount } from 'hooks/useOnMount';
import Messages from 'i18n/en/vault.json';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isTeen } from 'utils';
import { getTimezone } from 'utils/dateTime';
import { CareProviderNotesLabel } from 'utils/notes';

import { TherapyIntakeNotePrepopulator } from '../data/therapyIntakeNotePrepopulator';
import { getNonNullTherapyIntakeNoteSections } from '../data/utils';
import { useTherapyIntakeNotesAPI } from './useClinicalNotesAPI';
import {
  isItemAlreadyExistsError,
  StateController,
  useStateSlice,
  UseTherapyNoteResult,
} from './utils';

export function useTherapyIntakeNote(
  userId: string,
  appointmentId: string,
): UseTherapyNoteResult<TherapyIntakeNote, TherapyIntakeSection> {
  const logger = useLogger();
  const api = useTherapyIntakeNotesAPI();
  const dispatch = useDispatch();
  const noteState = useStateSlice<TherapyIntakeNote>();
  const noteDraftState = useStateSlice<TherapyIntakeNote>();
  const [appointment, setAppointment] = useState<Appointment>();
  const timezone = useAppState((_) => getTimezone(_.user.timezone));
  const {
    transientFeatureFlags: {
      enable_supervisor_sign_and_lock_notes_for_user: allowGroupWriteAccess,
      enable_prefill_phqgad_answer_in_note: preFillPHQGADAnswer,
    },
  } = useFeatureFlags();
  const { uploadClinicalNoteForQaChecks } = useUploadClinicalNoteForQaChecks();
  const isLockable = validateTherapyIntakeNote(noteState.state.current.data);

  useOnMount(() => {
    const analyticsEventData = {
      appointmentId,
      clinicianId: userId,
      label: CareProviderNotesLabel.NOTE_VIEWED,
      noteType: ShareableClinicianNoteType.THERAPY_INTAKE,
    };
    const callback = async () => {
      try {
        noteState.setLoading();
        const [appointmentData, noteData] = await Promise.all([
          api.getAppointment(appointmentId),
          api.getNote(appointmentId),
        ]);

        setAppointment(appointmentData);
        await setInitialNote(appointmentData, noteData);

        logger.info(
          'useTherapyIntakeNote onMount succeeded',
          analyticsEventData,
        );
      } catch (e) {
        noteState.setError(e);
        logger.error(e, {
          message: 'useTherapyIntakeNote onMount failed',
          ...analyticsEventData,
        });
      }
    };
    callback();
    dispatch(careProviderNotesEvent(analyticsEventData));
  });

  const setInitialNote = useCallback(
    async (appointmentData: Appointment, noteData: TherapyIntakeNote) => {
      try {
        const prepopulatedNote = await createPrepopulatedNoteSections({
          allowGroupWriteAccess,
          api,
          appointment: appointmentData,
          note: noteData,
          preFillPHQGADAnswer,
          userId,
        });

        noteState.setData(prepopulatedNote);
        noteDraftState.setData(prepopulatedNote);

        logger.debug(
          `useTherapyIntakeNote: Successfully prepopulated note for appointment ${appointmentData.id}`,
          {
            appointmentId: appointmentData.id,
            clinicianId: userId,
            memberId: appointmentData.member.id,
          },
        );
      } catch (error) {
        // Swallow any errors for failed prepopulation and continue with the original note data
        logger.info(
          `useTherapyIntakeNote: Failed to prepopulate note for appointmnet ${appointmentData.id}: ${error.message}`,
          {
            appointmentId: appointmentData.id,
            clinicianId: userId,
            error,
            memberId: appointmentData.member.id,
          },
        );

        noteState.setData(noteData);
        noteDraftState.setData(noteData);
      }
    },
    [allowGroupWriteAccess, api, logger, noteDraftState, noteState, userId],
  );

  const createOrUpdateSection = useCallback(
    createOrUpdateSectionHandler(
      userId,
      appointment,
      api,
      noteState,
      allowGroupWriteAccess,
      dispatch,
      logger,
    ),
    [userId, appointment, api, noteState.state.current, allowGroupWriteAccess],
  );

  const updateDraftNoteState = useCallback(updateDraftState(noteDraftState), [
    noteDraftState.state.current,
  ]);

  const lockNote = useCallback(
    lockNoteHandler(
      userId,
      appointment,
      api,
      noteState,
      timezone,
      allowGroupWriteAccess,
      dispatch,
      logger,
      uploadClinicalNoteForQaChecks,
    ),
    [
      appointment,
      api,
      noteState.state.current,
      timezone,
      userId,
      allowGroupWriteAccess,
    ],
  );

  const deleteDraft = useCallback(
    appointment && noteState.state.current.data
      ? deleteDraftHandler(
          userId,
          appointment,
          api,
          noteState.state.current.data,
          dispatch,
        )
      : () => Promise.resolve({} as DeleteVaultItemsMutation),
    [appointment, api, noteState.state.current],
  );

  return {
    appointment,
    createOrUpdateSection,
    deleteDraft,
    draftNote: noteDraftState.state,
    isLockable,
    lockNote,
    note: noteState.state,
    updateDraftNoteState,
  };
}

async function createAmendment(
  userId: string,
  appointment: Appointment,
  api: TherapyIntakeNotesAPI,
  noteState: StateController<TherapyIntakeNote>,
  section: TherapyIntakeSection,
  dispatch: ReturnType<typeof useDispatch>,
  logger: ILogger,
) {
  const note = noteState.state.current.data;
  if (note === null) {
    throw new Error(
      `${Messages.failureToCreateAmendmentSection}: ${noteState.state.current.status}`,
    );
  }

  const analyticsEventData = {
    appointmentId: appointment.id,
    clinicianId: userId,
    label: CareProviderNotesLabel.NOTE_AMENDED,
    memberId: appointment.member.id,
    noteType: ShareableClinicianNoteType.THERAPY_INTAKE,
  };

  try {
    await api.createAmendment(userId, appointment.id, section);
  } catch (e) {
    logger.error(e, {
      message: 'useTherapyIntakeNote createAmendment failed',
      ...analyticsEventData,
    });
    throw e;
  }

  const updatedNote: TherapyIntakeNote = {
    ...note,
    [section.name]: section.data,
  };
  noteState.setData(updatedNote);
  dispatch(careProviderNotesEvent(analyticsEventData));
}

export const createOrUpdateSectionHandler = (
  userId: string,
  appointment: Appointment | undefined,
  api: TherapyIntakeNotesAPI,
  noteState: StateController<TherapyIntakeNote>,
  allowGroupWriteAccess: boolean,
  dispatch: ReturnType<typeof useDispatch>,
  logger: ILogger,
  onCreateDraftNoteSectionHandler = createDraftNoteSectionHandler,
  onUpdateDraftNoteSectionHandler = updateDraftNoteSectionHandler,
): ((section: TherapyIntakeSection) => Promise<void>) => {
  return async (section: TherapyIntakeSection) => {
    const note = noteState.state.current.data;

    if (note === null) {
      throw new Error(
        `${Messages.failureToCreateOrUpdateNoteSection}: ${noteState.state.current.status}`,
      );
    }

    if (appointment === undefined) {
      throw new Error(Messages.appointmentNotDefined);
    }

    if (api.amendmentSectionName === section.name) {
      return createAmendment(
        userId,
        appointment,
        api,
        noteState,
        section,
        dispatch,
        logger,
      );
    }

    if (note[section.name] === null) {
      try {
        await onCreateDraftNoteSectionHandler({
          allowGroupWriteAccess,
          api,
          appointment,
          dispatch,
          logger,
          note,
          noteState,
          section,
          userId,
        });
      } catch (e) {
        if (!isItemAlreadyExistsError(e)) {
          logger.info(
            'useTherapyIntakeNote: onCreateDraftNoteSectionHandler skipping retry',
            { error: e },
          );
          throw e;
        }
        logger.info(
          `useTherapyIntakeNote: onCreateDraftNoteSectionHandler failed for section ${section.name}, retrying as update...`,
          {
            appointmentId: appointment.id,
            clinicianId: userId,
            error: e,
            label: CareProviderNotesLabel.NOTE_CREATED,
            noteType: ShareableClinicianNoteType.THERAPY_INTAKE,
            section: section.name,
          },
        );
        await onUpdateDraftNoteSectionHandler({
          allowGroupWriteAccess,
          api,
          appointment,
          dispatch,
          logger,
          note,
          noteState,
          section,
          userId,
        });
      }
    } else if (note[section.name] !== null) {
      await onUpdateDraftNoteSectionHandler({
        allowGroupWriteAccess,
        api,
        appointment,
        dispatch,
        logger,
        note,
        noteState,
        section,
        userId,
      });
    }
  };
};
export type createOrUpdateDraftNoteSectionProps = {
  note: TherapyIntakeNote;
  section: TherapyIntakeSection;
  api: TherapyIntakeNotesAPI;
  userId: string;
  appointment: GetAppointmentById_getAppointmentById;
  allowGroupWriteAccess: boolean;
  noteState: StateController<TherapyIntakeNote>;
  dispatch: ReturnType<typeof useDispatch>;
  logger: ILogger;
};

export const createDraftNoteSectionHandler = async (
  data: createOrUpdateDraftNoteSectionProps,
) => {
  const {
    note,
    section,
    api,
    userId,
    appointment,
    allowGroupWriteAccess,
    noteState,
    dispatch,
    logger,
  } = data;

  const careProviderNoteEventPayload = {
    appointmentId: note.metadata.appointmentId,
    clinicianId: userId,
    label: CareProviderNotesLabel.NOTE_CREATED,
    memberId: appointment.member.id,
    noteType: ShareableClinicianNoteType.THERAPY_INTAKE,
    section: section.name,
  };
  const needToCreateMetadata =
    note.metadata.status === Metadata_NoteStatus.undefined_note_status;

  const updatedNote: TherapyIntakeNote = {
    ...note,
    metadata: {
      ...note.metadata,
      status: Metadata_NoteStatus.draft_note,
    },
    [section.name]: section.data,
  };

  await api.createDraftNoteSection(
    userId,
    appointment,
    section,
    needToCreateMetadata ? updatedNote.metadata : undefined,
    allowGroupWriteAccess,
  );
  logger.info(
    `useTherapyIntakeNote: createOrUpdateSectionHandler successfully created draft section ${section.name}`,
    {
      ...careProviderNoteEventPayload,
    },
  );

  noteState.setData(updatedNote);
  dispatch(careProviderNotesEvent(careProviderNoteEventPayload));
};

export const updateDraftNoteSectionHandler = async (
  data: createOrUpdateDraftNoteSectionProps,
) => {
  const {
    note,
    section,
    api,
    userId,
    appointment,
    allowGroupWriteAccess,
    noteState,
    dispatch,
    logger,
  } = data;

  const amplitudePayload = {
    appointmentId: note.metadata.appointmentId,
    clinicianId: userId,
    label: CareProviderNotesLabel.NOTE_UPDATED,
    memberId: appointment.member.id,
    noteType: ShareableClinicianNoteType.THERAPY_INTAKE,
    section: section.name,
  };
  const updatedNote = { ...note, [section.name]: section.data };

  try {
    await api.updateDraftNoteSection(
      userId,
      appointment,
      section,
      undefined,
      allowGroupWriteAccess,
    );
    logger?.info(
      `useTherapyIntakeNote: createOrUpdateSectionHandler successfully updated draft section ${section.name}`,
      { ...amplitudePayload },
    );
  } catch (e) {
    logger?.error(e, {
      message: 'useTherapyIntakeNote: failed to updateDraftNoteSection',
      ...amplitudePayload,
    });
    throw e;
  }
  noteState.setData(updatedNote);
  dispatch(careProviderNotesEvent(amplitudePayload));
};

/**
 * Creates draft note sections with data prepopulated from the intake survey.
 */
async function createPrepopulatedNoteSections(params: {
  appointment: Appointment;
  note: TherapyIntakeNote;
  api: TherapyIntakeNotesAPI;
  allowGroupWriteAccess: boolean;
  preFillPHQGADAnswer: boolean;
  userId: string;
}): Promise<TherapyIntakeNote> {
  const {
    api,
    allowGroupWriteAccess,
    userId,
    appointment,
    note,
    preFillPHQGADAnswer,
  } = params;
  // Return early if the note is already in an in-progress state that should not be overwritten with prepopulated data
  if (note.metadata.status !== Metadata_NoteStatus.undefined_note_status) {
    throw new Error('Note is already in an in-progress state');
  }

  const memberId = appointment.member.id;

  const intakeSurveyPromise = !isTeen(appointment.member.dateOfBirth)
    ? api.getMemberIntakeSurvey(memberId)
    : Promise.resolve(null);

  const phqGadSurveyPromise = preFillPHQGADAnswer
    ? api.getPHQGADSurvey({ memberId })
    : Promise.resolve(null);

  const [intakeSurvey, phqGadSurvey] = await Promise.all([
    intakeSurveyPromise,
    phqGadSurveyPromise,
  ]);

  const prepopulatedNote = TherapyIntakeNotePrepopulator.prepopulate({
    appointmentId: appointment.id,
    initialNote: note,
    intakeSurvey,
    phqGadSurvey,
  });

  await api.createDraftNoteSections(
    userId,
    appointment,
    getNonNullTherapyIntakeNoteSections(prepopulatedNote),
    prepopulatedNote.metadata,
    allowGroupWriteAccess,
  );

  return prepopulatedNote;
}

function updateDraftState(
  noteState: StateController<TherapyIntakeNote>,
): (section: TherapyIntakeSection) => void {
  return (section: TherapyIntakeSection) => {
    const note = noteState.state.current.data;
    if (note === null) {
      throw new Error(
        `${Messages.failureToUpdateDraftState}: ${noteState.state.current.status}`,
      );
    }

    if (section.name === TherapyIntakeSectionName.AMENDMENTS) return;

    const updatedNote = { ...note, [section.name]: section.data };
    noteState.setData(updatedNote);
  };
}

function lockNoteHandler(
  clinicianId: string,
  appointment: Appointment | undefined,
  api: TherapyIntakeNotesAPI,
  noteState: StateController<TherapyIntakeNote>,
  timezone: string | null,
  allowGroupWriteAccess: boolean,
  dispatch: ReturnType<typeof useDispatch>,
  logger: ILogger,
  uploadClinicalNoteForQaChecks: (
    props: ClinicalNoteUploadData,
  ) => Promise<void>,
): () => Promise<void> {
  return async () => {
    if (noteState.state.current.data === null) {
      throw new Error(
        `${Messages.failureToLockNote}: ${noteState.state.current.status}`,
      );
    }

    if (appointment === undefined) {
      throw new Error(Messages.appointmentNotDefined);
    }

    const appointmentId = appointment.id;
    const analyticsEventData = {
      appointmentId,
      clinicianId: appointment.clinician.userId,
      label: CareProviderNotesLabel.NOTE_SIGNED_AND_LOCKED,
      memberId: appointment.member.id,
      noteType: ShareableClinicianNoteType.THERAPY_INTAKE,
      safetyRiskEndorsed:
        noteState.state.current.data.SAFETY?.anyChangesOrRisks ===
        BooleanOption.yes,
    };
    const updatedNote: TherapyIntakeNote = {
      ...noteState.state.current.data,
      metadata: {
        appointmentId,
        status: Metadata_NoteStatus.signed_and_locked_note,
      },
    };

    try {
      await api.updateNoteMetadata(
        updatedNote.metadata,
        appointment.member.id,
        allowGroupWriteAccess,
      );
      logger.info(
        'useTherapyIntakeNote: lockNoteHandler successfully updateNoteMetadata',
        { ...analyticsEventData },
      );
    } catch (e) {
      logger.error(e, {
        message:
          'useTherapyIntakeNote: lockNoteHandler failed to updateNoteMetadata',
        ...analyticsEventData,
      });
      throw e;
    }

    try {
      await api.lockNoteSections(appointment);
      logger.info(
        'useTherapyIntakeNote: lockNoteHandler successfully lockNoteSections',
        { ...analyticsEventData },
      );
    } catch (e) {
      logger.error(e, {
        message:
          'useTherapyIntakeNote: lockNoteHandler failed to lockNoteSections',
        ...analyticsEventData,
      });
      throw e;
    }

    try {
      await api.createShareableSubsections(
        appointment,
        NoteType.THERAPY_INTAKE,
        {
          [SubsectionType.SAFETY_PLAN]: updatedNote.SAFETY?.safetyPlan,
          [SubsectionType.TREATMENT_PLAN_GOALS]:
            updatedNote.TREATMENT_PLAN?.goal,
          [SubsectionType.MESSAGE_TO_CARE_TEAM]:
            updatedNote.TREATMENT_PLAN?.messageToCareTeam,
        },
      );
      logger.info(
        'useTherapyIntakeNote: lockNoteHandler successfully createShareableSubsections',
        {
          ...analyticsEventData,
        },
      );
    } catch (e) {
      logger.error(e, {
        message:
          'useTherapyIntakeNote: lockNoteHandler failed to createShareableSubsections. Continuing anyway.',
        ...analyticsEventData,
      });
    }

    noteState.setData(updatedNote);
    dispatch(careProviderNotesEvent(analyticsEventData));

    // useTherapyIntakeNote: Submit the signed and locked note for Brellium QA checks
    await uploadClinicalNoteForQaChecks({
      appointmentMetadata: appointment,
      clinicalNote: updatedNote,
      noteType: NoteType.THERAPY_INTAKE,
    });
  };
}

function deleteDraftHandler(
  userId: string,
  appointment: Appointment,
  api: TherapyIntakeNotesAPI,
  note: TherapyIntakeNote,
  dispatch: ReturnType<typeof useDispatch>,
): () => Promise<DeleteVaultItemsMutation> {
  return async () => {
    const deletePromise = await api.deleteDraftNote(userId, appointment, note);
    dispatch(
      careProviderNotesEvent({
        appointmentId: note.metadata.appointmentId,
        clinicianId: userId,
        label: CareProviderNotesLabel.NOTE_DELETED,
        memberId: appointment.member.id,
        noteType: ShareableClinicianNoteType.THERAPY_INTAKE,
      }),
    );
    return deletePromise;
  };
}
