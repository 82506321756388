import { ApolloClient } from '@apollo/client';
import { CoachClinicianCollaborationChatMessage } from '@ginger.io/vault-care-collaboration/dist/generated/protobuf-schemas/vault-care-collaboration/CoachClinicianCollaborationChatMessage';
import { KeyGenerator } from '@ginger.io/vault-core/dist/crypto';
import {
  getClinicalCareTeamGroupId,
  getCoachingTeamGroupId,
} from '@ginger.io/vault-core/dist/IdHelpers';
import {
  ChatMessageMutation,
  ChatMessageMutationVariables,
  createVaultItemInput,
  CreateVaultItemInputParams,
  VaultItemPermissions,
  VaultSystemName,
} from '@ginger.io/vault-ui';
import { createChatMessage } from '@headspace/carehub-graphql/dist/collaboration/queries';
import { v4 as uuidv4 } from 'uuid';

import { ChatMessage } from './type';
import {
  chatMessageToVaultItem,
  decodeCollaborationChatMessage,
  getCollaborationChatStreamTag,
} from './utils';

export async function createCollaborationChatMessage(
  apolloClient: ApolloClient<any>,
  chatMessage: CoachClinicianCollaborationChatMessage,
  memberId: string,
  keyGenerator: KeyGenerator = new KeyGenerator(),
  idGenerator: () => string = uuidv4,
): Promise<ChatMessage> {
  const groupsToShareWith = [
    getClinicalCareTeamGroupId(memberId),
    getCoachingTeamGroupId(memberId),
  ];

  let item: CreateVaultItemInputParams = {
    groupsToShareWith,
    permissions: VaultItemPermissions.ReadOnly,
    tags: [getCollaborationChatStreamTag(memberId)],
    vaultItem: chatMessageToVaultItem(chatMessage),
  };
  if (chatMessage.requireTimelyReview) {
    item = {
      ...item,
      systemToShareWith: VaultSystemName.CollaborationSyncProcess,
    };
  }

  const createChatMessageInput = await createVaultItemInput(
    item,
    keyGenerator,
    idGenerator,
  );

  const { errors, data } = await apolloClient.mutate<
    ChatMessageMutation,
    ChatMessageMutationVariables
  >({
    mutation: createChatMessage,
    variables: { input: [createChatMessageInput] },
  });

  if (errors) {
    throw new CreateCollaborationChatMessageError(
      'An error occurred while creating collaboration chat message',
      errors,
    );
  }

  const vaultItems = data!.createVaultItems;
  return (await decodeCollaborationChatMessage(vaultItems))[0];
}

class CreateCollaborationChatMessageError extends Error {
  constructor(public message: string, public errors: any) {
    super(message);
  }
}
