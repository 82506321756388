import { UpdateListenerShiftStatusInput } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import {
  UpdateListenerShiftStatus as Output,
  UpdateListenerShiftStatusVariables as Variables,
} from '@headspace/carehub-graphql/dist/inbox-vertical-menu/generated/UpdateListenerShiftStatus';
import { updateListenerShiftStatusMutationQuery } from '@headspace/carehub-graphql/dist/inbox-vertical-menu/mutations';
import { useAppState } from 'app/state';
import { useLogger } from 'app/state/log/useLogger';
import { updateShiftStatus } from 'app/state/user/actions';
import { useMutationWithGlobalState } from 'hooks/useMutationWithGlobalState';
import { useSnackNotification } from 'hooks/useSnackNotification';
import Messages from 'i18n/en/inbox.json';
import { useDispatch } from 'redux-reloaded';

export const useListenerShiftStatus = () => {
  const currentStatus = useAppState(({ user }) => user.shiftStatus);
  const logger = useLogger();

  const [updateListenerShiftStatusMutation] = useMutationWithGlobalState<
    Output,
    Variables
  >(updateListenerShiftStatusMutationQuery);
  const {
    showErrorNotification,
    showSuccessNotification,
  } = useSnackNotification();

  const dispatch = useDispatch();

  const updateListenerShiftStatus = async (
    input: UpdateListenerShiftStatusInput,
  ) => {
    try {
      const response = await updateListenerShiftStatusMutation({ input }, {});
      const shiftStatus =
        response.data?.updateListenerShiftStatus?.shiftStatus ?? null;
      const error =
        response.errors ??
        response.data?.updateListenerShiftStatus?.error ??
        null;
      if (error || !shiftStatus) {
        logger.error(new Error('Failed to update coach status'), {
          currentStatus,
          error,
        });
        showErrorNotification(Messages.failureUpdatingShiftStatus);
      } else {
        dispatch(updateShiftStatus(shiftStatus));
        showSuccessNotification(Messages.successUpdatingShiftStatus);
      }

      return { error, shiftStatus };
    } catch (error) {
      showErrorNotification(Messages.failureUpdatingShiftStatus);
      return { error, shiftStatus: null };
    }
  };

  return { currentStatus, updateListenerShiftStatus };
};
