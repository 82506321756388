import { useAppState } from '@ginger.io/core-ui';
import { UserRole } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { Divider } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { Links } from 'app/constants';
import styles from 'app/inbox/vertical-menu/styles/index.module.scss';
import { useContentPortalHelpers } from 'app/patients/tabs/content/UseContentHooks';
import { homePathForRole } from 'app/routes/AppRoutes';
import React, { ReactElement, useMemo, useState } from 'react';
import {
  classNameCombiner,
  isClinicalSupervisor,
  isClinicianOrSupervisor,
  isCoachOrSupervisor,
} from 'utils';

import { SideMenu } from './SideMenu';

export interface Props {
  redirectTo: (path: string) => void;
  role: UserRole | null;
}

export function QuickLinks(props: Props) {
  const clinicianId = useAppState((_) => _.user.clinicianId);
  const { role, redirectTo } = props;
  const [open, setOpen] = useState(false);
  const { openContentPortal } = useContentPortalHelpers();

  const onSwitchToView = (userRole: UserRole) => () => {
    const destination = homePathForRole[userRole];
    redirectTo(destination);
  };

  const openLinkInNewTab = (link: string) => {
    setOpen(false);
    window.open(link, '_blank');
  };

  const menuItems: ReactElement[] = useMemo(() => {
    const items: ReactElement[] = [];
    if (isCoachOrSupervisor(role)) {
      items.push(
        ...[
          <MenuItem
            key="on-call-schedule"
            data-testid="on-call-schedule"
            onClick={() => openLinkInNewTab(Links.ON_CALL_SCHEDULE)}
          >
            On Call Schedule
          </MenuItem>,
          <MenuItem
            key="ccr"
            data-testid="crr"
            onClick={() => openLinkInNewTab(Links.CRR_FORM)}
          >
            CCR Form
          </MenuItem>,
          <MenuItem
            key="risk-policy-protocols"
            data-testid="risk-policy-protocols"
            onClick={() => openLinkInNewTab(Links.RISK_POLICY_PROTOCOLS)}
          >
            Risk Policy & Protocols
          </MenuItem>,
        ],
      );
    }

    if (isClinicalSupervisor(role)) {
      items.push(
        <MenuItem
          key="switchToClinicianUI"
          data-testid="switchToClinicianUI"
          onClick={onSwitchToView(UserRole.CLINICIAN)}
        >
          Switch to Clinician UI
        </MenuItem>,
        <MenuItem
          key="switchToMSUI"
          data-testid="switchToMSUI"
          onClick={onSwitchToView(UserRole.MEMBER_SUPPORT)}
        >
          Switch to MS UI
        </MenuItem>,
      );
    }

    if (isClinicianOrSupervisor(role)) {
      items.push(
        <MenuItem
          key="accoladeRoundForm"
          data-testid="accoladeRoundForm"
          onClick={() => openLinkInNewTab(Links.ACCOLADE_CASE_ROUND_FORM)}
        >
          Accolade Case Rounds Form
        </MenuItem>,
      );

      items.push(
        ...[
          <MenuItem
            key="careCoordinationForm"
            data-testid="careCoordinationForm"
            onClick={() => openLinkInNewTab(Links.CARE_COORDINATION_FORM)}
          >
            Care Coordination Request
          </MenuItem>,
        ],
      );
    }

    if (isCoachOrSupervisor(role)) {
      items.push(
        <MenuItem key="content-portal" onClick={() => openContentPortal}>
          Ginger Content Library
        </MenuItem>,
      );
    }

    if (isClinicianOrSupervisor(role)) {
      items.push(
        <MenuItem
          key="clinician-site"
          data-testid="clinician-site"
          onClick={() => openLinkInNewTab(Links.CLINICIAN_SITE)}
        >
          Internal Clinician Site
        </MenuItem>,
      );
    }

    if (isCoachOrSupervisor(role)) {
      items.push(
        ...[
          <MenuItem
            key="coaching-site"
            data-testid="coaching-site"
            onClick={() => openLinkInNewTab(Links.COACHING_SITE)}
          >
            Internal Coach Site
          </MenuItem>,
          <MenuItem
            key="ccaReferralForm"
            data-testid="ccaReferralForm"
            onClick={() => openLinkInNewTab(Links.CCA_REFERRAL_FORM)}
          >
            Headspace EAP/CCA Referral Request
          </MenuItem>,
        ],
      );
    }

    items.push(
      ...[
        <Divider key="divider" />,
        <MenuItem
          key="product-feedback"
          onClick={() => openLinkInNewTab(Links.BUG_SUBMISSION_FORM_URL)}
        >
          Give Product Feedback
        </MenuItem>,
      ],
    );

    return items;
  }, [
    clinicianId,
    isClinicalSupervisor(role),
    isClinicianOrSupervisor(role),
    redirectTo,
    role,
  ]);

  return (
    <SideMenu
      open={open}
      setOpen={setOpen}
      title="Quick Links"
      Icon={<div className={styles.menuIcon} />}
      menuItems={menuItems}
      idAttr="quick-links"
      classes={{
        button: classNameCombiner([
          styles.menuItem,
          styles.menuItemBtn,
          styles.settingsIcon,
        ]),
        menuList: styles.menuList,
        popper: styles.statusPopper,
        tooltip: styles.tooltip,
      }}
    />
  );
}
