import { Formatters, Services } from 'app/services';
import { AppDispatch, RootState } from 'app/state/hooks/baseTypedHooks';
import { RejectValue } from 'app/state/middlewares/types';

export enum SessionState {
  NOT_INITIALIZED = 'NOT_INITIALIZED',
  VALID = 'VALID',
  EXPIRED = 'EXPIRED',
}
export interface IAuthSlice {
  sessionState: SessionState;
}

export type AuthAsyncThunkAPI = {
  dispatch: AppDispatch;
  extra: {
    services: Services;
    formatters: Formatters;
  };
  rejectValue: RejectValue;
  state: RootState;
};
