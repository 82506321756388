import { BooleanOption } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/BooleanOption';
import { CollaborationPlan } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/CollaborationPlan';
import { NoteType as ClinicalNoteType } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/NoteType';
import {
  SafetyPlanStatus,
  Version as SafetyPlanVersion,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/safety/SafetyPlan';
import {
  Safety,
  Version as SafetyVersion,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SafetyProgress';
import {
  SubstanceUse,
  SubstanceUse_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SubstanceUse';
import {
  Assessment,
  Assessment_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/progress/Assessment';
import { ClinicianChecklist } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/progress/ClinicianChecklist';
import {
  TreatmentPlan,
  TreatmentPlan_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/progress/TreatmentPlan';
import {
  BehavioralObservations,
  BehavioralObservations_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/BehavioralObservations';
import { TherapyProgressSectionName } from '@ginger.io/vault-clinical-notes/dist/TherapyProgressSection';
import { remapSubstanceUse } from 'app/notes-ui/shared/substance-abuse/utils';
import { PHQGADSurvey } from 'app/vault/api/ClinicalNotesAPI';
import { TherapyIntakeNote } from 'app/vault/api/TherapyIntakeNotesAPI';
import { TherapyProgressNote } from 'app/vault/api/TherapyProgressNotesAPI';
import { formatSurveyDetailFromClinicalNote } from 'app/vault/data/utils';
import {
  buildCodeSets,
  collaborationPlanGoalsToTreatmentGoals,
} from 'app/vault/hooks/utils';

export function buildTherapyProgressNote(params: {
  appointmentId: string;
  currentNote: TherapyProgressNote;
  deprecatedDiagnosisCodes: number[];
  enableCareHubNotesEfficiency: boolean;
  phqGadSurvey: PHQGADSurvey | null;
  previousNote: TherapyProgressNote | TherapyIntakeNote | null;
}): Partial<TherapyProgressNote> {
  const {
    appointmentId,
    enableCareHubNotesEfficiency,
    previousNote,
    currentNote,
    deprecatedDiagnosisCodes,
    phqGadSurvey,
  } = params;
  // copy forward from previous note: Safety.safetyPlan, SubstanceUsed current &
  // past used substances, Assessment icd-10 codes, CollaborationPlan goals
  // Note: Vault client may return null of each section of a previous note.
  // This can happen when previous note has not been shared with the clinician.

  let safety: Safety | null = null;
  let collaborationPlan: CollaborationPlan | null = null;
  let substanceAbuse: SubstanceUse | null = null;
  let assessment: Assessment | null = null;
  let behavioralObservation: BehavioralObservations | null = null;
  let treatmentPlan: TreatmentPlan | null = null;

  if (previousNote?.SAFETY) {
    let fallbackSafetyVersion = SafetyVersion.undefined_version;
    if (enableCareHubNotesEfficiency) {
      fallbackSafetyVersion = SafetyVersion.v1;
    }
    safety = {
      anyChangesOrRisks:
        currentNote.SAFETY?.anyChangesOrRisks ||
        previousNote.SAFETY.anyChangesOrRisks,
      appointmentId,
      currentHomicidalIdeation:
        currentNote.SAFETY?.currentHomicidalIdeation ||
        previousNote.SAFETY.currentHomicidalIdeation,
      currentSelfInjury:
        currentNote.SAFETY?.currentSelfInjury ||
        previousNote.SAFETY.currentSelfInjury,
      currentSuicidalIdeation:
        currentNote.SAFETY?.currentSuicidalIdeation ||
        previousNote.SAFETY.currentSuicidalIdeation,
      domesticViolenceRisk:
        currentNote.SAFETY?.domesticViolenceRisk ||
        previousNote.SAFETY.domesticViolenceRisk,
      eatingDisorderRisk:
        currentNote.SAFETY?.eatingDisorderRisk ||
        previousNote.SAFETY.eatingDisorderRisk,
      highSubstanceUse:
        currentNote.SAFETY?.highSubstanceUse ||
        previousNote.SAFETY.highSubstanceUse,
      otherConcerns:
        currentNote.SAFETY?.otherConcerns || previousNote.SAFETY.otherConcerns,
      safetyPlan: {
        actionsTaken:
          currentNote.SAFETY?.safetyPlan?.actionsTaken ||
          previousNote.SAFETY?.safetyPlan?.actionsTaken,
        appointmentEnd: '',
        appointmentId: '',
        appointmentStart: '',
        description:
          currentNote.SAFETY?.safetyPlan?.description ??
          previousNote.SAFETY?.safetyPlan?.description ??
          '',
        noteType: ClinicalNoteType.undefined_note_type,
        psychiatryQuestions:
          currentNote.SAFETY?.safetyPlan?.psychiatryQuestions ||
          previousNote.SAFETY?.safetyPlan?.psychiatryQuestions,
        status:
          currentNote.SAFETY?.safetyPlan?.status ||
          previousNote.SAFETY.safetyPlan?.status ||
          SafetyPlanStatus.undefined_status,
        therapyQuestions:
          currentNote.SAFETY?.safetyPlan?.therapyQuestions ||
          previousNote.SAFETY?.safetyPlan?.therapyQuestions,
        version:
          currentNote.SAFETY?.safetyPlan?.version ||
          SafetyPlanVersion.undefined_version,
      },
      selectedConcerns:
        currentNote.SAFETY?.selectedConcerns ||
        previousNote.SAFETY.selectedConcerns ||
        [],
      version: currentNote.SAFETY?.version ?? fallbackSafetyVersion,
      vulnerablePopulationsAbuse:
        currentNote.SAFETY?.vulnerablePopulationsAbuse ||
        previousNote.SAFETY.vulnerablePopulationsAbuse,
    };
  }

  if (previousNote?.COLLABORATION_PLAN) {
    const goals = previousNote.COLLABORATION_PLAN!.goal;
    collaborationPlan = {
      appointmentId,
      goal: currentNote.COLLABORATION_PLAN?.goal || goals,
    };
  }

  if (previousNote?.ASSESSMENT) {
    const previousAssessment = previousNote.ASSESSMENT as TherapyProgressNote['ASSESSMENT'];
    assessment = {
      appointmentId,
      changeInSymptoms:
        currentNote.ASSESSMENT?.changeInSymptoms ||
        previousAssessment?.changeInSymptoms ||
        '',
      codeSets: buildCodeSets(
        currentNote.ASSESSMENT?.codeSets ?? [],
        previousAssessment?.codeSets ?? [],
        deprecatedDiagnosisCodes,
      ),
      cptCode: currentNote.ASSESSMENT?.cptCode ?? 0,
      icd10Codes:
        currentNote.ASSESSMENT?.icd10Codes ||
        previousAssessment?.icd10Codes.filter(
          (c) => !deprecatedDiagnosisCodes.includes(c),
        ) ||
        [],
      version:
        currentNote.ASSESSMENT?.version || previousNote.ASSESSMENT.version,
    };

    if (enableCareHubNotesEfficiency) {
      assessment.changeInSymptoms =
        currentNote.ASSESSMENT?.changeInSymptoms || ''; // we don't want to copy forward change in symptoms from previous note
      assessment.version = Assessment_Version.v0;
    }
  }

  if (previousNote?.SUBSTANCE_ABUSE) {
    let fallbackSubstanceAbuseVersion = SubstanceUse_Version.undefined_version;
    if (enableCareHubNotesEfficiency) {
      fallbackSubstanceAbuseVersion = SubstanceUse_Version.v0;
    }
    const substanceUsed = {
      anySubstanceUsed:
        previousNote.SUBSTANCE_ABUSE.anySubstanceUsed ?? BooleanOption.yes,
      appointmentId,
      pastEtohOrBenzoWithdrawal: previousNote.SUBSTANCE_ABUSE
        .pastEtohOrBenzoWithdrawal || {
        description: '',
        isPresent: false,
      },
      pastSubstanceUseTreatment: previousNote.SUBSTANCE_ABUSE
        .pastSubstanceUseTreatment || {
        description: '',
        isPresent: false,
      },
      substancesCurrentlyUsed:
        previousNote.SUBSTANCE_ABUSE!.substancesCurrentlyUsed || [],
      substancesPreviouslyUsed:
        previousNote.SUBSTANCE_ABUSE!.substancesPreviouslyUsed || [],
      version: fallbackSubstanceAbuseVersion,
    };
    substanceAbuse = {
      anySubstanceUsed:
        currentNote.SUBSTANCE_ABUSE?.anySubstanceUsed ??
        substanceUsed.anySubstanceUsed,
      appointmentId,
      pastEtohOrBenzoWithdrawal: {
        description:
          currentNote.SUBSTANCE_ABUSE?.pastEtohOrBenzoWithdrawal?.description ??
          substanceUsed.pastEtohOrBenzoWithdrawal.description ??
          '',
        isPresent:
          currentNote.SUBSTANCE_ABUSE?.pastEtohOrBenzoWithdrawal?.isPresent ||
          substanceUsed.pastEtohOrBenzoWithdrawal.isPresent,
      },
      pastSubstanceUseTreatment: {
        description:
          currentNote.SUBSTANCE_ABUSE?.pastSubstanceUseTreatment?.description ??
          substanceUsed.pastSubstanceUseTreatment.description ??
          '',
        isPresent:
          currentNote.SUBSTANCE_ABUSE?.pastSubstanceUseTreatment?.isPresent ||
          substanceUsed.pastSubstanceUseTreatment.isPresent,
      },
      substancesCurrentlyUsed: remapSubstanceUse(
        currentNote.SUBSTANCE_ABUSE?.substancesCurrentlyUsed ??
          substanceUsed.substancesCurrentlyUsed,
      ),
      substancesPreviouslyUsed: remapSubstanceUse(
        currentNote.SUBSTANCE_ABUSE?.substancesPreviouslyUsed ??
          substanceUsed.substancesPreviouslyUsed,
      ),
      version: currentNote.SUBSTANCE_ABUSE?.version ?? substanceUsed.version,
    };
  }

  if (previousNote?.BEHAVIORAL_OBSERVATION && enableCareHubNotesEfficiency) {
    behavioralObservation = {
      affect:
        currentNote.BEHAVIORAL_OBSERVATION?.affect ||
        previousNote.BEHAVIORAL_OBSERVATION?.affect,
      appointmentId,
      insight:
        currentNote.BEHAVIORAL_OBSERVATION?.insight ||
        previousNote.BEHAVIORAL_OBSERVATION?.insight,
      interventionsUsed:
        currentNote.BEHAVIORAL_OBSERVATION?.interventionsUsed || [],
      judgment:
        currentNote.BEHAVIORAL_OBSERVATION?.judgment ||
        previousNote.BEHAVIORAL_OBSERVATION?.judgment,
      mood:
        currentNote.BEHAVIORAL_OBSERVATION?.mood ||
        previousNote.BEHAVIORAL_OBSERVATION?.mood ||
        [],
      speech:
        currentNote.BEHAVIORAL_OBSERVATION?.speech ||
        previousNote.BEHAVIORAL_OBSERVATION?.speech ||
        [],
      thoughtContent:
        currentNote.BEHAVIORAL_OBSERVATION?.thoughtContent ||
        previousNote.BEHAVIORAL_OBSERVATION?.thoughtContent ||
        [],
      thoughtProcess:
        currentNote.BEHAVIORAL_OBSERVATION?.thoughtProcess ||
        previousNote.BEHAVIORAL_OBSERVATION?.thoughtProcess ||
        [], // we don't want to copy forward interventions used from previous note
      version:
        currentNote.BEHAVIORAL_OBSERVATION?.version ||
        BehavioralObservations_Version.v0,
    };
  }

  if (previousNote?.TREATMENT_PLAN && enableCareHubNotesEfficiency) {
    const previousIntakeTreatmentPlan = previousNote.TREATMENT_PLAN as TherapyIntakeNote['TREATMENT_PLAN'];
    const previousProgressTreatmentPlan = previousNote.TREATMENT_PLAN as TherapyProgressNote['TREATMENT_PLAN'];

    treatmentPlan = {
      additionalSessionsRequired:
        currentNote.TREATMENT_PLAN?.additionalSessionsRequired ?? 0,
      anticipatedSession:
        currentNote.TREATMENT_PLAN?.anticipatedSession ??
        previousIntakeTreatmentPlan?.anticipatedSession ??
        previousProgressTreatmentPlan?.anticipatedSession,
      appointmentId,
      appointmentOffered:
        currentNote.TREATMENT_PLAN?.appointmentOffered ??
        previousIntakeTreatmentPlan?.appointmentOffered ??
        previousProgressTreatmentPlan?.appointmentOffered ??
        BooleanOption.undefined_choice,
      // we don't want to copy forward the message to care team from the previous note
      approaches:
        currentNote.TREATMENT_PLAN?.approaches ??
        previousIntakeTreatmentPlan?.approaches ??
        previousProgressTreatmentPlan?.approaches ??
        [],

      approachesUpdateComments:
        currentNote.TREATMENT_PLAN?.approachesUpdateComments ?? '',

      changesToTreatmentPlan:
        currentNote.TREATMENT_PLAN?.changesToTreatmentPlan ?? '',

      goal: collaborationPlanGoalsToTreatmentGoals(currentNote, previousNote),

      interventionsUsed: currentNote.TREATMENT_PLAN?.interventionsUsed ?? [],

      memberOutOfScope: currentNote.TREATMENT_PLAN?.memberOutOfScope ?? false,

      memberOutOfScopeReason:
        currentNote.TREATMENT_PLAN?.memberOutOfScopeReason ??
        previousIntakeTreatmentPlan?.memberOutOfScopeReason ??
        previousProgressTreatmentPlan?.memberOutOfScopeReason ??
        '',

      messageToCareTeam: currentNote.TREATMENT_PLAN?.messageToCareTeam ?? '',
      offeredAppointmentAccepted:
        currentNote.TREATMENT_PLAN?.offeredAppointmentAccepted ??
        previousIntakeTreatmentPlan?.offeredAppointmentAccepted ??
        previousProgressTreatmentPlan?.offeredAppointmentAccepted ??
        BooleanOption.undefined_choice,
      otherApproachComment:
        currentNote.TREATMENT_PLAN?.otherApproachComment ?? '',
      referralsToCareOutsideGingerNecessary:
        currentNote.TREATMENT_PLAN?.referralsToCareOutsideGingerNecessary ?? '',
      version: currentNote.TREATMENT_PLAN?.version ?? TreatmentPlan_Version.v0,
      waitTimeDetrimentalEffect:
        currentNote.TREATMENT_PLAN?.waitTimeDetrimentalEffect ?? false,
    };
  }

  if (enableCareHubNotesEfficiency) {
    const phqGadSurveySummary = formatSurveyDetailFromClinicalNote(
      phqGadSurvey,
    );
    const clinicalChecklist = phqGadSurveySummary
      ? ClinicianChecklist.fromPartial({
          remarkableEntries: phqGadSurveySummary,
        })
      : null;

    return {
      [TherapyProgressSectionName.CLINICIAN_CHECKLIST]:
        currentNote.CLINICIAN_CHECKLIST ?? clinicalChecklist,

      [TherapyProgressSectionName.ASSESSMENT]: assessment,

      [TherapyProgressSectionName.BEHAVIORAL_OBSERVATION]: behavioralObservation,

      [TherapyProgressSectionName.COLLABORATION_PLAN]: collaborationPlan,

      [TherapyProgressSectionName.SUBSTANCE_ABUSE]: substanceAbuse,

      [TherapyProgressSectionName.SAFETY]: safety,

      [TherapyProgressSectionName.TREATMENT_PLAN]: treatmentPlan,
    };
  }
  return {
    [TherapyProgressSectionName.ASSESSMENT]: assessment,

    [TherapyProgressSectionName.COLLABORATION_PLAN]: collaborationPlan,

    [TherapyProgressSectionName.SUBSTANCE_ABUSE]: substanceAbuse,

    [TherapyProgressSectionName.SAFETY]: safety,
  };
}
