import { Avatar } from '@ginger.io/core-ui';
import { CoachShiftStatusEnum } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import styles from 'app/care-team/avatar-vith-status/AvatarWithStatus.module.scss';
import React from 'react';

export type AvatarWithStatusProps = {
  picture?: string;
  name: string;
  status?: CoachShiftStatusEnum | null;
};

const AvatarWithStatus = (props: AvatarWithStatusProps) => {
  const { picture, name } = props;
  // convert status to lowercase to match css class and also display in the element title
  const status = props.status?.toLowerCase();

  // get first and last name from full name
  const nameParts = name.split(' ');
  const firstName = nameParts[0];
  const lastName = nameParts.length > 1 ? nameParts[nameParts.length - 1] : '';

  return (
    <div className={styles.pictureContainer}>
      <Avatar
        firstName={firstName}
        lastName={lastName}
        includeLastName={true}
        src={picture}
        className={styles.picture}
      />
      {status && (
        <div
          data-testid="avatar-status"
          title={status}
          className={styles[status]}
        />
      )}
    </div>
  );
};

export default AvatarWithStatus;
