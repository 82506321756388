import { ApolloClient, useApolloClient } from '@apollo/client';
import { KeyGenerator } from '@ginger.io/vault-core/dist/crypto';
import { Base64 } from '@ginger.io/vault-core/dist/crypto/Base64';
import DownloadIcon from '@mui/icons-material/GetApp';
import {
  ClinicalNotePDF,
  ClinicalNotes,
} from 'app/notes-ui/pdf/ClinicalNotePDF';
import { NotePDFPasswordModal } from 'app/notes-ui/pdf/NotePDFPasswordModal';
import { PsychiatryIntakeNotePDF } from 'app/notes-ui/pdf/PsychiatryIntakeNotePDF';
import { PsychiatryProgressNotePDF } from 'app/notes-ui/pdf/PsychiatryProgressNotePDF';
import { TherapyIntakeNotePDF } from 'app/notes-ui/pdf/TherapyIntakeNotePDF';
import { TherapyProgressNotePDF } from 'app/notes-ui/pdf/TherapyProgressNotePDF';
import { EnumOption } from 'app/notes-ui/types';
import { useLogger } from 'app/state/log/useLogger';
import {
  ClinicalNotesAPIClients,
  useClinicalNotesAPI,
} from 'app/vault/hooks/useClinicalNotesAPI';
import blobStream from 'blob-stream';
import { ClinicalAppointmentNoteType, NoteType } from 'generated/globalTypes';
import { useDiagnosisCodes } from 'hooks/useDiagnosisCodes';
import { useSnackNotification } from 'hooks/useSnackNotification';
import Messages from 'i18n/en/appointment.json';
import React, { useState } from 'react';
import { Loader } from 'shared-components/loader/Loader';

import { NoteHeader } from 'app/notes-ui/pdf/types';
import { GetAppointmentById_getAppointmentById } from 'app/vault/generated/GetAppointmentById';
import { ILogger } from '../state/log/Logger';
import { getAppointmentMetadata, toNoteHeader } from './AppointmentMetadata';
import { IconActionBarItem } from './IconActionBarItem';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';

export type DownloadableNoteType =
  | ClinicalAppointmentNoteType.THERAPY_INTAKE
  | ClinicalAppointmentNoteType.THERAPY_PROGRESS
  | NoteType.THERAPY_INTAKE
  | NoteType.THERAPY_PROGRESS
  | ClinicalAppointmentNoteType.PSYCHIATRY_PROGRESS
  | ClinicalAppointmentNoteType.PSYCHIATRY_INTAKE
  | NoteType.PSYCHIATRY_PROGRESS
  | NoteType.PSYCHIATRY_INTAKE
  | NoteType.UNSPECIFIED;

export const downloadableNoteTypes = new Set([
  ClinicalAppointmentNoteType.THERAPY_INTAKE,
  ClinicalAppointmentNoteType.THERAPY_PROGRESS,
  NoteType.THERAPY_INTAKE,
  NoteType.THERAPY_PROGRESS,
  NoteType.PSYCHIATRY_PROGRESS,
  NoteType.PSYCHIATRY_INTAKE,
  ClinicalAppointmentNoteType.PSYCHIATRY_PROGRESS,
  ClinicalAppointmentNoteType.PSYCHIATRY_INTAKE,
]);

export function isDownloadableNoteType(
  noteType: ClinicalAppointmentNoteType | NoteType,
): noteType is DownloadableNoteType {
  return downloadableNoteTypes.has(noteType);
}

type Props = {
  appointmentId?: string;
  noteType?: DownloadableNoteType;
  noteDownloadFn?: (
    apolloClient: ApolloClient<object>,
    clinicalNotesApi: ClinicalNotesAPIClients,
    password: string | undefined,
    enableNewPdfFont: boolean | undefined,
    diagnosisCodesOptions: EnumOption[],
    logger: ILogger,
    appointment?: AppointmentIdWithNoteType,
  ) => Promise<void>;
  passwordGeneratorFn?: () => Promise<string>;
};

export function DownloadClinicalNoteButton(props: Props) {
  const {
    appointmentId,
    noteType,
    noteDownloadFn = downloadClinicalNote,
    passwordGeneratorFn = generatePassword,
  } = props;

  const {
    transientFeatureFlags: {
      enable_note_pdf_password_bypass: skipPassword,
      enable_new_pdf_font: enableNewPdfFont,
    },
  } = useFeatureFlags();
  const [isLoading, setIsLoading] = useState(false);
  const [pdfPassword, setPdfPassword] = useState<string | undefined>(undefined);
  const apis = useClinicalNotesAPI();
  const apolloClient = useApolloClient();
  const logger = useLogger();
  const { showErrorNotification } = useSnackNotification();

  const {
    diagnosisCodeOptions,
    loading: isLoadingDiagnosisCodes,
  } = useDiagnosisCodes();

  const onClickDownload = async () => {
    try {
      setIsLoading(true);

      let password;

      if (!skipPassword) {
        password = await passwordGeneratorFn();
      }
      setPdfPassword(password);
      let apptWithType: AppointmentIdWithNoteType | undefined;
      if (appointmentId && noteType) {
        apptWithType = { appointmentId, noteType };
      }
      await noteDownloadFn(
        apolloClient,
        apis,
        password,
        enableNewPdfFont,
        diagnosisCodeOptions,
        logger,
        apptWithType,
      );
      logger.info(
        `DownloadClinicalNoteButton: Successfully downloaded clinical note PDF`,
        { appointmentId, noteType },
      );
    } catch (error) {
      setPdfPassword(undefined); // don't show password modal when an error occurs
      showErrorNotification(`Unable to download Note`, true);
      logger.error(new Error(Messages.downloadPDFFailure), {
        appointmentId,
        error,
        noteType,
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loader topMargin={false} />;
  }

  return (
    <>
      <IconActionBarItem
        stopPropagation={true}
        onClick={onClickDownload}
        title="Download Note as PDF"
        Icon={DownloadIcon}
        testId="downloadNote"
        disabled={isLoadingDiagnosisCodes || isLoading}
      />
      {pdfPassword ? (
        <NotePDFPasswordModal
          password={pdfPassword}
          onClose={() => setPdfPassword(undefined)}
        />
      ) : null}
    </>
  );
}

type AppointmentIdWithNoteType = {
  appointmentId: string;
  noteType: DownloadableNoteType;
};

async function downloadClinicalNote(
  apolloClient: ApolloClient<object>,
  clinicalNotesApi: ClinicalNotesAPIClients,
  password: string | undefined,
  enableNewPdfFont: boolean | undefined,
  diagnosisCodesOptions: EnumOption[],
  logger: ILogger,
  appointment?: AppointmentIdWithNoteType,
) {
  if (!appointment) throw new Error(Messages.unknownAppointmentType);

  const appointmentMetadata = await getAppointmentMetadata(
    apolloClient,
    appointment.appointmentId,
  );

  if (!appointmentMetadata) {
    throw new Error(`${Messages.noAppointment} ${appointment.appointmentId}`);
  }

  let pdfBuilder: ClinicalNotePDF<ClinicalNotes>;

  switch (appointment.noteType) {
    case ClinicalAppointmentNoteType.THERAPY_INTAKE:
    case NoteType.THERAPY_INTAKE: {
      const api = clinicalNotesApi.therapyIntakeNotes;
      const note = await api.getNote(appointment.appointmentId);
      pdfBuilder = new TherapyIntakeNotePDF(
        note,
        generateNoteHeader(note, appointmentMetadata),
        diagnosisCodesOptions,
        logger,
        password,
        enableNewPdfFont,
      );
      break;
    }
    case ClinicalAppointmentNoteType.THERAPY_PROGRESS:
    case NoteType.THERAPY_PROGRESS: {
      const api = clinicalNotesApi.therapyProgressNotes;
      const note = await api.getNote(appointment.appointmentId);
      pdfBuilder = new TherapyProgressNotePDF(
        note,
        generateNoteHeader(note, appointmentMetadata),
        diagnosisCodesOptions,
        logger,
        password,
        enableNewPdfFont,
      );
      break;
    }
    case ClinicalAppointmentNoteType.PSYCHIATRY_PROGRESS:
    case NoteType.PSYCHIATRY_PROGRESS: {
      const api = clinicalNotesApi.psychiatryProgressNotes;
      const note = await api.getNote(appointment.appointmentId);
      pdfBuilder = new PsychiatryProgressNotePDF(
        note,
        generateNoteHeader(note, appointmentMetadata),
        diagnosisCodesOptions,
        logger,
        password,
        enableNewPdfFont,
      );
      break;
    }
    case ClinicalAppointmentNoteType.PSYCHIATRY_INTAKE:
    case NoteType.PSYCHIATRY_INTAKE: {
      const api = clinicalNotesApi.psychiatryIntakeNotes;
      const note = await api.getNote(appointment.appointmentId);
      pdfBuilder = new PsychiatryIntakeNotePDF(
        note,
        generateNoteHeader(note, appointmentMetadata),
        diagnosisCodesOptions,
        logger,
        password,
        enableNewPdfFont,
      );
      break;
    }
    default:
      throw new Error(Messages.unsupportedNoteType);
  }

  const pdfDoc = pdfBuilder.getPDF();
  const stream = pdfDoc.pipe(blobStream());

  const { appointmentId, noteType } = appointment;

  return new Promise((resolve, reject) => {
    stream.on('error', (e) => {
      reject(e);
    });

    stream.on('finish', () => {
      try {
        const href = stream.toBlobURL('application/pdf');
        const filename = `clinical-note-${appointmentId}.pdf`;
        startPDFDownload(href, filename);
        logger.info(
          `downloadClinicalNote: Successfully downloaded note PDF file ${filename}`,
          {
            appointmentId,
            noteType,
          },
        );
        resolve(true);
      } catch (e) {
        reject(e);
      }
    });
  });
}

export function startPDFDownload(href: string, filename: string) {
  const downloadableAnchorTag = document.createElement('a');
  downloadableAnchorTag.href = href;
  downloadableAnchorTag.setAttribute('download', filename);
  downloadableAnchorTag.click();
  downloadableAnchorTag.remove();
}

async function generatePassword(): Promise<string> {
  const keyGenerator = new KeyGenerator();
  const passwordBytes = await keyGenerator.generateRandomPassword(12);
  return await Base64.encode(passwordBytes);
}

const generateNoteHeader = (
  note: ClinicalNotes,
  appointmentMetadata: GetAppointmentById_getAppointmentById,
): NoteHeader => {
  const vaultMetadata = {
    signingClinicianName: note.signingClinicianName,
    updatedAt: note.updatedAt,
  };
  return toNoteHeader(appointmentMetadata, vaultMetadata);
};
