import { UserRole } from '@headspace/carehub-graphql/dist/generated/globalTypes';

export const shouldShowPrescriptionsCard = (
  loggedInUserRole: UserRole | null,
  patientDosespotId: string | null | undefined,
): boolean => {
  if (!loggedInUserRole) {
    return false;
  }
  const allowedRoles: UserRole[] = [
    UserRole.MEMBER_SUPPORT,
    UserRole.PSYCHIATRIST,
    UserRole.PSYCHIATRIST_SUPERVISOR,
  ];
  if (!allowedRoles.includes(loggedInUserRole)) {
    return false;
  }
  return !!patientDosespotId;
};
