import { ApolloQueryResult, useApolloClient } from '@apollo/client';
import {
  AppointmentType,
  ClinicalAppointmentNoteStatus,
  ClinicalNoteStatus,
  TherapistRole,
  UserRole,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { AppointmentsTableSchedule } from 'app/appointments/AppointmentsTableSchedule';
import { Appointment, ClinicalNote, NoteAction } from 'app/appointments/types';
import { DEFAULT_TIMEZONE } from 'app/clinician/ClinicianSettingsComponent';
import {
  ApolloCachingStrategy,
  GQL_QUERY_POLLING_INTERVAL,
} from 'app/constants';
import {
  GetClinicalAppointmentsForMember,
  GetClinicalAppointmentsForMember_getClinicalAppointmentsForMember_appointments,
  GetClinicalAppointmentsForMember_getClinicalAppointmentsForMember_appointments_clinicalNote,
  GetClinicalAppointmentsForMemberVariables,
} from 'app/patients/generated/GetClinicalAppointmentsForMember';
import { queryApptsForMemberSchedule } from 'app/patients/queries';
import { useAppState } from 'app/state';
import {
  appointmentListMSViewed,
  viewPatientAppointmentTabAction,
} from 'app/state/amplitude/actions/appointments';
import { memberListNewAppointmentClick } from 'app/state/amplitude/actions/etc';
import { createAppointmentRoute } from 'app/top-nav/Routes';
import gql from 'graphql-tag';
import { useQuery } from 'hooks/useQuery';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { formatName, formatZoomMeetingUrl } from 'utils';
import { formatDate, getTimezone } from 'utils/dateTime';
import { formatClinicalNoteStatus } from 'utils/notes';

import { GetClinicianIdForAppointmentsTab_getAuthenticatedClinician } from './generated/GetClinicianIdForAppointmentsTab';

export const queryClinicianId = gql`
  query GetClinicianIdForAppointmentsTab {
    getAuthenticatedClinician {
      id
    }
  }
`;

export type AppointmentsTabScheduleProps = {
  patientId: string;
  navigateTo: (url: string) => void;
  signedLockedAction: NoteAction.VIEW | NoteAction.DOWNLOAD;
  role?: UserRole;
  memberTimezone?: string;
};

export function AppointmentsTabSchedule(props: AppointmentsTabScheduleProps) {
  const {
    patientId,
    navigateTo,
    signedLockedAction,
    role,
    memberTimezone = DEFAULT_TIMEZONE,
  } = props;
  const { timezone } = useAppState((_) => _.user);
  const localTimezone = getTimezone(timezone);
  const dispatch = useDispatch();
  const history = useHistory();
  const roleIsMemberSupport = role === UserRole.MEMBER_SUPPORT;
  const [clinicianId, setClinicianId] = useState<string | undefined>(undefined);
  const apollo = useApolloClient();
  const [todaysAppointmentsOnly, setTodaysAppointmentsOnly] = React.useState<
    boolean
  >(false);

  useEffect(() => {
    const loadClinicianId = async () => {
      const response: ApolloQueryResult<GetClinicianIdForAppointmentsTab_getAuthenticatedClinician> = await apollo.query(
        {
          query: queryClinicianId,
        },
      );

      if (response.data) {
        setClinicianId(response.data.id);
      }
    };

    dispatch(viewPatientAppointmentTabAction());
    if (roleIsMemberSupport) {
      dispatch(appointmentListMSViewed({ patientId }));
    } else {
      void loadClinicianId();
    }
  }, [dispatch]);

  return useQuery<
    GetClinicalAppointmentsForMember,
    GetClinicalAppointmentsForMemberVariables
  >(
    (data) => {
      const appointments =
        data.getClinicalAppointmentsForMember?.appointments ??
        ([] as GetClinicalAppointmentsForMember_getClinicalAppointmentsForMember_appointments[]);
      const tableAppointments = appointments.map((r) =>
        appointmentScheduleToTableAppointment(r, localTimezone),
      );
      const onNewAppointment = () => {
        dispatch(memberListNewAppointmentClick({ clinicianId, patientId }));
        if (roleIsMemberSupport) {
          history.push(
            createAppointmentRoute({
              patientId,
              role: UserRole.MEMBER_SUPPORT,
            }),
          );
        } else {
          history.push(createAppointmentRoute({ clinicianId, patientId }));
        }
      };

      return (
        <AppointmentsTableSchedule
          localTimezone={localTimezone}
          memberTimezone={memberTimezone}
          appointments={tableAppointments}
          navigateTo={navigateTo}
          onNewAppointment={onNewAppointment}
          hideMemberName={true}
          signedLockedAction={signedLockedAction}
          role={role}
          todaysAppointmentsOnly={todaysAppointmentsOnly}
          updateTodaysAppointmentsOnly={setTodaysAppointmentsOnly}
        />
      );
    },
    queryApptsForMemberSchedule,
    {
      fetchPolicy: ApolloCachingStrategy.NETWORK_ONLY,
      pollInterval: GQL_QUERY_POLLING_INTERVAL,
      variables: { input: { memberId: patientId } },
    },
  );
}

export function appointmentScheduleToTableAppointment(
  appointment: GetClinicalAppointmentsForMember_getClinicalAppointmentsForMember_appointments,
  localTimezone: string,
): Appointment {
  const {
    member,
    appointmentStatus,
    drchronoId,
    clinicalNote,
    zoomMeetingId,
    type,
    cancelledReason,
  } = appointment;
  // https://github.com/HeadspaceMeditation/ginger-graphql/blob/develop/app/src/main/resolvers/appointments.ts#L53-L55
  const {
    status: noteStatus = ClinicalNoteStatus.NOT_STARTED,
    statusUpdatedAt,
  } = clinicalNote ?? {};
  const clinician = appointment.clinician!;
  const { user, fullName, role } = clinician;
  const { id: clinicianId } = user!;
  // Legacy appointment type based on the therapist role for older appointments that do not have an
  // explicit appointment type
  const appointmentTypeByTherapistRole = therapistRoleToAppointmentType(role);
  // https://github.com/HeadspaceMeditation/web/blob/develop/internal_apps/clinical_portal/serializers.py#L259
  const clinicalNoteUrl = drchronoId
    ? `https://gingerio.drchrono.com/appointments/${drchronoId}/`
    : '';
  const zoomUrl = formatZoomMeetingUrl(zoomMeetingId);

  return {
    appointmentStatus,
    appointmentType: type ?? appointmentTypeByTherapistRole,
    cancelledReason: cancelledReason ?? '',
    clinicalNote: clinicalNote
      ? clinicalAppointmentNoteToTableNote(clinicalNote, clinicalNoteUrl)
      : createPlaceholderTableNote(clinicalNoteUrl, appointment.id),
    clinicianId,
    end: appointment.end,
    id: appointment.id,
    member: {
      id: member?.id || '',
      name: formatName(member?.firstName, member?.lastName) || '',
    },
    name: fullName ?? '',
    noteStatus: formatClinicalNoteStatus(
      appointment.appointmentStatus,
      noteStatus,
    ),
    noteUpdatedAt: statusUpdatedAt
      ? formatDate(statusUpdatedAt, localTimezone)
      : '-',
    start: appointment.start,
    type: '',
    zoomUrl,
  };
}

function clinicalAppointmentNoteToTableNote(
  note: GetClinicalAppointmentsForMember_getClinicalAppointmentsForMember_appointments_clinicalNote,
  clinicalNoteUrl: string,
): ClinicalNote {
  return {
    id: note?.id,
    location: note.location,
    locked: note.status === ClinicalAppointmentNoteStatus.SIGNED_AND_LOCKED,

    noteType: note.noteType,

    // https://github.com/HeadspaceMeditation/ginger-graphql/blob/develop/packages/app/src/main/resolvers/appointments.ts#L53-L55
    status: note.status ?? ClinicalNoteStatus.NOT_STARTED,

    updatedAt: note.statusUpdatedAt,
    url: clinicalNoteUrl,
  };
}

function createPlaceholderTableNote(
  clinicalNoteUrl: string,
  appointmentId: string,
): ClinicalNote {
  // Existing logic expects a placeholder note with some default values if there is not ClinicalAppointmentNote record
  // in the DB. This stubbing happens in both web and ginger-graphql for the REST API. For the GraphQL API, the
  // stubbing is not happening, so do that here: if "note" is null, return a placeholder note with default values
  // corresponding to those that would've been filled out.
  return {
    // https://github.com/HeadspaceMeditation/web/blob/develop/internal_apps/clinical_portal/serializers.py#L288-L295
    id: appointmentId,
    location: null,
    locked: false,

    noteType: null,

    // https://github.com/HeadspaceMeditation/ginger-graphql/blob/develop/packages/app/src/main/resolvers/appointments.ts#L53-L55
    status: ClinicalNoteStatus.NOT_STARTED,

    updatedAt: null,
    url: clinicalNoteUrl,
  };
}

function therapistRoleToAppointmentType(role: TherapistRole): AppointmentType {
  // github.com/HeadspaceMeditation/web/blob/develop/internal_apps/clinical_portal/serializers.py#L267
  switch (role) {
    case TherapistRole.THERA:
      return AppointmentType.THERAPY;
    case TherapistRole.PSYCH:
      return AppointmentType.PSYCHIATRY;
    default:
      throw Error(`Unmapped TherapistRole ${role} for appointment type lookup`);
  }
}
