import blobStream from 'blob-stream';
// eslint-disable-next-line import/no-unresolved
import PDFKit from 'pdfkit';

export async function convertPdfDocToBlobStream(
  pdfDocument: PDFKit.PDFDocument,
) {
  const stream = pdfDocument.pipe(blobStream());
  // Convert to Blob
  const pdfBlob: Blob = await new Promise((resolve) => {
    stream.on('finish', () => {
      resolve(stream.toBlob('application/pdf'));
    });
  });
  return pdfBlob;
}
