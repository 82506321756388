import { useApolloClient } from '@apollo/client';
import {
  GetCalendarEvents,
  GetCalendarEvents_getClinicalAppointmentsForMe_appointments,
  GetCalendarEvents_getClinicalEventsForMe_events,
  GetCalendarEvents_getClinicianAvailabilitiesForMe_availabilities,
  GetCalendarEvents_getEventsForMe_calendarEvents,
  GetCalendarEventsVariables,
} from '@headspace/carehub-graphql/dist/appointments/generated/GetCalendarEvents';
import { getCalendarEventsQuery } from '@headspace/carehub-graphql/dist/appointments/queries';
import {
  ClinicalEventType,
  GoogleCalendarEventAttendanceStatus,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { Grid } from '@mui/material';
import { DEFAULT_TIMEZONE } from 'app/clinician/ClinicianSettingsComponent';
import { ApolloCachingStrategy } from 'app/constants';
import { useAppState } from 'app/state';
import {
  clickScheduleCalendarNewAppointmentAction,
  clickScheduleCalendarNewEventAction,
  doubleClickedAppointment,
  doubleClickedEvent,
  viewScheduleCalendarScreenAction,
} from 'app/state/amplitude/actions/appointments';
import { useLogger } from 'app/state/log/useLogger';
import {
  appointmentRoute,
  createAppointmentRoute,
  eventRoute,
  Routes,
} from 'app/top-nav/Routes';
import { useOnMount } from 'hooks/useOnMount';
import ClinicalEventTypeDisplayValues from 'i18n/apps/appointments/ClinicalEventType/en_US.json';
import Messages from 'i18n/en/appointment.json';
import { union, uniqueId } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DateRange } from 'react-big-calendar';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';

import { ScheduleContainer } from './ScheduleContainer';
import styles from './ScheduleScreen.module.scss';
import {
  AppointmentGingerEvent,
  AvailabilityGingerEvent,
  EventGingerEvent,
  EventType,
  GingerEvent,
  GoogleCalendarMeetingGingerEvent,
} from './types';

export interface AvailabilityWindow {
  // ISO8601 strings
  windowStart: string;
  windowEnd: string;
}

export interface RouteComponentPropsWithAvailabilityWindow
  extends RouteComponentProps {
  availabilityWindow?: AvailabilityWindow;
}

export function getClinicalEventTypeDisplayValue(
  eventType: ClinicalEventType | string,
): string {
  const displayValues: Record<string, string> = ClinicalEventTypeDisplayValues;

  return displayValues[eventType] ?? eventType;
}

export function createDefaultAvailabilityWindow(
  timezone: string = DEFAULT_TIMEZONE,
): AvailabilityWindow {
  const startOfWeek = moment.tz(timezone).startOf('week');
  const endOfWeek = moment.tz(timezone).endOf('week');

  return {
    windowEnd: endOfWeek.add(1, 'day').toISOString(),
    windowStart: startOfWeek.subtract(1, 'day').toISOString(),
  };
}

export function ScheduleScreen(
  props: RouteComponentPropsWithAvailabilityWindow,
) {
  const { timezone } = useAppState((_) => _.user);
  const localTimezone = timezone ?? DEFAULT_TIMEZONE;
  const {
    history,
    availabilityWindow = createDefaultAvailabilityWindow(
      timezone ?? localTimezone,
    ),
  } = props;
  const dispatch = useDispatch();
  const apollo = useApolloClient();
  const [events, setEvents] = useState<GingerEvent[]>([]);
  const [clinicianId, setClinicianId] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [availabilityWindowState, setAvailabilityWindow] = useState(
    availabilityWindow,
  );
  const { windowStart, windowEnd } = availabilityWindowState;
  const logger = useLogger();

  useOnMount(() => {
    dispatch(viewScheduleCalendarScreenAction());
  });

  useEffect(() => {
    let mounted = true;
    setLoading(true);

    const query = apollo
      .watchQuery<GetCalendarEvents, GetCalendarEventsVariables>({
        fetchPolicy: ApolloCachingStrategy.NETWORK_ONLY,
        query: getCalendarEventsQuery,
        variables: {
          availabilityInput: { windowEnd, windowStart },
          calendarEventInput: { windowEnd, windowStart },
          eventInput: {
            filters: {
              windowEnd,
              windowStart,
            },
          },
          input: {
            filters: {
              windowEnd,
              windowStart,
            },
          },
        },
      })
      .subscribe(
        ({ data }) => {
          if (mounted) {
            const { appointments } = data.getClinicalAppointmentsForMe!;
            const availability = data.getClinicianAvailabilitiesForMe!
              .availabilities;
            const fetchedEvents = data.getClinicalEventsForMe!.events;
            const googleCalendarEvents = data.getEventsForMe!.calendarEvents;
            const clinicianId = data.getAuthenticatedClinician.id;

            setEvents(
              union<GingerEvent>(
                appointments.map(
                  makeAppointmentToGingerEventWithTimezone(localTimezone),
                ),
                availability.map(
                  makeAvailabilityToGingerEventWithTimezone(localTimezone),
                ),
                fetchedEvents.map(
                  makeEventToGingerEventWithTimezone(localTimezone),
                ),
                googleCalendarEvents.map(
                  makeGoogleCalendarEventToGingerEventWithTimezone(
                    localTimezone,
                  ),
                ),
              ),
            );
            setClinicianId(clinicianId);
            setLoading(false);

            logger.info(
              `ScheduleScreen: Displaying Schedule calendar for clinicianId ${clinicianId} with events: `,
              {
                events,
                windowEnd,
                windowStart,
              },
            );
          }
        },
        (err) => {
          logger.error(new Error(Messages.queryAppointmentsFailure), {
            errors: err,
          });
          setLoading(false);
        },
      );
    return () => {
      /* Cancel query if new effect already started */
      query.unsubscribe();
      mounted = false;
    };
  }, [dispatch, apollo, windowStart, windowEnd]);

  return (
    <Grid container={true} justifyContent="center" className={styles.inset}>
      <Grid item={true} xs={10}>
        <ScheduleTitleNav />
        <ScheduleContainer
          clinicianId={clinicianId}
          events={events}
          onDoubleClickEvent={(e) => {
            switch (e.gingerType) {
              case EventType.APPOINTMENT:
                history.push(appointmentRoute({ appointmentId: e.id }));
                dispatch(doubleClickedAppointment({ appointmentId: e.id }));
                return;
              case EventType.EVENT:
                history.push(eventRoute(e.id));
                dispatch(doubleClickedEvent({ eventId: e.id }));
            }
          }}
          onNewEvent={() => {
            dispatch(clickScheduleCalendarNewEventAction());
            history.push(Routes.EVENT_CREATE);
          }}
          onNewAppointment={(start?: string, end?: string) => {
            dispatch(clickScheduleCalendarNewAppointmentAction());
            history.push(createAppointmentRoute({ clinicianId, end, start }));
          }}
          onCalendarRangeChange={(dateRange: DateRange) => {
            setAvailabilityWindow({
              windowEnd: dateRange.end.toISOString(),
              windowStart: dateRange.start.toISOString(),
            });
          }}
          timezone={localTimezone}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
}

export function formatTitle(
  title: string,
  start: Date,
  end: Date,
  timezone: string,
) {
  const startFormat = moment.tz(start, timezone).format('h:mm A');
  const endFormat = moment.tz(end, timezone).format('h:mm A');
  return `${title} ${startFormat} - ${endFormat}`;
}

function makeAppointmentToGingerEventWithTimezone(timezone: string) {
  return function (
    appointment: GetCalendarEvents_getClinicalAppointmentsForMe_appointments,
  ): AppointmentGingerEvent {
    const start = moment(appointment.start).toDate();
    const end = moment(appointment.end).toDate();
    const { member } = appointment;

    const title = member
      ? `${member.firstName} ${member.lastName}`
      : 'Appointment';
    return {
      appointmentStatus: appointment.appointmentStatus,
      appointmentType: appointment.type,
      end,
      gingerType: EventType.APPOINTMENT,
      id: appointment.id,
      start,
      title: formatTitle(title, start, end, timezone),
    };
  };
}

const makeAvailabilityToGingerEventWithTimezone = (timezone: string) => {
  return (
    availability: GetCalendarEvents_getClinicianAvailabilitiesForMe_availabilities,
  ): AvailabilityGingerEvent => {
    const start = moment(availability.start).toDate();
    const end = moment(availability.end).toDate();
    const title = 'Availability';
    return {
      end,
      gingerType: EventType.AVAILABILITY,

      id: uniqueId(),

      start,
      // TODO update once api is updated to pass through the actual availability idea
      title: formatTitle(title, start, end, timezone),
    };
  };
};

const makeGoogleCalendarEventToGingerEventWithTimezone = (timezone: string) => {
  return (
    calendarEvent: GetCalendarEvents_getEventsForMe_calendarEvents,
  ): GoogleCalendarMeetingGingerEvent => {
    const start = moment(calendarEvent.start).toDate();
    const end = moment(calendarEvent.end).toDate();
    const title = 'Meeting';
    return {
      attendanceStatus:
        calendarEvent?.attendanceStatus ??
        GoogleCalendarEventAttendanceStatus.ACCEPTED,
      end,
      gingerType: EventType.MEETING,
      id: calendarEvent.id,
      start,
      title: formatTitle(title, start, end, timezone),
    };
  };
};

const makeEventToGingerEventWithTimezone = (timezone: string) => {
  return (
    event: GetCalendarEvents_getClinicalEventsForMe_events,
  ): EventGingerEvent => {
    const start = moment(event.start).toDate();
    const end = moment(event.end).toDate();
    const title = getClinicalEventTypeDisplayValue(event.eventType ?? 'Event');

    return {
      end,
      gingerType: EventType.EVENT,
      id: event.id,
      start,
      title: formatTitle(title, start, end, timezone),
    };
  };
};

export function ScheduleTitleNav(props: {}) {
  return (
    <div>
      <h1>Schedule</h1>
      <nav className={styles.nav}>
        <ul className={styles.main}>
          <li>
            <NavLink
              to={Routes.SCHEDULE}
              activeClassName={styles.active}
              exact={true}
              data-testid="schedule-calendar-tab"
            >
              Calendar
            </NavLink>
          </li>
          <li>
            <NavLink
              to={Routes.SCHEDULE_APPOINTMENT_LIST}
              activeClassName={styles.active}
              data-testid="schedule-appointment-list-tab"
            >
              Appointment List
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default withRouter(ScheduleScreen);
