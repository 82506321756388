import { KeyboardArrowDown } from '@mui/icons-material';
import { TextField, TextFieldProps } from '@mui/material';
import React, {
  FocusEventHandler,
  MouseEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';
import { TooltipWithRedux } from 'shared-components/tooltip/TooltipWithRedux';
import { classNameCombiner } from 'utils';

import styles from './CardTextArea.module.scss';
import {
  BUTTON_TEXT_LESS,
  BUTTON_TEXT_MORE,
  DEFAULT_NUMBER_OF_ROWS_IN_TEXTAREA,
  MAX_NUMBER_OF_ROWS_IN_TEXTAREA,
} from './constants';

// From CardTextArea's styles.root: line-height = 1.5 = 24px
const getNumberOfLines = (scrollHeight: number, lineHeightInPx = 24): number =>
  Math.floor(scrollHeight / lineHeightInPx);

type CardTextAreaProps = TextFieldProps & {
  readOnly: boolean;
  tooltipEventLabel: string;
  tooltipText?: string;
  multiline?: boolean;
};

export const CardTextArea = ({
  minRows = DEFAULT_NUMBER_OF_ROWS_IN_TEXTAREA,
  maxRows = MAX_NUMBER_OF_ROWS_IN_TEXTAREA,
  multiline = true,
  value,
  tooltipEventLabel,
  tooltipText,
  readOnly,
  onBlur,
  ...rest
}: CardTextAreaProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [numberOfLines, setNumberOfLines] = useState(minRows);

  const isMaxNumberOfLinesExceeded = numberOfLines > maxRows;
  const isButtonDisplayed = !isFocused && isMaxNumberOfLinesExceeded;
  const withGradient = isButtonDisplayed && !isExpanded;
  const withPadding = isButtonDisplayed && isExpanded;

  const onViewMoreClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  useEffect(() => {
    if (inputRef.current) {
      setNumberOfLines(getNumberOfLines(inputRef.current.scrollHeight));
    }
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      setNumberOfLines(getNumberOfLines(inputRef.current.scrollHeight));
    }

    if (!value) {
      setNumberOfLines(0);
    }
  }, [inputRef?.current?.scrollHeight]);

  const handleFocus: FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = () => {
    if (!readOnly) {
      setIsFocused(true);
      if (isMaxNumberOfLinesExceeded) {
        setIsExpanded(true);
      }
    }
  };

  const handleBlur: FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    setIsFocused(false);
    if (isExpanded) {
      setIsExpanded(false);
    }
    if (!readOnly) {
      onBlur?.(e);
    }
  };

  return (
    <div
      className={classNameCombiner([
        styles.textFieldWrapper,
        withGradient ? styles.textFieldWrapperGradientLayer : '',
      ])}
    >
      <TooltipWithRedux
        labelForAnalytics={tooltipEventLabel}
        title={tooltipText || ''}
        placement="bottom-start"
      >
        <TextField
          variant="standard"
          minRows={!value && readOnly ? 1 : minRows}
          maxRows={isExpanded || isFocused ? Infinity : maxRows}
          multiline={multiline}
          className={classNameCombiner([
            styles.textField,
            readOnly ? styles.readOnly : '',
            isFocused ? styles.focusedTextField : '',
          ])}
          classes={{
            root: styles.root,
          }}
          onBlur={handleBlur}
          onFocus={handleFocus}
          inputRef={inputRef}
          InputProps={{
            classes: {
              inputMultiline: classNameCombiner([
                styles.input,
                withPadding ? styles.inputWithPaddingForButton : '',
              ]),
              root: classNameCombiner([
                styles.input,
                isFocused ? styles.focused : '',
              ]),
            },
            disableUnderline: true,
            endAdornment: isButtonDisplayed && (
              <button
                onClick={onViewMoreClick}
                className={styles.textFieldButton}
                type="button"
              >
                <KeyboardArrowDown
                  className={classNameCombiner([
                    styles.textFieldIcon,
                    isExpanded ? styles.textFieldIconUpsideDown : '',
                  ])}
                />

                {isExpanded ? BUTTON_TEXT_LESS : BUTTON_TEXT_MORE}
              </button>
            ),
            readOnly,
          }}
          value={value}
          {...rest}
        />
      </TooltipWithRedux>
    </div>
  );
};
