import { UserRole } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import * as Sentry from '@sentry/react';
import { Team } from 'app/care-team/Team';
import { SurveyResponseRoutesV3 } from 'app/coach/member-chart/SurveyResponseRoutesV3';
import { ContentTab } from 'app/coach/member-chart/tabs/ContentTab';
import {
  DeprecatedMasonryLayout,
  MasonryItem,
  TABS_PATIENTS_PROFILE_V2_1_COLUMN_CONFIG,
  TABS_PATIENTS_PROFILE_V2_2_COLUMNS_CONFIG,
} from 'app/masonry-layout/DeprecatedMasonryLayout';
import { SupervisorProfile } from 'app/member-support/SupervisorProfile';
import { IntakeTabView } from 'app/patients/IntakeTabView';
import { MemberProfileNavigationTabsV2 } from 'app/patients/MemberProfileNavigationTabsV2';
import { PrescriptionRoute } from 'app/patients/PrescriptionsRoute';
import { GetMemberQueryWithCareTeam_getMember as Member } from 'app/queries/generated/GetMemberQueryWithCareTeam';
import { SecureRoute } from 'app/routes/SecureRoute';
import { selectUserRole } from 'app/state/user/selectors';
import { Routes } from 'app/top-nav/Routes';
import { TransientFeatureFlag } from 'hooks/useFeatureFlags';
import React, { useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  CARE_TEAM_USER_ROLE,
  CLINICIAN_OR_SUPERVISOR_USER_ROLE,
  SUPERVISOR_USER_ROLE,
} from 'utils';

import patientProfileStyles from './PatientProfile.module.scss';
import {
  careTeamRoute,
  chatCollaborationRoute,
  contentRoute,
  demographicsRoute,
  documentsRoute,
  intakeRoute,
  summaryRoute,
} from './Routes';
import { AppointmentsTabScheduleV2 } from './tabs/appointments/AppointmentsTabScheduleV2';
import ChatCollaboration from './tabs/chat-collaboration/ChatCollaboration';
import DocumentUpload from './tabs/document-upload/DocumentUpload';
import { SummaryTabV2 } from './tabs/summary/SummaryTabV2';

const Profile = (props: { member: Member; pathPrefix: string }) => {
  const SentryRoute = Sentry.withSentryRouting(Route);

  const [appointmentsExpanded, setAppointmentsExpanded] = useState(false);

  const role = selectUserRole();
  const { pathPrefix, member } = props;

  const {
    id,
    isMinorDependent,
    intakeCompletedAt,
    id: memberId,
    preferredFirstName,
    preferredLastName,
    preferences,
    timezone,
    gender: memberGender,
    genderIdentification,
    ethnicities,
    pronouns,
    dateOfBirth,
    firstName,
    lastName,
    email,
    address,
    phone,
    emergencyContact,
    insurance,
    coverageDetails,
    guardianRelationship,
    dosespotId,
  } = member;

  const isSupervisorAndMSUI = pathPrefix.startsWith(Routes.MEMBERS);

  const breakPointCols = appointmentsExpanded
    ? TABS_PATIENTS_PROFILE_V2_1_COLUMN_CONFIG.breakPointCols
    : TABS_PATIENTS_PROFILE_V2_2_COLUMNS_CONFIG.breakPointCols;
  return (
    <DeprecatedMasonryLayout
      className={patientProfileStyles.layout}
      breakPointCols={breakPointCols}
    >
      <MasonryItem className={patientProfileStyles.column}>
        <AppointmentsTabScheduleV2
          patientId={id}
          onExpandToggle={setAppointmentsExpanded}
        />
      </MasonryItem>

      {!appointmentsExpanded && (
        <MasonryItem className={patientProfileStyles.column}>
          <MemberProfileNavigationTabsV2
            id={memberId}
            pathPrefix={pathPrefix}
          />

          <SentryRoute
            exact={true}
            path={pathPrefix}
            render={() => {
              if (role === UserRole.MEMBER_SUPPORT) {
                return <Redirect to={demographicsRoute(pathPrefix, id)} />;
              }
              return <Redirect to={summaryRoute(pathPrefix, id)} />;
            }}
          />

          <SentryRoute
            path={summaryRoute(pathPrefix, id)}
            render={() => (
              <SummaryTabV2
                data-testid="summaryTab"
                id={memberId}
                insurance={insurance}
                coverageDetails={coverageDetails}
                preferredFirstName={preferredFirstName}
                preferredLastName={preferredLastName}
                preferences={preferences}
                timezone={timezone}
                gender={memberGender}
                genderIdentification={genderIdentification}
                ethnicities={ethnicities}
                pronouns={pronouns}
                dateOfBirth={dateOfBirth}
                firstName={firstName}
                lastName={lastName}
                email={email}
                address={address}
                phone={phone}
                emergencyContact={emergencyContact}
                isSupervisorAndMSUI={isSupervisorAndMSUI}
                guardianRelationship={guardianRelationship}
                dosespotId={dosespotId}
                intakeCompletedAt={intakeCompletedAt}
              />
            )}
          />

          <SecureRoute
            exact={true}
            path={contentRoute(pathPrefix, id)}
            permittedRoles={[...CLINICIAN_OR_SUPERVISOR_USER_ROLE]}
            component={() => (
              <ContentTab data-testid="contentTab" memberId={id} />
            )}
          />

          <SentryRoute
            path={intakeRoute(pathPrefix, id)}
            component={() => (
              <IntakeTabView
                pathPrefix={pathPrefix}
                memberId={memberId}
                isMinorDependent={isMinorDependent ?? false}
              />
            )}
          />

          <SecureRoute
            exact={true}
            path={chatCollaborationRoute(pathPrefix, id)}
            requiresFeatureFlag={TransientFeatureFlag.CHAT_COLLABORATION}
            permittedRoles={CLINICIAN_OR_SUPERVISOR_USER_ROLE}
            component={() => <ChatCollaboration memberId={id} />}
          />

          <SecureRoute
            exact={true}
            path={documentsRoute(pathPrefix, id)}
            requiresFeatureFlag={TransientFeatureFlag.ENABLE_DOCUMENT_UPLOAD}
            permittedRoles={[
              ...CLINICIAN_OR_SUPERVISOR_USER_ROLE,
              UserRole.MEMBER_SUPPORT,
            ]}
            component={() => <DocumentUpload memberId={id} />}
          />

          <SecureRoute
            exact={true}
            path={demographicsRoute(pathPrefix, id)}
            permittedRoles={[UserRole.MEMBER_SUPPORT, ...SUPERVISOR_USER_ROLE]}
            component={() => <SupervisorProfile memberId={id} />}
          />
          <PrescriptionRoute id={id} pathPrefix={pathPrefix} />
          <SecureRoute
            exact={true}
            path={careTeamRoute(pathPrefix, id)}
            permittedRoles={CARE_TEAM_USER_ROLE}
            component={() => (
              <Team data-testid="patientProfileCareTeam" memberId={id} />
            )}
          />

          <SurveyResponseRoutesV3 memberId={id} pathPrefix={pathPrefix} />
        </MasonryItem>
      )}
    </DeprecatedMasonryLayout>
  );
};

export type Props = {
  selectedPatientId: string;
  pathPrefix: string;
  member: Member | null;
};

export function PatientProfile({ member, pathPrefix }: Readonly<Props>) {
  if (!member) return <></>;
  return (
    <Profile
      data-testid="patientProfile"
      member={member}
      pathPrefix={pathPrefix}
    />
  );
}
