import { useMutation } from '@apollo/client';
import {
  DemographicsInput,
  InsuranceProviderInput,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { GetMember_getMember as Member } from '@headspace/carehub-graphql/dist/member-support/generated/GetMember';
import {
  UpdateInsurance,
  UpdateInsuranceVariables,
} from '@headspace/carehub-graphql/dist/member-support/generated/UpdateInsurance';
import { updateInsuranceMutation } from '@headspace/carehub-graphql/dist/member-support/MemberDemographicsGQL';
import { PrescriptionsCard } from 'app/member-chart-cards/prescriptions/PrescriptionsCard';
import { updateMemberProfileAction } from 'app/state/amplitude/actions/etc';
import { useSnackNotification } from 'hooks/useSnackNotification';
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { COLUMN_WIDTH, Columns, Grid } from 'shared-components/grid';

import { EditableDemographicsCard } from './components/EditableDemographicsCard';
import { InsuranceProviderCard } from './components/InsuranceProviderCard';
import { extractDemographics } from './utils';

type SummaryTabProps = {
  member: Member;
  onUpdateDemographics: (demographics: Partial<DemographicsInput>) => void;
  isSchedulingUIEnabled?: boolean;
};

export function SummaryTab(props: SummaryTabProps) {
  const { member, onUpdateDemographics } = props;
  const data = useMemo(() => extractDemographics(member), [member]);
  const canShowPrescriptionCard = !!member.dosespotId;

  const handleSave = (demographics: Partial<DemographicsInput>) => {
    onUpdateDemographics(demographics);
  };

  const updateInsurance = useUpdateInsuranceProvider(member.id);

  const preferredName =
    member.firstName && member.lastName
      ? `${member.firstName} ${member.lastName}`
      : '';

  return (
    <Grid>
      <Columns widths={[COLUMN_WIDTH.HALF, COLUMN_WIDTH.HALF]}>
        <EditableDemographicsCard
          data-testid="profileCard"
          title="Profile"
          data={data.profile}
          onSave={handleSave}
          preferredName={preferredName}
        />
        <EditableDemographicsCard
          data-testid="addressCard"
          title="Address"
          data={data.address}
          onSave={handleSave}
        />
      </Columns>
      <Columns widths={[COLUMN_WIDTH.HALF, COLUMN_WIDTH.HALF]}>
        <EditableDemographicsCard
          data-testid="emergencyContactCard"
          title="Emergency Contact"
          data={data.emergencyContact}
          onSave={handleSave}
        />
        <InsuranceProviderCard
          data-testid="insuranceProviderCard"
          member={member}
          updateInsurance={updateInsurance}
        />
        {canShowPrescriptionCard && <PrescriptionsCard memberId={member.id} />}
      </Columns>
    </Grid>
  );
}

function useUpdateInsuranceProvider(
  memberId: string,
): (insuranceProvider: InsuranceProviderInput) => Promise<void> {
  const dispatch = useDispatch();
  const {
    showErrorNotification,
    showSuccessNotification,
  } = useSnackNotification();
  const [updateInsurance, updateInsuranceMutationResult] = useMutation<
    UpdateInsurance,
    UpdateInsuranceVariables
  >(updateInsuranceMutation);
  useEffect(() => {
    const {
      data: updateData,
      error: updateError,
      called,
    } = updateInsuranceMutationResult;
    if (called) {
      if (updateData) {
        const message = `Updated user #${memberId}`;
        showSuccessNotification(message, true);
      } else if (updateError) {
        const message = `Unable to update user #${memberId}`;
        showErrorNotification(message, true);
      }
    }
  }, [
    updateInsuranceMutationResult,
    memberId,
    showSuccessNotification,
    showErrorNotification,
  ]);

  const runMutation = async (insuranceProvider: InsuranceProviderInput) => {
    await updateInsurance({
      variables: { insuranceProvider },
    });
    dispatch(updateMemberProfileAction({ patientId: memberId }));
  };
  return runMutation;
}
