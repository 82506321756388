import { useAppState } from '@ginger.io/core-ui';
import { UserRole } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { homePathForRole } from 'app/routes/AppRoutes';
import { AuthService } from 'app/state/features/auth/AuthService';
import { useAppDispatch } from 'app/state/hooks/baseTypedHooks';
import { TransientFeatureFlag, useFeatureFlags } from 'hooks/useFeatureFlags';
import React, { ReactElement } from 'react';
import { isClinicalSupervisor, isClinicianOrSupervisor } from 'utils';

import Menu from './Menu';
import { metricsRoute, TopLevelRoutes } from './Routes';

const accoladeCaseRoundForm =
  'https://docs.google.com/forms/d/1jfnSRnFs-ca3DWHZkz8LRvj_EOoG_t4vfLu8KPTOPQM/viewform?ts=5f7f749a&gxids=7628&edit_requested=true';
const ccaReferralForm =
  'https://docs.google.com/forms/d/1Htg0IjUQN-c9kh4HZIzUAq2Dgft4jz0ceQQsM81nz8U/viewform?edit_requested=true';
const careCoordinationForm =
  'https://docs.google.com/forms/d/e/1FAIpQLSdcqDbpNzgqkTz4uILtuNO0spl-ZJ7xAXDbBPyVclTClTVDVA/viewform';
const clinicalRiskReviewForm =
  'https://docs.google.com/forms/d/e/1FAIpQLSdWBQFIhIOu1YKDc3qfpOtoAeMdPdsjFdxYlOVpVylbvVcJXw/viewform';

export interface Props {
  redirectTo: (path: string) => void;
  role: UserRole | null;
}

export function SettingsMenu(props: Props) {
  const clinicianId = useAppState((_) => _.user.clinicianId);
  const dispatch = useAppDispatch();
  const { role, redirectTo } = props;
  const featureFlags = useFeatureFlags();
  const isSettingsEnabled =
    featureFlags.transientFeatureFlags[TransientFeatureFlag.SCHEDULE];
  const isCareCoordinationRequestEnabled =
    featureFlags.transientFeatureFlags[
      TransientFeatureFlag.ENABLE_FORM_CARE_COORDINATION_REQUEST
    ];
  const isCareMetricsEnabled =
    featureFlags.transientFeatureFlags[
      TransientFeatureFlag.ENABLE_CARE_METRICS
    ];

  const onSwitchToView = (role: UserRole) => () => {
    const destination = homePathForRole[role];
    redirectTo(destination);
  };

  const onLogout = () => {
    void dispatch(AuthService.logout());
  };

  const openLinkInNewTab = (link: string) => window.open(link, '_blank');

  const menuItems: ReactElement[] = [];
  const bookmarkItems: ReactElement[] = [];

  if (isClinicalSupervisor(role)) {
    menuItems.push(
      <MenuItem
        key="switchToClinicianUI"
        data-testid="switchToClinicianUI"
        onClick={onSwitchToView(UserRole.CLINICIAN)}
      >
        Switch to Clinician UI
      </MenuItem>,
      <MenuItem
        key="switchToMSUI"
        data-testid="switchToMSUI"
        onClick={onSwitchToView(UserRole.MEMBER_SUPPORT)}
      >
        Switch to MS UI
      </MenuItem>,
    );
  }

  if (isClinicianOrSupervisor(role)) {
    bookmarkItems.push(
      <MenuItem
        key="accoladeRoundForm"
        data-testid="accoladeRoundForm"
        onClick={() => openLinkInNewTab(accoladeCaseRoundForm)}
      >
        Accolade Case Rounds Form
      </MenuItem>,
    );

    if (isCareMetricsEnabled && clinicianId) {
      menuItems.push(
        <MenuItem
          key="careMetrics"
          data-testid="careMetrics"
          onClick={() => redirectTo(metricsRoute(clinicianId))}
        >
          Care Metrics
        </MenuItem>,
      );
    }

    menuItems.push(
      <MenuItem
        key="ccaReferralForm"
        data-testid="ccaReferralForm"
        onClick={() => openLinkInNewTab(ccaReferralForm)}
      >
        Headspace EAP/CCA Referral Request
      </MenuItem>,
    );

    if (isCareCoordinationRequestEnabled) {
      bookmarkItems.push(
        <MenuItem
          key="careCoordinationForm"
          data-testid="careCoordinationForm"
          onClick={() => openLinkInNewTab(careCoordinationForm)}
        >
          Care Coordination Request
        </MenuItem>,
      );
    }

    bookmarkItems.push(
      <MenuItem
        key="clinicalRiskReviewForm"
        data-testid="clinicalRiskReviewForm"
        onClick={() => openLinkInNewTab(clinicalRiskReviewForm)}
      >
        Clinical Risk Review (CRR)
      </MenuItem>,
    );

    if (isSettingsEnabled) {
      menuItems.push(
        <MenuItem
          key="settings"
          data-testid="settings"
          onClick={() => redirectTo(TopLevelRoutes.SETTINGS)}
        >
          Settings
        </MenuItem>,
      );
    }
  }

  menuItems.push(
    <MenuItem data-testid="logout" key="logout" onClick={onLogout}>
      Logout
    </MenuItem>,
  );

  return (
    <>
      <Menu
        menuItems={bookmarkItems}
        Icon={({ onClick }) => (
          <Button data-testid="bookmarkButton" onClick={onClick}>
            <BookmarksIcon />
          </Button>
        )}
        testId="bookmark"
      />
      <Menu
        menuItems={menuItems}
        Icon={({ onClick }) => (
          <Button data-testid="settingsButton" onClick={onClick}>
            <AccountCircleIcon />
          </Button>
        )}
        testId="settings"
      />
    </>
  );
}
