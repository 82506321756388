import { useAppState } from 'app/state';
import { navigationTabClicked } from 'app/state/amplitude/actions/etc';
import React from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';

import styles from './NavigationTabs.module.scss';
import { TabLabel } from './TabLabel';

export type TabLink = {
  label: string;
  path: string;
  isNewFeature?: boolean;
  isNotificationVisible?: boolean;
};

type Props = {
  links: TabLink[];
  className?: string;
  preserveQueries?: boolean;
};

export function NavigationTabs({
  links,
  className = '',
  preserveQueries = false,
}: Props) {
  const dispatch = useDispatch();
  const role = useAppState((_) => _.user.role);
  const { search } = useLocation();

  const navLinks = links.map(
    ({ label, path, isNewFeature = false, isNotificationVisible = false }) => {
      const onClick = () => {
        dispatch(navigationTabClicked({ label, pathname: path, role }));
      };

      return (
        <li key={path}>
          <NavLink
            data-testid={`navLink-${label}`}
            to={{
              pathname: path,
              search: preserveQueries ? search : '',
            }}
            activeClassName={styles.active}
            onClick={onClick}
          >
            <TabLabel
              isNewFeature={isNewFeature}
              isNotificationVisible={isNotificationVisible}
            >
              {label}
            </TabLabel>
          </NavLink>
        </li>
      );
    },
  );

  return <ul className={[styles.tabs, className].join(' ')}>{navLinks}</ul>;
}
