import { Base64 } from '@ginger.io/vault-core/dist/crypto/Base64';
import { GetMemberChartVaultItemsVariables as GetVaultItemsVariables } from '@headspace/carehub-graphql/dist/coach-member-chart/generated/GetMemberChartVaultItems';
import { GetCoachNotesAndUserMetadataVariables } from '@headspace/carehub-graphql/dist/coach-notes/generated/GetCoachNotesAndUserMetadata';
import {
  UserRole,
  VaultItemSortField,
  VaultItemSortOrder,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { getGroupId } from 'app/coach/member-chart/queries';
import { isClinicianOrSupervisor, isMS } from 'utils';

export const getNotesVariables = async (
  memberId: string,
  role: UserRole,
): Promise<GetVaultItemsVariables> => {
  return {
    groupId: await getGroupId(memberId, role),
    pagination: {
      cursor: null,
      maxItemsPerPage: 200,
      sortField: VaultItemSortField.CREATED_AT,
      sortOrder: VaultItemSortOrder.DESC,
    },
    tag:
      isClinicianOrSupervisor(role) || isMS(role)
        ? await Base64.hash(`member-chart-${memberId}-shareable-coach-notes`)
        : await Base64.hash(`member-chart-${memberId}-coach-notes`),
  };
};

export const getMetadataVariables = async (
  memberId: string,
  userId: string,
): Promise<GetVaultItemsVariables> => {
  return {
    groupId: null,
    pagination: {
      cursor: null,
      maxItemsPerPage: 200,
      sortField: VaultItemSortField.CREATED_AT,
      sortOrder: VaultItemSortOrder.DESC,
    },
    tag: await Base64.hash(`notes-items-metadata-${userId}-${memberId}`),
  };
};

export const getLegacyNotesVariables = async (
  memberId: string,
  role: UserRole,
): Promise<GetVaultItemsVariables> => {
  return {
    groupId: await getGroupId(memberId, role),
    pagination: {
      cursor: null,
      maxItemsPerPage: 200,
      sortField: VaultItemSortField.CREATED_AT,
      sortOrder: VaultItemSortOrder.DESC,
    },
    tag: await Base64.hash(`coach-note-coaching-${memberId}`),
  };
};

export const getVariables = async (
  memberId: string,
  userId: string,
  role: UserRole,
): Promise<GetCoachNotesAndUserMetadataVariables> => ({
  legacySummaryNotesInput: memberId,
  metadataInput: await getMetadataVariables(memberId, userId),
  notesInput: await getNotesVariables(memberId, role),
});
