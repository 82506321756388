import {
  Metadata,
  Metadata_NoteStatus,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/Metadata';
import {
  PsychiatryIntakeSection,
  PsychiatryProgressSection,
  PsychiatrySectionName,
} from '@ginger.io/vault-clinical-notes/dist/PsychiatryIntakeSection';
import {
  TherapyIntakeSection,
  TherapyIntakeSectionName,
} from '@ginger.io/vault-clinical-notes/dist/TherapyIntakeSection';
import {
  TherapyProgressSection,
  TherapyProgressSectionName,
} from '@ginger.io/vault-clinical-notes/dist/TherapyProgressSection';
import { getClinicalCareTeamGroupId } from '@ginger.io/vault-core/dist/IdHelpers';
import { useScreen, VaultItemPermissions } from '@ginger.io/vault-ui';
import { AuditLogEntryView } from 'app/notes-ui/AuditLogEntryView';
import { AmendmentsForm } from 'app/notes-ui/shared/amendments/AmendmentsForm';
import { AmendmentWithAuditLog } from 'app/notes-ui/shared/amendments/types';
import { TabPanel } from 'app/notes-ui/tabs/TabPanel';
import { Tab, Tabs } from 'app/notes-ui/tabs/Tabs';
import { getTherapyIntakeSectionLabel } from 'app/notes-ui/utils';
import { useLogger } from 'app/state/log/useLogger';
import { Status } from 'app/state/status/types/StateSlice';
import { VaultIds } from 'app/vault/api/VaultIds';
import { useDiagnosisCodes } from 'hooks/useDiagnosisCodes';
import { useSnackNotification } from 'hooks/useSnackNotification';
import { isEqual } from 'lodash';
import React, { ElementType, useState } from 'react';

import { TabState } from './tabs/Tab';

export type ClinicalNoteSection =
  | TherapyIntakeSection
  | TherapyProgressSection
  | PsychiatryIntakeSection
  | PsychiatryProgressSection;

// We only one PsychiatrySectionName because both psych intake and progress notes have identical sections
export type ClinicalNoteSectionName =
  | TherapyIntakeSectionName
  | TherapyProgressSectionName
  | PsychiatrySectionName;

export type Section<T extends ClinicalNoteSection> = [
  T['name'],
  string,
  ElementType,
  (data: any) => boolean,
];

export type ClinicalNote<T extends ClinicalNoteSection> = {
  [key in ClinicalNoteSectionName]: Extract<T, { name: key }>['data'] | null;
} & {
  metadata: Metadata;
  permissions: VaultItemPermissions | null;
  amendments: AmendmentWithAuditLog[];
  createdAt: string | null;
  updatedAt: string | null;
  signingClinicianName: string | null;
};

export type Props<T extends ClinicalNoteSection> = {
  appointmentId: string;
  memberId?: string;
  status: Status;
  note: ClinicalNote<T>;
  draftNote: ClinicalNote<T>;
  onSubmit: (section: T) => Promise<void>;
  updateDraftNoteState: (section: T) => void;
  sections: Section<T>[];
  ids: typeof VaultIds;
  readOnly?: boolean;
};

export function ClinicalNotesScreen<T extends ClinicalNoteSection>(
  props: Props<T>,
) {
  const {
    appointmentId,
    memberId,
    note,
    sections,
    draftNote,
    readOnly = true,
  } = props;
  const isSignedAndLocked =
    note.metadata.status === Metadata_NoteStatus.signed_and_locked_note;
  const { showErrorNotification } = useSnackNotification();
  const logger = useLogger();
  const [saving, setSaving] = useState(false);
  useDiagnosisCodes();

  const screenNames: Array<any> = sections.map(([name]) => name);

  if (isSignedAndLocked) {
    screenNames.push(TherapyIntakeSectionName.AMENDMENTS);
  }

  const { goto, next, screen } = useScreen(screenNames);

  const vaultItemId = (sectionName: TherapyIntakeSectionName) => {
    return props.ids.section(appointmentId, sectionName);
  };

  const onSubmit = async (section: any) => {
    try {
      setSaving(true);
      if (!section.data) {
        logger.info('Section data is empty, aborting save attempt.', {
          appointmentId,
          sectionName: section.name,
        });
        return;
      }
      await props.onSubmit(section);
      next();
    } catch (error) {
      const [, label] = sections.find(([name]) => section.name === name) ?? [];
      showErrorNotification(`An error occurred while saving ${label}`);
      logger.error(error);
    } finally {
      setSaving(false);
    }
  };

  const saveDraftNote = async () => {
    try {
      const sectionName = screen as ClinicalNoteSectionName;
      const sectionNote = note[sectionName];
      const draftSectionNote = draftNote[sectionName];

      if (!isSignedAndLocked && !isEqual(sectionNote, draftSectionNote)) {
        await props.onSubmit({
          data: draftSectionNote,
          name: sectionName,
        } as T);
      }
    } catch (error) {
      logger.error(error);
    }
  };

  const onTabClick = (key: string) => {
    void saveDraftNote();
    goto((sectionName) => sectionName === key);
  };

  const tabs: Tab[] = sections.map(([key, label, Form, validator]) => {
    let tabState: TabState;
    if (isSignedAndLocked) {
      tabState = TabState.COMPLETED;
    } else {
      tabState =
        note[key] !== null
          ? validator(note[key])
            ? TabState.COMPLETED
            : TabState.PARTIAL
          : TabState.NOT_STARTED;
    }
    return {
      component: (
        <TabPanel title={label} key={key} testId={`${key.toLowerCase()}Panel`}>
          <FormSection
            disabled={isSignedAndLocked || readOnly}
            appointmentId={appointmentId}
            sectionName={key}
            initialValue={note[key]}
            form={Form}
            savingNote={saving}
            onSubmit={onSubmit}
            noteLastUpdatedAt={note.updatedAt}
            updateDraftNoteState={(section: any) => {
              const sectionName = screen as ClinicalNoteSectionName;
              const draftSectionNote = draftNote && draftNote[sectionName];
              if (!isEqual(draftSectionNote, section.data))
                props.updateDraftNoteState(section);
            }}
          />
        </TabPanel>
      ),
      key,
      label,
      tabState,
    };
  });

  if (isSignedAndLocked) {
    const key = TherapyIntakeSectionName.AMENDMENTS;
    const label = getTherapyIntakeSectionLabel(key);
    const disabled = note[key] !== null || readOnly;
    tabs.push({
      component: (
        <TabPanel title={label} key={key} testId="amendmentsPanel">
          <AmendmentsForm
            sectionName={TherapyIntakeSectionName.AMENDMENTS}
            savingNote={saving}
            appointmentId={appointmentId}
            onSubmit={onSubmit}
            previousValues={note.amendments}
            disabled={disabled}
          />
        </TabPanel>
      ),
      key,
      label,
      tabState:
        note[key] !== null || note.amendments.length > 0
          ? TabState.COMPLETED
          : TabState.NOT_STARTED,
    });
  }

  const auditLogView =
    screen !== TherapyIntakeSectionName.AMENDMENTS && memberId ? (
      <AuditLogEntryView
        key={screen}
        vaultItemId={vaultItemId(screen)}
        vaultGroupId={getClinicalCareTeamGroupId(memberId)}
      />
    ) : null;

  return (
    <Tabs
      currentTabKey={screen}
      onTabClick={onTabClick}
      tabs={tabs}
      auditLogView={auditLogView}
    />
  );
}

function FormSection<T extends ClinicalNoteSection>(props: {
  appointmentId: string;
  sectionName: T['name'];
  initialValue: T['data'] | null;
  disabled: boolean;
  savingNote: boolean;
  form: ElementType;
  onSubmit: (section: ClinicalNoteSection) => void;
  noteLastUpdatedAt: string | null;
  updateDraftNoteState: (section: ClinicalNoteSection) => void;
}) {
  const { form: Form, initialValue } = props;

  return (
    <Form
      savingNote={props.savingNote}
      appointmentId={props.appointmentId}
      onSubmit={props.onSubmit}
      initialValue={initialValue || undefined}
      disabled={props.disabled}
      updateDraftNoteState={props.updateDraftNoteState}
      noteLastUpdatedAt={props.noteLastUpdatedAt || undefined}
    />
  );
}
