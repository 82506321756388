import { useQuery } from '@apollo/client';
import { DependentIntakeReport } from '@ginger.io/core-ui';
import {
  GetMemberCareTeam,
  GetMemberCareTeamVariables,
} from '@headspace/carehub-graphql/dist/care-team/generated/GetMemberCareTeam';
import { getMemberCareTeam } from '@headspace/carehub-graphql/dist/care-team/queries';
import { useAppState } from 'app/state';
import { viewDependentReport } from 'app/state/amplitude/actions/etc';
import Messages from 'i18n/en/patients.json';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ErrorState } from 'shared-components/error-state/ErrorState';
import { Loader } from 'shared-components/loader/Loader';

export type CoachDependentIntakeScreenParams = {
  patientId: string;
};

export function CoachDependentIntakeScreen(
  props: RouteComponentProps<CoachDependentIntakeScreenParams>,
) {
  const { match } = props;
  const { params } = match;
  const { userId, timezone, role } = useAppState(({ user }) => ({
    role: user.role,
    timezone: user.timezone,
    userId: user.userId,
  }));

  const dispatch = useDispatch();
  useEffect(() => {
    if (role)
      dispatch(viewDependentReport({ memberId: params.patientId, role }));
  }, [dispatch, role]);

  const { loading, error, data } = useQuery<
    GetMemberCareTeam,
    GetMemberCareTeamVariables
  >(getMemberCareTeam, {
    variables: { id: params.patientId, timezone },
  });

  if (loading) return <Loader />;

  if (error) return <ErrorState error={error} />;

  // just current coaches can view the report
  // checks if the current user is not an active coach for this member
  if (
    data?.getMember?.coachingCareTeam?.current?.coaches?.every(
      (coach) => coach?.gingerId !== userId,
    )
  ) {
    return <ErrorState error={new Error(Messages.notActiveCoachForMember)} />;
  }

  return (
    <div data-testid="dependentIntakeReport">
      <DependentIntakeReport id={params.patientId} />
    </div>
  );
}

export default withRouter(CoachDependentIntakeScreen);
