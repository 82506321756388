import { useQuery } from '@apollo/client';
import { useAppState } from '@ginger.io/core-ui';
import { field, stringField, useForm } from '@ginger.io/react-use-form';
import { getClinicianAppointmentConfig } from '@headspace/carehub-graphql/dist/appointments/appointmentQueries';
import { GetMembersForMe_getMembersForMe_members } from '@headspace/carehub-graphql/dist/appointments/generated/GetMembersForMe';
import {
  ClinicalAppointmentRecurrenceInput,
  ClinicalAppointmentStatus,
  ClinicalAppointmentType,
  ClinicianNewAppointmentsConfigInput,
  CreateClinicalAppointmentForMeInput,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { Divider, Typography } from '@mui/material';
import { getAppointmentTypeDuration } from 'app/appointments/utils';
import { MemberSearchFieldForMeContainer } from 'app/member-search/MemberSearchFieldForMeContainer';
import {
  DateTimeRangeField,
  DropDownField,
  NumberField,
  TextAreaField,
} from 'app/notes-ui/forms/fields';
import { DATE_TIME_FORMAT } from 'app/notes-ui/forms/form-controls/DateTimeRangeInput';
import { NoteFormControlLabel } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { patientProfileRoute, TopLevelRoutes } from 'app/top-nav/Routes';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Size, Width } from 'types/StyleTypes';
import { formatAppointmentStatus } from 'utils';

import { AppointmentControls } from './AppointmentControls';
import styles from './AppointmentScreen.module.scss';
import { MemberSessionStatsContainer } from './MemberSessionStatsContainer';

export enum ClinicalAppointmentRecurrenceIntervalOptions {
  NONE = 'NONE',
  ONE_WEEK = 'ONE_WEEK',
  TWO_WEEKS = 'TWO_WEEKS',
}

type NewAppointmentForMeFormType = {
  memberId: string;
  type: ClinicalAppointmentType;
  appointmentStatus: ClinicalAppointmentStatus;
  start: string;
  end: string;
  details: string;
  recurrence: {
    interval: ClinicalAppointmentRecurrenceIntervalOptions;
    occurrenceCount: PositiveInt;
  };
};
export type NewAppointmentForMeFormProps = {
  member?: GetMembersForMe_getMembersForMe_members;
  onClose: () => void;
  onCreate: (input: CreateClinicalAppointmentForMeInput) => Promise<void>;
  timezone: string;
  onRecurrenceClick?: () => void;
  onRecurrenceSelect?: (
    selected: ClinicalAppointmentRecurrenceIntervalOptions,
  ) => void;
  start?: string;
  end?: string;
};

export interface ClinicianNewAppointmentsConfigInputVariables {
  input: ClinicianNewAppointmentsConfigInput;
}
export interface ClinicianNewAppointmentsConfigResponse {
  maxRecurrenceCount: number;
}
/**
 * Form for new appointments for clinicians
 */
export function NewAppointmentForMeForm({
  member,
  timezone,
  onClose,
  onCreate,
  onRecurrenceClick,
  onRecurrenceSelect,
  start,
  end,
}: NewAppointmentForMeFormProps) {
  const { clinicianId } = useAppState((_) => _.user);

  const { data } = useQuery<
    ClinicianNewAppointmentsConfigResponse,
    ClinicianNewAppointmentsConfigInputVariables
  >(getClinicianAppointmentConfig, {
    variables: { input: { clinicianId } },
  });

  const [sessionCoverageExhausted, setSessionCoverageExhausted] = useState(
    false,
  );
  const { fields, validate, getValue } = useForm<NewAppointmentForMeFormType>({
    appointmentStatus: field<ClinicalAppointmentStatus>({
      default: ClinicalAppointmentStatus.Confirmed,
    }),
    details: stringField({ rules: [] }),
    end: stringField({
      default: end ? moment(end).format(DATE_TIME_FORMAT) : '',
    }),
    memberId: stringField({ default: member?.id ?? '' }),
    recurrence: {
      interval: field<ClinicalAppointmentRecurrenceIntervalOptions>({
        default: ClinicalAppointmentRecurrenceIntervalOptions.NONE,
      }),
      occurrenceCount: field({ rules: [] }),
    },
    start: stringField({
      default: start ? moment(start).format(DATE_TIME_FORMAT) : '',
    }),
    type: field(),
  });

  const onSave = async () => {
    const isValid = await validate();
    if (isValid) {
      const appointmentData = getValue();
      const recurrence = (appointmentData.recurrence.interval ===
        ClinicalAppointmentRecurrenceIntervalOptions.NONE ||
      !appointmentData.recurrence.interval
        ? null
        : appointmentData.recurrence) as ClinicalAppointmentRecurrenceInput | null;
      const start = moment
        .tz(appointmentData.start, DATE_TIME_FORMAT, timezone)
        .toISOString();
      const end = moment
        .tz(appointmentData.end, DATE_TIME_FORMAT, timezone)
        .toISOString();
      const memberId = Number.parseInt(appointmentData.memberId);
      return onCreate({
        appointmentData: {
          ...appointmentData,
          end,
          memberId,
          recurrence,
          start,
        },
      });
    }
  };

  const memberComponent = member ? (
    <NavLink
      data-testid="member-link"
      to={patientProfileRoute(member.id)}
      className={styles.memberLink}
    >
      {member.firstName} {member.lastName} ({member.id})
    </NavLink>
  ) : (
    <MemberSearchFieldForMeContainer field={fields.memberId} />
  );
  const errorMessage = sessionCoverageExhausted
    ? getSessionCoverageExhaustedErrorMessage()
    : undefined;

  return (
    <AppointmentControls
      onSave={onSave}
      onClose={onClose}
      saveDisabled={sessionCoverageExhausted}
      error={errorMessage}
    >
      <div
        data-testid="new-appointment-for-clinician"
        className={styles.controlGroup}
      >
        <div className={styles.member}>
          <NoteFormControlLabel label="Member">
            {memberComponent}
            <MemberSessionStatsContainer
              memberId={fields.memberId.value}
              timezone={timezone}
              onSessionCoverageExhausted={(sessionCoverageExhausted) =>
                setSessionCoverageExhausted(sessionCoverageExhausted)
              }
            />
          </NoteFormControlLabel>
        </div>
      </div>

      <Divider />

      <div className={styles.flex}>
        <div className={styles.controlGroup}>
          <NoteFormControlLabel label="Appt Type">
            <DropDownField
              field={fields.type}
              dropdownProps={{
                className: styles.input,
                dataTestId: 'appointmentType',
                options: [
                  {
                    name: 'Therapy Intake',
                    value: ClinicalAppointmentType.THERAPY_INTAKE,
                  },
                  {
                    name: 'Therapy Follow-up',
                    value: ClinicalAppointmentType.THERAPY_PROGRESS,
                  },
                  {
                    name: 'Psychiatry Intake',
                    value: ClinicalAppointmentType.PSYCHIATRY_INTAKE,
                  },
                  {
                    name: 'Psychiatry Follow-up',
                    value: ClinicalAppointmentType.PSYCHIATRY_PROGRESS,
                  },
                ],
                placeholder: 'Appt Type',
                size: Size.MD,
              }}
            />
          </NoteFormControlLabel>
        </div>

        <div className={styles.controlGroup}>
          <NoteFormControlLabel label="Appt Status">
            <DropDownField
              field={fields.appointmentStatus}
              dropdownProps={{
                dataTestId: 'appointmentStatus',
                options: [
                  {
                    name: formatAppointmentStatus(
                      ClinicalAppointmentStatus.Confirmed,
                    ),
                    value: ClinicalAppointmentStatus.Confirmed,
                  },
                ],
                placeholder: 'Appt Status',
                size: Size.MD,
              }}
            />
          </NoteFormControlLabel>
        </div>
      </div>

      <div className={styles.flex}>
        <DateTimeRangeField
          label="Date and Time"
          field={{ end: fields.end, start: fields.start }}
          autoEndTime={getAppointmentTypeDuration(fields.type.value)}
        >
          <div className={styles.timezone}>
            <NavLink to={TopLevelRoutes.SETTINGS}>{timezone}</NavLink>
          </div>
        </DateTimeRangeField>
      </div>

      <div className={styles.flex}>
        <div className={styles.marginAfter}>
          <DropDownField
            onSelect={onRecurrenceSelect}
            onOpen={onRecurrenceClick}
            field={fields.recurrence.interval}
            dropdownProps={{
              dataTestId: 'interval',
              options: [
                {
                  name: 'Does not repeat',
                  value: ClinicalAppointmentRecurrenceIntervalOptions.NONE,
                },
                {
                  name: 'Once a week',
                  value: ClinicalAppointmentRecurrenceIntervalOptions.ONE_WEEK,
                },
                {
                  name: 'Every two weeks',
                  value: ClinicalAppointmentRecurrenceIntervalOptions.TWO_WEEKS,
                },
              ],
              size: Size.MD,
            }}
          />
        </div>
        <span className={styles.formText}>ends after</span>
        <div className={styles.marginBeforeAndAfter}>
          <NumberField
            testId="occurrenceCount"
            label=""
            field={fields.recurrence.occurrenceCount}
            inputProps={{ max: data?.maxRecurrenceCount || 4, min: 1 }}
          />
        </div>
        <span className={styles.formText}>occurrences</span>
      </div>

      <Divider />

      <div className={styles.controlGroup}>
        <NoteFormControlLabel label="Zoom Link">
          <Typography variant="body1">
            Link will be added once the appointment is saved.
          </Typography>
        </NoteFormControlLabel>
        <TextAreaField
          label="Notes"
          field={fields.details}
          placeholder="Add note"
          testId="details"
          width={Width.FULL}
          className={styles.textArea}
        />
      </div>
    </AppointmentControls>
  );
}

function getSessionCoverageExhaustedErrorMessage() {
  // not-so-distant future: I18N?
  return 'Member has no remaining pre-paid sessions available and no credit card on file or expired. Please contact Member Support to inform the member that they must provide a credit card through the Ginger Member App in order to schedule future appointments.';
}
