import { UserRole } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { EventType } from 'app/EventType';
import { InboxSections, InboxTab } from 'app/inbox/types';
import { createAction } from 'redux-reloaded';

export type Source = 'COACH_INBOX' | 'CLINICIAN_INBOX';
export const clickInboxTabAction = createAction(
  EventType.INBOX_TAB_CLICKED,
  (payload: { tab: InboxTab; source: Source; memberCount: number }) => ({
    payload,
  }),
);
export const clickMemberItemAction = createAction(
  EventType.MEMBER_ITEM_CLICKED,
  (payload: {
    tab: InboxTab;
    source: Source;
    section: InboxSections;
    memberId: string;
    role: UserRole;
  }) => ({
    payload,
  }),
);
export const clickMemberChartTabAction = createAction(
  EventType.MEMBER_CHART_TAB_CLICKED,
  (payload: { source: Source; memberId: string; role: UserRole }) => ({
    payload,
  }),
);
export const openedNewMemberChartTab = createAction(
  EventType.OPEN_NEW_MEMBER_CHART_TAB,
  (payload: {
    source: Source;
    section: InboxSections;
    memberId: string;
    role: UserRole;
    openTabCount: number;
  }) => ({
    payload,
  }),
);
export const clickMemberChartTabClosedAction = createAction(
  EventType.MEMBER_CHART_TAB_CLOSED,
  (payload: {
    source: Source;
    memberId: string;
    role: UserRole;
    openTabCount: number;
  }) => ({
    payload,
  }),
);
export const clickInboxMenuAction = createAction(
  EventType.INBOX_MENU_BUTTON_CLICKED,
  (payload: { source: Source; state: 'OPEN' | 'CLOSED'; role: UserRole }) => ({
    payload,
  }),
);
export const loadMemberListAction = createAction(
  EventType.INBOX_MEMBER_LIST_LOADED,
  (payload: { source: Source; role: UserRole; tab: InboxTab }) => ({
    payload,
  }),
);
export const loadMoreMemberListAction = createAction(
  EventType.INBOX_MEMBER_LIST_PAGINATION,
  (payload: {
    userId: string;
    role: UserRole;
    section: InboxSections;
    totalItems: number;
    itemsShown: number;
    hasMore: boolean;
    errorMessage?: string;
    success?: boolean;
  }) => ({
    ...payload,
  }),
);
export const inboxMemberSearch = createAction(
  EventType.INBOX_MEMBER_SEARCHED,
  (payload: { source: Source; role: UserRole }) => ({
    payload,
  }),
);
