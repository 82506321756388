import {
  MessageToCareTeam,
  MessageToCareTeam_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/MessageToCareTeam';
import { NoteType as ClinicalNoteType } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/NoteType';
import { SafetyPlan } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/safety/SafetyPlan';
import { TreatmentGoal } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/TreatmentGoal';
import {
  TreatmentGoals,
  TreatmentGoals_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/TreatmentGoals';
import {
  VaultItem,
  VaultItem_SchemaType as SchemaType,
} from '@ginger.io/vault-core/dist/generated/protobuf-schemas/vault-core/VaultItem';
import {
  VaultAuditLogEvent,
  VaultAuditLogEventType,
} from '@ginger.io/vault-ui';
import { DeleteVaultItemsMutation } from '@ginger.io/vault-ui/src/generated/graphql';
import { GetAppointmentById_getAppointmentById as Appointment } from 'app/vault/generated/GetAppointmentById';
import { IdAndNoteType } from 'app/vault/hooks/useTherapyProgressNote';
import { NoteType } from 'generated/globalTypes';

import { Subsection, SubsectionType } from './ShareableSubsectionTypes';

export function getItemCreatedEvent(
  auditLog: VaultAuditLogEvent[],
): VaultAuditLogEvent | null {
  return (
    auditLog.filter(
      (_) => _.eventType === VaultAuditLogEventType.ItemCreated,
    )[0] || null
  );
}

/**
 * We only want to throw an error if there is no successes, and we find an error
 */
export function deletionMutationErrorHandler(
  deletedVaultItemsMutation: DeleteVaultItemsMutation,
): void {
  const { deleteVaultItems } = deletedVaultItemsMutation;
  const failedDeleteItem = deleteVaultItems.find((_) => !_.success);
  if (failedDeleteItem) {
    const reasons = deleteVaultItems
      .filter((_) => !_.success)
      .map((_) => `Item id ${_.id} failed because ${_.errorMessage}`)
      .join(', ');
    const message = `We're unable to delete some sections\n ${reasons}`;
    throw new Error(message);
  }
}

export const clinicalNoteTypeEnumMap: Record<NoteType, ClinicalNoteType> = {
  [NoteType.UNSPECIFIED]: ClinicalNoteType.undefined_note_type,
  [NoteType.PSYCHIATRY_INTAKE]: ClinicalNoteType.psychiatry_intake,
  [NoteType.PSYCHIATRY_PROGRESS]: ClinicalNoteType.psychiatry_progress,
  [NoteType.THERAPY_INTAKE]: ClinicalNoteType.therapy_intake,
  [NoteType.THERAPY_PROGRESS]: ClinicalNoteType.therapy_progress,
};

interface AppointmentDetails {
  appointmentId: string;
  appointmentStart: string;
  appointmentEnd: string;
  noteType: ClinicalNoteType;
}
/**
@description In order to comply with HIPAA requirements, we have to limit the amount of information
that is shown across care teams to just the minimum amount required for a care provider to do their job.
In order to do so, we have to save the shareable portion of the Clinical Note as a subsection that
can be stored in Vault with the necessary permissions to share it with Coaches. This function receives
the subsection data and type as input and encodes it according to the Vault schema definitions.
*/
export function clinicalNoteSubsectionInputToVaultItem({
  subsectionType,
  data,
  appointment,
  noteType,
}: {
  subsectionType: SubsectionType;
  data?: Subsection | string;
  appointment: Appointment;
  noteType: NoteType;
}): VaultItem | null {
  const appointmentDetails: AppointmentDetails = {
    appointmentEnd: appointment.end,
    appointmentId: appointment.id,
    appointmentStart: appointment.start,
    noteType: clinicalNoteTypeEnumMap[noteType],
  };

  if (data === undefined) {
    return null;
  }

  if (subsectionType === SubsectionType.SAFETY_PLAN) {
    return {
      data: SafetyPlan.encode({
        ...(data as SafetyPlan),
        ...appointmentDetails,
      }).finish(),
      schemaType: SchemaType.vault_clinical_notes_safety_plan,
    };
  }
  if (subsectionType === SubsectionType.TREATMENT_PLAN_GOALS) {
    return {
      data: TreatmentGoals.encode({
        ...appointmentDetails,
        goal: data as TreatmentGoal[],
        version: TreatmentGoals_Version.v0,
      }).finish(),
      schemaType: SchemaType.vault_clinical_notes_treatment_goals,
    };
  }
  if (subsectionType === SubsectionType.MESSAGE_TO_CARE_TEAM) {
    return {
      data: MessageToCareTeam.encode({
        ...appointmentDetails,
        messageToCareTeam: data as string,
        version: MessageToCareTeam_Version.v0,
      }).finish(),
      schemaType: SchemaType.vault_clinical_notes_message_to_care_team,
    };
  }
  throw new Error("You're not permitted to perform this operation");
}

export function getPreviousAppointment(
  appt: Appointment,
): IdAndNoteType | null {
  let previousAppt: IdAndNoteType | null = null;
  if (appt.previousAppointment) {
    const {
      id,
      clinicalNote: { noteType },
    } = appt.previousAppointment;
    previousAppt = { id, noteType: noteType! };
  }
  return previousAppt;
}
