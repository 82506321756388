import 'date-fns';

import { field, useForm } from '@ginger.io/react-use-form';
import { InsuranceProviderInput } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { GetMember_getMember as Member } from '@headspace/carehub-graphql/dist/member-support/generated/GetMember';
import { FormLabel } from '@mui/material';
import { TextBoxField } from 'app/notes-ui/forms/fields';
import { useLogger } from 'app/state/log/useLogger';
import React, { useState } from 'react';
import { Box } from 'shared-components/Box';
import { Option, Select } from 'shared-components/form-inputs/Select';

import { CardTitle } from './CardTitle';
import classes from './components.module.scss';

type Props = {
  member: Member;
  updateInsurance: (insuranceProvider: InsuranceProviderInput) => Promise<void>;
};

function getCarrierOptions(member: Member) {
  const memberCarrierChoices:
    | Option[]
    | undefined = member.carrierChoices?.reduce<Option[]>(
    (options, carrier) => {
      if (carrier && carrier.carrierName && carrier.carrierId)
        options.push({
          text: carrier.carrierName,
          value: carrier.carrierId.toString(),
        });
      return options;
    },
    [],
  );
  return [...(memberCarrierChoices ?? []), { text: 'Other', value: 'other' }];
}

function getInsuranceInfo(member: Member) {
  const { insurance } = member;
  const memberInsurance = {
    carrierId: '',
    company: '',
    idNumber: '',
    patientId: member.id,
  };
  if (insurance) {
    memberInsurance.carrierId = insurance.carrierId?.toString() || 'other';
    memberInsurance.company = insurance.company ?? '';
    memberInsurance.idNumber = insurance.idNumber ?? '';
  }
  return memberInsurance;
}

export function InsuranceProviderCard(props: Props) {
  const logger = useLogger();
  const { member, updateInsurance } = props;
  const insuranceInfo: InsuranceProviderInput | undefined = getInsuranceInfo(
    member,
  );
  const options: Option[] = getCarrierOptions(member);

  const { getValue, validate, fields, reset } = useForm<InsuranceProviderInput>(
    {
      carrierId: field({ rules: [] }),
      company: field({
        rules: [
          (value, state) =>
            (state.carrierId === 'other' || state.carrier === null) && !value
              ? 'This field is required.'
              : undefined,
        ],
      }),
      idNumber: field(),
      patientId: field(),
    },
    insuranceInfo,
  );

  const [edit, setEdit] = useState(false);

  const onSave = async () => {
    try {
      const data: InsuranceProviderInput = getValue();
      const carrierId = data.carrierId !== 'other' ? data.carrierId : '';
      const company = data.company || '';
      await updateInsurance({ ...data, carrierId, company });
    } catch (error) {
      logger.error(
        new Error('InsuranceProviderCard: Unable to save insurance details'),
        {
          error,
          member: props.member.id,
        },
      );
    }
  };

  const boxTitle = (
    <CardTitle
      data-testid="cardTitle"
      validate={validate}
      title="Insurance Provider"
      onSave={onSave}
      onCancel={reset}
      onEdit={setEdit}
    />
  );
  return (
    <Box testId="insuranceCard" title={boxTitle}>
      <div className={classes.root}>
        <FormLabel>Insurance Number</FormLabel>
        {edit ? (
          <TextBoxField
            testId="insuranceNumberInput"
            variant="standard"
            field={fields.idNumber}
            label=""
          />
        ) : (
          <span data-testid="readOnlyInsuranceCard">
            {fields.idNumber.value || 'N/A'}
          </span>
        )}
      </div>
      <div className={classes.root}>
        <FormLabel>Insurance Company</FormLabel>
        {edit ? (
          <>
            <Select
              data-testid="carrierSelectInput"
              name=""
              className={classes.selectOption}
              error={fields.carrierId?.error}
              value={fields.carrierId?.value || 'other'}
              options={options || []}
              onChange={(val) => fields.carrierId?.setValue(val)}
            />

            {(fields.carrierId?.value === 'other' ||
              fields.carrierId?.value === undefined) && (
              <TextBoxField
                testId="freetextInput"
                variant="standard"
                field={fields.company}
                label=""
              />
            )}
          </>
        ) : (
          <span data-testid="readOnlyInsuranceCompany">
            {getDisplayText(
              fields.carrierId?.value,
              fields.company?.value,
              options,
            )}
          </span>
        )}
      </div>
    </Box>
  );
}
function getDisplayText(
  carrierId: string | null | undefined,
  company: string,
  options: Option[],
) {
  let text = company || 'N/A';
  if (carrierId !== null && carrierId !== 'other')
    text = options.find((option) => option.value === carrierId)?.text || 'N/A';
  return text;
}
