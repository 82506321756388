import {
  VaultItem,
  VaultItem_SchemaType as SchemaType,
} from '@ginger.io/vault-core/dist/generated/protobuf-schemas/vault-core/VaultItem';
import {
  MemberBackgroundSection,
  MemberBackgroundSection_SectionType as SectionType,
  MemberBackgroundSection_Version as Version,
} from '@ginger.io/vault-member-chart/dist/generated/protobuf-schemas/vault-member-chart/MemberBackgroundSection';
import { UpdateVaultItemInputParams } from '@ginger.io/vault-ui';
import {
  CreateMemberChartVaultItems,
  CreateMemberChartVaultItemsVariables,
} from '@headspace/carehub-graphql/dist/coach-member-chart/generated/CreateMemberChartVaultItems';
import {
  GetMemberChartVaultItems,
  GetMemberChartVaultItemsVariables,
} from '@headspace/carehub-graphql/dist/coach-member-chart/generated/GetMemberChartVaultItems';
import {
  UpdateMemberChartVaultItems,
  UpdateMemberChartVaultItemsVariables,
} from '@headspace/carehub-graphql/dist/coach-member-chart/generated/UpdateMemberChartVaultItems';
import {
  createVaultItems,
  getMemberChartVaultItems,
  updateVaultItems,
} from '@headspace/carehub-graphql/dist/coach-member-chart/queries';
import {
  createMemberChartVaultItem,
  createVaultInputParams,
  getMemberBackgroundVariables,
  mapPaginatedVaultItems,
  MemberChartMapQueries,
  Options,
  updateCache,
  updateMemberChartVaultItem,
} from 'app/coach/member-chart/queries';
import {
  MemberBackgroundSectionFieldItem,
  MemberBackgroundSectionHookState,
  MemberBackgroundSectionValues,
  MutationResponse,
  ValidSectionType,
  VersionedMemberBackgroundSection,
} from 'app/member-chart-cards/member-background/MemberBackgroundTypes';
import { formatTooltipText } from 'app/member-chart-cards/member-background/utils';
import { useAppState } from 'app/state';
import { useLogger } from 'app/state/log/useLogger';
import { useMappedQuery } from 'app/vault/hooks/useMappedQuery';
import { useMutationWithGlobalState as useMutation } from 'hooks/useMutationWithGlobalState';
import Messages from 'i18n/en/memberChartCard.json';
import moment from 'moment-timezone';

const sectionTypeLabelMap: Record<SectionType, string> = {
  [SectionType.UNRECOGNIZED]: '',
  [SectionType.undefined_section_type]: '',
  [SectionType.additional_background_info]: 'Additional Background Info',
  [SectionType.member_values]: 'Member Values',
  [SectionType.self_care_strategies]: 'Self-care Strategies',
  [SectionType.support_system]: 'Support System',
};

export function memberInfoToVaultItem(data: {
  memberId: string;
  sectionType: SectionType;
  textValue: string;
  version: Version;
}): VaultItem {
  const { memberId, sectionType, textValue, version = Version.v0 } = data; // protobuf vault item defintion
  return {
    data: MemberBackgroundSection.encode({
      memberId,
      sectionType,
      textValue,
      version,
    }).finish(),
    schemaType: SchemaType.vault_member_chart_member_background_section,
  };
}

export function useMemberBackground(
  memberId: string,
  opts: Partial<Options>,
): MemberBackgroundSectionHookState {
  const logger = useLogger();

  const { role, timezone, vaultUserId } = useAppState(({ user }) => ({
    role: user.role!,
    timezone: user.timezone!,
    vaultUserId: user.vaultUserId!,
  }));

  const userInfo = { role, timezone, vaultUserId };

  const [createMemberBackgroundSectionFn] = useMutation<
    CreateMemberChartVaultItems,
    CreateMemberChartVaultItemsVariables
  >(createVaultItems, {
    update(cache, { data }) {
      if (!data) return;
      void getMemberBackgroundVariables(memberId, role)
        .then((value) => updateCache(cache, data, value))
        .catch();
    },
  });

  const [updateMemberBackgroundSectionFn] = useMutation<
    UpdateMemberChartVaultItems,
    UpdateMemberChartVaultItemsVariables
  >(updateVaultItems);

  const memberBackground = useMappedQuery<
    GetMemberChartVaultItems,
    GetMemberChartVaultItemsVariables,
    MemberBackgroundSectionValues
  >(
    {
      query: getMemberChartVaultItems,
      variables: async () => getMemberBackgroundVariables(memberId, role),
    },
    async ({ getPaginatedVaultItemsByTag }) => {
      const paginatedVaultItems = (
        await Promise.all(
          getPaginatedVaultItemsByTag?.items.map(({ encryptedItem }) =>
            mapPaginatedVaultItems(
              encryptedItem,
              { role, timezone, vaultUserId },
              MemberChartMapQueries.MEMBER_BACKGROUND_SECTION,
            ),
          ),
        )
      ).filter(Boolean);

      return paginatedVaultItems.reduce(
        (
          obj: MemberBackgroundSectionValues,
          item: MemberBackgroundSectionFieldItem,
        ) => ({
          ...obj,
          [item.sectionType]: {
            id: item.id,
            sourceVersion: item.sourceVersion,
            tooltipText: formatTooltipText(
              moment.tz(item.updatedAt, 'M/D/YYYY h:mma z', timezone),
              timezone,
              item.updatedBy,
            ),
            value: item.textValue,
          },
        }),
        getEmptyMemberBackgroundSectionValues(),
      );
    },
  );
  const createVaultItem = async (
    input: VersionedMemberBackgroundSection,
  ): Promise<MutationResponse> => {
    try {
      const inputParams = {
        itemId: `member-chart-${memberId}-member-background-${SectionType[
          input.sectionType
        ].replace(/_/g, '-')}`,
        tags: [
          `member-chart-${memberId}`,
          `member-chart-${memberId}-member-background`,
        ],
        vaultItem: memberInfoToVaultItem(input),
      };
      const param = await createVaultInputParams(memberId, role, inputParams);
      return createMemberChartVaultItem({
        createMemberChartVaultItemFn: (data) =>
          createMemberBackgroundSectionFn(data, {
            amplitudeMetadata: {
              action: 'Create',
              source: `Member Background Card > ${
                sectionTypeLabelMap[input.sectionType]
              }`,
            },
          }),
        logger,
        opts,
        param,
        query: MemberChartMapQueries.MEMBER_BACKGROUND_SECTION,
        source: input.source || '',
        userInfo,
      }) as Promise<MutationResponse>;
    } catch (e) {
      logger.error(new Error('Unable to create Member Background Section'), {
        error: e,
        inputSource: input.source,
        memberId,
      });
      return {
        data: null,
        errorMessage: Messages.failureToCreateMemberBackground,
        success: false,
      };
    }
  };

  const updateVaultItem = async (
    input: VersionedMemberBackgroundSection,
  ): Promise<MutationResponse> => {
    try {
      const inputParams = {
        itemId: `member-chart-${memberId}-member-background-${SectionType[
          input.sectionType
        ].replace(/_/g, '-')}`,
        sourceVersion: input.sourceVersion,
        tags: [
          `member-chart-${memberId}`,
          `member-chart-${memberId}-member-background`,
        ],
        vaultItem: memberInfoToVaultItem(input),
      };
      const param = (await createVaultInputParams(
        memberId,
        role,
        inputParams,
        true,
      )) as UpdateVaultItemInputParams;
      return updateMemberChartVaultItem({
        logger,
        opts,
        param,
        query: MemberChartMapQueries.MEMBER_BACKGROUND_SECTION,
        source: input.source || '',
        updateMemberChartVaultItemFn: (data) =>
          updateMemberBackgroundSectionFn(data, {
            amplitudeMetadata: {
              action: 'Update',
              source: `Member Background Card > ${
                sectionTypeLabelMap[input.sectionType]
              }`,
            },
          }),
        userInfo,
      }) as Promise<MutationResponse>;
    } catch (e) {
      logger.error(new Error('Unable to update Member Background Section'), {
        error: e,
        inputSource: input.source,
        memberId,
      });
      return {
        data: null,
        errorMessage: Messages.failureToUpdateMemberBackground,
        success: false,
      };
    }
  };
  const saveMemberBackgroundSection = async (
    input: VersionedMemberBackgroundSection,
  ) => {
    const { data } = memberBackground;

    if (data && data[input.sectionType as ValidSectionType]?.id) {
      return updateVaultItem(input);
    }
    return createVaultItem(input);
  };
  return {
    createMemberBackgroundSection: (input: VersionedMemberBackgroundSection) =>
      createVaultItem(input),
    memberBackground,
    saveMemberBackgroundSection,
    updateMemberBackgroundSection: (input: VersionedMemberBackgroundSection) =>
      updateVaultItem(input),
  };
}

export function getEmptyMemberBackgroundSectionValues(): MemberBackgroundSectionValues {
  return {
    [SectionType.self_care_strategies]: undefined,
    [SectionType.member_values]: undefined,
    [SectionType.support_system]: undefined,
    [SectionType.additional_background_info]: undefined,
  };
}
