import FourDots from 'app/charts/FourDots';
import RISC10LineChart from 'app/charts/RISC10LineChart';
import { Box } from 'shared-components/Box';
import { ScoreDetails } from 'app/patients/tabs/surveys/SurveysV3/ScoreDetails';
import {
  GetMemberSurvey_getMember_surveyResponses,
  GetMemberSurvey_getMember_surveyResponses_adaptabilities_surveys,
} from 'app/queries/generated/GetMemberSurvey';
import { viewSurveyTabAction } from 'app/state/amplitude/actions/surveys';
import { formatEnumValue, sortTimestampAsc } from 'utils';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { COLUMN_WIDTH, Columns, Grid } from 'shared-components/grid';
import { NavigationTabs } from 'shared-components/tabs/NavigationTabs';

import { RISC10MAX, RISC_SCORE_LABEL, titleImageMapping } from './constants';
import InfoBox from './InfoBox';
import styles from './SurveysTabV3RISC.module.scss';
import {
  InfoBoxContent,
  Insight,
  RISC10SurveyResponse,
  Summary,
} from './types';
import { useTabLinksForSurveyV3 } from './utils';

interface Props {
  id: string;
  pathPrefix: string;
  surveyResponses: GetMemberSurvey_getMember_surveyResponses;
  coachingPaths?: boolean;
}

export default function SurveysTabV3RISC(props: Props) {
  const { id, pathPrefix, surveyResponses, coachingPaths = false } = props;
  const tabs = useTabLinksForSurveyV3(pathPrefix, id, coachingPaths);
  const risc10 = formatRISC10(surveyResponses);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(viewSurveyTabAction());
  }, [dispatch]);

  const [selectedInfo, setSelectedInfo] = useState<InfoBoxContent | null>(null);
  const [selectedResponse, setSelectedResponse] = useState<
    GetMemberSurvey_getMember_surveyResponses_adaptabilities_surveys | undefined
  >(undefined);

  const handleClick = (index: number, data: RISC10SurveyResponse) => {
    setSelectedInfo({
      insights: data.strengths,
      scoreType: data.total <= 29 ? 'negative' : 'positive',
      summaries: data.summaries,
    });
    setSelectedResponse(surveyResponses?.adaptabilities?.surveys[index]);
  };

  return (
    <div className={styles.body}>
      <div className={styles.content}>
        <Grid>
          <Columns widths={[COLUMN_WIDTH.FULL]}>
            <Box>
              <div className={styles.surveyScore}>
                <NavigationTabs links={tabs} preserveQueries={true} />
                <div className={styles.graph}>
                  {risc10.length >= 1 ? (
                    <RISC10LineChart
                      disableTooltip={true}
                      responses={risc10}
                      onClick={handleClick}
                    />
                  ) : (
                    <p>Member has not completed this survey yet. </p>
                  )}
                </div>
                <InfoBox info={selectedInfo} useStrengthsLabel={true} />
                <ScoreDetails
                  title="Over the last month, how much do you agree with the following statement:"
                  Component={FourDots}
                  scoreLabels={RISC_SCORE_LABEL}
                  questions={surveyResponses?.adaptabilities?.questions}
                  answers={selectedResponse}
                />
              </div>
            </Box>
          </Columns>
        </Grid>
      </div>
    </div>
  );
}

export function formatRISC10(
  surveyResponses: GetMemberSurvey_getMember_surveyResponses | null,
): RISC10SurveyResponse[] {
  return (surveyResponses?.adaptabilities?.surveys ?? [])
    .sort(sortTimestampAsc)
    .map(({ total, timestamp, severity, strengths, ...rest }) => {
      const summaries: Summary[] = [];
      if (severity !== null && total !== null) {
        summaries.push({
          colorText: 'normal',
          icon: severity ? titleImageMapping[severity] : '',
          subText: `${total ?? 0} / ${RISC10MAX}`,
          title: `${formatEnumValue(severity)} Adaptability`,
        });
      }
      return {
        flexibility: rest.flexibility ?? 0,
        confidence: rest.confidence ?? 0,
        perseverance: rest.perseverance ?? 0,
        determination: rest.determination ?? 0,
        focus: rest.focus ?? 0,
        positivity: rest.positivity ?? 0,
        severity: severity ? formatEnumValue(severity) : '',
        grit: rest.grit ?? 0,
        personalGrowth: rest.personalGrowth ?? 0,
        selfReliance: rest.selfReliance ?? 0,
        strengths: formatStrengths(strengths),
        summaries,
        timestamp: timestamp ?? '',
        total: total ?? 0,
        weatheringEmotions: rest.weatheringEmotions ?? 0,
      };
    });
}

export function formatStrengths(
  insights: GetMemberSurvey_getMember_surveyResponses_adaptabilities_surveys['strengths'],
): Insight[] {
  return (insights ?? []).map(({ frequency, details, title }) => ({
    details: details ?? '',
    frequency: frequency ? formatEnumValue(frequency) : '',
    title: title ?? '',
  }));
}
