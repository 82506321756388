import { ClinicalDocument_ClinicalDocumentType as ClinicalDocumentType } from '@ginger.io/vault-core/dist/generated/protobuf-schemas/vault-core/ClinicalDocument';
import { FormHelperText } from '@mui/material';
import { ActionModal } from 'app/patients/tabs/document-upload/ActionModal';
import {
  Document,
  InputFile,
  UpdateDocumentInput,
} from 'app/patients/tabs/document-upload/types';
import React, { useCallback, useEffect, useState } from 'react';

import UploadModalHeader from '../UploadModalHeader';
import UploadModalRow from '../UploadModalRow';

interface Props {
  open: boolean;
  document: Document | undefined;
  onClose: () => void;
  onUploadClick: (input: UpdateDocumentInput) => void;
}

function DocumentUpdateModal(props: Props) {
  const { open, document, onClose, onUploadClick } = props;
  const [file, setFile] = useState<InputFile | undefined>(undefined);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (document) {
      const inputFile = {
        file: null,
        fileType: document.documentType,
        name: document.name,
        size: parseFloat(document.fileSize),
      };
      setFile(inputFile);
    }
    setShowError(false);
  }, [document]);

  const validateFile = () =>
    (file?.fileType !== ClinicalDocumentType.undefined_type ||
      file?.name !== '') &&
    file !== undefined;

  const handleOnUploadClick = useCallback(async () => {
    setShowError(false);
    if (!validateFile()) {
      setShowError(true);
    }
    const editInput: UpdateDocumentInput = {
      documentType: file!.fileType,
      itemId: document!.itemId,
      name: file!.name,
    };
    onUploadClick(editInput);
    onClose();
  }, [file, document, onUploadClick, onClose, validateFile]);

  const onDelete = () => {};
  const onFileUpdate = (newFile: InputFile) => {
    setFile(newFile);
    setShowError(false);
  };

  return (
    <ActionModal
      isOpen={open}
      isConfirmDisabled={file === undefined}
      title="Edit Document"
      onClose={onClose}
      onConfirm={handleOnUploadClick}
      actionLabel="Save"
    >
      <>
        <UploadModalHeader />
        {file ? (
          <UploadModalRow
            file={file}
            onDeleteClick={onDelete}
            updateFileProps={onFileUpdate}
            allowDelete={false}
          />
        ) : null}
        {showError ? (
          <FormHelperText error={true}>
            Please ensure you have a file name and file type selected
          </FormHelperText>
        ) : null}
      </>
    </ActionModal>
  );
}

export default DocumentUpdateModal;
