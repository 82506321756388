import { useMutation } from '@apollo/client';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import {
  UpdateClinicalAppointmentForMe,
  UpdateClinicalAppointmentForMeVariables,
} from '@headspace/carehub-graphql/dist/appointments/generated/UpdateClinicalAppointmentForMe';
import { UpdateClinicalAppointmentInput } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { useDispatch } from 'app/state';
import { clickScheduleNewAppointmentSaveAction } from 'app/state/amplitude/actions/appointments';
import { useSnackNotification } from 'hooks/useSnackNotification';
import Messages from 'i18n/en/appointment.json';

import { getErrorMessage } from './errorUtils';

export function useUpdateAppointmentMutation<TData, TVariables>(
  document: TypedDocumentNode<TData, TVariables>,
  appointmentId: string,
): (input: UpdateClinicalAppointmentInput) => Promise<void> {
  const {
    showErrorNotification,
    showSuccessNotification,
  } = useSnackNotification();
  const dispatch = useDispatch();
  const [updateAppointment] = useMutation<
    UpdateClinicalAppointmentForMe,
    UpdateClinicalAppointmentForMeVariables
  >(document);
  return async (input: UpdateClinicalAppointmentInput) => {
    let response;
    try {
      response = await updateAppointment({
        variables: { input: { appointmentData: input, id: appointmentId } },
      });
      dispatch(
        clickScheduleNewAppointmentSaveAction({
          appointmentIds: [appointmentId],
        }),
      );
      showSuccessNotification('Appointment Updated');
    } catch (e) {
      const errors = response?.errors ? response.errors.join(', ') : e;
      const errorMessage = getErrorMessage(errors);
      const message = [Messages.errorUpdatingEvent, errorMessage].join(': ');

      showErrorNotification(message);
    }
  };
}
