import { UserRole } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { EventType } from 'app/EventType';
import { createAction } from 'redux-reloaded';

export const viewMemberTabAction = createAction(EventType.MEMBER_TAB_VIEWED);

export const viewMemberListAction = createAction(EventType.MEMBER_LIST_VIEWED);

export const viewIntakeTabAction = createAction(
  EventType.PATIENT_INTAKE_TAB_VIEWED,
);

export const viewSummaryTabAction = createAction(
  EventType.PATIENT_SUMMARY_VIEWED,
);

export const viewDependentReport = createAction(
  EventType.DEPENDENT_REPORT_VIEWED,
  (payload: { memberId: string; role: UserRole }) => ({ ...payload }),
);

export const selectPatientAction = createAction(
  EventType.PATIENT_NAME_CLICKED,
  (payload: { patientId: string; location: string; taskId?: string }) => ({
    ...payload,
  }),
);

export const viewMemberSearchPageAction = createAction(
  EventType.MEMBER_SEARCH_SCREEN_VIEWED,
);

export const updateMemberProfileAction = createAction(
  EventType.MEMBER_PROFILE_UPDATED,
  (payload: { patientId: string }) => ({ ...payload }),
);

export const viewMemberProfileAction = createAction(
  EventType.MEMBER_PROFILE_VIEWED,
  (payload: { patientId: string }) => ({ ...payload }),
);

export const memberListNewAppointmentClick = createAction(
  EventType.SCHEDULE_MEMBER_LIST_NEW_APPOINTMENT_CLICK,
  (payload: { patientId: string; clinicianId?: string }) => ({
    payload,
  }),
);

export const memberScreenViewed = createAction(
  EventType.MEMBER_SCREEN_VIEWED,
  (payload: { role?: string | null | undefined; pathname: string }) => ({
    payload,
  }),
);

export const navigationTabClicked = createAction(
  EventType.NAV_LINK_CLICKED,
  (payload: {
    role?: string | null | undefined;
    label: string;
    pathname: string;
  }) => ({
    payload,
  }),
);

// TODO: remove this event in a separate PR: https://headspace.atlassian.net/browse/CARE-175
export const tooltipViewed = createAction(
  EventType.TOOLTIP_VIEWED,
  (payload: { role?: string | null | undefined; label?: string }) => ({
    payload,
  }),
);

export const bannerLinkClicked = createAction(
  EventType.BANNER_LINK_CLICKED,
  (payload: { role?: string | null | undefined; label: string }) => ({
    ...payload,
  }),
);

export const itemModified = createAction(
  EventType.ITEM_MODIFIED,
  (payload: Record<string, any>) => ({
    payload,
  }),
);

export const vaultLoginCompleted = createAction(
  EventType.VAULT_LOGIN_COMPLETED,
  (payload: {
    role: UserRole | null;
    windowWidth: number;
    windowHeight: number;
    screenWidth: number;
    screenHeight: number;
  }) => ({ ...payload }),
);

export const pageLoad = createAction(
  EventType.PAGE_LOAD,
  (payload: {
    role: UserRole | null;
    windowWidth: number;
    windowHeight: number;
    screenWidth: number;
    screenHeight: number;
  }) => ({ ...payload }),
);

export const userIdleTimeOutEvent = createAction(
  EventType.USER_IDLE_TIMEOUT,
  (payload: {
    role: UserRole;
    userId: string;
    listenerId?: string | null;
    idleTimeSeconds: number;
    idleStartTime: DateTime;
    idleTimeout: number;
  }) => ({ ...payload }),
);
