import { LoginCallback, useOktaAuth } from '@okta/okta-react';
import { MemberChartErrorState } from 'app/member-chart-cards/error-state/MemberChartErrorState';
import { useLogger } from 'app/state/log/useLogger';
import { useOnMount } from 'hooks/useOnMount';
import qs from 'query-string';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Loader } from 'shared-components/loader/Loader';
import { oktaTokenExpirationDate } from 'utils';

import { Routes } from '../top-nav/Routes';

function OktaErrorComponent({ error }: { error: Error }) {
  const logger = useLogger();
  useOnMount(() => {
    logger.warning('OktaCallbackScreen: Okta login error', { error });
  });
  return <MemberChartErrorState error={error} />;
}

interface OktaCallbackQueryParams {
  code?: string;
}

export function OktaCallbackScreen() {
  const logger = useLogger();
  const history = useHistory();
  const { search } = useLocation();
  const { code } = qs.parse(search) as OktaCallbackQueryParams;

  const { authState } = useOktaAuth();
  const idToken = authState?.idToken?.idToken;

  useEffect(() => {
    if (idToken) {
      logger.info(
        'OktaCallbackScreen: Okta login complete, handling authentication',
        {
          oktaExpiration: oktaTokenExpirationDate(idToken)?.toISOString(),
        },
      );
    } else if (!code) {
      history.push(Routes.HOME);
    }
  }, [idToken, logger, history, code]);
  return (
    <LoginCallback
      errorComponent={OktaErrorComponent}
      loadingElement={<Loader />}
    />
  );
}
