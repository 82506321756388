import {
  ClinicalDocument_ClinicalDocumentType,
  ClinicalDocument_ClinicalDocumentType as ClinicalDocumentType,
} from '@ginger.io/vault-core/dist/generated/protobuf-schemas/vault-core/ClinicalDocument';
import { FormHelperText } from '@mui/material';
import { ActionModal } from 'app/patients/tabs/document-upload/ActionModal';
import {
  InputFile,
  UploadDocumentInput,
} from 'app/patients/tabs/document-upload/types';
import React, { useCallback, useEffect, useState } from 'react';

import { getFileName } from '../../utils/fileNameUtils';
import UploadModalHeader from '../UploadModalHeader';
import UploadModalRow from '../UploadModalRow';

interface DocumentUploadModalProps {
  open: boolean;
  files: FileList | null;
  onClose: () => void;
  onUploadClick: (input: UploadDocumentInput[]) => void;
  memberId: string;
}

export default function DocumentUploadModal(props: DocumentUploadModalProps) {
  const { open, onClose, memberId, onUploadClick, files } = props;
  const [uploadedFiles, setUploadedFiles] = useState<InputFile[]>([]);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    const inputFiles: InputFile[] = [];
    if (files) {
      const filesArray: File[] = Array.from(files);
      filesArray.forEach((file) => {
        const { name } = file;
        inputFiles.push({
          file,
          fileType: ClinicalDocumentType.undefined_type,
          name: getFileName(name),
          size: file.size,
        });
      });
    }
    setUploadedFiles(inputFiles);
    setShowError(false);
  }, [files, setUploadedFiles, setShowError]);

  const validateFiles = useCallback(() => {
    return (
      uploadedFiles.find(
        (item) =>
          item.fileType ===
            ClinicalDocument_ClinicalDocumentType.undefined_type ||
          item.name === '',
      ) === undefined
    );
  }, [uploadedFiles]);

  const handleOnUploadClick = useCallback(() => {
    setShowError(false);
    if (!validateFiles()) {
      setShowError(true);
    } else {
      const uploadInput: UploadDocumentInput[] = uploadedFiles.map((item) => ({
        documentType: item.fileType,
        file: item.file!,
        memberId,
        name: item.name,
      }));

      onUploadClick(uploadInput);
      onClose();
    }
  }, [onUploadClick, onClose, uploadedFiles, memberId, validateFiles]);

  const onDelete = useCallback(
    (index: number) => {
      const newFiles = [...uploadedFiles];
      newFiles.splice(index, 1);
      setUploadedFiles(newFiles);
    },
    [uploadedFiles],
  );

  const onFileUpdate = (index: number) => (newFile: InputFile) => {
    const newFiles = [...uploadedFiles];
    newFiles[index] = newFile;
    setUploadedFiles(newFiles);
    setShowError(false);
  };

  const filesRows = uploadedFiles.map((file, index) => (
    <UploadModalRow
      key={file.size}
      file={file}
      onDeleteClick={() => onDelete(index)}
      updateFileProps={onFileUpdate(index)}
      data-testid={`uploadFileRow-${index}`}
    />
  ));

  return (
    <ActionModal
      isOpen={open}
      isConfirmDisabled={uploadedFiles.length === 0}
      onClose={onClose}
      title="Upload Documents"
      onConfirm={handleOnUploadClick}
      actionLabel="Upload"
    >
      <>
        <UploadModalHeader />
        {filesRows}
        {showError ? (
          <FormHelperText error={true}>
            Please ensure you have a file name and file type selected
          </FormHelperText>
        ) : null}
      </>
    </ActionModal>
  );
}
