import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { QuerygetPrescriptionsForMemberArgs } from '@ginger.io/core-ui/dist/generated/graphql';
import {
  GetPharmacies,
  GetPharmaciesVariables,
} from '@headspace/carehub-graphql/dist//member-chart-cards/generated/GetPharmacies';
import {
  GetPreferredPharmacy,
  GetPreferredPharmacyVariables,
} from '@headspace/carehub-graphql/dist//member-chart-cards/generated/GetPreferredPharmacy';
import { GetPrescriptionsForMember } from '@headspace/carehub-graphql/dist//member-chart-cards/generated/GetPrescriptionsForMember';
import {
  UpdatePatientPreferredPharmacy,
  UpdatePatientPreferredPharmacyVariables,
} from '@headspace/carehub-graphql/dist//member-chart-cards/generated/UpdatePatientPreferredPharmacy';
import { UserRole } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import {
  getPharmaciesQuery,
  getPreferredPharmacyQuery,
  getPrescriptionsForMember,
  updatePatientPreferredPharmacyMutation,
} from '@headspace/carehub-graphql/dist/member-chart-cards/prescriptionsQueries';
import { getErrorMessage } from 'app/appointments/errorUtils';
import Titles from 'app/member-chart-cards/constants/cards-titles';
import { PharmacyCard } from 'app/member-chart-cards/prescriptions/PharmacyCard';
import { PrescriptionCard } from 'app/member-chart-cards/prescriptions/PrescriptionCard';
import { Pharmacy } from 'app/member-chart-cards/prescriptions/PrescriptionCardTypes';
import { SearchPharmacy } from 'app/member-chart-cards/prescriptions/SearchPharmacy';
import { UpdatePharmacy } from 'app/member-chart-cards/prescriptions/UpdatePharmacy';
import { useAppState } from 'app/state';
import { useLogger } from 'app/state/log/useLogger';
import { useSnackNotification } from 'hooks/useSnackNotification';
import Messages from 'i18n/en/memberChartCard.json';
import React, { useState } from 'react';
import { Accordion } from 'shared-components/Accordion';
import { Button } from 'shared-components/button/Button';
import { Card } from 'shared-components/Card';
import { Loader } from 'shared-components/loader/Loader';

import styles from './PrescriptionCard.module.scss';

export enum PrescriptionCardStage {
  DEFAULT,
  SEARCH_PHARMACY,
  UPDATE_PHARMACY,
}

interface PrescriptionsCardProps {
  memberId: string;
}

export const PrescriptionsCard = (
  props: PrescriptionsCardProps,
): React.ReactElement => {
  const logger = useLogger();
  const { memberId } = props;
  const [stage, setStage] = useState(PrescriptionCardStage.DEFAULT);
  const [enableSave, setEnableSave] = useState(false);
  const [pharmacies, setPharmacies] = useState<(Pharmacy | null)[]>([]);
  const [selectedPharmacy, setSelectedPharmacy] = useState('');
  const {
    showErrorNotification,
    showSuccessNotification,
  } = useSnackNotification();

  const [getPharmacies] = useLazyQuery<GetPharmacies, GetPharmaciesVariables>(
    getPharmaciesQuery,
  );
  const getPreferredPharmacy = useQuery<
    GetPreferredPharmacy,
    GetPreferredPharmacyVariables
  >(getPreferredPharmacyQuery, {
    variables: { input: { memberId } },
  });

  const getPrescriptions = useQuery<
    GetPrescriptionsForMember,
    QuerygetPrescriptionsForMemberArgs
  >(getPrescriptionsForMember, {
    variables: { input: { memberId } },
  });

  const [updatePatientPreferredPharmacy] = useMutation<
    UpdatePatientPreferredPharmacy,
    UpdatePatientPreferredPharmacyVariables
  >(updatePatientPreferredPharmacyMutation, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: getPreferredPharmacyQuery,
        variables: { input: { memberId } },
      },
    ],
  });
  const { isMemberSupport } = useAppState(({ user: { role } }) => ({
    isMemberSupport:
      role === UserRole.MEMBER_SUPPORT || role === UserRole.CLINICAL_SUPERVISOR,
  }));

  const onSearchPharmacies = async (filters: { [k: string]: string }) => {
    try {
      const { data, error } = await getPharmacies({
        variables: { input: filters },
      });
      if (error) {
        showErrorNotification(error.message);
      } else if (data?.getPharmacies?.pharmacies) {
        setPharmacies(data.getPharmacies.pharmacies);
        logger.info(
          'PrescriptionsCard.onSearchPharmacies: Found matching pharmacies',
          { filters, memberId },
        );
      } else {
        logger.warning('No pharmacies found for the following filters: ', {
          filters,
          memberId,
        });
        setPharmacies([]);
      }
      setStage(PrescriptionCardStage.UPDATE_PHARMACY);
    } catch (e) {
      logger.error(new Error('Could not get available pharmacies'), {
        error: e,
        memberId,
      });
    }
  };
  const onBackToSearchPharmacy = () => {
    setStage(PrescriptionCardStage.SEARCH_PHARMACY);
    setEnableSave(false);
  };
  const onSelectPharmacy = (pharmacyId: string) => {
    setEnableSave(true);
    setSelectedPharmacy(pharmacyId);
  };
  const onUpdatePharmacy = async () => {
    setEnableSave(false);
    let response;
    const defaultErrorMessage = `PrescriptionsCard.onUpdatePharmacy: ${Messages.failureToUpdatePreferredPharmacy}`;
    try {
      response = await updatePatientPreferredPharmacy({
        variables: { input: { memberId, pharmacyId: selectedPharmacy } },
      });

      if (response.errors) {
        logger.error(new Error(defaultErrorMessage), {
          error: response.errors,
          memberId,
        });
        showErrorNotification(Messages.failureToUpdatePreferredPharmacy);
      }

      if (response.data?.updatePatientPreferredPharmacy?.ok) {
        showSuccessNotification(Messages.pharmacyUpdated);
        setStage(PrescriptionCardStage.DEFAULT);
      } else {
        const errorMsg = response.data?.updatePatientPreferredPharmacy?.error;
        logger.warning(defaultErrorMessage, { error: errorMsg });
        showErrorNotification(errorMsg ?? '');
      }
    } catch (e) {
      logger.error(new Error(defaultErrorMessage), { error: e });
      const errors = response?.errors ? response.errors.join(', ') : e;
      const errorMessage = getErrorMessage(errors);
      const message = [Messages.errorUpdatingPharmacy, errorMessage].join(': ');
      showErrorNotification(message);
    } finally {
      setEnableSave(true);
    }
  };

  const actionComponent = (
    <Button
      className={styles.editBtn}
      size="small"
      onClick={onUpdatePharmacy}
      disabled={!enableSave}
      testId="saveBtn"
    >
      Save
    </Button>
  );

  const renderPrescriptionsContent = () => {
    if (getPrescriptions.loading) {
      return <Loader topMargin={false} />;
    }

    if (getPrescriptions.error) {
      logger.error(
        new Error(
          'PrescriptionsCard.renderPrescriptionsContent: Could not get prescriptions',
        ),
        {
          error: getPrescriptions.error,
          memberId,
        },
      );
      return (
        <p className={styles.accordionText}>
          Dosespot is unavailable at the moment
        </p>
      );
    }

    const prescriptions =
      getPrescriptions.data?.getPrescriptionsForMember?.prescriptions;
    if (!prescriptions || prescriptions.length === 0) {
      return (
        <p className={styles.accordionText}>
          {getPrescriptions.error
            ? 'Dosespot is unavailable at the moment'
            : 'No active prescriptions'}
        </p>
      );
    }

    return prescriptions.map((prescription, index) => (
      <PrescriptionCard
        key={`active-prescription-${index}`}
        data-testid={`active-prescription-${index}`}
        prescription={prescription}
      />
    ));
  };

  const renderPharmacyContent = () =>
    getPreferredPharmacy.loading ? (
      <Loader topMargin={false} />
    ) : (
      <PharmacyCard
        data-testid="default-pharmacy"
        pharmacy={getPreferredPharmacy.data?.getPreferredPharmacy}
        fallbackCardMessage={
          getPreferredPharmacy.error
            ? 'Dosespot service is unavailable at the moment'
            : ''
        }
      />
    );

  return (
    <Card
      data-testid="prescriptionsCard"
      boxTitle={Titles.PRESCRIPTIONS_TITLE}
      actionMenu={
        stage === PrescriptionCardStage.UPDATE_PHARMACY
          ? actionComponent
          : undefined
      }
      size="large"
    >
      {stage === PrescriptionCardStage.SEARCH_PHARMACY ? (
        <SearchPharmacy
          onBack={() => setStage(PrescriptionCardStage.DEFAULT)}
          onSearch={onSearchPharmacies}
        />
      ) : stage === PrescriptionCardStage.UPDATE_PHARMACY ? (
        <UpdatePharmacy
          pharmacies={pharmacies}
          onBack={onBackToSearchPharmacy}
          onSelect={onSelectPharmacy}
        />
      ) : (
        <>
          <Accordion
            classes={{ titleHeader: styles.accordionTitle }}
            subsectionIndex={0}
            accordionTitle={Titles.ACTIVE_PRESCRIPTIONS_SUBTITLE}
            testId="prescriptions-accordion"
          >
            {renderPrescriptionsContent()}
          </Accordion>
          <Accordion
            classes={{ titleHeader: styles.accordionTitle }}
            subsectionIndex={0}
            accordionTitle={Titles.DEFAULT_PHARMACY_SUBTITLE}
          >
            {renderPharmacyContent()}
            {isMemberSupport &&
              !getPreferredPharmacy.error &&
              !getPreferredPharmacy.loading && (
                <Button
                  testId="editBtn"
                  className={styles.editBtn}
                  size="small"
                  onClick={() =>
                    setStage(PrescriptionCardStage.SEARCH_PHARMACY)
                  }
                >
                  Edit
                </Button>
              )}
          </Accordion>
        </>
      )}
    </Card>
  );
};
