import { useQuery } from '@apollo/client';
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';
import { QueryHookOptions } from '@apollo/client/react/types/types';
import { getCoachStatus } from '@headspace/carehub-graphql/dist/inbox-vertical-menu/generated/getCoachStatus';
import { getCoachStatusQuery } from '@headspace/carehub-graphql/dist/inbox-vertical-menu/queries';
import { COACH_STATUS_POLL_INTERVAL } from 'app/constants';
import { useAppState } from 'app/state';
import { updateSessionState } from 'app/state/features/auth/authSlice';
import { SessionState } from 'app/state/features/auth/types';
import { useLogger } from 'app/state/log/useLogger';
import { updateShiftStatus } from 'app/state/user/actions';
import { useEffect } from 'react';
import { useDispatch } from 'redux-reloaded';
import { isGraphQLAuthenticationError } from 'shared-components/error-state/utils';

type Option = {
  enablePolling?: boolean;
  fetchPolicy: WatchQueryFetchPolicy;
};

export function useCoachStatus({ fetchPolicy, enablePolling = false }: Option) {
  const currentStatus = useAppState(({ user }) => user.shiftStatus);
  const logger = useLogger();

  const dispatch = useDispatch();
  const options: QueryHookOptions = { fetchPolicy };
  if (enablePolling) {
    options.pollInterval = COACH_STATUS_POLL_INTERVAL;
  }
  const { loading, error, data, stopPolling } = useQuery<getCoachStatus>(
    getCoachStatusQuery,
    options,
  );

  // observe the polling result and update the state if got a new status
  const shiftStatus =
    data?.getListenerAuthenticatedUser.coach?.state?.shiftStatus;
  useEffect(() => {
    if (loading) return;

    const newStatus = shiftStatus;
    if (newStatus && newStatus !== currentStatus) {
      dispatch(updateShiftStatus(newStatus));
    }
    if (isGraphQLAuthenticationError(error)) {
      stopPolling();
      dispatch(updateSessionState(SessionState.EXPIRED));
    } else if (error || !newStatus) {
      logger.error(new Error('Failed to poll coach status'), {
        data,
        error,
        newStatus,
      });
    }
  }, [shiftStatus, error, loading]);
  return shiftStatus;
}
