import {
  GetAppointments,
  GetAppointments_getAppointments_all,
} from '@headspace/carehub-graphql/dist/appointments/generated/GetAppointments';
import { getAppointmentsQuery } from '@headspace/carehub-graphql/dist/appointments/queries';
import { AppointmentsTable } from 'app/appointments/AppointmentsTable';
import { GQL_QUERY_POLLING_INTERVAL } from 'app/constants';
import { useAppState } from 'app/state';
import { viewAppointmentScreenAction } from 'app/state/amplitude/actions/appointments';
import { useQuery } from 'hooks/useQuery';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { COLUMN_WIDTH, Columns, Grid } from 'shared-components/grid';
import { formatEnumValue, formatZoomMeetingUrl } from 'utils';
import { formatDate, getTimezone } from 'utils/dateTime';
import { formatClinicalNoteStatus } from 'utils/notes';

import { Appointment, NoteAction } from './types';

function AppointmentsScreen(props: RouteComponentProps) {
  const { history } = props;

  const { timezone } = useAppState((_) => _.user);
  const localTimezone = getTimezone(timezone);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(viewAppointmentScreenAction());
  }, [dispatch]);

  return useQuery(
    (data: GetAppointments) => {
      const tableAppointments = data.getAppointments.all.map((a) =>
        toTableAppointment(a, localTimezone),
      );

      return (
        <Grid>
          <Columns widths={[COLUMN_WIDTH.FULL]}>
            <AppointmentsTable
              localTimezone={localTimezone}
              navigateTo={(url) => history.push(url)}
              appointments={tableAppointments}
              signedLockedAction={NoteAction.VIEW}
              hideClinicianName={true}
            />
          </Columns>
        </Grid>
      );
    },
    getAppointmentsQuery,
    { pollInterval: GQL_QUERY_POLLING_INTERVAL },
  );
}

export default withRouter(AppointmentsScreen);

function toTableAppointment(
  appointment: GetAppointments_getAppointments_all,
  localTimezone: string,
): Appointment {
  const { status: noteStatus, updatedAt } = appointment.clinicalNote;
  const { name, userId: clinicianId } = appointment.clinician;
  const type = formatEnumValue(appointment.appointmentType);
  const { member, zoomID } = appointment;

  return {
    appointmentStatus: appointment.status,
    appointmentType: appointment.appointmentType,
    cancelledReason: appointment.cancelledReason,
    clinicalNote: appointment.clinicalNote,
    clinicianId,
    end: appointment.end,
    id: appointment.id,
    member: {
      id: member.id,
      name: `${member.firstName} ${member.lastName}`,
    },
    name,
    noteStatus: formatClinicalNoteStatus(appointment.status, noteStatus),
    noteUpdatedAt: updatedAt ? formatDate(updatedAt, localTimezone) : '-',
    start: appointment.start,
    type,
    zoomUrl: formatZoomMeetingUrl(zoomID),
  };
}
