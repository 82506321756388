import {
  GetMemberCareTeam,
  GetMemberCareTeamVariables,
} from '@headspace/carehub-graphql/dist/care-team/generated/GetMemberCareTeam';
import { getMemberCareTeam } from '@headspace/carehub-graphql/dist/care-team/queries';
import { PUBNUB_STATUS } from 'app/coach/pubnub/types';
import { ApolloCachingStrategy } from 'app/constants';
import { onNewRiskAlertTaskCreated } from 'app/state/tasks/actions';
import { createActionHandlers } from 'redux-reloaded';

import { CurrentCoach } from 'app/care-team/types';
import { Context } from '../context';
import { State } from '../schema';
import {
  loadCareTeam,
  loadCareTeamFailed,
  loadCareTeamRequest,
  loadCareTeamSuccess,
  reloadAddSICoachButton,
  subscribeCoachChannels,
  unsubscribeCoachChannels,
} from './actions';

export const handlers = createActionHandlers<Context, State>();

handlers.on(onNewRiskAlertTaskCreated, async ({ action, redux, context }) => {
  const {
    payload: { taskType, memberId },
  } = action;

  context.services.logger.info(
    'onNewRiskAlertTaskCreated: reloading SI coach button',
    { memberId, taskType },
  );
  redux.dispatch(
    reloadAddSICoachButton({
      memberId,
    }),
  );
});

handlers.on(
  reloadAddSICoachButton,
  async ({ action, redux, context: { services } }) => {
    const {
      payload: { memberId },
    } = action;
    services.logger.debug('reloadAddSICoachButton: ', { memberId });
    // re-fetch the get member care team query, it will update the card component with the SI coach added
    // and will display the button AddSiCoach to enable assessment transfer to the SI coach
    redux.dispatch(loadCareTeam({ forceRefresh: true, memberId }));
  },
);

handlers.on(loadCareTeam, async ({ action, redux, context }) => {
  const {
    payload: { memberId, forceRefresh },
  } = action;
  const { timezone } = redux.getState().user;

  redux.dispatch(loadCareTeamRequest({ memberId }));

  try {
    const { error, data } = await context.services.apollo.query<
      GetMemberCareTeam,
      GetMemberCareTeamVariables
    >({
      fetchPolicy: forceRefresh
        ? ApolloCachingStrategy.NETWORK_ONLY
        : ApolloCachingStrategy.CACHE_FIRST,
      query: getMemberCareTeam,
      variables: { id: memberId, timezone },
    });

    if (error || !data?.getMember === null) {
      const message = 'No data returned from getMemberCareTeam';
      context.services.logger.error(new Error(`loadCareTeam: ${message}`), {
        error,
        forceRefresh,
        memberId,
      });
      redux.dispatch(
        loadCareTeamFailed({ error: error || new Error(message) }),
      );
      return;
    }

    redux.dispatch(loadCareTeamSuccess({ careTeam: data?.getMember }));
  } catch (error) {
    context.services.logger.error(
      new Error(`loadCareTeam: unable to load care team`),
      {
        error,
        forceRefresh,
        memberId,
      },
    );
    redux.dispatch(loadCareTeamFailed({ error }));
  }
});

handlers.on(subscribeCoachChannels, async ({ action, context }) => {
  const {
    payload: { coaches },
  } = action;

  // if pubnub is not instantiated, does not subscribe to channels
  if (context.services.pubnub.status !== PUBNUB_STATUS.INSTANTIATED) {
    context.services.logger.error(
      new Error(
        'subscribeCoachChannels failed due to Pubnub not being instantiated',
      ),
      {
        coaches,
      },
    );
    return;
  }

  // array of broadcastChannelIds for current coaches
  const currentCoachesBroadcastChannel =
    coaches
      ?.filter(
        (coach) =>
          coach?.broadcastChannelId !== null &&
          coach?.broadcastChannelId !== undefined,
      )
      .map((coach) => coach?.broadcastChannelId as string) || [];

  // subscribe to the broadcast channels for current coaches
  context.services.pubnub.subscribeToChannels({
    channels: currentCoachesBroadcastChannel,
  });
});

/**
 * Unsubscribe from all coach broadcast channels.
 * This is to prevent the app from receiving shift_change events from coaches that are
 * no longer on the care team card component.
 */
handlers.on(unsubscribeCoachChannels, async ({ redux, context }) => {
  // if pubnub is not instantiated, does not unsubscribe to channels
  if (context.services.pubnub.status !== PUBNUB_STATUS.INSTANTIATED) return;

  // array of broadcastChannelIds for current coaches
  const currentCoachesBroadcastChannel =
    redux
      .getState()
      .careTeam.coachingCareTeam?.current?.coaches?.filter(
        (coach: CurrentCoach) =>
          coach?.broadcastChannelId !== null &&
          coach?.broadcastChannelId !== undefined,
      )
      .map((coach: CurrentCoach) => coach?.broadcastChannelId as string) || [];

  if (currentCoachesBroadcastChannel.length === 0) return;

  context.services.pubnub.unsubscribeChannels(currentCoachesBroadcastChannel);
});
