import { useApolloClient, useLazyQuery } from '@apollo/client';
import {
  CoachClinicianCollaborationChatMessage,
  CoachClinicianCollaborationChatMessage_Author_AuthorType as AuthorType,
} from '@ginger.io/vault-care-collaboration/dist/generated/protobuf-schemas/vault-care-collaboration/CoachClinicianCollaborationChatMessage';
import { KeyGenerator } from '@ginger.io/vault-core/dist/crypto';
import { Base64 } from '@ginger.io/vault-core/dist/crypto/Base64';
import {
  getClinicalCareTeamGroupId,
  getCoachingTeamGroupId,
} from '@ginger.io/vault-core/dist/IdHelpers';
import {
  GetChatStreamQuery,
  GetChatStreamQueryVariables,
  VaultItemSortField,
  VaultItemSortOrder,
} from '@ginger.io/vault-ui';
import { getChatStream } from '@headspace/carehub-graphql/dist/collaboration/queries';
import { HookState, ReadOnlyRef, useHookState } from 'hooks/useHookState';
import { useCallback, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { createCollaborationChatMessage } from './createCollaborationChatMessage';
import { AuthorWithName, ChatMessage } from './type';
import {
  decodeCollaborationChatMessage,
  getCollaborationChatStreamTag,
} from './utils';

export interface Results {
  chats: ChatMessage[];
  cursor: string | null;
}

export interface UseCollaborationChatStreamResult {
  chatMessages: ReadOnlyRef<HookState<Results>>;
  loadMoreChatMessage: (cursor: string | null) => Promise<void>;
  createChatMessage: (
    chatMessage: CoachClinicianCollaborationChatMessage,
    idGenerator?: () => string,
  ) => Promise<void>;
}

export function useCollaborationChatStream(
  memberId: string,
  author: AuthorWithName,
  maxItems: number = 35,
  keyGenerator: KeyGenerator = new KeyGenerator(),
): UseCollaborationChatStreamResult {
  const { type: authorType } = author;
  const { setData, setError, state, setLoading } = useHookState<Results>();
  const apolloClient = useApolloClient();
  const fetchedInitialChatStream = useRef(false);
  const [runInitialQuery, { fetchMore, data, error }] = useLazyQuery<
    GetChatStreamQuery,
    GetChatStreamQueryVariables
  >(getChatStream);

  const currentChatMessages = state.current.data?.chats || [];
  const loadMoreChatMessage = useCallback(
    async (nextCursor: string | null) => {
      if (fetchMore === undefined) return;
      const variables = await getVariables(
        memberId,
        authorType,
        nextCursor,
        maxItems,
      );
      const { error, data } = await fetchMore({ variables });

      if (error !== undefined) throw new Error(error.message);

      if (data) {
        const { chats, cursor } = await decodeChat(
          data.getPaginatedVaultItemsByTag,
        );
        setData({ chats: currentChatMessages.concat(chats), cursor });
      }
    },
    [memberId, fetchMore, authorType, currentChatMessages],
  );

  useEffect(() => {
    const runChatStreamQuery = async () => {
      try {
        fetchedInitialChatStream.current = false;
        const variables = await getVariables(
          memberId,
          authorType,
          null,
          maxItems,
        );
        return runInitialQuery({ variables });
      } catch (error) {
        return setError(error);
      }
    };
    void runChatStreamQuery();
  }, [memberId, authorType]);

  useEffect(() => {
    // We only want this effect to run once after executing runInitialQuery,
    // so we don't override the data when we call loadMoreChatMessage.
    if (fetchedInitialChatStream.current) return;

    const handleQueryResponse = async () => {
      try {
        fetchedInitialChatStream.current =
          error !== undefined || data !== undefined;
        if (error !== undefined) {
          return setError(error);
        }
        if (data !== undefined) {
          const decodedData = await decodeChat(
            data.getPaginatedVaultItemsByTag,
          );
          setData(decodedData);
        } else {
          setLoading();
        }
      } catch (error) {
        fetchedInitialChatStream.current = true;
        return setError(error);
      }
    };
    void handleQueryResponse();
  }, [data, error]);

  const createChatMessage = useCallback(
    async (
      chatMessage: CoachClinicianCollaborationChatMessage,
      idGenerator: () => string = uuidv4,
    ) => {
      const { data: currentData } = state.current;
      if (currentData === null) {
        throw new Error(
          `Illegal state when to create chat message: ${state.current.status}`,
        );
      }
      await createCollaborationChatMessage(
        apolloClient,
        chatMessage,
        memberId,
        keyGenerator,
        idGenerator,
      );
      // update state with new message.
      const updatedChats: ChatMessage[] = [
        { ...chatMessage, author },
        ...currentData.chats,
      ];
      setData({
        ...currentData,
        chats: updatedChats,
      });
    },
    [memberId, apolloClient, keyGenerator, state.current],
  );
  return {
    chatMessages: state,
    createChatMessage,
    loadMoreChatMessage,
  };
}

export async function getVariables(
  memberId: string,
  authorType: AuthorType,
  nextCursor: string | null = null,
  maxItemsPerPage: number,
): Promise<GetChatStreamQueryVariables> {
  let groupId;
  if (authorType === AuthorType.clinician) {
    groupId = getClinicalCareTeamGroupId(memberId);
  } else if (authorType === AuthorType.coach) {
    groupId = getCoachingTeamGroupId(memberId);
  } else {
    throw Error('Invalid Author');
  }

  return {
    groupId: await Base64.hash(groupId),
    pagination: {
      cursor: nextCursor,
      maxItemsPerPage,
      sortField: VaultItemSortField.CreatedAt,
      sortOrder: VaultItemSortOrder.Desc,
    },
    tag: await Base64.hash(getCollaborationChatStreamTag(memberId)),
  };
}

async function decodeChat(
  paginatedVaultItemsByTag: GetChatStreamQuery['getPaginatedVaultItemsByTag'],
) {
  const { items, cursor } = paginatedVaultItemsByTag;
  const chats = await decodeCollaborationChatMessage(items);
  return { chats, cursor };
}
