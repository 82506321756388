/** Routes for the path={} param of React router */
import {
  ClinicalAppointmentNoteType,
  NoteType,
  UserRole,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { appointmentDetailRoute } from 'app/patients/Routes';

export enum Routes {
  TASKS = '/tasks/:taskId?',
  PATIENTS = '/patients/:patientId?',
  COACHING_PATIENTS_TEEN_INTAKE = '/coaching/members/:patientId/teenIntake',
  PATIENTS_TEEN_INTAKE = '/patients/:patientId/teen_intake',
  MEMBERS = '/members/search/:patientId?',
  NOTES = '/notes/:patientId',
  APPOINTMENTS = '/appointments',
  SCHEDULE = '/schedule',
  SCHEDULE_APPOINTMENT_LIST = '/schedule/appointments',
  APPOINTMENT_NOTES = '/appointments/:appointmentId/notes',
  APPOINTMENT_CREATE = '/appointments/create',
  APPOINTMENT = '/appointments/:appointmentId?',
  EVENT_CREATE = '/events/create',
  EVENT = '/events/:eventId?',
  LOGIN = '/oauth/login',
  LOGOUT = '/oauth/logout',
  COACHING_MEMBERS = '/coaching/members/:memberId?',
  CARE_METRICS = '/metrics/:clinicianId',
  HOME = '/',
}

/** Used for linking to top-level tabs */
export enum TopLevelRoutes {
  TASKS = '/tasks',
  PATIENTS = '/patients',
  APPOINTMENTS = '/appointments',
  SCHEDULE = '/schedule',
  MEMBERS = '/members',
  SETTINGS = '/settings',
  COACHING = '/coaching/members',
  ERX_NOTIFICATIONS = '/prescriptions',
}

export enum NoteRoutes {
  OUT_OF_SESSION = 'out-of-session',
  TERMINATION = 'termination',
}

export enum ActionMenuRoutes {
  OUT_OF_SESSION = 'out-of-session',
  TERMINATION = 'termination',
  APPOINTMENT = 'appointment',
}

export type AppointmentIdWithNoteType = {
  appointmentId: string;
  noteType: ClinicalAppointmentNoteType | NoteType;
};

export function appointmentNoteRoute(
  appointment: AppointmentIdWithNoteType,
  userId: string,
  featureFlags: Record<string, boolean> = {},
): string {
  const { appointmentId, noteType } = appointment;
  const apptRoute = Routes.APPOINTMENT_NOTES.replace(
    ':appointmentId',
    appointmentId,
  );

  const { enable_care_hub_notes_efficiency } = featureFlags;

  if (enable_care_hub_notes_efficiency) {
    return appointmentDetailRoute(Routes.PATIENTS, userId, appointmentId);
  }
  switch (noteType) {
    case ClinicalAppointmentNoteType.THERAPY_INTAKE:
    case NoteType.THERAPY_INTAKE:
      return `${apptRoute}/therapy-intake`;
    case ClinicalAppointmentNoteType.THERAPY_PROGRESS:
    case NoteType.THERAPY_PROGRESS:
      return `${apptRoute}/therapy-progress`;
    case ClinicalAppointmentNoteType.PSYCHIATRY_INTAKE:
    case NoteType.PSYCHIATRY_INTAKE:
      return `${apptRoute}/psychiatry-intake`;
    case ClinicalAppointmentNoteType.UNSPECIFIED:
    case NoteType.UNSPECIFIED:
      return '/';
    case ClinicalAppointmentNoteType.PSYCHIATRY_PROGRESS:
    case NoteType.PSYCHIATRY_PROGRESS:
      return `${apptRoute}/psychiatry-progress`;
  }
}

export function patientNoteRoute(
  patientId: string,
  location: NoteRoutes | ActionMenuRoutes,
  vaultId?: string,
): string {
  const route = Routes.NOTES.replace(':patientId', patientId);
  const url = [route, location];
  if (vaultId) {
    url.push(vaultId);
  }
  return url.join('/');
}

export function patientProfileRoute(patientId: string): string {
  return Routes.PATIENTS.replace(':patientId?', patientId);
}

export function coachingMemberRoute(memberId: string): string {
  return Routes.COACHING_MEMBERS.replace(':memberId?', memberId);
}

export function teenIntakeRoute(patientId: string): string {
  return Routes.PATIENTS_TEEN_INTAKE.replace(':patientId', patientId);
}

export function taskRoute(taskId: string): string {
  return `${Routes.TASKS.replace(':taskId?', taskId)}/patient/:patientId?`;
}

export function metricsRoute(clinicianId: string): string {
  return Routes.CARE_METRICS.replace(':clinicianId', clinicianId);
}

type CreateAppointmentRouteType = {
  appointmentId: string;
  role?: UserRole;
};

export function appointmentRoute({
  appointmentId,
  role,
}: CreateAppointmentRouteType): string {
  const params = [];
  if (role) {
    params.push(`role=${role}`);
  }
  const queryString = params.join('&');

  const route = Routes.APPOINTMENT.replace(':appointmentId?', appointmentId);
  if (queryString) {
    return [route, queryString].join('?');
  }
  return route;
}

type CreateAppointmentType = {
  clinicianId?: string;
  patientId?: string;
  role?: UserRole;
  start?: string;
  end?: string;
};

export function createAppointmentRoute(args: CreateAppointmentType): string {
  const { clinicianId, patientId, role, start, end } = args;

  const params = [];
  if (clinicianId) {
    params.push(`clinicianId=${clinicianId}`);
  }
  if (patientId) {
    params.push(`patientId=${patientId}`);
  }
  if (role) {
    params.push(`role=${role}`);
  }
  if (start) {
    params.push(`start=${start}`);
  }
  if (end) {
    params.push(`end=${end}`);
  }

  const queryString = params.join('&');

  return [Routes.APPOINTMENT_CREATE, queryString].join('?');
}

export function eventRoute(eventId: string): string {
  return Routes.EVENT.replace(':eventId?', eventId);
}

export function searchRoute(prefix: string, patientId: string): string {
  return prefix.replace(':patientId?', patientId);
}

export enum CoachNotesRoutes {
  FOLLOW_UP = 'follow-up',
  DROP_IN = 'drop-in',
  QUICK_NOTE = 'quick-note',
  RISK = 'risk',
  OUTREACH_ATTEMPT = 'outreach-attempt',
  INITIAL_CONSULT = 'initial-consult',
}
