import {
  ClearCareProviderTasksByReasonInput,
  CoachType,
  CompleteRiskAssessmentInput,
  Reason,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';
import {
  clearCareProviderTasksByReasonMutation,
  completeRiskAssessmentMutation,
} from '@headspace/carehub-graphql/dist/member-chart-cards/tasksQueries';
import { useDispatch } from 'app/state';
import { refreshTodaysTaskCard } from 'app/state/tasks/actions';
import { useMutationWithGlobalState as useMutation } from 'hooks/useMutationWithGlobalState';

import { UseTaskMutationsResult } from './types';

export default function useTaskMutations({
  memberId,
}: {
  memberId: string;
}): UseTaskMutationsResult {
  const dispatch = useDispatch();
  const [clearCareProviderTasksByReason] = useMutation(
    clearCareProviderTasksByReasonMutation,
  );
  const [completeRiskAssessment] = useMutation(completeRiskAssessmentMutation);

  const dismissTasksByReason = (reasons: Reason[]) => {
    const input: ClearCareProviderTasksByReasonInput = {
      memberId,
      reasons,
    };
    void clearCareProviderTasksByReason({ input }).then(() =>
      dispatch(refreshTodaysTaskCard({ memberId })),
    );
  };

  const dismissNoteTasks = (isTerminationNote: boolean = false) => {
    const reasons = [
      Reason.MissedSession,
      Reason.ClinicalNotePastDue,
      Reason.CareProviderNotesDue,
      Reason.ReengagementReminder,
    ];
    if (isTerminationNote) reasons.push(Reason.TerminationReminder);
    dismissTasksByReason(reasons);
  };

  const closeRiskAssessment = (
    coachType: CoachType,
    leadCoachGingerId: string,
  ) => {
    const input: CompleteRiskAssessmentInput = {
      coachType,
      leadCoachGingerId,
      memberId,
    };
    void completeRiskAssessment({ input });
  };

  return {
    closeRiskAssessment,
    dismissNoteTasks,
    dismissTasksByReason,
  };
}
