/* eslint-disable no-undef */
import { ClinicianRole } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/ClinicianRole';
import { Risks } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/RiskAssessment';
import {
  VaultItem_SchemaType,
  vaultItem_SchemaTypeToJSON,
} from '@ginger.io/vault-core/dist/generated/protobuf-schemas/vault-core/VaultItem';
import { GetCoachNotesAndUserMetadata_LegacyCoachSummaryNotes_notes as LegacyCoachSummaryNotes } from '@headspace/carehub-graphql/dist/coach-notes/generated/GetCoachNotesAndUserMetadata';
import {
  CoachCareTeamRoleEnum,
  CoachType,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';
import {
  AdditionalRiskActions,
  CareProviderNoteType,
  CoachNoteFormatType,
  CoachNoteSessionType,
  CoachNotesItem,
  CoachNoteType,
  Details,
  DropInNoteType,
  FilterKeys,
  LegacyNoteType,
  NoteItemWithRiskAssessments,
  NoteItemWithRisks,
  NoteItemWithSessionCount,
  NoteOrSubsection,
  NoteViewOrder,
  RiskType,
  RoleType,
  SHAREABLE_COACH_NOTES_FOR_CLINICIAN,
  ShareableClinicianNoteType,
  SummaryNote,
  SummaryNoteDetails,
  ValidClinicianRole,
} from 'app/coach/coach-notes/CoachNotesTypes';
import { noteTypeFormValidatorMap } from 'app/coach/coach-notes/formValidator';
import { WritableNoteType } from 'app/coach/coach-notes/types';
import {
  GetMemberCoachingTeam_getMember_coachingCareTeam as CoachingCareTeam,
  GetMemberCoachingTeam_getMember_coachingCareTeam_current_coaches,
  GetMemberCoachingTeam_getMember_coachingCareTeam_past_coaches,
} from 'app/queries/generated/GetMemberCoachingTeam';
import { GraphQLProps } from 'app/typeUtils';
import moment from 'moment-timezone';
import qs from 'query-string';
import { MenuItem } from 'shared-components/menu/FilterMenu';
import { camelCaseToTitleCase } from 'utils';

type SortAndFilterParams = {
  direction: NoteViewOrder;
  typeFilters: MenuItem[];
};

function formatRiskDetails(associatedRisks?: RiskType[]): string | null {
  if (!associatedRisks) {
    return null;
  }

  const formattedRisks = associatedRisks.map((risk) =>
    camelCaseToTitleCase(risk),
  );

  if (formattedRisks.length > 1) {
    return formattedRisks.join(', ');
  }

  return formattedRisks[0];
}

export function formatTitleAndSessionNumber(
  title?: string,
  sessionNumber?: number,
): string {
  const titleString = title ?? '';
  const sessionString = sessionNumber ? `S${sessionNumber}` : '';
  return `${sessionString}${title && sessionNumber ? `: ` : ''}${titleString}`;
}

export function getSessionCount(
  data?: NoteOrSubsection,
  noteType?: CareProviderNoteType,
): MaybeUndefined<number> {
  if (!data) {
    return undefined;
  }

  return 'sessionCount' in data ? data.sessionCount : undefined;
}

export function getTitle(
  data?: NoteOrSubsection,
  noteType?: CareProviderNoteType,
): MaybeUndefined<string> {
  if (!data) {
    return undefined;
  }

  return 'title' in data ? data.title : undefined;
}

export function autoIncrementSessionCount(params: {
  noteId?: string;
  currentSessionCount?: number;
  latestSessionCount?: number;
  readOnly?: boolean;
}): number {
  const {
    noteId,
    currentSessionCount = 1,
    latestSessionCount = 0,
    readOnly,
  } = params;
  return readOnly || noteId ? currentSessionCount : latestSessionCount + 1;
}

export function getLatestSessionInfo(
  data?: NoteItemWithSessionCount,
  sessionCount?: number,
): number | undefined {
  let latestSessionCount = sessionCount;

  if ((data as NoteItemWithSessionCount)?.sessionCount) {
    if (
      !sessionCount ||
      (data as NoteItemWithSessionCount).sessionCount > sessionCount
    ) {
      latestSessionCount = (data as NoteItemWithSessionCount).sessionCount;
    }
  }

  return latestSessionCount;
}

export function getNoteDetails(
  data?: NoteOrSubsection,
  noteType?: CareProviderNoteType,
  associatedRisks?: RiskType[],
): MaybeUndefined<Details> {
  if (!noteType) {
    return;
  }

  const titleAndSessionNumber = formatTitleAndSessionNumber(
    getTitle(data, noteType),
    getSessionCount(data, noteType),
  );
  const risks = formatRiskDetails(associatedRisks);

  const coachNoteDetails: Record<CoachNoteType, Details> = {
    [CoachNoteType.INITIAL_CONSULT]: {
      risks,
      role: RoleType.COACHING,
      session: CoachNoteSessionType.INITIAL_CONSULT,
      titleAndSessionNumber,
    },
    [CoachNoteType.FOLLOW_UP]: {
      risks,
      role: RoleType.COACHING,
      session: CoachNoteSessionType.FOLLOW_UP,
      titleAndSessionNumber,
    },
    [CoachNoteType.OUTREACH_ATTEMPT]: {
      role: RoleType.COACHING,
      session: CoachNoteSessionType.OUTREACH_ATTEMPT,
    },
    [CoachNoteType.QUICK_NOTE]: {
      role: RoleType.COACHING,
      session: CoachNoteSessionType.QUICK_NOTE,
      titleAndSessionNumber,
    },
    [CoachNoteType.RISK]: {
      risks,
      role: RoleType.COACHING,
      session: CoachNoteSessionType.RISK,
    },
  };

  const shareableClinicianNoteDetails: Record<
    ShareableClinicianNoteType,
    Details
  > = {
    [ShareableClinicianNoteType.UNDEFINED]: {
      role: RoleType.CLINICAL,
      session: CoachNoteSessionType.CLINICAL_NOTE,
      titleAndSessionNumber,
    },
    [ShareableClinicianNoteType.PSYCHIATRY_INTAKE]: {
      role: RoleType.CLINICAL,
      session: CoachNoteSessionType.PSYCHIATRY_INTAKE,
      titleAndSessionNumber,
    },
    [ShareableClinicianNoteType.PSYCHIATRY_PROGRESS]: {
      role: RoleType.CLINICAL,
      session: CoachNoteSessionType.PSYCHIATRY_PROGRESS,
      titleAndSessionNumber,
    },
    [ShareableClinicianNoteType.PSYCHIATRY_TERMINATION]: {
      role: RoleType.CLINICAL,
      session: CoachNoteSessionType.PSYCHIATRY_TERMINATION,
      titleAndSessionNumber,
    },
    [ShareableClinicianNoteType.THERAPY_INTAKE]: {
      role: RoleType.CLINICAL,
      session: CoachNoteSessionType.THERAPY_INTAKE,
      titleAndSessionNumber,
    },
    [ShareableClinicianNoteType.THERAPY_PROGRESS]: {
      role: RoleType.CLINICAL,
      session: CoachNoteSessionType.THERAPY_PROGRESS,
      titleAndSessionNumber,
    },
    [ShareableClinicianNoteType.THERAPY_TERMINATION]: {
      role: RoleType.CLINICAL,
      session: CoachNoteSessionType.THERAPY_TERMINATION,
      titleAndSessionNumber,
    },
  };

  const legacyNoteDetails: Record<LegacyNoteType, Details> = {
    [LegacyNoteType.SUMMARY_NOTE]: {
      role: RoleType.COACHING,
      session: CoachNoteSessionType.SUMMARY_NOTE,
      titleAndSessionNumber,
    },
    [LegacyNoteType.DAILY_COACHING_NOTE]: {
      role: RoleType.COACHING,
      session: CoachNoteSessionType.DAILY_COACHING_NOTE,
      titleAndSessionNumber,
    },
  };

  const dropInNoteDetails: Record<DropInNoteType, Details> = {
    [DropInNoteType.DROP_IN]: {
      risks,
      role: RoleType.COACHING,
      session: CoachNoteSessionType.DROP_IN_CONSULT,
    },
    [DropInNoteType.DROP_IN_CONSULT]: {
      risks,
      role: RoleType.COACHING,
      session: CoachNoteSessionType.DROP_IN_CONSULT,
    },
    [DropInNoteType.DE_ESCALATION_NEED]: {
      risks,
      role: RoleType.COACHING,
      session: CoachNoteSessionType.DE_ESCALATION_NEED,
    },
    [DropInNoteType.EXPERIENCING_RISK]: {
      risks,
      role: RoleType.COACHING,
      session: CoachNoteSessionType.EXPERIENCING_RISK,
    },
    [DropInNoteType.EXPLORING_THE_APP]: {
      risks,
      role: RoleType.COACHING,
      session: CoachNoteSessionType.EXPLORING_THE_APP,
    },
    [DropInNoteType.SEEKING_CLINICAL]: {
      risks,
      role: RoleType.COACHING,
      session: CoachNoteSessionType.SEEKING_CLINICAL,
    },
    [DropInNoteType.DROP_IN_OTHER]: {
      risks,
      role: RoleType.COACHING,
      session: CoachNoteSessionType.DROP_IN_OTHER,
      titleAndSessionNumber,
    },
  };

  const noteDetails: Record<CareProviderNoteType, Details> = {
    ...coachNoteDetails,
    ...shareableClinicianNoteDetails,
    ...legacyNoteDetails,
    ...dropInNoteDetails,
  };

  return noteDetails[noteType];
}

export function getNoteDetailsFromQueryString(
  type: string,
): MaybeUndefined<Details> {
  const noteType = type.toLowerCase();
  const noteTypeMapping = {
    [CoachNoteType.FOLLOW_UP]: CoachNoteSessionType.FOLLOW_UP,
    [CoachNoteType.INITIAL_CONSULT]: CoachNoteSessionType.INITIAL_CONSULT,
    [CoachNoteType.OUTREACH_ATTEMPT]: CoachNoteSessionType.OUTREACH_ATTEMPT,
    [CoachNoteType.QUICK_NOTE]: CoachNoteSessionType.QUICK_NOTE,
    [CoachNoteType.RISK]: CoachNoteSessionType.RISK,

    [LegacyNoteType.SUMMARY_NOTE]: CoachNoteSessionType.SUMMARY_NOTE,

    [ShareableClinicianNoteType.PSYCHIATRY_INTAKE]:
      CoachNoteSessionType.PSYCHIATRY_INTAKE,
    [ShareableClinicianNoteType.PSYCHIATRY_PROGRESS]:
      CoachNoteSessionType.PSYCHIATRY_PROGRESS,
    [ShareableClinicianNoteType.THERAPY_INTAKE]:
      CoachNoteSessionType.THERAPY_INTAKE,
    [ShareableClinicianNoteType.THERAPY_PROGRESS]:
      CoachNoteSessionType.THERAPY_PROGRESS,
  };

  return {
    role: RoleType.COACHING,
    session: noteTypeMapping[noteType as CoachNoteType],
  };
}

export function parseQueryString(
  queryString: string,
): { noteId?: string; noteType?: CareProviderNoteType } {
  let noteId: MaybeUndefined<string>;
  let noteType: MaybeUndefined<CareProviderNoteType>;

  const { noteId: qsNoteId, noteType: qsNoteType } = qs.parse(queryString);

  if (qsNoteId && typeof qsNoteId === 'string') {
    noteId = qsNoteId;
  }

  if (
    (qsNoteType &&
      typeof qsNoteType === 'string' &&
      (Object.values(CoachNoteType).includes(qsNoteType as CoachNoteType) ||
        isShareableSubsectionType(qsNoteType as ShareableClinicianNoteType) ||
        isLegacyNoteType(qsNoteType as LegacyNoteType))) ||
    isDropInNoteType(qsNoteType as DropInNoteType)
  ) {
    noteType = qsNoteType as CareProviderNoteType;
  }

  return { noteId, noteType };
}

export function getRiskFields(riskAssessments?: Risks): RiskType[] {
  // RiskType enum maps 1:1 with the protobuf Risks fields
  return (
    Object.entries(riskAssessments ?? {})
      // exclude actionsTaken because it's not actually a risk assessment
      .filter(
        ([key, value]) =>
          !!value && key !== AdditionalRiskActions.ACTIONS_TAKEN,
      )
      .map(([key]) => key) as RiskType[]
  );
}

export function getColumns(params: SortAndFilterParams) {
  const { direction, typeFilters } = params;
  return [
    {
      active: true,
      direction,
      label: '',
      name: FilterKeys.ORDER,
      size: 'xs',
      sortBy: 'start',
      sortable: true,
    },
    {
      active: true,
      direction: 'asc',
      filters: typeFilters,
      label: 'Type',
      name: FilterKeys.NOTE_TYPE,
      size: 'large',
      sortBy: 'start',
      sortable: false,
    },
  ];
}

export function isLegacyNoteType(noteType: CareProviderNoteType): boolean {
  return Object.values(LegacyNoteType).includes(noteType as LegacyNoteType);
}

export function isShareableSubsectionType(
  noteType: CareProviderNoteType,
): boolean {
  return Object.values(ShareableClinicianNoteType).includes(
    noteType as ShareableClinicianNoteType,
  );
}

export function isShareableWithClinicians(noteType: CareProviderNoteType) {
  return (
    noteType &&
    SHAREABLE_COACH_NOTES_FOR_CLINICIAN.has(noteType as CoachNoteType)
  );
}

export function isDropInNoteType(noteType: CareProviderNoteType): boolean {
  return Object.values(DropInNoteType).includes(noteType as DropInNoteType);
}

export function getStartDateAndEndTime(
  timezone: string,
  start?: string,
  end?: string,
) {
  if (!start) {
    return {
      dateString: undefined,
      startDate: undefined,
    };
  }

  const startObj = moment.tz(start, timezone);
  const startTime = end ? startObj.format('h:mm') : startObj.format('h:mma z');
  const startDate = startObj.format('MMM D, YYYY');
  const endTime = end ? moment.tz(end, timezone).format('h:mma z') : undefined;
  const dateString = end ? `${startTime}-${endTime}` : startTime;

  return {
    dateString,
    startDate,
  };
}

export function getTerminationReasonsNoteType(
  clinicianRole: ValidClinicianRole,
): ShareableClinicianNoteType {
  const noteTypes: Record<ValidClinicianRole, ShareableClinicianNoteType> = {
    [ClinicianRole.psychiatrist]:
      ShareableClinicianNoteType.PSYCHIATRY_TERMINATION,
    [ClinicianRole.psychiatrist_supervisor]:
      ShareableClinicianNoteType.PSYCHIATRY_TERMINATION,
    [ClinicianRole.therapist]: ShareableClinicianNoteType.THERAPY_TERMINATION,
    [ClinicianRole.therapist_supervisor]:
      ShareableClinicianNoteType.THERAPY_TERMINATION,
  };

  return noteTypes[clinicianRole];
}

export function formatSummaryNotes(
  summaryNotes: GraphQLProps<LegacyCoachSummaryNotes>,
): SummaryNoteDetails {
  let latestCreationDate: MaybeUndefined<ISODateString | null>;
  let lastCreatedBy: MaybeUndefined<string | null>;

  const formattedSummaryNotes = Object.keys(summaryNotes).reduce<SummaryNote>(
    (obj: SummaryNote, key) => {
      const subsection =
        summaryNotes[key as keyof GraphQLProps<LegacyCoachSummaryNotes>];
      let noteValue = null;

      if (subsection) {
        const { lastUpdatedByName, lastUpdatedAt, value } = subsection;

        if (
          !latestCreationDate ||
          moment(lastUpdatedAt).isAfter(latestCreationDate)
        ) {
          latestCreationDate = lastUpdatedAt;
          lastCreatedBy = lastUpdatedByName;
        }

        noteValue = value;
      }

      return {
        ...obj,
        [key]: noteValue,
      };
    },
    {} as SummaryNote,
  );
  return {
    data: formattedSummaryNotes,
    lastCreatedBy: lastCreatedBy || undefined,
    latestCreationDate: latestCreationDate || undefined,
  };
}

interface CareTeamInfo {
  coachType?: CoachType;
  leadCoachId?: string;
}

const careTeamRoleToCoachType = (careTeamRole: CoachCareTeamRoleEnum) => {
  if (careTeamRole === CoachCareTeamRoleEnum.TEAM_LEAD) return CoachType.LEAD;
  if (careTeamRole === CoachCareTeamRoleEnum.SI_ASSESSMENT) return CoachType.SI;
  if (careTeamRole === CoachCareTeamRoleEnum.DROPIN) return CoachType.DROPIN;
  return CoachType.COACH;
};

export function getCareTeamInfo(
  coachId: string,
  coachingCareTeam?: CoachingCareTeam | null,
): CareTeamInfo {
  const currentCoaches = coachingCareTeam?.current?.coaches || null;
  const pastCoaches = coachingCareTeam?.past?.coaches || null;

  if (currentCoaches) {
    const currentCareTeamInfo = retrieveCurrentCareInfo(
      coachId,
      currentCoaches,
    );
    if (!currentCareTeamInfo.coachType && pastCoaches) {
      const pastCareTeamInfo: CareTeamInfo = retrievePastCareInfo(
        coachId,
        pastCoaches,
      );
      return {
        coachType: pastCareTeamInfo.coachType,
        leadCoachId: currentCareTeamInfo.leadCoachId,
      };
    }
    return currentCareTeamInfo;
  }
  return { coachType: '' as CoachType, leadCoachId: '' };
}

function retrieveCurrentCareInfo(
  coachId: string,
  coaches: (GetMemberCoachingTeam_getMember_coachingCareTeam_current_coaches | null)[],
): CareTeamInfo {
  return coaches.reduce(
    (
      teamInfo: CareTeamInfo,
      coach: GetMemberCoachingTeam_getMember_coachingCareTeam_current_coaches | null,
    ) => {
      const careTeamInfo = teamInfo;
      if (coach?.careTeamRole === CoachCareTeamRoleEnum.TEAM_LEAD)
        careTeamInfo.leadCoachId = coach.gingerId as string;

      if (coach?.id === coachId) {
        if (coach?.careTeamRole) {
          careTeamInfo.coachType = careTeamRoleToCoachType(coach?.careTeamRole);
        } else {
          careTeamInfo.coachType = CoachType.COACH;
        }
      }
      return careTeamInfo;
    },
    { coachType: '' as CoachType, leadCoachId: '' },
  );
}

function retrievePastCareInfo(
  coachId: string,
  coaches: (GetMemberCoachingTeam_getMember_coachingCareTeam_past_coaches | null)[],
): CareTeamInfo {
  return coaches.reduce(
    (
      teamInfo: CareTeamInfo,
      coach: GetMemberCoachingTeam_getMember_coachingCareTeam_past_coaches | null,
    ) => {
      const careTeamInfo = teamInfo;
      if (coach?.id === coachId) {
        if (coach?.careTeamRole) {
          careTeamInfo.coachType = careTeamRoleToCoachType(coach?.careTeamRole);
        } else {
          careTeamInfo.coachType = CoachType.COACH;
        }
        careTeamInfo.leadCoachId = '';
      }
      return careTeamInfo;
    },
    { coachType: '' as CoachType, leadCoachId: '' },
  );
}

export const noteContainsRisks = (data?: NoteOrSubsection): boolean => {
  if (!data) return false;

  // Check if this is a type of Note that can contain Risks
  const riskAssessmentData =
    (data as NoteItemWithRiskAssessments).riskAssessments ||
    (data as NoteItemWithRisks).risks;

  if (riskAssessmentData) {
    // Now that we know the Note can contain Risks, check if any Risks are actually present
    const riskExists = Object.keys(riskAssessmentData).find(
      (risk: string) => (riskAssessmentData as any)[risk],
    );
    return !!riskExists;
  }

  return false;
};

export function hasValidParams(params: CoachNotesItem): boolean {
  const { schemaType, data, noteType } = params;

  if (!schemaType || !noteType || !data) {
    return false;
  }

  return true;
}

export const getSchemaTypeStr = (
  schemaType?: VaultItem_SchemaType,
): string | undefined => {
  return schemaType ? vaultItem_SchemaTypeToJSON(schemaType) : undefined;
};

export function getTags(
  memberId: string,
  noteType: CareProviderNoteType,
): string[] {
  const tags = [
    `care-provider-note-${memberId}`,
    `member-chart-${memberId}`,
    `member-chart-${memberId}-coach-notes`,
  ];
  if (noteType) tags.push(`member-chart-${memberId}-coach-notes-${noteType}`);
  if (isShareableWithClinicians(noteType)) {
    tags.push(`member-chart-${memberId}-shareable-coach-notes`);
  }
  return tags;
}

export const classifyCoachNoteType = (
  canContainNestedRisks: boolean,
  isBaseRiskAssessment: boolean,
): CoachNoteFormatType => {
  if (!canContainNestedRisks)
    return CoachNoteFormatType.NOTE_ITEM_WITHOUT_RISK_ASSESSMENTS;
  if (isBaseRiskAssessment) return CoachNoteFormatType.BASE_RISK_ASSESSMENT;
  return CoachNoteFormatType.NOTE_ITEM_WITH_RISK_ASSESSMENTS;
};

function isWritableNoteType(
  noteType: CareProviderNoteType,
): noteType is WritableNoteType {
  return noteType in noteTypeFormValidatorMap;
}

export const isNoteValidToSignAndLock = (
  selectedNote?: CoachNotesItem,
): boolean => {
  if (
    !selectedNote?.data ||
    !selectedNote?.noteType ||
    !isWritableNoteType(selectedNote.noteType)
  ) {
    return false;
  }

  const validator = noteTypeFormValidatorMap[selectedNote.noteType];
  return validator(selectedNote.data);
};
