import { useQuery } from '@apollo/client';
import { Field } from '@ginger.io/react-use-form/dist/types';
import { getMembersForMe } from '@headspace/carehub-graphql/dist/appointments/appointmentQueries';
import { GetMembersForMe } from '@headspace/carehub-graphql/dist/appointments/generated/GetMembersForMe';
import React, { useState } from 'react';

import { MemberSearchField } from './MemberSearchField';

export function MemberSearchFieldForMeContainer({
  field,
}: {
  field: Field<string>;
}) {
  const [searchQuery, setSearchQuery] = useState(field.value);

  const { error, data, loading } = useQuery<GetMembersForMe>(getMembersForMe, {
    variables: { input: { q: searchQuery } },
  });

  const members = data?.getMembersForMe?.members;

  return (
    <MemberSearchField
      members={members}
      field={field}
      error={error?.message}
      setSearchQuery={setSearchQuery}
      loading={loading}
    />
  );
}
