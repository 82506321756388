import {
  AddMemberSupportMutation,
  AddMemberSupportMutationVariables,
} from '@headspace/carehub-graphql/dist/care-team/generated/AddMemberSupportMutation';
import { addMemberSupportMutation } from '@headspace/carehub-graphql/dist/care-team/mutations';
import {
  AddMemberSupportErrorCode,
  AddMemberSupportInput,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { useMutationWithGlobalState } from 'hooks/useMutationWithGlobalState';
import Messages from 'i18n/en/careTeam.json';

export interface AddMemberSupportErrorState {
  title?: string;
  message?: string;
  errorType?: AddMemberSupportErrorCode;
}

export const AddMemberSupportErrorsMap: Record<
  AddMemberSupportErrorCode,
  AddMemberSupportErrorState
> = {
  [AddMemberSupportErrorCode.UNEXPECTED_ERROR]: {
    errorType: AddMemberSupportErrorCode.UNEXPECTED_ERROR,
    message: Messages.useAddMemberSupportMessage,
    title: Messages.useAddMemberSupportTitle,
  },
};

export interface UseAddMemberSupporthHook {
  addMemberSupport: (input: AddMemberSupportInput) => Promise<void>;
}

export default function useAddMemberSupport(): UseAddMemberSupporthHook {
  const [addMemberSupportMutationFn] = useMutationWithGlobalState<
    AddMemberSupportMutation,
    AddMemberSupportMutationVariables
  >(addMemberSupportMutation);

  const addMemberSupport = async (input: AddMemberSupportInput) => {
    const { data } = await addMemberSupportMutationFn({ input });

    if (
      data?.addMemberSupport?.errors?.length &&
      data.addMemberSupport.errors.length > 0
    ) {
      throw AddMemberSupportErrorsMap[data.addMemberSupport.errors[0]];
    }

    if (data?.addMemberSupport?.success) {
    } else {
      throw AddMemberSupportErrorsMap[
        AddMemberSupportErrorCode.UNEXPECTED_ERROR
      ];
    }
  };

  return { addMemberSupport };
}
