import {
  ApolloClient,
  ApolloError,
  NormalizedCacheObject,
} from '@apollo/client';
import {
  CoachClinicianCollaborationChatMessage_Author as Author,
  CoachClinicianCollaborationChatMessage_Author_AuthorType as AuthorType,
  CoachClinicianCollaborationChatMessage_GeneratedFrom as GeneratedFrom,
} from '@ginger.io/vault-care-collaboration/dist/generated/protobuf-schemas/vault-care-collaboration/CoachClinicianCollaborationChatMessage';
import { ClinicianRole } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/ClinicianRole';
import { OutOfSessionNote } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/OutOfSessionNote';
import { TerminationNote } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/TerminationNote';
import {
  TerminationReasons,
  TerminationReasons_Reason,
  TerminationReasons_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/TerminationReasons';
import { decodeBase64VaultItems, Decoder } from '@ginger.io/vault-core';
import { KeyGenerator } from '@ginger.io/vault-core/dist/crypto';
import { Base64 } from '@ginger.io/vault-core/dist/crypto/Base64';
import {
  VaultItem,
  VaultItem_SchemaType,
} from '@ginger.io/vault-core/dist/generated/protobuf-schemas/vault-core/VaultItem';
import {
  getClinicalCareTeamGroupId,
  getCoachingTeamGroupId,
  getVaultWebUserId,
} from '@ginger.io/vault-core/dist/IdHelpers';
import {
  CreateVaultItemInputParams,
  VaultAPI,
  VaultItemPermissions,
  VaultSystemName,
} from '@ginger.io/vault-ui';
import { UserRole } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { createCollaborationChatMessage } from 'app/collaboration/createCollaborationChatMessage';
import {
  MemberDetailsById,
  MemberDetailsById_getMember as MemberDetails,
  MemberDetailsByIdVariables,
} from 'app/vault/generated/MemberDetailsById';
import {
  GetVaultItemById,
  GetVaultItemByIdVariables,
} from 'app/vault/hooks/NonAppointments/generated/GetVaultItemById';
import { vaultItemFragment_creator as Creator } from 'app/vault/hooks/NonAppointments/generated/vaultItemFragment';
import { getNonAppointmentNoteById } from 'app/vault/hooks/NonAppointments/queries';
import { getMemberDetails } from 'app/vault/queries';
import { Clock } from 'app/vault/VaultTokenStorage';
import Messages from 'i18n/en/vault.json';
import moment from 'moment';

import { OutOfSessionNoteIds } from './OutOfSessionNoteIds';
import { TerminationNoteIds } from './TerminationNoteIds';

export type NoteWithCreator<T> = {
  note: T;
  creator: Creator;
  itemId: string;
  permissions: VaultItemPermissions;
};

export enum NonAppointmentNoteAction {
  CREATE,
  LOCK,
  CREATE_AND_LOCK,
  UPDATE,
}

export const clinicianRoleEnumMap: Record<UserRole, ClinicianRole> = {
  [UserRole.COACH]: ClinicianRole.undefined_role,
  [UserRole.COACH_SUPERVISOR]: ClinicianRole.undefined_role,
  [UserRole.CLINICIAN]: ClinicianRole.undefined_role,
  [UserRole.CLINICAL_SUPERVISOR]: ClinicianRole.undefined_role,
  [UserRole.MEMBER_SUPPORT]: ClinicianRole.undefined_role,

  [UserRole.PSYCHIATRIST]: ClinicianRole.psychiatrist,
  [UserRole.PSYCHIATRIST_SUPERVISOR]: ClinicianRole.psychiatrist_supervisor,
  [UserRole.THERAPIST]: ClinicianRole.therapist,
  [UserRole.THERAPIST_SUPERVISOR]: ClinicianRole.therapist_supervisor,
};

export class NonAppointmentNotesAPI {
  protected vaultAPI: VaultAPI;

  static encodeOutOfSessionSection(
    outOfSessionNote: OutOfSessionNote,
  ): VaultItem {
    return {
      data: OutOfSessionNote.encode(outOfSessionNote).finish(),
      schemaType: VaultItem_SchemaType.vault_clinical_notes_out_of_session,
    };
  }

  static encodeTerminationSection(terminationNote: TerminationNote): VaultItem {
    return {
      data: TerminationNote.encode(terminationNote).finish(),
      schemaType: VaultItem_SchemaType.vault_clinical_notes_termination,
    };
  }

  static encodeTerminationReasonsSection(
    reasons: TerminationReasons_Reason[],
    patientId: string,
    clinicianId: string,
    role: UserRole,
  ): VaultItem {
    const clinicianRole = clinicianRoleEnumMap[role];
    return {
      data: TerminationReasons.encode({
        clinicianId,
        clinicianRole,
        patientId,
        reasons,
        version: TerminationReasons_Version.v0,
      }).finish(),
      schemaType: VaultItem_SchemaType.vault_clinical_notes_termination_reasons,
    };
  }

  public constructor(
    private apollo: ApolloClient<NormalizedCacheObject>,
    private keyGenerator: KeyGenerator,
    private clock: Clock = { now: () => new Date() },
  ) {
    this.vaultAPI = new VaultAPI(apollo, keyGenerator);
  }

  async getTerminationNote(
    itemId: string,
    memberId: string,
  ): Promise<NoteWithCreator<TerminationNote>> {
    const decoder = {
      decoder: TerminationNote,
      type: VaultItem_SchemaType.vault_clinical_notes_termination,
    };
    return this.getDecodedVaultItemById(itemId, memberId, decoder);
  }

  async getOutOfSessionNote(
    itemId: string,
    memberId: string,
  ): Promise<NoteWithCreator<OutOfSessionNote>> {
    const decoder = {
      decoder: OutOfSessionNote,
      type: VaultItem_SchemaType.vault_clinical_notes_out_of_session,
    };

    return this.getDecodedVaultItemById(itemId, memberId, decoder);
  }

  async createTerminationNote(params: {
    memberId: string;
    clinicianId: string;
    clinicianRole: UserRole;
    terminationNote: TerminationNote;
    action?: NonAppointmentNoteAction;
    itemId?: string;
  }): Promise<{ itemId?: string }> {
    const {
      memberId,
      clinicianId,
      clinicianRole,
      terminationNote,
      action = NonAppointmentNoteAction.CREATE_AND_LOCK,
      itemId,
    } = params;
    const tags = TerminationNoteIds.sectionTags(memberId, clinicianId);
    const vaultItem = NonAppointmentNotesAPI.encodeTerminationSection(
      terminationNote,
    );
    const reasonsVaultItem = NonAppointmentNotesAPI.encodeTerminationReasonsSection(
      (terminationNote.reasons as unknown) as TerminationReasons_Reason[],
      memberId,
      clinicianId,
      clinicianRole,
    );
    const result = await this.createOrUpdateNote({
      action,
      clinicianId,
      itemId,
      memberId,
      reasonsVaultItem,
      tags,
      vaultItem,
    });

    if (
      action === NonAppointmentNoteAction.LOCK ||
      action === NonAppointmentNoteAction.CREATE_AND_LOCK
    ) {
      // create and send message to collaborators
      const author: Author = {
        id: getVaultWebUserId(clinicianId),
        type: AuthorType.clinician,
      };

      await createCollaborationChatMessage(
        this.apollo,
        {
          author,
          body: terminationNote.careContinuationPlan,
          createdAt: moment(this.clock.now()).utc().toISOString(),
          generatedFrom:
            GeneratedFrom.clinical_termination_note_care_continuation_plan,
          generatedFromVaultItemId: '',
          memberId,
          requireTimelyReview: false,
          subject: 'Termination care continuation plan',
          version: 1,
        },
        memberId,
        this.keyGenerator,
      );
    }
    return result;
  }

  async createOutOfSessionNote(params: {
    memberId: string;
    clinicianId: string;
    outOfSessionNote: OutOfSessionNote;
    action?: NonAppointmentNoteAction;
    itemId?: string;
  }): Promise<{ itemId?: string }> {
    const {
      memberId,
      clinicianId,
      outOfSessionNote,
      action = NonAppointmentNoteAction.CREATE_AND_LOCK,
      itemId,
    } = params;
    const tags = OutOfSessionNoteIds.sectionTags(memberId, clinicianId);
    const vaultItem = NonAppointmentNotesAPI.encodeOutOfSessionSection(
      outOfSessionNote,
    );
    return this.createOrUpdateNote({
      action,
      itemId,
      memberId,
      tags,
      vaultItem,
    });
  }

  async getMemberDetails(patientId: string): Promise<MemberDetails> {
    const { data, errors, error } = await this.apollo.query<
      MemberDetailsById,
      MemberDetailsByIdVariables
    >({
      query: getMemberDetails,
      variables: { patientId },
    });

    if (errors || error) {
      throw new Error(`${Messages.memberCouldNotBeRetrieved} #${patientId}`, {
        cause: error ?? new ApolloError({ graphQLErrors: errors }),
      });
    }

    if (data?.getMember == null) {
      throw new Error(`${Messages.memberNotFound} #${patientId}`);
    }
    return data.getMember;
  }

  private async getDecodedVaultItemById<T>(
    itemId: string,
    memberId: string,
    decoder: { decoder: Decoder<T>; type: VaultItem_SchemaType },
  ): Promise<NoteWithCreator<T>> {
    const groupId = await Base64.hash(getClinicalCareTeamGroupId(memberId));
    const { data } = await this.apollo.query<
      GetVaultItemById,
      GetVaultItemByIdVariables
    >({
      query: getNonAppointmentNoteById,
      variables: { groupId, id: itemId },
    });

    if (data.getVaultItemById === null)
      throw new Error(Messages.vaultItemNotFound);

    const { encryptedItem } = data.getVaultItemById;
    const { creator, permissions } = encryptedItem;
    const decodedItem = await decodeBase64VaultItems(
      [encryptedItem.encryptedData.cipherText],
      {
        [decoder.type]: decoder.decoder,
      },
    );
    return {
      creator,
      itemId,
      note: decodedItem[decoder.type][0],
      permissions: (permissions as unknown) as VaultItemPermissions,
    };
  }

  async createOrUpdateNote(params: {
    action: NonAppointmentNoteAction;
    tags: string[];
    vaultItem: VaultItem;
    reasonsVaultItem?: VaultItem;
    memberId: string;
    clinicianId?: string;
    itemId?: string;
  }): Promise<{ itemId?: string }> {
    const {
      action,
      tags,
      vaultItem,
      reasonsVaultItem,
      memberId,
      clinicianId,
      itemId,
    } = params;

    switch (action) {
      case NonAppointmentNoteAction.CREATE: {
        const items = [
          {
            groupsToShareWith: [getClinicalCareTeamGroupId(memberId)],
            permissions: VaultItemPermissions.WritableByAll,
            tags,
            vaultItem,
          },
        ];

        if (reasonsVaultItem) {
          items.push({
            groupsToShareWith: [
              getClinicalCareTeamGroupId(memberId),
              getCoachingTeamGroupId(memberId),
            ],
            permissions: VaultItemPermissions.WritableByAll,
            tags: TerminationNoteIds.subSectionTags(memberId, clinicianId!),
            vaultItem: reasonsVaultItem,
          });
        }

        // todo: replace with createVaultItemsInBatch https://app.asana.com/0/1201082158239874/1204017937014215/f
        const data = await Promise.all(
          items.map((item) => this.vaultAPI.createVaultItems([item])),
        );
        return {
          // return only the encryptedItem.id of the first item, not the shareable subsection
          itemId: data[0]?.createVaultItems[0].encryptedItem.id,
        };
      }
      case NonAppointmentNoteAction.LOCK: {
        if (!itemId) throw new Error(Messages.itemToLockNotProvided);
        await this.vaultAPI.shareVaultItems(
          {
            itemIds: [itemId],
            setItemPermissionsToReadOnly: true,
            systemNames: [VaultSystemName.ClinicalNotesSyncProcess],
          },
          await Base64.hash(getClinicalCareTeamGroupId(memberId)),
          { hashItemIds: false },
        );
        return { itemId };
      }
      case NonAppointmentNoteAction.CREATE_AND_LOCK: {
        const items: CreateVaultItemInputParams[] = [
          {
            groupsToShareWith: [getClinicalCareTeamGroupId(memberId)],
            permissions: VaultItemPermissions.ReadOnly,
            systemToShareWith: VaultSystemName.ClinicalNotesSyncProcess,
            tags,
            vaultItem,
          },
        ];

        if (reasonsVaultItem) {
          items.push({
            groupsToShareWith: [
              getClinicalCareTeamGroupId(memberId),
              getCoachingTeamGroupId(memberId),
            ],
            permissions: VaultItemPermissions.ReadOnly,
            tags: TerminationNoteIds.subSectionTags(memberId, clinicianId!),
            vaultItem: reasonsVaultItem,
          });
        }

        // todo: replace with createVaultItemsInBatch https://app.asana.com/0/1201082158239874/1204017937014215/f
        const data = await Promise.all(
          items.map((item) => this.vaultAPI.createVaultItems([item])),
        );

        return {
          // return only the encryptedItem.id of the first item, not the shareable subsection
          itemId: data[0]?.createVaultItems[0].encryptedItem.id,
        };
      }
      case NonAppointmentNoteAction.UPDATE: {
        if (!itemId) throw new Error(Messages.itemToLockNotProvided);
        await this.vaultAPI.updateVaultItems(
          [
            {
              groupId: await Base64.hash(getClinicalCareTeamGroupId(memberId)),
              itemId,
              permissions: VaultItemPermissions.WritableByAll,
              tags,
              vaultItem,
            },
          ],
          { hashItemIds: false },
        );
        return { itemId };
      }
      default:
        throw new Error(`Unsupported action: ${action}`);
    }
  }
}
