export const Labels = {
  childhood: {
    description: 'Environment, circumstances, relevant details',
    id: 'childhoodDescription',
    label: 'Childhood description',
    placeholder: 'Type here...',
  },
  accessToGuns: {
    id: 'accessToGuns',
    label: 'Does the member have access to guns?',
  },
  childhoodAbuse: {
    commentId: 'childhoodAbuseComment',
    deniesLabel: 'Not endorsing childhood abuse',
    id: 'childhoodAbuse',
    label: 'Did the member experience any childhood abuse?',
  },
  comments: {
    id: 'comments',
    label: 'Additional social / family history comments',
    placeholder: 'Brief additional comments not captured above...',
  },
  culturalConsiderations: {
    id: 'culturalConsiderations',
    label: 'Cultural considerations',
    placeholder: 'Type here...',
  },
  developmentHistory: {
    id: 'developmentHistory',
    label: 'Development history',
    placeholder: 'Type here...',
  },
  education: {
    id: 'education',
    label: 'Education',
    placeholder: 'Type here...',
  },
  employment: {
    id: 'employment',
    label: 'Employment',
    placeholder: 'Type here...',
  },
  family: {
    description:
      'Psychiatric and medical conditions that run in genetic family',
    id: 'familyHistory',
    label: ' Family History',
    placeholder:
      'Presence or absence of bipolar, suicide, substance use disorder...',
  },
  historyOfDomesticViolence: {
    id: 'historyOfDomesticViolence',
    id2: 'historyOfDomesticViolence',
    label:
      'Does the member currently or have they in the past experienced trauma / domestic violence / abuse',
    placeholder: '(Optional) Comment...',
  },
  legalStressors: {
    commentId: 'legalStressorsComment',
    id: 'legalStressors',
    label:
      'Does the member currently or have they in the past had any legal stressors?',
    placeholder: 'Type here...',
  },
  livingSituation: {
    id: 'livingSituation',
    label: 'Current living situation',
    placeholder: 'Type here...',
  },
  relationships: {
    id: 'relationships',
    label: 'Relationships and family support',
    placeholder: 'Type here...',
  },
};
