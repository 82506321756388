import { ServerType } from 'app/services/server/types';
import { webApi } from 'app/services/server/webApi';

export const ClinicalNoteQaCheckBaseUrl = 'care_hub/clinical-note-qa/v1';

export const clinicalNoteQaCheckApi = webApi.injectEndpoints({
  endpoints: (builder) => ({
    uploadClinicalNote: builder.mutation({
      extraOptions: { server: ServerType.Web },
      query: (data) => ({
        body: data,
        formData: true,
        method: 'POST',
        url: `${ClinicalNoteQaCheckBaseUrl}/uploads`,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useUploadClinicalNoteMutation } = clinicalNoteQaCheckApi;
