import { TextareaAutosize } from '@mui/material';
import { InputBaseProps } from '@mui/material/InputBase';
import { FormError } from 'shared-components/FormError';
import { Width } from 'types/StyleTypes';
import React from 'react';

import {
  NoteFormControlLabel,
  NoteFormControlLabelStyles,
} from './NoteFormControlLabel';
import styles from './TextArea.module.scss';

export type TextAreaProps = {
  name?: string;
  label?: string;
  value?: string;
  placeholder?: string;
  error?: string;
  className?: string;
  rows?: number;
  inputRef?: InputBaseProps['inputRef'];
  onChange: (value: string) => void;
  disabled?: boolean;
  autoExpand?: boolean;
  width?: Width;
};

export function TextArea(props: TextAreaProps) {
  const {
    value = '',
    error,
    placeholder,
    name,
    className = '',
    rows = 4,
    disabled,
    autoExpand = false,
  } = props;
  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const {
      target: { value },
    } = event;
    props.onChange(value);
  };

  const { width = Width.HALF } = props;

  let widthStyle = '';

  if (width === Width.FULL) {
    widthStyle = styles.fullWidth;
  } else if (width === Width.HALF) {
    widthStyle = styles.halfWidth;
  } else if (width === Width.MAXWIDTH) {
    widthStyle = styles.maxWidth;
  }

  return (
    <div className={[styles.root, className, widthStyle].join(' ')}>
      <TextareaAutosize
        minRows={rows}
        maxRows={autoExpand ? undefined : rows}
        data-testid={name}
        readOnly={disabled}
        className={styles.textAreaAutosize}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
      />
      {error && <FormError error={error} />}
    </div>
  );
}

type TextAreaWithLabelProps = TextAreaProps & {
  tooltip?: string;
  subtext?: string | React.ReactNode;
  labelDescription?: string;
  formControlStyles?: NoteFormControlLabelStyles;
  optional?: boolean;
  button?: React.ReactNode;
};

export function TextAreaWithLabel(props: TextAreaWithLabelProps) {
  return (
    <NoteFormControlLabel
      label={props.label}
      styles={props.formControlStyles}
      tooltip={props.tooltip}
      subtext={props.subtext}
      labelDescription={props.labelDescription}
      optional={props.optional}
      button={props.button}
    >
      <TextArea {...props} />
    </NoteFormControlLabel>
  );
}
