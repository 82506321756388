import { useMutation } from '@apollo/client';
import {
  DeleteEventForMe,
  DeleteEventForMeVariables,
} from '@headspace/carehub-graphql/dist/appointments/generated/DeleteEventForMe';
import {
  GetClinicalEvent,
  GetClinicalEventVariables,
} from '@headspace/carehub-graphql/dist/appointments/generated/GetClinicalEvent';
import {
  UpdateEventForMe,
  UpdateEventForMeVariables,
} from '@headspace/carehub-graphql/dist/appointments/generated/UpdateEventForMe';
import {
  deleteEventForMeMutation,
  getClinicalEventQuery,
  updateEventForMeMutation,
} from '@headspace/carehub-graphql/dist/appointments/queries';
import { UpdateClinicalEventInput } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { DEFAULT_TIMEZONE } from 'app/clinician/ClinicianSettingsComponent';
import { DATE_TIME_FORMAT } from 'app/notes-ui/forms/form-controls/DateTimeRangeInput';
import { useAppState, useDispatch } from 'app/state';
import {
  clickScheduleEventUpdateAction,
  eventViewedAction,
} from 'app/state/amplitude/actions/events';
import { useOnMount } from 'hooks/useOnMount';
import { useQuery } from 'hooks/useQuery';
import { useSnackNotification } from 'hooks/useSnackNotification';
import moment from 'moment-timezone';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { EventForm, EventFormFields } from './EventForm';
import { EmptyFormValue } from './NewEventForm';

export type EventContainerProps = { eventId: string };

export function EventContainer(
  props: RouteComponentProps<EventContainerProps>,
) {
  const dispatch = useDispatch();
  const { match, history } = props;
  const {
    params: { eventId },
  } = match;
  const {
    showErrorNotification,
    showSuccessNotification,
  } = useSnackNotification();
  const timezone = useAppState((_) => _.user.timezone) ?? DEFAULT_TIMEZONE;

  useOnMount(() => {
    dispatch(eventViewedAction({ eventId }));
  });
  const [updateEvent] = useMutation<
    UpdateEventForMe,
    UpdateEventForMeVariables
  >(updateEventForMeMutation);
  const [deleteEvent] = useMutation<
    DeleteEventForMe,
    DeleteEventForMeVariables
  >(deleteEventForMeMutation);

  async function onSave(eventData: UpdateClinicalEventInput) {
    let response;
    try {
      response = await updateEvent({
        variables: { input: { eventData, id: eventId } },
      });
      dispatch(clickScheduleEventUpdateAction({ eventId }));
      showSuccessNotification('Event Updated');
    } catch (e) {
      const errors = response?.errors ? response.errors.join(', ') : e;
      const message = ['Error updating event', errors].join(': ');
      showErrorNotification(message);
    }
  }

  async function onDelete() {
    await deleteEvent({ variables: { input: { id: eventId } } });
  }

  return useQuery<GetClinicalEvent, GetClinicalEventVariables>(
    (data) => {
      const { getClinicalEvent } = data;
      if (getClinicalEvent) {
        const { eventType, start, end } = getClinicalEvent;
        const eventTypeCleaned = eventType || EmptyFormValue.NONE;
        const startCleaned = moment
          .tz(start, timezone)
          .format(DATE_TIME_FORMAT);
        const endCleaned = moment.tz(end, timezone).format(DATE_TIME_FORMAT);
        const initialValues: EventFormFields = {
          end: endCleaned,
          eventType: eventTypeCleaned,
          start: startCleaned,
        };
        return (
          <EventForm
            onSave={onSave}
            onDelete={onDelete}
            onClose={history.goBack}
            initialValues={initialValues}
            timezone={timezone}
          />
        );
      }
      return <h1>Event not found</h1>;
    },
    getClinicalEventQuery,
    { variables: { input: { id: eventId } } },
  );
}

export const EventScreen = withRouter(EventContainer);
