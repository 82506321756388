import { AutoCompleteWithTagV2 } from 'app/notes-ui/forms/form-controls/AutoCompleteWithTag';
import { EnumOption } from 'app/notes-ui/types';
import { useLogger } from 'app/state/log/useLogger';
import { useDiagnosisCodes } from 'hooks/useDiagnosisCodes';
import React, { useEffect, useState } from 'react';
import { Width } from 'types/StyleTypes';

export type ICD10CodesSelectorProps = {
  name: string;
  disabled?: boolean;
  className?: string;
  value?: number[];
  error?: string;
  onChange: (icd10Codes: number[]) => void;
  tagClass?: string;
  'data-testid'?: string;
};

export const ICD10CodesSelector = (props: ICD10CodesSelectorProps) => {
  const { name, disabled, className, value = [], error, tagClass } = props;

  const [tags, setTags] = useState<EnumOption[]>([] as EnumOption[]);
  const { diagnosisCodeOptions, loading } = useDiagnosisCodes();
  const logger = useLogger();

  const onChange = (tags: EnumOption[]) => {
    setTags(tags);
    props.onChange(tags.map((tag) => tag.value));
  };

  useEffect(() => {
    const initialValue = loading
      ? value.map((v) => ({ name: 'Loading...', value: v }))
      : value
          .map(
            (option) => diagnosisCodeOptions.find((o) => o.value === option)!,
          )
          .filter(Boolean);

    if (initialValue?.length !== value?.length) {
      const codesFound = initialValue.map((v) => v.value);
      const codesNotFound = value.filter((v) => !codesFound.includes(v));
      logger.error(new Error('ICD10CodesSelector: Diagnosis codes not found'), {
        codesNotFound,
      });
    }

    setTags(initialValue);
  }, [loading]);

  return (
    <AutoCompleteWithTagV2
      disabled={loading || disabled}
      noOptionsText="Not found"
      placeholder="Search..."
      name={name}
      tags={tags}
      options={diagnosisCodeOptions.filter((o) => o.readonly !== true)}
      onChange={onChange}
      error={error}
      getOptionLabel={(option) => option.name}
      width={Width.FULL}
      className={className}
      tagClass={tagClass}
      data-testid={props['data-testid']}
    />
  );
};
