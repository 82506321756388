import { UserRole } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { useTeamCommunication } from 'app/collaboration/hooks/useTeamCommunication';
import {
  careTeamRoute,
  chatCollaborationRoute,
  contentRoute,
  demographicsRoute,
  documentsRoute,
  intakeRoute,
  prescriptionsRoute,
  summaryRoute,
  surveysV3Route,
} from 'app/patients/Routes';
import { selectUserRole } from 'app/state/user/selectors';
import { Routes } from 'app/top-nav/Routes';
import { TransientFeatureFlag, useFeatureFlags } from 'hooks/useFeatureFlags';
import { useMemberChartIndicators } from 'hooks/useMemberChartIndicators';
import { useOnMount } from 'hooks/useOnMount';
import React from 'react';
import { NavigationTabs, TabLink } from 'shared-components/tabs/NavigationTabs';
import navStyles from 'shared-components/tabs/NavigationTabs.module.scss';
import { isClinicalSupervisor, isPsychiatristOrSupervisor } from 'utils';

type Props = { id: string; pathPrefix: string };

export function MemberProfileNavigationTabsV2({
  id,
  pathPrefix,
}: Readonly<Props>) {
  const {
    showTeamNotification,
    updateUserMetadata,
    updateMetadataInput,
    createMetadataInput,
    createUserMetadata,
  } = useTeamCommunication(id);

  useOnMount(() => {
    if (updateMetadataInput !== undefined && updateUserMetadata !== undefined) {
      void updateUserMetadata(updateMetadataInput);
    }
    if (createMetadataInput !== undefined && createUserMetadata !== undefined) {
      void createUserMetadata(createMetadataInput);
    }
  });

  const navigationTabLinks = useMemberChartNavigationLinksV2(
    id,
    pathPrefix,
    showTeamNotification,
  );
  return (
    <nav className={navStyles.nav}>
      <NavigationTabs
        className={navStyles.tabsV2}
        links={navigationTabLinks}
        preserveQueries={true}
      />
    </nav>
  );
}

function useMemberChartNavigationLinksV2(
  id: string,
  pathPrefix: string,
  showTeamNotification: boolean,
) {
  const role = selectUserRole();
  const showChatCollaboration = useFeatureFlags().transientFeatureFlags[
    TransientFeatureFlag.CHAT_COLLABORATION
  ];
  const enableDocumentUpload = useFeatureFlags().transientFeatureFlags[
    TransientFeatureFlag.ENABLE_DOCUMENT_UPLOAD
  ];

  const showPrescriptionsTab = isPsychiatristOrSupervisor(role);

  const {
    showSurveyScoreIndicator,
    showIntakeIndicator,
  } = useMemberChartIndicators(id);
  const navigationTabLinks: TabLink[] = [
    { label: 'Summary', path: summaryRoute(pathPrefix, id) },
    { label: 'Content', path: contentRoute(pathPrefix, id) },
    {
      isNotificationVisible: showIntakeIndicator,
      label: 'Intake',
      path: intakeRoute(pathPrefix, id),
    },
    {
      isNotificationVisible: showSurveyScoreIndicator,
      label: 'Scores',
      path: surveysV3Route(pathPrefix, id),
    },
    {
      isNewFeature: false,
      isNotificationVisible: showTeamNotification,
      label: 'Team',
      path: careTeamRoute(pathPrefix, id),
    },
  ];

  if (showChatCollaboration) {
    navigationTabLinks.push({
      isNewFeature: false,
      label: 'Collaboration',
      path: chatCollaborationRoute(pathPrefix, id),
    });
  }

  if (enableDocumentUpload) {
    navigationTabLinks.push({
      label: 'Documents',
      path: documentsRoute(pathPrefix, id),
    });
  }

  const isSupervisorAndMSUI = pathPrefix.startsWith(Routes.MEMBERS);
  const _isSupervisor =
    (isClinicalSupervisor(role) || role === UserRole.MEMBER_SUPPORT) &&
    isSupervisorAndMSUI;
  if (_isSupervisor) {
    navigationTabLinks.push({
      label: 'Demographics',
      path: demographicsRoute(pathPrefix, id),
    });
  }

  if (showPrescriptionsTab) {
    navigationTabLinks.push({
      label: 'Prescriptions',
      path: prescriptionsRoute(pathPrefix, id),
    });
  }
  return navigationTabLinks;
}
