import {
  Metadata,
  Metadata_NoteStatus,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/Metadata';
import {
  TherapyIntakeSection,
  TherapyIntakeSectionName,
} from '@ginger.io/vault-clinical-notes/dist/TherapyIntakeSection';
import { GAD7SeverityMap, PHQ9SeverityMap } from 'app/patients/header/utils';
import {
  GADSurveyAnswer,
  PHQGADSurvey,
  PHQSurveyAnswer,
} from 'app/vault/api/ClinicalNotesAPI';
import moment from 'moment/moment';

import { TherapyIntakeNote } from '../api/TherapyIntakeNotesAPI';

export function newClinicalNoteMetadata(
  appointmentId: string,
  status: Metadata_NoteStatus = Metadata_NoteStatus.undefined_note_status,
): Metadata {
  return {
    appointmentId,
    status,
  };
}

export function getNonNullTherapyIntakeNoteSections(
  note: TherapyIntakeNote,
): TherapyIntakeSection[] {
  return Object.entries(note)
    .filter(([key, value]) => key in TherapyIntakeSectionName && value)
    .map(
      ([sectionName, sectionData]) =>
        ({
          data: sectionData,
          name: sectionName,
        } as TherapyIntakeSection),
    );
}

export function formatSurveyDetailFromClinicalNote(
  phqGadSurvey: PHQGADSurvey | null,
): string {
  if (
    !phqGadSurvey ||
    (phqGadSurvey.phq9s.length === 0 && phqGadSurvey.gad7s.length === 0)
  ) {
    return '';
  }
  const [latestPHQ, previousPHQ] = phqGadSurvey.phq9s;
  const [latestGAD, previousGAD] = phqGadSurvey.gad7s;
  if (latestGAD && latestPHQ && previousGAD && previousPHQ) {
    return getFollowUpSurveyText({
      latestGAD,
      latestPHQ,
      previousGAD,
      previousPHQ,
    });
  }

  return getIntakeSurveyText({ latestGAD, latestPHQ });
}

function getFollowUpSurveyText(params: {
  latestGAD: GADSurveyAnswer;
  latestPHQ: PHQSurveyAnswer;
  previousGAD: GADSurveyAnswer;
  previousPHQ: PHQSurveyAnswer;
}) {
  const { previousPHQ, previousGAD, latestGAD, latestPHQ } = params;
  const { total: previousPHQTotal } = previousPHQ;
  const { total: latestPHQTotal, severity: phqSeverity, timestamp } = latestPHQ;
  const { total: previousGADTotal } = previousGAD;
  const { total: latestGADTotal, severity: gadSeverity } = latestGAD;
  const dateStr = moment(timestamp).format('M/D/YYYY');
  const phqChange = latestPHQTotal - previousPHQTotal;
  const gadChange = latestGADTotal - previousGADTotal;

  const phqSeverityText = phqSeverity ? PHQ9SeverityMap[phqSeverity] : '';
  const gadSeverityText = gadSeverity ? GAD7SeverityMap[gadSeverity] : '';
  const phqAbsChange = Math.abs(phqChange);
  const gadAbsChange = Math.abs(gadChange);

  const gadDirection = gadChange > 0 ? 'increased' : 'decreased';
  const phqDirection = phqChange > 0 ? 'increased' : 'decreased';

  const phqInfo = `PHQ-9 score ${phqDirection} ${phqAbsChange} points to ${latestPHQTotal}/27 (indicating "${phqSeverityText}" depressive symptoms)`;
  const gadInfo = `GAD-7 score ${gadDirection} ${gadAbsChange} points to ${latestGADTotal}/21 (indicating "${gadSeverityText}" anxious symptoms)`;

  return `Member updated survey scores ${dateStr}. ${phqInfo}. ${gadInfo}`;
}

function getIntakeSurveyText(params: {
  latestGAD: GADSurveyAnswer;
  latestPHQ: PHQSurveyAnswer;
}) {
  const { latestGAD, latestPHQ } = params;
  const { total: latestGADTotal, severity: gadSeverity, timestamp } = latestGAD;
  const { total: latestPHQTotal, severity: phqSeverity } = latestPHQ;

  const dateStr = moment(timestamp).format('M/D/YYYY');
  const phqSeverityText = phqSeverity ? PHQ9SeverityMap[phqSeverity] : '';
  const gadSeverityText = gadSeverity ? GAD7SeverityMap[gadSeverity] : '';

  const phqInfo = `PHQ-9 score is ${latestPHQTotal}/27 (indicating "${phqSeverityText}" depressive symptoms).`;
  const gadInfo = `GAD-7 score is ${latestGADTotal}/21 (indicating "${gadSeverityText}" anxious symptoms).`;

  return `Initial survey scores completed ${dateStr}. ${phqInfo} ${gadInfo}`;
}
