import {
  CareTeam,
  Coach,
  CurrentCoach,
  Psychiatrist,
  Therapist,
} from 'app/care-team/types';
import { createAction } from 'redux-reloaded';

import { ActiveView } from './schema';

export enum Actions {
  RELOAD_ADD_SI_COACH_BUTTON = 'RELOAD_ADD_SI_COACH_BUTTON',
  LOAD_CARE_TEAM = 'ON_LOAD_CARE_TEAM',
  SUBSCRIBE_COACH_CHANNELS = 'SUBSCRIBE_COACH_CHANNELS',
  UNSUBSCRIBE_COACH_CHANNELS = 'UNSUBSCRIBE_COACH_CHANNELS',
  LOAD_MEMBER_GUARDIAN_RELATIONSHIP = 'LOAD_MEMBER_GUARDIAN_RELATIONSHIP',
  LOAD_MEMBER_GUARDIAN_RELATIONSHIP_REQUEST = 'LOAD_MEMBER_GUARDIAN_RELATIONSHIP_REQUEST',
  LOAD_MEMBER_GUARDIAN_RELATIONSHIP_SUCCESS = 'LOAD_MEMBER_GUARDIAN_RELATIONSHIP_SUCCESS',
  LOAD_MEMBER_GUARDIAN_RELATIONSHIP_FAILED = 'LOAD_MEMBER_GUARDIAN_RELATIONSHIP_FAILED',
  LOAD_CARE_TEAM_REQUEST = 'LOAD_CARE_TEAM_REQUEST',
  LOAD_CARE_TEAM_SUCCESS = 'LOAD_CARE_TEAM_SUCCESS',
  LOAD_CARE_TEAM_FAILED = 'LOAD_CARE_TEAM_FAILED',
  SELECT_ACTIVE_MEMBER_PROFILE = 'SELECT_ACTIVE_MEMBER_PROFILE',
  CLEAR_ACTIVE_MEMBER_PROFILE = 'CLEAR_ACTIVE_MEMBER_PROFILE',
  TOGGLE_ACTIVE_CARE_TEAM_VIEW = 'TOGGLE_ACTIVE_CARE_TEAM_VIEW',
}

export const reloadAddSICoachButton = createAction(
  Actions.RELOAD_ADD_SI_COACH_BUTTON,
  (payload: { memberId: string }) => payload,
);

/**
 * Action to load the care team for a member
 */
export const loadCareTeam = createAction(
  Actions.LOAD_CARE_TEAM,
  (payload: { memberId: string; forceRefresh: boolean }) => payload,
);

export const subscribeCoachChannels = createAction(
  Actions.SUBSCRIBE_COACH_CHANNELS,
  (payload: { coaches: CurrentCoach[] }) => payload,
);

export const unsubscribeCoachChannels = createAction(
  Actions.UNSUBSCRIBE_COACH_CHANNELS,
);

export const loadCareTeamRequest = createAction(
  Actions.LOAD_CARE_TEAM_REQUEST,
  (payload: { memberId: string }) => payload,
);

export const loadCareTeamSuccess = createAction(
  Actions.LOAD_CARE_TEAM_SUCCESS,
  (payload: { careTeam: CareTeam | null }) => payload,
);

export const loadCareTeamFailed = createAction(
  Actions.LOAD_CARE_TEAM_FAILED,
  (payload: { error: Error }) => payload,
);

export const selectActiveMemberProfile = createAction(
  Actions.SELECT_ACTIVE_MEMBER_PROFILE,
  (payload: { careTeamMember: Coach | Therapist | Psychiatrist | null }) =>
    payload,
);

export const clearActiveMemberProfile = createAction(
  Actions.CLEAR_ACTIVE_MEMBER_PROFILE,
);

export const toggleActiveCareTeamView = createAction(
  Actions.TOGGLE_ACTIVE_CARE_TEAM_VIEW,
  (payload: { view?: ActiveView }) => payload,
);
