import DOMPurify from 'dompurify';

export const ALLOWED_FILE_TYPES = [
  'image/jpeg',
  'image/png',
  'image/jpg',
  'image/svg+xml',
  'application/pdf',
  'application/html',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/html',
  'text/plain',
];

export const SANITIZE_FILE_TYPES = [
  'image/svg+xml',
  'application/html',
  'text/html',
  'text/plain',
];

export const sanitizeFile = async (file: File) => {
  if (SANITIZE_FILE_TYPES.includes(file.type)) {
    const fileContent = await file.text();
    const sanitizedText = DOMPurify.sanitize(fileContent);
    const sanitizedContent = new File([sanitizedText], file.name, {
      type: file.type,
    });

    return sanitizedContent;
  }
  return file;
};
