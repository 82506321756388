import { SearchMember_searchMember as Member } from '@headspace/carehub-graphql/dist/member-support/generated/SearchMember';
import React from 'react';
import styles from 'styles/LeftRail.module.scss';

type Props = {
  members: Member[];
  selectedMemberId: string | null;
  onSelect: (memberId: string) => void;
};

const List = (props: Props) => {
  return (
    <>
      {props.members.map(
        ({
          firstName,
          lastName,
          billingFirstName,
          billingLastName,
          email,
          id,
        }) => {
          const className =
            props.selectedMemberId === id ? styles.active : undefined;
          return (
            <button
              data-testid={`member-${id}`}
              key={id}
              className={className}
              onClick={() => props.onSelect(id)}
            >
              <div>
                {firstName ?? billingFirstName} {lastName ?? billingLastName} (
                {id})<small>{email}</small>
              </div>
            </button>
          );
        },
      )}
    </>
  );
};

export function MemberResultList(props: Props) {
  return (
    <div data-testid="memberResultList" className={styles.patientList}>
      <h3>Search Results</h3>
      <List {...props} />
      {!props.members.length && (
        <button className={styles.emptyList}>
          <div>No members found</div>
        </button>
      )}
    </div>
  );
}
