import {
  AppointmentCadence,
  AvailabilityTypeEnum,
  CoachingSessionEventType,
  CoachingSessionModificationScope,
  CreateMemberCoachingSessionInput,
  CreateMemberCoachingSessionRecurrenceInput,
  RecurrentSessionSpec,
  UpdateMemberCoachingSessionInput,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';
import {
  GetMemberUpcomingCoachingSessions_getMemberUpcomingCoachingSessions_coachingSessions_recurrence as Recurrence,
  GetMemberUpcomingCoachingSessions_getMemberUpcomingCoachingSessions_coachingSessions_sessions as CoachingSession,
} from '@headspace/carehub-graphql/dist/scheduler/generated/GetMemberUpcomingCoachingSessions';
import {
  CreationFields,
  DisplayTimes,
  IntakeOrFollowUpSession,
  NewCoachingSessionParams,
  RECURRENCE_VALUES,
  RecurrenceEnum,
  SessionDisplayOptions,
  SessionFormat,
  SessionInfo,
  SessionOption,
  SessionType,
  StartDate,
  StartTime,
  TimeSlot,
} from 'app/member-chart-cards/scheduler/types';
import { SelectedSessionTimes } from 'app/state/features/scheduler/types';
import { ReactComponent as ChatBubbleIcon } from 'assets/icons/chat-bubble.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/video-icon.svg';
import moment from 'moment-timezone';

import styles from './SchedulerCard.module.scss';

export const DEFAULT_SESSION_COUNT_OLD = 4; // remove when enable_coaching_scheduler is enabled for all
export const DEFAULT_SESSION_COUNT = 1;
export const MAX_CONCURRENT_CHAT_SESSIONS = 2;
export const MAX_CONCURRENT_VIDEO_SESSIONS = 1;

export function getSessionDurationInMinutes(
  startTime: string,
  endTime: string,
) {
  const start = moment(startTime);
  const end = moment(endTime);
  return moment.duration(end.diff(start)).asMinutes();
}

export function getSessionDuration(
  startTime: string,
  endTime: string,
  occurrence: number,
) {
  const duration = getSessionDurationInMinutes(startTime, endTime);
  switch (occurrence) {
    case 0:
      return `${duration}  min`;
    case 1:
      return `Weekly • ${duration} min`;
    default:
      return `Every ${occurrence} weeks • ${duration} min`;
  }
}

export function get15MinuteIncrement(minute: number): number {
  // Don't round 45 or higher up; keep it at 45 so that we don't have to also increment the hour and, potentially, day.
  if (minute >= 45) {
    return 45;
  }

  return Math.ceil(minute / 15) * 15;
}

export const DATE_TIME_FORMAT_WITHOUT_TZ = 'YYYY-MM-DDTHH:mm';

export function formatAs2Digits(number: number): string {
  return String(number).padStart(2, '0');
}

export function getMomentDurationMinutes(
  start: moment.Moment,
  end: moment.Moment,
): number {
  return moment(end).diff(moment(start), 'minutes');
}

/**
 * Formats StartDate and StartTime to a datetime string
 *
 * @return string datetime without timezone, in DATE_TIME_FORMAT_WITHOUT_TZ format
 */
export function toDateTimeString(
  startDate: StartDate,
  startTime: StartTime,
): string {
  const { year, month, date } = startDate;
  const { hour, minute } = startTime;
  return `${year}-${formatAs2Digits(month)}-${formatAs2Digits(
    date,
  )}T${formatAs2Digits(hour)}:${formatAs2Digits(minute)}`;
}

export function formatWith15MinuteIncrement(
  moment: moment.Moment,
): moment.Moment {
  const minute = get15MinuteIncrement(moment.minute());
  return moment.clone().minute(minute);
}

export const eventTypeToSessionTypeMap: Record<
  CoachingSessionEventType,
  SessionType
> = {
  [CoachingSessionEventType.EVENT_TYPE_INITIAL_CONSULT]: SessionType.INTAKE,
  [CoachingSessionEventType.EVENT_TYPE_COACHING_SESSION]: SessionType.FOLLOW_UP,
  [CoachingSessionEventType.EVENT_TYPE_REMINDER]: SessionType.REMINDER,
};

export const sessionTypeToEventTypeMap: Record<
  SessionType,
  CoachingSessionEventType
> = Object.fromEntries(
  Object.entries(eventTypeToSessionTypeMap).map(([key, value]) => [value, key]),
) as Record<SessionType, CoachingSessionEventType>;

export function getInitialValues(params: {
  coachTimeZone: string;
  existingCoachingSession?: CoachingSession;
  recurrence?: Recurrence | null;
}): CreationFields {
  const { coachTimeZone, existingCoachingSession, recurrence } = params;
  let startDTCoachLocal: moment.Moment;
  let endDTCoachLocal: moment.Moment;
  const nowDateTime = moment();
  const nowCoachLocal = nowDateTime.tz(coachTimeZone);

  if (existingCoachingSession && !existingCoachingSession.endTime) {
    const utcDate = moment(existingCoachingSession.startTime).tz('UTC');
    startDTCoachLocal = formatWith15MinuteIncrement(
      moment()
        .tz(coachTimeZone)
        .year(utcDate.year())
        .month(utcDate.month())
        .date(utcDate.date())
        .hour(nowCoachLocal.hour())
        .minute(nowCoachLocal.minute())
        .second(0),
    );
    endDTCoachLocal = startDTCoachLocal.clone().add(30, 'minutes');
  } else if (existingCoachingSession && existingCoachingSession.endTime) {
    startDTCoachLocal = moment(existingCoachingSession.startTime).tz(
      coachTimeZone,
    );
    endDTCoachLocal = moment(existingCoachingSession.endTime).tz(coachTimeZone);
  } else {
    startDTCoachLocal = formatWith15MinuteIncrement(nowCoachLocal);
    endDTCoachLocal = startDTCoachLocal.clone().add(30, 'minutes');
  }

  const sessionType = existingCoachingSession?.eventType
    ? eventTypeToSessionTypeMap[existingCoachingSession.eventType]
    : SessionType.FOLLOW_UP;

  const sessionFormat = mapStringToSessionFormat(
    existingCoachingSession?.sessionFormat,
  );

  const everyNWeeks =
    (recurrence?.everyNWeeks as RecurrenceEnum) ??
    RecurrenceEnum.DOES_NOT_REPEAT;
  const numOccurrences =
    everyNWeeks === RecurrenceEnum.DOES_NOT_REPEAT
      ? DEFAULT_SESSION_COUNT
      : recurrence?.numOccurrences || DEFAULT_SESSION_COUNT;

  return {
    duration: getMomentDurationMinutes(startDTCoachLocal, endDTCoachLocal),
    everyNWeeks,
    id: existingCoachingSession?.id,
    numOccurrences,
    sessionFormat,
    sessionStartTime: {
      hour: startDTCoachLocal.hour(),
      minute: startDTCoachLocal.minute(),
    },
    sessionType,
    startDate: {
      date: startDTCoachLocal.date(),
      month: startDTCoachLocal.month() + 1,
      year: startDTCoachLocal.year(),
    },
  };
}

export function getSessionStartAndEndDates(
  sessionInfo: CreationFields,
  coachTimezone: string,
): {
  startMoment: moment.Moment;
  startFormatted: string;
  endFormatted: string;
  startDateTime: ISODateString;
  endDateTime: ISODateString;
} {
  const {
    startDate,
    sessionStartTime,
    numOccurrences,
    everyNWeeks,
  } = sessionInfo;

  const isToday = moment()
    .tz(coachTimezone)
    .startOf('day')
    .isSame(
      moment.tz(
        {
          day: startDate.date,
          month: startDate.month - 1,
          year: startDate.year,
        },
        coachTimezone,
      ),
      'day',
    );

  const startMoment = moment.tz(
    {
      day: startDate.date,
      hour: isToday ? sessionStartTime.hour : 0,
      minute: isToday ? sessionStartTime.minute : 0,
      month: startDate.month - 1,
      second: 0,
      year: startDate.year,
    },
    coachTimezone,
  );

  let endMoment = startMoment.clone().endOf('day');
  if (everyNWeeks > 0 && numOccurrences > 1) {
    endMoment = startMoment
      .clone()
      .add((numOccurrences - 1) * everyNWeeks, 'weeks')
      .endOf('day');
  }

  const startFormatted = startMoment.format('MMM D, YYYY');
  const endFormatted = endMoment.format('MMM D, YYYY');
  const startDateTime = startMoment.utc().format();
  const endDateTime = endMoment.utc().format();

  return {
    endDateTime,
    endFormatted,
    startDateTime,
    startFormatted,
    startMoment,
  };
}

export function formatSessionInfo(
  sessionInfo: CreationFields,
  coachTimezone: string,
): SessionInfo {
  const { numOccurrences, everyNWeeks, duration } = sessionInfo;

  const {
    startMoment,
    startFormatted,
    endFormatted,
  } = getSessionStartAndEndDates(sessionInfo, coachTimezone);
  const isNonRecurrentSession = everyNWeeks === RecurrenceEnum.DOES_NOT_REPEAT;

  let titleText = startMoment.format('dddd');
  if (!isNonRecurrentSession) {
    titleText =
      everyNWeeks > 1
        ? `Every ${everyNWeeks} weeks on ${titleText}s`
        : `Weekly on ${titleText}s`;
  }

  return {
    duration: `${duration} min`,
    endDate: isNonRecurrentSession ? undefined : endFormatted,
    numOfSessions: `${isNonRecurrentSession ? 1 : numOccurrences} session(s)`,
    startDate: startFormatted,
    titleText,
  };
}

export function getRecurrenceSpec(
  session: CreationFields,
): RecurrentSessionSpec | null {
  const { everyNWeeks, numOccurrences } = session;

  if (everyNWeeks === 0 || numOccurrences === 1) {
    return null; // Not a recurrent session
  }

  let cadence: AppointmentCadence;

  switch (everyNWeeks) {
    case 1:
      cadence = AppointmentCadence.WEEKLY;
      break;
    case 2:
      cadence = AppointmentCadence.EVERY_2_WEEKS;
      break;
    case 3:
      cadence = AppointmentCadence.EVERY_3_WEEKS;
      break;
    case 4:
      cadence = AppointmentCadence.EVERY_4_WEEKS;
      break;
    default:
      throw new Error('Unsupported cadence');
  }

  return {
    cadence,
    numberOfSessions: numOccurrences,
  };
}

export function getCoachAndMemberDisplayTimes(params: {
  referenceTime: moment.Moment;
  coachTimezone: string;
  memberTimezone: string;
  format?: string;
}): DisplayTimes {
  const {
    referenceTime,
    coachTimezone,
    memberTimezone,
    format = 'h:mm A',
  } = params;
  return {
    displayCoachStartTime: referenceTime.tz(coachTimezone).format(format),
    displayMemberStartTime: referenceTime.tz(memberTimezone).format(format),
  };
}

export function generateTimeSlots(params: {
  startTime: ISODateString;
  endTime: ISODateString;
  coachTimezone?: string;
  memberTimezone?: string;
  duration?: number;
  increment?: number;
}): TimeSlot[] {
  const {
    startTime,
    endTime,
    coachTimezone = 'UTC',
    memberTimezone = 'UTC',
    duration = 30,
    increment = 15,
  } = params;
  const startTimeMoment = moment(startTime);
  const endTimeMoment = moment(endTime);

  const latestStartTime = endTimeMoment.clone().subtract(duration, 'minutes');

  const timeSlots: TimeSlot[] = [];
  const currentTime = startTimeMoment.clone();

  while (currentTime.isSameOrBefore(latestStartTime)) {
    const {
      displayCoachStartTime,
      displayMemberStartTime,
    } = getCoachAndMemberDisplayTimes({
      coachTimezone,
      memberTimezone,
      referenceTime: currentTime,
    });

    timeSlots.push({
      coachTimezone: currentTime.tz(coachTimezone).format('z'),
      displayCoachStartTime,
      displayMemberStartTime,
      id: currentTime.toISOString(),
      memberTimezone: currentTime.tz(memberTimezone).format('z'),
    });
    currentTime.add(increment, 'minutes');
  }

  return timeSlots;
}

export function getUniqueTimeSlots(timeSlots: TimeSlot[]) {
  const uniqueTimes = new Set();
  const uniqueTimeSlots: TimeSlot[] = [];

  timeSlots.forEach((timeSlot) => {
    if (!uniqueTimes.has(timeSlot.displayCoachStartTime)) {
      uniqueTimes.add(timeSlot.displayCoachStartTime);
      uniqueTimeSlots.push(timeSlot);
    }
  });

  return uniqueTimeSlots;
}

export const SESSION_DISPLAY_OPTION_TO_SESSION_OPTION: Record<
  SessionDisplayOptions,
  SessionOption
> = {
  [SessionDisplayOptions.CHAT_FOLLOW_UP]: {
    sessionFormat: SessionFormat.CHAT,
    sessionType: SessionType.FOLLOW_UP,
  },
  [SessionDisplayOptions.CHAT_DISCOVERY]: {
    sessionFormat: SessionFormat.CHAT,
    sessionType: SessionType.INTAKE,
  },
  [SessionDisplayOptions.VIDEO_FOLLOW_UP]: {
    sessionFormat: SessionFormat.VIDEO,
    sessionType: SessionType.FOLLOW_UP,
  },
  [SessionDisplayOptions.VIDEO_DISCOVERY]: {
    sessionFormat: SessionFormat.VIDEO,
    sessionType: SessionType.INTAKE,
  },
};

export const CHAT_SESSION_OPTIONS: Record<
  SessionFormat.CHAT,
  Record<IntakeOrFollowUpSession, SessionDisplayOptions>
> = {
  [SessionFormat.CHAT]: {
    [SessionType.INTAKE]: SessionDisplayOptions.CHAT_DISCOVERY,
    [SessionType.FOLLOW_UP]: SessionDisplayOptions.CHAT_FOLLOW_UP,
  },
};

export const VIDEO_SESSION_OPTIONS: Record<
  SessionFormat.VIDEO,
  Record<IntakeOrFollowUpSession, SessionDisplayOptions>
> = {
  [SessionFormat.VIDEO]: {
    [SessionType.INTAKE]: SessionDisplayOptions.VIDEO_DISCOVERY,
    [SessionType.FOLLOW_UP]: SessionDisplayOptions.VIDEO_FOLLOW_UP,
  },
};

export const SESSION_OPTIONS: Record<
  SessionFormat,
  Record<IntakeOrFollowUpSession, SessionDisplayOptions>
> = {
  ...CHAT_SESSION_OPTIONS,
  ...VIDEO_SESSION_OPTIONS,
};

export const sessionFormatToAvailabilityTypeMap: Record<
  SessionFormat,
  AvailabilityTypeEnum
> = {
  [SessionFormat.CHAT]: AvailabilityTypeEnum.CHAT,
  [SessionFormat.VIDEO]: AvailabilityTypeEnum.VIDEO,
};

export const generateSessionOptionsWithIcons = (
  sessionOptionMap: Partial<
    Record<
      SessionFormat,
      Record<IntakeOrFollowUpSession, SessionDisplayOptions>
    >
  >,
): { name: JSX.Element; value: SessionDisplayOptions }[] => {
  return Object.entries(sessionOptionMap).flatMap(
    ([sessionFormat, sessionTypes]) =>
      Object.entries(sessionTypes).map(([_, sessionDisplayOption]) => ({
        name: (
          <div className={styles.optionContainer}>
            {sessionFormat === SessionFormat.CHAT ? (
              <ChatBubbleIcon className={styles.optionIcon} />
            ) : (
              <VideoIcon className={styles.optionIcon} />
            )}
            <span className={styles.optionText}>{sessionDisplayOption}</span>
          </div>
        ),
        value: sessionDisplayOption,
      })),
  );
};

// todo: Update query return type to enum instead of string (EVOL-3979)
export const mapStringToSessionFormat = (
  format?: string | null,
): SessionFormat => {
  switch (format?.toLocaleLowerCase()) {
    case 'video':
      return SessionFormat.VIDEO;
    case 'chat':
      return SessionFormat.CHAT;
    default:
      return SessionFormat.CHAT;
  }
};

// remove when enable_coaching_scheduler is enabled for all
export function startEndTimesToMoment(
  formData: CreationFields,
  coachTimeZone: string,
): { startTime: moment.Moment; endTime: moment.Moment } {
  const { startDate, sessionStartTime, duration } = formData;
  const dateTime = toDateTimeString(startDate, sessionStartTime);
  const utcStartTime = moment
    .tz(dateTime, DATE_TIME_FORMAT_WITHOUT_TZ, coachTimeZone)
    .tz('utc');
  const utcEndTime = utcStartTime.clone().add(duration, 'minutes');
  return {
    endTime: utcEndTime,
    startTime: utcStartTime,
  };
}

export function toNewCoachingSession(
  params: NewCoachingSessionParams,
): CreateMemberCoachingSessionInput {
  const { coachTimeZone, memberTimeZone, memberId, formData } = params;
  let startTime: ISODateString;
  let endTime: ISODateString;

  if (coachTimeZone) {
    const computedTimes = startEndTimesToMoment(formData, coachTimeZone);
    startTime = computedTimes.startTime.toISOString();
    endTime = computedTimes.endTime.toISOString();
  } else if (params.startTime && params.endTime) {
    startTime = params.startTime;
    endTime = params.endTime;
  } else {
    throw new Error(
      'Either coachTimeZone or both startTime and endTime must be provided.',
    );
  }

  const { everyNWeeks, numOccurrences, sessionFormat, sessionType } = formData;
  let recurrenceData: CreateMemberCoachingSessionRecurrenceInput | null = null;
  if (RECURRENCE_VALUES.includes(everyNWeeks) && numOccurrences) {
    recurrenceData = {
      everyNWeeks,
      numOccurrences,
    };
  }
  return {
    endTime,
    eventType: sessionTypeToEventTypeMap[sessionType],
    memberId,
    memberTimezone: memberTimeZone,
    recurrence: recurrenceData,
    sessionFormat: sessionFormatToAvailabilityTypeMap[sessionFormat],
    startTime,
  };
}

export function toUpdatedCoachingSession(
  formData: CreationFields,
  coachTimezone: string,
  memberTimeZone: string,
  modificationScope: CoachingSessionModificationScope,
  selectedSessionTimes?: SelectedSessionTimes,
): UpdateMemberCoachingSessionInput {
  if (!formData.id) {
    throw new Error('An existing CoachingSession must have an ID!');
  }

  let startTimeISO = selectedSessionTimes?.startTime ?? '';
  let endTimeISO = selectedSessionTimes?.endTime ?? '';

  if (!selectedSessionTimes) {
    const { startTime, endTime } = startEndTimesToMoment(
      formData,
      coachTimezone,
    );
    startTimeISO = startTime.toISOString();
    endTimeISO = endTime.toISOString();
  }

  return {
    coachingSessionId: formData.id,
    endTime: endTimeISO,
    memberTimezone: memberTimeZone,
    modificationScope,
    startTime: startTimeISO,
  };
}

export function validateNumOccurrences(
  value: number,
  data: CreationFields,
): string | undefined {
  if (RECURRENCE_VALUES.includes(data.everyNWeeks) && !value) {
    return 'This field is required.';
  }
  if (
    data.everyNWeeks === RecurrenceEnum.DOES_NOT_REPEAT ||
    (value >= 2 && value <= 12)
  ) {
    return undefined;
  }
  return 'Must be between 2 and 12.';
}
