import MaterialButton from '@mui/material/Button';
import { buttonText } from 'i18n/en/populateAppointmentDuration.json';
import React from 'react';
import Tooltip from 'shared-components/tooltip/Tooltip';

import styles from './PopulateAppointmentDurationButton.module.scss';

type Props = {
  disabled?: boolean;
  onClick?: () => void;
  tooltipTitle?: string;
};

const PopulateAppointmentDurationButton = (props: Props) => {
  const { disabled, onClick, tooltipTitle } = props;
  return (
    <span className={styles.root}>
      <Tooltip
        classes={{ popper: styles.popper, tooltip: styles.tooltip }}
        title={tooltipTitle}
        enterDelay={0}
        enterTouchDelay={0}
        placement="right"
      >
        <span>
          <MaterialButton
            className={styles.button}
            disabled={disabled}
            onClick={onClick}
          >
            {buttonText}
          </MaterialButton>
        </span>
      </Tooltip>
    </span>
  );
};

export default PopulateAppointmentDurationButton;
