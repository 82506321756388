import {
  field,
  Fields,
  numberField,
  stringField,
  useForm,
} from '@ginger.io/react-use-form';
import { ClinicianRole } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/ClinicianRole';
import {
  OutOfSessionNote,
  OutOfSessionNote_ContactReason,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/OutOfSessionNote';
import { Metadata_NoteStatus as NoteStatus } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/Metadata';
import { UserRole } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import Container from '@mui/material/Container';
import {
  DateField,
  EnumDropdownField,
  TextAreaField,
  TextBoxField,
  TimeRangeField,
  YesOrNoField,
} from 'app/notes-ui/forms/fields';
import { NoteFormControlLabel } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { SaveButton } from 'app/notes-ui/forms/form-controls/SaveButton';
import { getDateValue, getTimeValue } from 'app/notes-ui/forms/utils';
import { NoteType } from 'app/notes-ui/NoteHeader';
import { NoteHeaderV2 } from 'app/notes-ui/NoteHeaderV2';
import {
  validateHour,
  validateMinute,
  validateTimeField,
  validateTimeFieldDifference,
} from 'app/notes-ui/shared/ValidationRules';
import { MemberHeaderBar } from 'app/patients/header/MemberHeaderBar';
import {
  clickedSaveDraftOOSNote,
  clickedSignAndLockOOSNote,
  reasonForContactSelected,
} from 'app/state/amplitude/actions/notes';
import {
  NoteRoutes,
  patientNoteRoute,
  TopLevelRoutes,
} from 'app/top-nav/Routes';
import {
  clinicianRoleEnumMap,
  NonAppointmentNoteAction,
} from 'app/vault/api/NonAppointmentNotesAPI';
import { useSnackNotification } from 'hooks/useSnackNotification';
import Messages from 'i18n/en/notes.json';
import moment, { Moment } from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Size, Width } from 'types/StyleTypes';
import { isClinicalSupervisor, isClinician } from 'utils';
import { labelFromEnumValue } from 'utils/notes';

import styles from './OutOfSessionNoteContainer.module.scss';

type Props = {
  patientId: string;
  clinicianId: string;
  clinicianName: string;
  patientName: string;
  timezone: string;
  noteStatus: NoteStatus;
  vaultItemId?: string;
  settingsEnabled?: boolean;
  onSubmit: (
    data: OutOfSessionNote,
    action: NonAppointmentNoteAction,
    itemId?: string,
  ) => Promise<{ itemId?: string }>;
  initialValue?: OutOfSessionNote;
  clinicianRole?: UserRole;
  allowedGroupWriteAccess?: boolean;
  navigateTo: (url: string) => void;
};

function OutOfSessionNoteForm(props: {
  disabled?: boolean;
  patientId: string;
  timezone: string;
  settingsEnabled: boolean;
  fields: Fields<OutOfSessionNote>;
}) {
  const { disabled, fields, timezone, settingsEnabled, patientId } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      fields.reasonForContact.value !==
      OutOfSessionNote_ContactReason.undefined_reason
    ) {
      dispatch(
        reasonForContactSelected({
          patientId,
          reason:
            labelFromEnumValue(
              OutOfSessionNote_ContactReason,
              fields.reasonForContact.value,
            ) ?? '',
        }),
      );
    }
  }, [dispatch, fields.reasonForContact.value]);

  return (
    <Container className={styles.root} maxWidth="sm">
      <div className={styles.flex}>
        <div>
          <NoteFormControlLabel label="Date*">
            <DateField
              disabled={disabled}
              className={styles.datePicker}
              testId="dateOfContact"
              name="dateOfContact"
              field={fields.dateOfContact}
            />
          </NoteFormControlLabel>
        </div>
        <div className={`${styles.flex} ${styles.flexColumn}`}>
          <TimeRangeField
            testId="durationOfContact"
            label="Time*"
            disabled={disabled}
            field={fields.durationOfContact}
          />
          {settingsEnabled && (
            <div className={styles.timezone}>
              <NavLink to={TopLevelRoutes.SETTINGS}>{timezone}</NavLink>
            </div>
          )}
        </div>
      </div>
      <NoteFormControlLabel label="Individual Contacted">
        <TextBoxField
          disabled={disabled}
          testId="individualContacted"
          label=""
          field={fields.individualContacted}
        />
      </NoteFormControlLabel>
      <NoteFormControlLabel label="Reason for contact*">
        <EnumDropdownField
          disabled={disabled}
          testId="reasonForContact"
          options={OutOfSessionNote_ContactReason}
          field={fields.reasonForContact}
          width={Width.FULL}
          size={Size.MD}
        />
      </NoteFormControlLabel>
      <TextAreaField
        tooltip="A brief summary of contact and plan of action"
        disabled={disabled}
        testId="topicsDiscussed"
        label="Summary of Contact and Outcome*"
        field={fields.topicsDiscussed}
        width={Width.FULL}
      />
      <YesOrNoField
        testId="releaseOfInformationObtainedIfIndicated"
        label="Release of information obtained if indicated?"
        field={fields.releaseOfInformationObtainedIfIndicated}
      />
      {disabled && fields.purposeOfNote.value && (
        <NoteFormControlLabel label="Purpose of note*">
          <TextBoxField
            disabled={disabled}
            testId="purposeOfNote"
            label=""
            field={fields.purposeOfNote}
          />
        </NoteFormControlLabel>
      )}
      {disabled && fields.nextSteps.value && (
        <TextAreaField
          disabled={disabled}
          testId="nextSteps"
          label="Next steps/plan*"
          field={fields.nextSteps}
        />
      )}
    </Container>
  );
}

export function OutOfSessionNoteContainer(props: Props) {
  const {
    initialValue,
    patientId,
    clinicianId,
    clinicianName,
    timezone,
    patientName,
    vaultItemId,
    noteStatus,
    settingsEnabled = false,
    clinicianRole = UserRole.CLINICIAN,
    allowedGroupWriteAccess = false,
  } = props;
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const {
    showErrorNotification,
    showSuccessNotification,
  } = useSnackNotification();
  const { fields, validate, getValue } = useForm<OutOfSessionNote>(
    {
      clinicianId: stringField({ default: clinicianId }),
      clinicianRole: field<ClinicianRole>({
        default: clinicianRoleEnumMap[clinicianRole],
      }),
      dateOfContact: {
        day: field(),
        month: field(),
        year: field(),
      },
      durationOfContact: {
        endTime: {
          hour: field({ rules: [validateHour] }),
          minute: field({ rules: [validateMinute] }),
          second: numberField({
            rules: [
              (_, state: OutOfSessionNote) =>
                validateTimeField(state.durationOfContact?.endTime),
              (_, state: OutOfSessionNote) =>
                validateTimeFieldDifference(
                  state.durationOfContact?.startTime,
                  state.durationOfContact?.endTime,
                ),
            ],
          }),
        },
        startTime: {
          hour: field({ rules: [validateHour] }),
          minute: field({ rules: [validateMinute] }),
          second: numberField({
            rules: [
              (_, state: OutOfSessionNote) =>
                validateTimeField(state.durationOfContact?.startTime),
            ],
          }),
        },
      },
      individualContacted: stringField({ rules: [] }),
      nextSteps: stringField({ rules: [] }),
      patientId: stringField({ default: patientId }),
      purposeOfNote: stringField({ rules: [] }),
      reasonForContact: field(),
      releaseOfInformationObtainedIfIndicated: field(),
      topicsDiscussed: stringField(),
    },
    initialValue
      ? formatOOSNoteDatetimeTZ(initialValue, 'UTC', timezone)
      : undefined,
  );

  const onNoteLocked = async () => {
    const isValid = await validate();
    if (!isValid || noteStatus === NoteStatus.signed_and_locked_note) return;
    dispatch(clickedSignAndLockOOSNote({ patientId }));
    if (vaultItemId && noteStatus === NoteStatus.draft_note) {
      await props.onSubmit(
        formatOOSNoteDatetimeTZ(getValue(), timezone, 'UTC'),
        NonAppointmentNoteAction.LOCK,
        vaultItemId,
      );
    } else {
      const { itemId } = await props.onSubmit(
        formatOOSNoteDatetimeTZ(getValue(), timezone, 'UTC'),
        NonAppointmentNoteAction.CREATE_AND_LOCK,
      );
      props.navigateTo(
        patientNoteRoute(patientId, NoteRoutes.OUT_OF_SESSION, itemId),
      );
    }
  };

  const onSubmit = async () => {
    try {
      dispatch(clickedSaveDraftOOSNote({ patientId }));
      const isValid = await validate();
      if (!isValid) return;
      setSaving(true);
      if (vaultItemId && noteStatus === NoteStatus.draft_note) {
        await props.onSubmit(
          formatOOSNoteDatetimeTZ(getValue(), timezone, 'UTC'),
          NonAppointmentNoteAction.UPDATE,
          vaultItemId,
        );
      } else {
        const { itemId } = await props.onSubmit(
          formatOOSNoteDatetimeTZ(getValue(), timezone, 'UTC'),
          NonAppointmentNoteAction.CREATE,
        );
        props.navigateTo(
          patientNoteRoute(patientId, NoteRoutes.OUT_OF_SESSION, itemId),
        );
      }
      showSuccessNotification(Messages.savedOutOfSessionNoteSuccessfully);
    } catch (e) {
      showErrorNotification(Messages.failureToSaveOutOfSessionNote);
    } finally {
      setSaving(false);
    }
  };

  const isLocked = noteStatus === NoteStatus.signed_and_locked_note;
  const isOwner = clinicianId === fields.clinicianId.value;
  const canSignAndLock =
    (isOwner && isClinician(clinicianRole) && !allowedGroupWriteAccess) ||
    (isOwner && isClinicalSupervisor(clinicianRole)) ||
    (isClinicalSupervisor(clinicianRole) && allowedGroupWriteAccess);

  return (
    <div>
      <MemberHeaderBar memberId={patientId} />
      <NoteHeaderV2
        memberId={patientId}
        clinicianName={clinicianName}
        memberName={patientName}
        noteType={NoteType.OUT_OF_SESSION}
        status={noteStatus}
        onNoteLocked={onNoteLocked}
        disabled={isLocked || !canSignAndLock}
        onValidate={validate}
      />
      <OutOfSessionNoteForm
        patientId={patientId}
        settingsEnabled={settingsEnabled}
        timezone={timezone}
        disabled={isLocked}
        fields={fields}
      />
      {allowedGroupWriteAccess && !isLocked && (
        <Container className={styles.root} maxWidth="sm">
          <SaveButton
            isLoading={saving}
            onSubmit={onSubmit}
            label="Save draft note"
          />
        </Container>
      )}
    </div>
  );
}

export function formatOOSNoteDatetimeTZ(
  value: OutOfSessionNote,
  fromTz: string,
  toTz: string,
): OutOfSessionNote {
  const dateOfContact = value.dateOfContact!;
  const duration = value.durationOfContact!;
  const start = `${getDateValue(dateOfContact)} ${getTimeValue(
    duration.startTime,
  )}`;
  const end = `${getDateValue(dateOfContact)} ${getTimeValue(
    duration.endTime,
  )}`;
  const startDateTime: Moment = moment
    .tz(start, 'YYYY-MM-DD HH:mm', fromTz)
    .tz(toTz);
  const endDateTime: Moment = moment
    .tz(end, 'YYYY-MM-DD HH:mm', fromTz)
    .tz(toTz);

  return {
    ...value,
    dateOfContact: {
      day: startDateTime.date(),
      month: startDateTime.month() + 1,
      year: startDateTime.year(),
    },
    durationOfContact: {
      endTime: {
        hour: endDateTime.get('hour'),
        minute: endDateTime.get('minute'),
        second: endDateTime.get('second'),
      },
      startTime: {
        hour: startDateTime.get('hour'),
        minute: startDateTime.get('minute'),
        second: startDateTime.get('second'),
      },
    },
  };
}
