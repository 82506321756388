import {
  CoachShiftStatusEnum,
  UserRole,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { MenuItem } from '@mui/material';
import styles from 'app/inbox/vertical-menu/styles/index.module.scss';
import { useAppState, useDispatch } from 'app/state';
import { coachShiftStatusChanged } from 'app/state/amplitude/actions/structuredCoaching';
import { AuthService } from 'app/state/features/auth/AuthService';
import { useAppDispatch } from 'app/state/hooks/baseTypedHooks';
import { useLogger } from 'app/state/log/useLogger';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import React, { useCallback, useMemo, useState } from 'react';
import { Avatar } from 'shared-components/avatar/Avatar';
import { Button } from 'shared-components/button/Button';
import { classNameCombiner, isCoach, isCoachOrSupervisor } from 'utils';

import { ClinicianMenuItems } from './ClinicianMenuItems';
import { CoachMenuItems } from './CoachMenuItems';
import { SideMenu } from './SideMenu';
import { useListenerShiftStatus } from './useUpdateListenerShiftStatus';

type LogoutProps = {
  role: UserRole;
};
function LogoutMenuItem({ role }: LogoutProps) {
  const dispatch = useAppDispatch();
  const { updateListenerShiftStatus } = useListenerShiftStatus();
  const logger = useLogger();
  const onLogout = () => {
    if (isCoach(role)) {
      return updateListenerShiftStatus({
        shiftStatus: CoachShiftStatusEnum.OFFLINE,
      })
        .then(({ error }) => {
          if (error) {
            logger.error(
              new Error('LogoutMenuItem: Failed to update shift status'),
              { error },
            );
          }
        })
        .catch((error) => {
          logger.error(
            new Error('LogoutMenuItem: Failed to update shift status'),
            { error },
          );
        })
        .finally(() => {
          void dispatch(AuthService.logout());
        });
    }
    void dispatch(AuthService.logout());
  };

  return (
    <MenuItem className={styles.menuItem} key="logout">
      <Button
        className={styles.logout}
        testId="logout-button"
        onClick={onLogout}
      >
        Log out
      </Button>
    </MenuItem>
  );
}

type Props = {
  redirectTo: (path: string) => void;
};

export function StatusMenu({ redirectTo }: Props) {
  const {
    role,
    listenerId,
    firstName,
    lastName,
    currentStatus,
    clinicianId,
  } = useAppState(({ user }) => ({
    clinicianId: user.clinicianId,
    currentStatus: user.shiftStatus ?? CoachShiftStatusEnum.OFFLINE,
    firstName: user.firstName ?? '',
    lastName: user.lastName ?? '',
    listenerId: user.listenerId,
    role: user.role!,
  }));
  const {
    enable_itms_toggle: enableItmsToggle,
  } = useFeatureFlags().transientFeatureFlags;
  const dispatch = useDispatch();

  const logger = useLogger();

  const avatarStyle = useMemo(() => {
    if (!isCoachOrSupervisor(role)) {
      return styles.online;
    }
    switch (currentStatus) {
      case CoachShiftStatusEnum.ONLINE:
        return styles.online;
      case CoachShiftStatusEnum.REPLY_ONLY:
        return styles.replyOnly;
      default:
        return styles.offline;
    }
  }, [currentStatus, role]);

  const { updateListenerShiftStatus } = useListenerShiftStatus();
  const [open, setOpen] = useState(false);

  const dispatchStatusChangeEvent = useCallback(
    (status: CoachShiftStatusEnum) => {
      if (listenerId && enableItmsToggle) {
        const updatedAt = new Date().toISOString();

        dispatch(
          coachShiftStatusChanged({
            listenerId,
            shiftStatus: status,
            updatedAt,
          }),
        );
      }
    },
    [listenerId, enableItmsToggle, dispatch],
  );

  const onStatusChange = (status: CoachShiftStatusEnum) => {
    return async () => {
      await updateListenerShiftStatus({ shiftStatus: status }).then(
        ({ error, shiftStatus }) => {
          if (error || !shiftStatus) {
            logger.error(
              new Error('StatusMenu: Failed to update coach shift'),
              { error, shiftStatus },
            );
          } else {
            dispatchStatusChangeEvent(status);
            // close the dropdown only when the status has been successfully changed
            setOpen(false);
          }
        },
      );
    };
  };

  const menuItems = useMemo(() => {
    const menuItemsArr = [];
    switch (role) {
      case UserRole.COACH:
        menuItemsArr.push(
          <CoachMenuItems
            currentStatus={currentStatus}
            firstName={firstName}
            lastName={lastName}
            onStatusChange={onStatusChange}
            key="coach-menu-items"
          />,
        );
        break;
      case UserRole.COACH_SUPERVISOR:
        /* TODO: What do supervisors need besides logout */
        break;
      case UserRole.CLINICIAN:
      case UserRole.PSYCHIATRIST:
      case UserRole.THERAPIST:
      case UserRole.PSYCHIATRIST_SUPERVISOR:
      case UserRole.THERAPIST_SUPERVISOR:
      case UserRole.CLINICAL_SUPERVISOR:
        menuItemsArr.push(...ClinicianMenuItems({ clinicianId, redirectTo }));
        break;
      case UserRole.MEMBER_SUPPORT:
        break;
      default:
        break;
    }

    menuItemsArr.push(<LogoutMenuItem key="logout" role={role} />);
    return menuItemsArr;
  }, [firstName, lastName, currentStatus, role]);

  return (
    <SideMenu
      title="Profile"
      Icon={<Avatar firstName={firstName} lastName={lastName} />}
      menuItems={menuItems}
      open={open}
      setOpen={setOpen}
      idAttr="status"
      classes={{
        button: classNameCombiner([
          styles.menuItem,
          styles.gingerAvatarIcon,
          avatarStyle,
        ]),
        menuList: styles.menuList,
        popper: styles.statusPopper,
        tooltip: styles.tooltip,
      }}
    />
  );
}
