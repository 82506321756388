import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { RefObject, useEffect, useRef, useState } from 'react';

/**
 * A hook to track the duration of user interaction within a component.
 *
 * @param param.componentRef - A ref to the component to track interactions for
 * @param param.onInteractionCompleted - A callback to call when the component is unmounted, which is considered the
 *    end of the user's interaction with the component. The callback will be passed the total active interaction time,
 *    in milliseconds
 * @param param.idleTimeout - The time in milliseconds to wait before considering the user idle; defaults to 60 sec.
 *     The default value is somewhat arbitrary and should be adjusted to match the expected user behavior.
 * @param param.disabled - A flag to disable interaction tracking, usually if the component to track is itself disabled
 */
export const useInteractionTracking = ({
  componentRef,
  onInteractionCompleted,
  idleTimeout = 60000, // 60 seconds
  disabled = false,
}: {
  componentRef: RefObject<HTMLElement | null>;
  onInteractionCompleted: (activeInteractionTime: number) => void;
  idleTimeout?: number;
  disabled?: boolean;
}) => {
  const activeInteractionTimeRef = useRef(0);
  const lastInteractionTimeRef = useRef<null | number>(null);
  const idleTimeoutRef = useRef<null | number>(null);
  const [isIdle, setIsIdle] = useState(false);

  const {
    transientFeatureFlags: { enable_note_interaction_tracking },
  } = useFeatureFlags();

  // Start tracking interaction time when the component is mounted
  useEffect(() => {
    if (!enable_note_interaction_tracking || disabled) {
      return () => {};
    }

    const handleUserInteraction = () => {
      const now = Date.now();

      setIsIdle(false);

      if (lastInteractionTimeRef.current) {
        activeInteractionTimeRef.current +=
          now - lastInteractionTimeRef.current;
      }

      lastInteractionTimeRef.current = now;
      resetIdleTimer();
    };

    const handleIdleTimeout = () => {
      setIsIdle(true);
      lastInteractionTimeRef.current = null;
    };

    const resetIdleTimer = () => {
      if (idleTimeoutRef.current) {
        clearTimeout(idleTimeoutRef.current);
      }

      idleTimeoutRef.current = window.setTimeout(
        handleIdleTimeout,
        idleTimeout,
      );
    };

    const element = componentRef.current;
    if (element) {
      element.addEventListener('keydown', handleUserInteraction);
      element.addEventListener('mousedown', handleUserInteraction);
      element.addEventListener('mousemove', handleUserInteraction);
    }

    return () => {
      if (element) {
        element.removeEventListener('keydown', handleUserInteraction);
        element.removeEventListener('mousedown', handleUserInteraction);
        element.removeEventListener('mousemove', handleUserInteraction);
      }

      if (idleTimeoutRef.current) {
        clearTimeout(idleTimeoutRef.current);
      }
    };
  }, [
    idleTimeout,
    isIdle,
    enable_note_interaction_tracking,
    componentRef,
    disabled,
  ]);

  // Finalize and send the final active interaction time on unmount
  useEffect(() => {
    if (!enable_note_interaction_tracking || disabled) {
      return () => {};
    }

    return () => onInteractionCompleted(activeInteractionTimeRef.current);
  }, [onInteractionCompleted, enable_note_interaction_tracking, disabled]);
};
