import { Metadata_NoteStatus } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/Metadata';
import {
  TherapyProgressSection,
  TherapyProgressSectionName,
} from '@ginger.io/vault-clinical-notes/dist/TherapyProgressSection';
import { useLogger } from 'app/state/log/useLogger';
import { TherapyIntakeNote } from 'app/vault/api/TherapyIntakeNotesAPI';
import { TherapyProgressNote } from 'app/vault/api/TherapyProgressNotesAPI';
import { buildTherapyProgressNote } from 'app/vault/data/buildTherapyProgressNote';
import { decodeTherapyIntakeNote } from 'app/vault/data/decodeTherapyIntakeNote';
import { decodeTherapyProgressNote } from 'app/vault/data/decodeTherapyProgressNote';
import { GetAppointmentById_getAppointmentById as Appointment } from 'app/vault/generated/GetAppointmentById';
import {
  useClinicalAppointmentsAPI,
  useTherapyIntakeNotesAPI,
  useTherapyProgressNotesAPI,
} from 'app/vault/hooks/useClinicalNotesAPI';
import { IdAndNoteType } from 'app/vault/hooks/useTherapyProgressNote';
import { NoteType } from 'generated/globalTypes';
import { useDeprecatedDiagnosisCodes } from 'hooks/useDiagnosisCodes';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useCallback } from 'react';

export function useInitializeTherapyProgressNote(
  clinicianUserId: string,
  appointmentId: string,
) {
  const logger = useLogger();
  const { transientFeatureFlags } = useFeatureFlags();
  const api = useTherapyProgressNotesAPI();
  const getTherapyProgressNoteAndPrevious = useGetTherapyProgressNoteAndPrevious();
  const clinicalAppointmentsAPI = useClinicalAppointmentsAPI();

  const { getDeprecatedDiagnosisCodes } = useDeprecatedDiagnosisCodes();
  const {
    enable_supervisor_sign_and_lock_notes_for_user: allowGroupWriteAccess,
    enable_single_request_on_progress_note_copied_section: useSingleRequest,
    enable_care_hub_notes_efficiency: enableCareHubNotesEfficiency,
    enable_prefill_phqgad_answer_in_note: canPrefillPHQGADSummary,
  } = transientFeatureFlags;

  const createDraftNoteSection = async (
    noteSection: Partial<TherapyProgressNote>,
    currentNote: TherapyProgressNote,
    appointment: Appointment,
  ): Promise<Partial<TherapyProgressNote>> => {
    const updatedNoteSection = noteSection;
    const sections: TherapyProgressSection[] = Object.entries(
      updatedNoteSection,
    )
      .filter(([name]) => {
        const sectionName = name as TherapyProgressSectionName;
        return (
          currentNote[sectionName] === null &&
          updatedNoteSection[sectionName] != null &&
          sectionName !== TherapyProgressSectionName.AMENDMENTS
        );
      })
      .map(([name, updatedData]) => {
        const sectionName = name as TherapyProgressSectionName;
        logger.info(
          `useTherapyProgressNote: ${sectionName} section in Vault doesn't exist. Creating draft section.`,
          { appointmentId, clinicianUserId },
        );
        return {
          data: updatedData,
          name,
        } as TherapyProgressSection;
      });

    if (sections.length === 0) return updatedNoteSection;

    const { metadata } = currentNote;
    if (useSingleRequest) {
      metadata.status = Metadata_NoteStatus.draft_note;
      await api
        .createDraftNoteSections(
          clinicianUserId,
          appointment,
          sections,
          metadata,
          allowGroupWriteAccess,
        )
        .catch((error) => {
          const sectionNames = sections.map((section) => section.name);
          sectionNames.forEach((section) => {
            updatedNoteSection[section] = null;
          });
          metadata.status = Metadata_NoteStatus.undefined_note_status;
          const message = `useTherapyProgressNote: Unable to copy forward from previous note. Clearing section data in local state.`;
          logger.error(error, {
            appointmentId,
            clinicianUserId,
            message,
            sectionNames,
          });
        });
    } else {
      await Promise.all(
        sections.map(async (section) =>
          api
            .createDraftNoteSection(
              clinicianUserId,
              appointment,
              section,
              undefined,
              allowGroupWriteAccess,
            )
            .catch((error) => {
              const sectionName = section.name;
              updatedNoteSection[sectionName] = null;
              const message = `useTherapyProgressNote: ${sectionName} section failed on createDraftNoteSection. Clearing section data in local state.`;
              logger.error(error, {
                appointmentId,
                clinicianUserId,
                message,
                sectionName,
              });
            }),
        ),
      );
    }

    return updatedNoteSection;
  };

  return useCallback(async (appointment: Appointment): Promise<
    TherapyProgressNote
  > => {
    const [
      previousAppointment,
      deprecatedDiagnosisCodes,
      phqGadSurvey,
    ] = await Promise.all([
      clinicalAppointmentsAPI.getLatestCompleteClinicalAppointmentAndNoteType(
        appointmentId,
      ),
      getDeprecatedDiagnosisCodes(),
      canPrefillPHQGADSummary
        ? api.getPHQGADSurvey({ memberId: appointment.member.id })
        : Promise.resolve(null),
    ]);
    const results = await getTherapyProgressNoteAndPrevious(
      appointment,
      previousAppointment,
    );
    const { currentNote, previousNote } = results;
    const canCopyForward =
      previousAppointment !== null &&
      (previousAppointment.noteType === NoteType.THERAPY_PROGRESS ||
        previousAppointment.noteType === NoteType.THERAPY_INTAKE);

    if (!canCopyForward) return currentNote;

    const updatedNoteSection: Partial<TherapyProgressNote> = await createDraftNoteSection(
      buildTherapyProgressNote({
        appointmentId,
        currentNote,
        deprecatedDiagnosisCodes,
        enableCareHubNotesEfficiency,
        phqGadSurvey,
        previousNote: previousNote ?? null,
      }),
      currentNote,
      appointment,
    );

    return {
      ...currentNote,
      ...updatedNoteSection,
    };
  }, []);
}

function useGetTherapyProgressNoteAndPrevious() {
  const api = useTherapyProgressNotesAPI();
  const therapyIntakeAPI = useTherapyIntakeNotesAPI();
  return useCallback(
    async (
      appointment: Appointment,
      previousAppt: IdAndNoteType | null,
    ): Promise<{
      previousNote?: TherapyProgressNote | TherapyIntakeNote;
      currentNote: TherapyProgressNote;
    }> => {
      const { id: appointmentId } = appointment;
      if (
        previousAppt === null ||
        (previousAppt.noteType !== NoteType.THERAPY_PROGRESS &&
          previousAppt.noteType !== NoteType.THERAPY_INTAKE)
      ) {
        return {
          currentNote: await api.getNote(appointmentId),
        };
      }

      if (previousAppt.noteType === NoteType.THERAPY_PROGRESS) {
        const [currentNote, previousNote] = await Promise.all([
          api.getNote(appointmentId),
          api
            .getNote(previousAppt.id)
            .catch(() => decodeTherapyProgressNote(previousAppt.id, [], [])),
        ]);
        return {
          currentNote,
          previousNote,
        };
      }
      const [currentNote, previousNote] = await Promise.all([
        api.getNote(appointmentId),
        therapyIntakeAPI
          .getNote(previousAppt.id)
          .catch(() => decodeTherapyIntakeNote(previousAppt.id, [], [])),
      ]);

      return { currentNote, previousNote };
    },
    [],
  );
}
