import { useApolloClient, useMutation } from '@apollo/client';
import { decodeBase64VaultItems } from '@ginger.io/vault-core';
import { KeyGenerator } from '@ginger.io/vault-core/dist/crypto';
import { Base64 } from '@ginger.io/vault-core/dist/crypto/Base64';
import {
  ClinicalDocument,
  ClinicalDocument_ClinicalDocumentType as ClinicalDocumentType,
  clinicalDocument_ClinicalDocumentTypeToJSON,
} from '@ginger.io/vault-core/dist/generated/protobuf-schemas/vault-core/ClinicalDocument';
import { EncryptedAttachment } from '@ginger.io/vault-core/dist/generated/protobuf-schemas/vault-core/EncryptedAttachment';
import { EncryptionVersion } from '@ginger.io/vault-core/dist/generated/protobuf-schemas/vault-core/EncryptionVersion';
import {
  VaultItem,
  VaultItem_SchemaType as SchemaType,
} from '@ginger.io/vault-core/dist/generated/protobuf-schemas/vault-core/VaultItem';
import { getClinicalCareTeamGroupId } from '@ginger.io/vault-core/dist/IdHelpers';
import {
  createVaultItemInput,
  CreateVaultItemInputParams,
  updateVaultItemInput,
  UpdateVaultItemInputParams,
  VaultItemPermissions,
  VaultSystemName,
} from '@ginger.io/vault-ui';
import { ApolloCachingStrategy } from 'app/constants';
import { useAppState } from 'app/state';
import { useLogger } from 'app/state/log/useLogger';
import { formatFileSize } from 'utils';
import { formatDate } from 'utils/dateTime';
import axios from 'axios';
import {
  CreateVaultItemInput,
  UpdateVaultItemInput,
  VaultItemSortField,
  VaultItemSortOrder,
} from 'generated/globalTypes';
import { v4 as uuidv4 } from 'uuid';
import {
  CreateClinicalDocument,
  CreateClinicalDocumentVariables,
} from './generated/CreateClinicalDocument';
import {
  CreatePreSignedDownloadUrl,
  CreatePreSignedDownloadUrlVariables,
} from './generated/CreatePreSignedDownloadUrl';
import {
  CreatePreSignedUploadUrl,
  CreatePreSignedUploadUrlVariables,
} from './generated/CreatePreSignedUploadUrl';
import {
  DeleteClinicalDocument,
  DeleteClinicalDocumentVariables,
} from './generated/DeleteClinicalDocument';
import {
  GetClinicalDocument,
  GetClinicalDocumentVariables,
} from './generated/GetClinicalDocument';
import {
  GetClinicalDocuments,
  GetClinicalDocuments_getPaginatedVaultItemsByTag_items as VaultClinicalDocument,
  GetClinicalDocumentsVariables,
} from './generated/GetClinicalDocuments';
import {
  UpdateClinicalDocument,
  UpdateClinicalDocumentVariables,
} from './generated/UpdateClinicalDocument';

import {
  createClinicalDocument,
  createPreSignedDownloadUrl,
  createPreSignedUploadUrl,
  deleteClinicalDocument,
  getClinicalDocument,
  getClinicalDocuments,
  updateClinicalDocument,
} from './queries';
import {
  ClinicalDocumentHookState,
  Document,
  UpdateDocumentInput,
  UploadDocumentInput,
  UploadResponse,
} from './types';
import { useRefreshableQuery } from './useRefreshableQuery';

interface Option {
  memberId: string;
  generateId?: () => string;
  onPut: (...args: Array<any>) => Promise<void>;
  keyGenerator: KeyGenerator;
}

export function useClinicalDocument(
  memberId: string,
  option: Partial<Option> = {},
): ClinicalDocumentHookState {
  const apolloClient = useApolloClient();
  const { authorId, timezone } = useAppState(({ user }) => ({
    authorId: user.userId!,
    timezone: user.timezone!,
  }));
  const tags = getClinicalDocumentTags(memberId, authorId);
  const logger = useLogger();

  const {
    generateId = uuidv4,
    onPut = axios.put,
    keyGenerator = new KeyGenerator(),
  } = option;
  const [createPreSignedUploadUrlFn] = useMutation<
    CreatePreSignedUploadUrl,
    CreatePreSignedUploadUrlVariables
  >(createPreSignedUploadUrl);
  const [createPreSignedDownloadUrlFn] = useMutation<
    CreatePreSignedDownloadUrl,
    CreatePreSignedDownloadUrlVariables
  >(createPreSignedDownloadUrl);
  const [createClinicalDocumentFn] = useMutation<
    CreateClinicalDocument,
    CreateClinicalDocumentVariables
  >(createClinicalDocument);
  const [deleteClinicalDocumentFn] = useMutation<
    DeleteClinicalDocument,
    DeleteClinicalDocumentVariables
  >(deleteClinicalDocument);

  const [updateClinicalDocumentFn] = useMutation<
    UpdateClinicalDocument,
    UpdateClinicalDocumentVariables
  >(updateClinicalDocument);

  const documents = useRefreshableQuery<
    GetClinicalDocuments,
    GetClinicalDocumentsVariables,
    Document[]
  >(
    {
      query: getClinicalDocuments,
      variables: () => getVariables(memberId, authorId),
    },
    async ({ getPaginatedVaultItemsByTag }) =>
      Promise.all(
        getPaginatedVaultItemsByTag.items.map((item: VaultClinicalDocument) =>
          mapToClinicalDocument(item.encryptedItem, timezone),
        ),
      ),
  );

  const uploadFileAndCreateVaultItem = async (
    input: UploadDocumentInput,
  ): Promise<UploadResponse> => {
    let additionalData = undefined;
    try {
      const { file, onUploadProgressCallback, documentType, name } = input;
      const ext = getFileExtension(file.name);

      const [fileId, itemId] = await Base64.hashList([file.name, generateId()]);
      const documentTypeJSON = clinicalDocument_ClinicalDocumentTypeToJSON(
        documentType,
      );
      additionalData = {
        memberId,
        itemId,
        fileId,
        ext,
        documentType: documentTypeJSON,
      };

      const { data, errors } = await createPreSignedUploadUrlFn({
        variables: { input: { fileId, itemId } },
      });

      if (errors || !data) {
        logger.error(
          new Error(
            'useClinicalDocument: Unable to generate pre-signed upload URL',
          ),
          {
            ...additionalData,
            errors,
          },
        );
        return {
          errorMessage:
            errors?.map((_) => _.message).join('\n') ??
            'Unable to upload document',
          data: null,
          success: false,
        };
      }

      const uploadUrl = data.createPreSignedUploadUrl.url;
      let onUploadProgress;
      if (onUploadProgressCallback) {
        onUploadProgress = (progressEvent: { loaded: number }) =>
          onUploadProgressCallback((progressEvent.loaded * 100) / file.size);
      }

      await onPut(uploadUrl, file, {
        // when uploading a file using the signed link, aws expects content-type to be application/octet-stream.
        // Since we generated the signed link with content-type set to application/octet-stream
        headers: { 'Content-Type': 'application/octet-stream' },
        onUploadProgress,
      });

      const vaultItem: VaultItem = clinicalDocumentToVaultItem({
        memberId,
        name,
        ext,
        fileId,
        documentType,
        authorId,
        fileSize: file.size,
      });

      const param: CreateVaultItemInputParams = {
        itemId,
        groupsToShareWith: [getClinicalCareTeamGroupId(memberId)],
        tags: Object.values(tags),
        vaultItem: vaultItem as any,
        permissions: VaultItemPermissions.Writable,
        systemToShareWith: VaultSystemName.ClinicalDocumentSyncProcess,
      };
      const item = await createVaultItemInput(param, keyGenerator, generateId, {
        hashItemIds: false,
      });
      // TypeScript believes that there is a type mismatch between the gql type "CreateVaultItemInput" generated in the
      // ginger-react-ui library and this repo, so we doing double assertion to keep TypeScript from complaining.
      const {
        errors: createVaultItemErrors,
        data: response,
      } = await createClinicalDocumentFn({
        variables: { input: [(item as unknown) as CreateVaultItemInput] },
      });

      if (createVaultItemErrors || !response) {
        logger.error(
          new Error(
            'useClinicalDocument: Unable to create clinical document vault item',
          ),
          {
            ...additionalData,
            errors: createVaultItemErrors,
          },
        );
        return {
          errorMessage:
            createVaultItemErrors?.map((_) => _.message).join('\n') ??
            'Unable to create vault item',
          data: null,
          success: false,
        };
      }

      return {
        success: true,
        data: await mapToClinicalDocument(
          response.createVaultItems[0].encryptedItem,
          timezone,
        ),
      };
    } catch (error) {
      logger.error(
        new Error('useClinicalDocument: Unable to upload document.'),
        { ...additionalData, error },
      );
      return {
        errorMessage: 'Unable to upload document.',
        data: null,
        success: false,
      };
    }
  };

  return {
    documents: documents.state,
    refreshData: async (
      sortOrder = VaultItemSortOrder.DESC,
      showLoader = false,
    ) =>
      documents.refreshData(
        await getVariables(memberId, authorId, sortOrder),
        showLoader,
      ),
    deleteDocument: async (itemId) => {
      const { data, errors } = await deleteClinicalDocumentFn({
        variables: {
          input: {
            id: itemId,
            groupId: await Base64.hash(getClinicalCareTeamGroupId(memberId)),
          },
        },
      });
      if (errors || !data) {
        logger.error(
          new Error('useClinicalDocument: Unable to delete document'),
          { errors, memberId, itemId },
        );
        return {
          success: false,
          id: itemId,
          errorMessage:
            errors?.map((_) => _.message).join('\n') ??
            'Unable to delete document',
        };
      }
      return data.deleteVaultItem;
    },
    uploadDocuments: (input: UploadDocumentInput[]) =>
      Promise.all(input.map(uploadFileAndCreateVaultItem)),
    updateDocument: async (
      input: UpdateDocumentInput,
    ): Promise<UploadResponse> => {
      const { itemId, documentType, name } = input;
      const { data, errors } = await apolloClient.query<
        GetClinicalDocument,
        GetClinicalDocumentVariables
      >({
        query: getClinicalDocument,
        variables: {
          id: itemId,
          groupId: await Base64.hash(getClinicalCareTeamGroupId(memberId)),
        },
        fetchPolicy: ApolloCachingStrategy.CACHE_FIRST,
      });

      if (!(data && data.getVaultItemById)) {
        logger.error(
          new Error(
            'useClinicalDocument: Unable to retrieve document to update',
          ),
          {
            errors,
            memberId,
            itemId,
            documentType: clinicalDocument_ClinicalDocumentTypeToJSON(
              documentType,
            ),
          },
        );
        return {
          errorMessage:
            errors?.map((_) => _.message).join('\n') ??
            'Unable to retrieve document to update',
          success: false,
          data: null,
        };
      }
      const {
        attachments = EncryptedAttachment.fromPartial({}),
        ...others
      } = await decodeClinicalDocument(data.getVaultItemById.encryptedItem);
      const updatedData: ClinicalDocument = {
        ...others,
        documentType,
        attachments: { ...attachments, name },
      };
      const vaultItem: VaultItem = {
        schemaType: SchemaType.vault_clinical_document,
        data: ClinicalDocument.encode(updatedData).finish(),
      };
      const param: UpdateVaultItemInputParams = {
        itemId,
        groupId: await Base64.hash(getClinicalCareTeamGroupId(memberId)),
        tags: Object.values(getClinicalDocumentTags(memberId, authorId)),
        vaultItem: vaultItem as any,
        permissions: VaultItemPermissions.Writable,
      };
      const item = await updateVaultItemInput(param, keyGenerator, {
        hashItemIds: false,
      });
      const {
        errors: updateError,
        data: resp,
      } = await updateClinicalDocumentFn({
        variables: { input: [(item as unknown) as UpdateVaultItemInput] },
      });
      if (updateError || !resp) {
        logger.error(
          new Error('useClinicalDocument: Unable to update document'),
          {
            errors: updateError,
            memberId,
            itemId,
            documentType: clinicalDocument_ClinicalDocumentTypeToJSON(
              documentType,
            ),
          },
        );
        return {
          errorMessage:
            updateError?.map((_) => _.message).join('\n') ??
            'updateDocument::Unable to update vault item',
          success: false,
          data: null,
        };
      }
      return {
        success: true,
        data: await mapToClinicalDocument(resp.updateVaultItems[0], timezone),
      };
    },
    generateDownloadUrl: async (document: Document) => {
      const { itemId, fileId, name, ext } = document;
      const { errors, data } = await createPreSignedDownloadUrlFn({
        variables: {
          input: {
            itemId,
            fileId,
            filename: encodeURIComponent(`${name}.${ext}`),
            groupId: await Base64.hash(getClinicalCareTeamGroupId(memberId)),
          },
        },
      });
      if (errors || !data) {
        throw new Error('Unable to retrieve signed download link');
      }
      return data.createPreSignedDownloadUrl.url;
    },
  };
}

export function getFileExtension(file: string) {
  const substrings = file.split('.');
  if (substrings.length === 1) {
    return '';
  } else {
    return substrings.pop() ?? '';
  }
}

export function clinicalDocumentToVaultItem(data: {
  authorId: string;
  memberId: string;
  documentType: ClinicalDocumentType;
  name: string;
  ext: string;
  fileId: string;
  fileSize: number;
}): VaultItem {
  const {
    authorId,
    memberId,
    documentType,
    name,
    ext,
    fileId,
    fileSize,
  } = data;
  return {
    schemaType: SchemaType.vault_clinical_document,
    data: ClinicalDocument.encode({
      authorId,
      memberId,
      documentType,
      attachments: {
        name,
        ext,
        fileSize,
        id: fileId,
        encryptionVersion: EncryptionVersion.v0,
        encryptedDataKey: Uint8Array.from(Buffer.from('dummy encryption key')),
        nonce: Uint8Array.from(Buffer.from('dummy nonce')),
      },
    }).finish(),
  };
}

async function mapToClinicalDocument(
  encryptedItem: VaultClinicalDocument['encryptedItem'],
  timezone: string,
): Promise<Document> {
  const { creator, createdAt, id: itemId } = encryptedItem;
  const { firstName, lastName } = creator;

  const { documentType, attachments } = await decodeClinicalDocument(
    encryptedItem,
  );
  return {
    documentType,
    itemId,
    author: `${firstName ?? ''} ${lastName ?? ''}`.trim(),
    createdAt: formatDate(createdAt, timezone),
    fileId: attachments?.id ?? '-',
    name: attachments?.name ?? '-',
    ext: attachments?.ext ?? '',
    fileSize: attachments?.fileSize
      ? formatFileSize(attachments.fileSize)
      : '-',
  };
}

async function getVariables(
  memberId: string,
  authorId: string,
  sortOrder = VaultItemSortOrder.DESC,
): Promise<GetClinicalDocumentsVariables> {
  const { byMember } = getClinicalDocumentTags(memberId, authorId);
  return {
    tag: await Base64.hash(byMember),
    groupId: await Base64.hash(getClinicalCareTeamGroupId(memberId)),
    pagination: {
      cursor: null,
      maxItemsPerPage: 200, // we do not anticipate users to upload up to 200 clinical documents per member, so there's not need to properly handle pagination
      sortOrder,
      sortField: VaultItemSortField.CREATED_AT,
    },
  };
}

async function decodeClinicalDocument(
  encryptedItem: VaultClinicalDocument['encryptedItem'],
): Promise<ClinicalDocument> {
  const { encryptedData } = encryptedItem;
  const decodedItems = await decodeBase64VaultItems(
    [encryptedData.cipherText],
    {
      [SchemaType.vault_clinical_document]: ClinicalDocument,
    },
  );
  const vaultClinicalDocuments =
    decodedItems[SchemaType.vault_clinical_document];

  if (vaultClinicalDocuments.length !== 1)
    throw Error('Failed to decode vault clinical document');

  return vaultClinicalDocuments[0];
}

function getClinicalDocumentTags(
  memberId: string,
  authorId: string,
): {
  byMember: string;
  byMemberAndAuthor: string;
  byAuthor: string;
} {
  return {
    byMemberAndAuthor: `clinical-document-member-${memberId}-author-${authorId}`, //  To retrieve documents uploaded by a given clinician or member support for a member
    byMember: `clinical-document-member-${memberId}`, // To retrieve documents for a member.
    byAuthor: `clinical-document-author-${authorId}`, //To retrieve documents for a clinician.
  };
}
