import { UserRole } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { NoteAction } from 'app/appointments/types';
import { useAppState } from 'app/state';
import { renderSliceStateResult } from 'app/state/status/RenderSliceState';
import { useOutOfSessionAndTerminationNotes } from 'app/vault/hooks/NonAppointments/useOutOfSessionAndTerminationNotes';
import React, { ReactElement } from 'react';
import { getTimezone } from 'utils/dateTime';

import { NotesTable } from './NotesTable';

export type Props = {
  patientId: string;
  navigateTo: (url: string) => void;
  signedLockedAction?: NoteAction.VIEW | NoteAction.DOWNLOAD;
  role?: UserRole;
};

export function NotesTab(props: Props): ReactElement {
  const { signedLockedAction = NoteAction.VIEW, patientId, navigateTo } = props;
  const { role, userId, timezone, vaultUserId } = useAppState(({ user }) => ({
    role: user.role!,
    timezone: user.timezone ?? 'UTC',
    userId: user.userId!,
    vaultUserId: user.vaultUserId!,
  }));
  const localTimezone = getTimezone(timezone);
  const state = useOutOfSessionAndTerminationNotes({
    memberId: props.patientId,
    role,
    timezone: localTimezone,
    userId,
    vaultUserId,
  });

  return renderSliceStateResult(state, (notes) => (
    <NotesTable
      signedLockedAction={signedLockedAction}
      notes={notes}
      memberId={patientId}
      navigateTo={navigateTo}
      clinicianId={userId!}
    />
  ));
}
