import { useQuery } from '@apollo/client';
import { appointmentQuery } from '@headspace/carehub-graphql/dist/appointments/appointmentQueries';
import {
  ClinicalAppointmentById,
  ClinicalAppointmentByIdVariables,
} from '@headspace/carehub-graphql/dist/appointments/generated/ClinicalAppointmentById';
import { ApolloCachingStrategy } from 'app/constants';
import { updateSessionState } from 'app/state/features/auth/authSlice';
import { SessionState } from 'app/state/features/auth/types';
import { useLogger } from 'app/state/log/useLogger';
import { useEffect } from 'react';
import { useDispatch } from 'redux-reloaded';
import { isGraphQLAuthenticationError } from 'shared-components/error-state/utils';

import { DURATION_POLL_INTERVAL } from './constants';

type Option = {
  appointmentId: string;
};

export function useAppointmentDuration({ appointmentId }: Option) {
  const logger = useLogger();
  const dispatch = useDispatch();

  const options: {
    fetchPolicy: ApolloCachingStrategy;
    pollInterval?: number;
  } = {
    fetchPolicy: ApolloCachingStrategy.NO_CACHE,
    pollInterval: DURATION_POLL_INTERVAL,
  };

  const { loading, error, data, stopPolling } = useQuery<
    ClinicalAppointmentById,
    ClinicalAppointmentByIdVariables
  >(appointmentQuery, {
    variables: {
      input: {
        id: appointmentId,
      },
    },
    ...options,
  });

  const duration = data?.getClinicalAppointment?.durationInSeconds;

  useEffect(() => {
    if (loading) return;

    if (duration) {
      stopPolling();
    }

    if (isGraphQLAuthenticationError(error)) {
      stopPolling();
      dispatch(updateSessionState(SessionState.EXPIRED));
    } else if (error) {
      logger.error(new Error('Failed to poll appointment duration'), {
        appointmentId,
        data,
        error,
      });
    }

    return () => {
      stopPolling();
    };
  }, [duration, error, loading]);

  return duration;
}
