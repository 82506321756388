import { useQuery } from '@apollo/client';
import {
  GetMemberSessionStats,
  GetMemberSessionStatsVariables,
} from '@headspace/carehub-graphql/dist/appointments/generated/GetMemberSessionStats';
import { getMemberSessionStats } from '@headspace/carehub-graphql/dist/appointments/queries';
import React, { useEffect } from 'react';
import { FormError } from 'shared-components/FormError';

import {
  MemberSessionStats,
  MemberSessionStatsBanner,
  MemberSessionStatsV2,
} from './MemberSessionStats';
import { useMemberSessionStats } from './UseMemberSessionStats';

export enum MemberSessionStatsVariant {
  OUTLINE,
  NORMAL,
  BANNER,
}

type MemberSessionStatsBannerContainerProps = {
  memberId: string;
  timezone: string;
  variant?: MemberSessionStatsVariant;
  onSessionCoverageExhausted?: (sessionCoverageExhausted: boolean) => void;
};

export function MemberSessionStatsContainer(
  props: MemberSessionStatsBannerContainerProps,
) {
  const {
    memberId,
    timezone,
    variant = 'normal',
    onSessionCoverageExhausted,
  } = props;
  const { error, data } = useQuery<
    GetMemberSessionStats,
    GetMemberSessionStatsVariables
  >(getMemberSessionStats, {
    skip: memberId === '',
    variables: { id: memberId },
  });
  const {
    memberSessionStats,
    sessionCoverageExhausted,
  } = useMemberSessionStats({
    data,
    timezone,
  });

  let Component;

  switch (variant) {
    case MemberSessionStatsVariant.OUTLINE:
      Component = MemberSessionStatsBanner;
      break;
    case MemberSessionStatsVariant.BANNER:
      Component = MemberSessionStatsV2;
      break;
    default:
      Component = MemberSessionStats;
  }

  useEffect(() => {
    if (onSessionCoverageExhausted) {
      onSessionCoverageExhausted(sessionCoverageExhausted);
    }
  }, [sessionCoverageExhausted]);

  return (
    <>
      <Component memberSessionStats={memberSessionStats} />
      <FormError error={error?.message} />
    </>
  );
}
