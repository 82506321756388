import { UserRole } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { ErrorState } from 'shared-components/error-state/ErrorState';

import { NewAppointmentForMeContainer } from './NewAppointmentForMeContainer';
import { NewAppointmentMSContainer } from './NewAppointmentMSContainer';

export function NewAppointmentScreen(props: RouteComponentProps<{}>) {
  const { location } = props;
  const searchParams = new URLSearchParams(location.search);
  const clinicianId = searchParams.get('clinicianId') || undefined;
  const patientId = searchParams.get('patientId') || undefined;
  const appointmentRole =
    (searchParams.get('role') as UserRole) || UserRole.CLINICIAN;
  const start = searchParams.get('start') || undefined;
  const end = searchParams.get('end') || undefined;

  if (appointmentRole === UserRole.MEMBER_SUPPORT) {
    if (patientId) {
      return <NewAppointmentMSContainer patientId={patientId} />;
    }
    return (
      <div data-testid="missing-patient-id">
        <ErrorState error={new Error('Missing patient id')} />
      </div>
    );
  }
  return (
    <NewAppointmentForMeContainer
      start={start}
      end={end}
      clinicianId={clinicianId}
      patientId={patientId}
    />
  );
}

export default withRouter(NewAppointmentScreen);
