import { useMutation } from '@apollo/client';
import { DemographicsInput } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import {
  GetMember,
  GetMember_getMember as Member,
  GetMemberVariables,
} from '@headspace/carehub-graphql/dist/member-support/generated/GetMember';
import {
  UpdateMemberDemographics,
  UpdateMemberDemographicsVariables,
} from '@headspace/carehub-graphql/dist/member-support/generated/UpdateMemberDemographics';
import {
  getMemberQuery,
  updateMemberDemographicsMutationV2,
} from '@headspace/carehub-graphql/dist/member-support/MemberDemographicsGQL';
import styles from 'app/patients/PatientProfile.module.scss';
import {
  updateMemberProfileAction,
  viewMemberProfileAction,
} from 'app/state/amplitude/actions/etc';
import { useLogger } from 'app/state/log/useLogger';
import { TransientFeatureFlag, useFeatureFlags } from 'hooks/useFeatureFlags';
import { useQuery } from 'hooks/useQuery';
import { useSnackNotification } from 'hooks/useSnackNotification';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tab, TabBar } from 'shared-components/tabs/TabBar';
import { getExpectedErrorMessages } from 'utils/errorUtils';

import { TabContent, Tabs } from './TabContent';

type ProfileProps = {
  member: Member;
  onUpdateDemographics: (demographics: Partial<DemographicsInput>) => void;
  isSchedulingUIEnabled?: boolean;
};

export function MSMemberProfile(props: ProfileProps) {
  const { canViewMSAppointmentsTable } = useFeatureFlags();
  const enableDocumentUpload = useFeatureFlags().transientFeatureFlags[
    TransientFeatureFlag.ENABLE_DOCUMENT_UPLOAD
  ];

  const [activeTab, setActiveTab] = useState(Tabs.SUMMARY);
  const dispatch = useDispatch();
  const {
    member,
    member: { id, firstName, lastName, billingFirstName, billingLastName },
    onUpdateDemographics,
  } = props;
  const tabs: Tab[] = [
    {
      active: activeTab === Tabs.SUMMARY,
      label: 'Summary',
      onClick: () => setActiveTab(Tabs.SUMMARY),
    },
  ];

  useEffect(() => {
    dispatch(viewMemberProfileAction({ patientId: id }));
  }, [dispatch, id]);

  if (canViewMSAppointmentsTable) {
    tabs.push({
      active: activeTab === Tabs.APPOINTMENTS_SCHEDULE,
      isNewFeature: false,
      label: 'Appointments',
      onClick: () => setActiveTab(Tabs.APPOINTMENTS_SCHEDULE),
    });
    tabs.push({
      active: activeTab === Tabs.NOTES,
      label: 'Notes',
      onClick: () => setActiveTab(Tabs.NOTES),
    });
  }

  if (enableDocumentUpload) {
    tabs.push({
      active: activeTab === Tabs.DOCUMENTS,
      isNewFeature: false,
      label: 'Documents',
      onClick: () => setActiveTab(Tabs.DOCUMENTS),
    });
  }

  return (
    <>
      <nav className={styles.nav}>
        <div className={styles.header}>
          <h1 data-testid="name">
            {firstName ?? billingFirstName} {lastName ?? billingLastName} ({id})
          </h1>
        </div>
        <TabBar tabs={tabs} />
      </nav>
      <TabContent
        activeTab={activeTab}
        member={member}
        onUpdateDemographics={onUpdateDemographics}
      />
    </>
  );
}

function MemberProfile(props: { memberId: string }) {
  const dispatch = useDispatch();
  const {
    showErrorNotification,
    showSuccessNotification,
  } = useSnackNotification();

  const featureFlags = useFeatureFlags();
  const isSchedulingUIEnabled =
    featureFlags.transientFeatureFlags[TransientFeatureFlag.SCHEDULE] ?? false;
  const [updateDemographics, mutationResult] = useMutation<
    UpdateMemberDemographics,
    UpdateMemberDemographicsVariables
  >(updateMemberDemographicsMutationV2);
  const { memberId } = props;
  const logger = useLogger();

  useEffect(() => {
    const { data: updateData, error: updateError, called } = mutationResult;
    if (called) {
      if (updateError) {
        showErrorNotification(`Unable to update user #${memberId}`, true);
      } else if (updateData) {
        const errors = updateData?.updateMemberDemographicsV2?.errors;

        if (errors && errors.length > 0) {
          showErrorNotification(
            `Unable to update user #${memberId}: ${getExpectedErrorMessages(
              errors,
              logger,
            )}`,
            true,
          );
        } else {
          showSuccessNotification(`Updated user #${memberId}`, true);
        }
      }
    }
  }, [
    mutationResult,
    memberId,
    dispatch,
    showErrorNotification,
    showSuccessNotification,
  ]);

  const onUpdateDemographics = (demographics: Partial<DemographicsInput>) => {
    void updateDemographics({
      variables: { demographics, patientId: props.memberId },
    });
    dispatch(updateMemberProfileAction({ patientId: memberId }));
  };

  return useQuery<GetMember, GetMemberVariables>(
    (data) => {
      const { getMember } = data;
      if (getMember) {
        return (
          <MSMemberProfile
            data-testid="memberProfile"
            onUpdateDemographics={onUpdateDemographics}
            member={getMember}
            isSchedulingUIEnabled={isSchedulingUIEnabled}
          />
        );
      }
      return <h1>Member not found</h1>;
    },
    getMemberQuery,
    {
      variables: { id: props.memberId },
    },
  );
}

export default MemberProfile;
