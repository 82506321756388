import { CareProviderNoteType } from 'app/coach/coach-notes/CoachNotesTypes';
import { EventType } from 'app/EventType';
import { ResponseStatus } from 'app/state/amplitude/actions/types';
import { CareProviderNotesLabel } from 'utils/notes';
import { createAction } from 'redux-reloaded';
import { NoteType } from 'app/notes-ui/NoteActions';
import { ClinicalNoteSection } from 'app/notes-ui/ClinicalNotesScreenV2';

export const therapyIntakeClick = createAction(
  EventType.SCHEDULE_LIST_THERAPY_INTAKE_CLICK,
  (payload: { appointmentId: string }) => ({
    payload,
  }),
);
export const therapyProgressClick = createAction(
  EventType.SCHEDULE_LIST_THERAPY_PROGRESS_CLICK,
  (payload: { appointmentId: string }) => ({
    payload,
  }),
);
export const psychiatryIntakeClick = createAction(
  EventType.SCHEDULE_LIST_PSYCHIATRY_INTAKE_CLICK,
  (payload: { appointmentId: string }) => ({
    payload,
  }),
);
export const psychiatryProgressClick = createAction(
  EventType.SCHEDULE_LIST_PSYCHIATRY_PROGRESS_CLICK,
  (payload: { appointmentId: string }) => ({
    payload,
  }),
);
export const noteTabViewed = createAction(
  EventType.NOTE_TAB_VIEWED,
  (payload: { patientId: string }) => ({
    payload,
  }),
);
export const nonAppointmentNoteButtonClicked = createAction(
  EventType.CLICK_NEW_BUTTON,
  (payload: { patientId: string }) => ({ payload }),
);
export const reasonForContactSelected = createAction(
  EventType.REASON_FOR_CONTACT_SELECTED,
  (payload: { patientId: string; reason: string }) => ({ payload }),
);
export const reasonForTerminationSelected = createAction(
  EventType.REASON_FOR_TERMINATION_SELECTED,
  (payload: { patientId: string; reason: string }) => ({ payload }),
);
export const selectNonAppointmentNoteTemplateFromDropdown = createAction(
  EventType.SELECT_NON_APPOINTMENT_NOTE_TEMPLATE_FROM_DROPDOWN,
  (payload: { patientId: string; template: string }) => ({ payload }),
);
export const clickedSignAndLockOOSNote = createAction(
  EventType.CLICKED_SIGN_AND_LOCK_OUT_OF_SESSION_NOTE,
  (payload: { patientId: string }) => ({ payload }),
);
export const clickedSignAndLockTerminationNote = createAction(
  EventType.CLICKED_SIGN_AND_LOCK_TERMINATION_NOTE,
  (payload: { patientId: string }) => ({ payload }),
);
export const clickedNoteListActionButtonNote = createAction(
  EventType.CLICKED_NOTE_LIST_ACTION_BUTTON_NOTE,
  (payload: any) => ({ payload }),
);
export const viewOOSNote = createAction(
  EventType.VIEW_OUT_OF_SESSION_NOTE,
  (payload: { patientId: string }) => ({
    payload,
  }),
);
export const viewTerminationNote = createAction(
  EventType.VIEW_OUT_OF_SESSION_NOTE,
  (payload: { patientId: string }) => ({
    payload,
  }),
);
export const clickedSaveDraftOOSNote = createAction(
  EventType.CLICKED_SAVE_DRAFT_OUT_OF_SESSION_NOTE,
  (payload: { patientId: string }) => ({ payload }),
);
export const clickedSaveDraftTerminationNote = createAction(
  EventType.CLICKED_SAVE_DRAFT_TERMINATION_NOTE,
  (payload: { patientId: string }) => ({ payload }),
);

type BaseClinicalNotesEvent = {
  noteType: CareProviderNoteType;
  clinicianId: string;
  appointmentId: string;
};

type BaseCoachNotesEvent = {
  noteType: CareProviderNoteType;
  memberId: string;
  noteId?: string | null;
};

export const wroteClinicalNote = createAction(
  EventType.CARE_PROVIDER_NOTES_EVENT,
  (
    payload: BaseClinicalNotesEvent &
      ResponseStatus & {
        memberId: string;
        section: string;
        label: Omit<
          CareProviderNotesLabel,
          | CareProviderNotesLabel.NOTE_VIEWED
          | CareProviderNotesLabel.NOTE_DELETED
        >;
      },
  ) => ({
    payload,
  }),
);

export const signedAndLockedClinicalNote = createAction(
  EventType.CARE_PROVIDER_NOTES_EVENT,
  (
    payload: BaseClinicalNotesEvent &
      ResponseStatus & {
        memberId: string;
        label: CareProviderNotesLabel.NOTE_SIGNED_AND_LOCKED;
      },
  ) => ({
    payload,
  }),
);

export const deletedDraftClinicalNote = createAction(
  EventType.CARE_PROVIDER_NOTES_EVENT,
  (
    payload: BaseClinicalNotesEvent &
      ResponseStatus & {
        memberId: string;
        label: CareProviderNotesLabel.NOTE_DELETED;
      },
  ) => ({
    payload,
  }),
);

export const viewedClinicalNote = createAction(
  EventType.CARE_PROVIDER_NOTES_EVENT,
  (
    payload: BaseClinicalNotesEvent & {
      label: CareProviderNotesLabel.NOTE_VIEWED;
    },
  ) => ({
    payload,
  }),
);

/**
 * This action is used to track the duration of a provider's active interaction with a note section. "Active"
 * interaction is when the provider is hands-on interacting with the note section, such as typing or clicking.
 *
 * This action should be dispatched when the provider completes their interaction with the note section, and
 * it will log a new `care_hub_care_provider_notes_interaction_completed` event in Amplitude.
 *
 * @param payload.appointmentId - The appointment ID for the note
 * @param payload.memberId - The member ID for the note
 * @param payload.clinicianId - The clinician ID for the note
 * @param payload.duration - The duration of the provider's active interaction with the note section in seconds
 * @param payload.noteType - The type of note
 * @param payload.section - The section of the note
 */
export const clinicalNoteInteractionCompleted = createAction(
  EventType.CARE_PROVIDER_NOTES_INTERACTION_COMPLETED,
  (payload: {
    appointmentId: string;
    memberId: string | undefined;
    clinicianId: string | null;
    duration: number;
    noteType: NoteType;
    section: ClinicalNoteSection['name'];
  }) => ({ payload }),
);

export const wroteCoachNote = createAction(
  EventType.CARE_PROVIDER_NOTES_EVENT,
  (
    payload: BaseCoachNotesEvent &
      ResponseStatus & {
        label: Omit<
          CareProviderNotesLabel,
          | CareProviderNotesLabel.NOTE_VIEWED
          | CareProviderNotesLabel.NOTE_DELETED
        >;
      },
  ) => ({
    payload,
  }),
);

export const deletedCoachNote = createAction(
  EventType.CARE_PROVIDER_NOTES_EVENT,
  (
    payload: BaseCoachNotesEvent &
      ResponseStatus & {
        label: CareProviderNotesLabel.NOTE_DELETED;
      },
  ) => ({
    payload,
  }),
);

export const viewedCoachNote = createAction(
  EventType.CARE_PROVIDER_NOTES_EVENT,
  (
    payload: BaseCoachNotesEvent & {
      label: CareProviderNotesLabel.NOTE_VIEWED;
      totalNotes: number;
    },
  ) => ({
    payload,
  }),
);

// TODO: Remove this in favor of the more granular events above.
export const careProviderNotesEvent = createAction(
  EventType.CARE_PROVIDER_NOTES_EVENT,
  (payload: {
    memberId?: string;
    clinicianId?: string;
    noteId?: string | null;
    noteType?: CareProviderNoteType;
    label?: CareProviderNotesLabel;
    totalNotes?: number;
    section?: string | null;
    appointmentId?: string | null;
    safetyRiskEndorsed?: boolean | null;
  }) => ({
    payload,
  }),
);
